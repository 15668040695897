export const APPOINTMENT_EDITOR_RESPONSES = {
  classFullyBookedAndCannotAutoIncrease: -23,
  invalidStaffId: -20,
  currentCourseIsHomeroomAndNoAutoOverride: -19,
  outsideYearDateRange: -18,
  invalidCourseId: -17,
  dateIsBlockedForEntireSchool: -16,
  schoolHasFrozenAppointment: -15,
  //commentIsRequired: -14,
  //selfSchedulingIsBlockedForEntireSchool: -13,
  //appointmentCannotBeClearedByStudent: -12,
  //userCannotBeStudent: -11,
  appointmentLockedAndUserCannotOverride: -10,
  //appointmentSlotDoesNotExist: -9,
  appointmentExistsAndNotOverriden: -8,
  appointmentHasBeenCompleted: -7,
  //courseUnavailableForSelfSchedule: -6,
  classFullyBookedCanBeAutoIncrease: -5,
  //studentCannotSelfSchedule: -4,
  //studentCannotModifyHomeroomAppointment: -3,
  dateIsBlocked: -2,
  unknownError: -1,
  appointmentSaved: 1,
  notSavedButOkToTry: 2,
  notInvalidated: 3
}
