import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'

import {
  Button,
  FlexColumn,
  Loading,
  PasswordField,
  ReactHookFormCheckbox
} from '../'

import { passwordRequirementValidationEnums } from '../../../enums'
import {
  useMembershipUpdatePasswordForLoggedOnUserMutation,
  useMembershipUpdatePasswordMutation
} from '../../../hooks'

const SecurityForm = ({
  displayCurrentPassword = false,
  displayWelcomeEmail = true,
  emailAddress,
  postUrl,
  userId,
  userToSchoolId,
  userType
}) => {
  const [validationErrorEnum, setValidationErrorEnum] = useState(null)
  const [loggedOnUserValidationErrorEnum, setLoggedOnUserValidationErrorEnum] =
    useState(null)

  const { control, errors, handleSubmit, watch } = useForm({
    mode: 'onBlur'
  })

  const password = useRef({})
  password.current = watch('password', '')

  const passwordConfirm = useRef({})
  passwordConfirm.current = watch('passwordConfirm', '')

  const passwordWatch = watch('password')
  const currentPasswordWatch = watch('currentPassword')

  const {
    data = { requirementValidationEnum: null },
    isLoading: isUpdatingPassword,
    mutate: updatePassword
  } = useMembershipUpdatePasswordMutation()

  const {
    data: loggedOnUserData = { requirementValidationEnum: null },
    isLoading: isUpdatingPasswordForLoggedOnUser,
    mutate: updatePasswordForLoggedOnUser
  } = useMembershipUpdatePasswordForLoggedOnUserMutation()

  useEffect(() => {
    if (data.requirementValidationEnum) {
      setValidationErrorEnum(data.requirementValidationEnum)
    }
  }, [data.requirementValidationEnum])

  useEffect(() => {
    if (passwordWatch !== '') {
      setValidationErrorEnum(null)
    }
  }, [passwordWatch])

  useEffect(() => {
    if (loggedOnUserData.requirementValidationEnum) {
      setLoggedOnUserValidationErrorEnum(
        loggedOnUserData.requirementValidationEnum
      )
    }
  }, [loggedOnUserData.requirementValidationEnum])

  useEffect(() => {
    if (currentPasswordWatch !== '') {
      setLoggedOnUserValidationErrorEnum(null)
    }
  }, [currentPasswordWatch])

  const onSubmit = (data) => {
    // update password for logged in user from My Account
    if (displayCurrentPassword) {
      updatePasswordForLoggedOnUser({
        data,
        emailAddress,
        userId,
        userToSchoolId,
        userType
      })
    } else {
      // update password from the Manage Staff, Manage Students or Manage Subs
      updatePassword({
        data,
        emailAddress,
        postUrl,
        userId,
        userToSchoolId,
        userType
      })
    }
  }

  if (isUpdatingPassword || isUpdatingPasswordForLoggedOnUser)
    return <Loading text='Saving Password' />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexColumn>
        {displayCurrentPassword && (
          <FormControl
            error={Boolean(
              errors.currentPassword ||
                loggedOnUserValidationErrorEnum ===
                  passwordRequirementValidationEnums.passwordDoesntMatchExistingPassword
            )}>
            <Controller
              as={
                <PasswordField
                  dataTestId='current-password'
                  error={Boolean(errors.currentPassword)}
                  label='Current Password'
                />
              }
              control={control}
              defaultValue=''
              name='currentPassword'
              rules={{
                required: 'Please enter your Current Password'
              }}
            />
            <FormHelperText>
              {errors.currentPassword ? errors.currentPassword.message : null}
              {loggedOnUserValidationErrorEnum ===
                passwordRequirementValidationEnums.passwordDoesntMatchExistingPassword &&
                `The current password doesn't match your existing password.`}
            </FormHelperText>
          </FormControl>
        )}
        <FormControl
          error={Boolean(
            errors.password ||
              validationErrorEnum ===
                passwordRequirementValidationEnums.passwordInCompromisedList
          )}>
          <Controller
            as={
              <PasswordField
                dataTestId='password'
                error={Boolean(errors.password)}
                label='Password'
              />
            }
            control={control}
            defaultValue=''
            name='password'
            rules={{
              required: 'Please enter a Password',
              minLength: {
                value: 12,
                message: 'A Password must have at least 12 characters'
              },
              maxLength: {
                value: 128,
                message: 'A Password cannot exceed 128 characters'
              }
            }}
          />
          <FormHelperText>
            {errors.password ? errors.password.message : null}
            {validationErrorEnum ===
              passwordRequirementValidationEnums.passwordInCompromisedList &&
              'This password was found in a list of compromised passwords. Please use a different password.'}
          </FormHelperText>
        </FormControl>
        <FormControl error={Boolean(errors.passwordConfirm)}>
          <Controller
            as={
              <PasswordField
                dataTestId='confirm-password'
                error={Boolean(errors.passwordConfirm)}
                id='passwordConfirm'
                label='Confirm Password'
              />
            }
            control={control}
            defaultValue=''
            name='passwordConfirm'
            rules={{
              validate: (value) =>
                value === password.current || 'The passwords do not match'
            }}
          />
          <FormHelperText>
            {errors.passwordConfirm ? errors.passwordConfirm.message : null}
          </FormHelperText>
        </FormControl>
        {password.current !== '' &&
          passwordConfirm.current !== '' &&
          password.current === passwordConfirm.current &&
          !errors.password &&
          displayWelcomeEmail && (
            <ReactHookFormCheckbox
              control={control}
              defaultChecked={false}
              label='Send Welcome Email'
              name='sendWelcomeEmail'
            />
          )}
        <Button color='secondary' label='Save' marginTop type='submit' />
      </FlexColumn>
    </form>
  )
}

export default SecurityForm
