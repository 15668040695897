import React from 'react'
import { styled } from '@mui/material/styles'

import { Checkbox, SubheadingContainer, SubheadingText } from '../shared'

const BulkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gridColumn: '1/3',

  [theme.breakpoints.up('xl')]: {
    gridColumn: '1/3'
  }
}))

const Subheading = ({
  handleSelectAllChange,
  isCancellingMultipleRequests,
  selectAll,
  selectedTab
}) => {
  return (
    <SubheadingContainer>
      <BulkContainer>
        {selectedTab === 0 && isCancellingMultipleRequests && (
          <Checkbox
            checked={selectAll}
            dataTestId='select-all-checkbox'
            onChange={handleSelectAllChange}
          />
        )}
        <SubheadingText text='Requested Date' />
      </BulkContainer>
      <SubheadingText
        sx={{ gridColumn: { lg: '3/5' } }}
        text='Requested Period'
      />
      <SubheadingText sx={{ gridColumn: { lg: '5/7' } }} text='Student' />
      <SubheadingText
        sx={{
          gridColumn: { lg: '7/8' },
          textAlign: 'center'
        }}
        text='Comment'
      />
      <SubheadingText
        sx={{ gridColumn: { lg: '8/10' } }}
        text='Date Submitted'
      />
      {selectedTab === 1 && (
        <SubheadingText
          sx={{
            gridColumn: { lg: '10/11' },
            textAlign: 'center'
          }}
          text='Scheduled'
        />
      )}
    </SubheadingContainer>
  )
}

export default Subheading
