import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getTimezonesQueryKey } from '../../../helpers/queryKeys'

export const useTimezoneQuery = () => {
  return useQuery(
    getTimezonesQueryKey(),
    async () => {
      const response = await axios.get(endpoints.TIMEZONE, {
        withCredentials: true
      })

      response.data.forEach((r) => {
        r.description = r.displayName
        r.value = r.timezoneId
      })

      return response.data
    },
    {
      staleTime: 1000 * 60
    }
  )
}
