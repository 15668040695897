import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import moment from 'moment'

import { useParams } from 'react-router-dom'

import { esuUrls } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  useAppointmentsDetailForAttendanceQuery,
  useAppointmentsUpdateAttendanceMutation,
  useAttendanceTypesQuery,
  useConfigurationFreezeAppointmentsQuery,
  useCoteacherWherePrimaryAndSecondaryQuery,
  useHeaderTitle,
  usePeriodsMinimalActiveOnlyQuery,
  useRostersOnlyByStafferQuery,
  useStaffQuery
} from '../../hooks'
import {
  Button,
  LinkButton,
  List,
  Loading,
  PageInstructions,
  Paper,
  PaperTitle,
  SelectField,
  Tabs,
  UserManual
} from '../shared'

import {
  AttendanceListItem,
  AttendanceTypeContainer,
  ButtonAndDrawer,
  ControlsContainer,
  CreateRosterDrawer,
  PaperTitleAndControls,
  PaperTitleContainer,
  SaveContainer,
  SendNotificationDrawer,
  Subheading,
  UserManualAndButtons
} from '../TakeAttendance'

const TakeAttendanceScreen = () => {
  const { attendanceDate } = useParams()

  const isFutureDate = moment().format('YYYY-MM-DD') < attendanceDate
  useHeaderTitle(isFutureDate ? 'View Appointments' : 'Take Attendance')

  const { isAdmin, loggedInUserId } = useLoggedInUser()

  const [hasUpdatedComment, setHasUpdatedComment] = useState(false)
  const [selectedAttendanceTypeForAll, setSelectedAttendanceTypeForAll] =
    useState('')
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedStaffer, setSelectedStaffer] = useState(loggedInUserId)

  const { data: periods = [], isLoading: isLoadingPeriods } =
    usePeriodsMinimalActiveOnlyQuery()
  const { data: attendanceTypes = [], isLoading: isLoadingAttendanceTypes } =
    useAttendanceTypesQuery({
      includeNotSetOption: true
    })

  const { data: freezeAppointments, isLoading: isLoadingConfigurations } =
    useConfigurationFreezeAppointmentsQuery()

  const activeAttendanceTypes = attendanceTypes.filter(
    (attendanceType) => attendanceType.isActive
  )

  const { data: rosters = [], isLoading: isLoadingStudentGroups } =
    useRostersOnlyByStafferQuery({ userId: selectedStaffer })

  const {
    data: appointments = [],
    isFetching: isFetchingAppointmentDetails,
    isLoading: isLoadingAppointmentDetails
  } = useAppointmentsDetailForAttendanceQuery({
    attendanceDate,
    userId: selectedStaffer
  })

  const [appointmentsState, setAppointmentsState] = useState(appointments)

  useEffect(() => {
    if (!isFetchingAppointmentDetails && !isLoadingAppointmentDetails) {
      if (appointments.length > 0) {
        setAppointmentsState(appointments)
      } else {
        setAppointmentsState([])
      }
    }
  }, [appointments, isFetchingAppointmentDetails, isLoadingAppointmentDetails])

  const { data: staffersForAdmin = [], isLoading: isLoadingStaffers } =
    useStaffQuery({ isEnabled: isAdmin })

  const { data: staffersForNonAdmin = [], isLoading: isLoadingCoTeachers } =
    useCoteacherWherePrimaryAndSecondaryQuery({
      enabled: !isAdmin,
      useLoggedInUserDetails: true,
      userId: loggedInUserId
    })

  const { isLoading: isSavingAttendance, mutate: saveAttendanceMutation } =
    useAppointmentsUpdateAttendanceMutation({
      attendanceDate,
      userId: selectedStaffer
    })

  const staffers = isAdmin ? staffersForAdmin : staffersForNonAdmin

  const getTabLabels = () => {
    let labels = []

    periods.forEach((period) => {
      labels.push(period.periodDescription)
    })

    return labels
  }

  const labels = getTabLabels()
  const selectedLabel = labels[selectedTab]
  const selectedPeriodId =
    periods.length > 0 ? periods[selectedTab].periodId : 0

  const appointmentsBySelectedPeriod =
    periods.length > 0
      ? appointmentsState.filter(
          (appointment) => appointment.periodId === selectedPeriodId
        )
      : []

  useEffect(() => {
    if (!hasUpdatedComment) {
      return
    }

    setHasUpdatedComment(false)

    const appointmentsToSave = appointmentsState.filter(
      (appointment) => appointment.periodId === periods[selectedTab].periodId
    )

    saveAttendanceMutation({
      attendanceDetails: appointmentsToSave,
      feedbackMessage: 'Comment Saved'
    })
  }, [
    appointmentsState,
    hasUpdatedComment,
    periods,
    saveAttendanceMutation,
    selectedTab
  ])

  const changeTab = (value) => {
    setSelectedTab(value)
    setSelectedAttendanceTypeForAll('')
  }

  const handleAttendanceTypeChange = (value, appointmentId) => {
    setAppointmentsState(
      appointmentsState.map((appointment) => {
        if (appointment.appointmentId !== appointmentId) return appointment
        return {
          ...appointment,
          currentAttendanceTypeId: value,
          dataIsDirty: true
        }
      })
    )
  }

  const handleAttendanceTypeForAllChange = (value) => {
    setSelectedAttendanceTypeForAll(value)

    let array = appointmentsState

    array.forEach((appointment) => {
      appointmentsBySelectedPeriod.forEach((selected) => {
        if (appointment.appointmentId === selected.appointmentId) {
          appointment.currentAttendanceTypeId = value
          appointment.dataIsDirty = true
        }
      })
    })

    setAppointmentsState([...array])
  }

  const handleTardyChange = (appointmentId) => {
    setAppointmentsState(
      appointmentsState.map((appointment) => {
        if (appointment.appointmentId !== appointmentId) return appointment
        return {
          ...appointment,
          dataIsDirty: true,
          studentWasTardy: !appointment.studentWasTardy
        }
      })
    )
  }

  const handleAttendanceCommentChange = (appointmentId, comment) => {
    setAppointmentsState(
      appointmentsState.map((appointment) => {
        if (appointment.appointmentId !== appointmentId) return appointment
        return { ...appointment, attendanceComment: comment, dataIsDirty: true }
      })
    )

    setHasUpdatedComment(true)
  }

  const removeComment = (appointmentId) => {
    const appointment = appointmentsBySelectedPeriod.find(
      (appointment) => appointment.appointmentId === appointmentId
    )

    appointment.attendanceComment = null
    appointment.dataIsDirty = true

    saveAttendanceMutation({ attendanceDetails: [appointment] })
  }

  const saveAttendance = () => {
    saveAttendanceMutation({
      attendanceDetails: appointmentsBySelectedPeriod,
      feedbackMessage: 'Attendance Taken!'
    })
  }

  if (
    isFetchingAppointmentDetails ||
    isLoadingAppointmentDetails ||
    isLoadingAttendanceTypes ||
    isLoadingConfigurations ||
    isLoadingCoTeachers ||
    isLoadingPeriods ||
    isLoadingStaffers ||
    isLoadingStudentGroups
  )
    return <Loading text='Loading Attendance Details' />
  if (isSavingAttendance) return <Loading text='Adjusting Attendance' />

  return (
    <div>
      <UserManualAndButtons>
        <UserManual href={esuUrls.TAKE_ATTENDANCE} />
        <ControlsContainer>
          {staffers.length > 1 && (
            <SelectField
              sx={{ marginRight: { sm: 1 } }}
              label={isAdmin ? 'Select Staffer' : 'Select Co-Teacher'}
              labelId='select-staffer'
              menuItems={staffers}
              onChange={(e) => setSelectedStaffer(e.target.value)}
              selectedValue={selectedStaffer}
            />
          )}
          {appointmentsBySelectedPeriod.length > 0 && (
            <ButtonAndDrawer>
              {isAdmin && (
                <LinkButton
                  label='Manage Appointments'
                  sx={{ marginRight: 1 }}
                  to={`/admin/manageAppointments/${moment(
                    attendanceDate
                  ).format(
                    'MM-DD-YYYY'
                  )}/${selectedStaffer}/${selectedPeriodId}`}
                />
              )}
              <SendNotificationDrawer
                appointmentDate={attendanceDate}
                appointmentsToNotify={appointmentsBySelectedPeriod}
                periodId={selectedPeriodId}
                periodToNotify={selectedLabel}
              />
              <CreateRosterDrawer
                attendanceDetails={appointmentsBySelectedPeriod}
                periodDescription={selectedLabel}
                rosters={rosters}
                stafferId={selectedStaffer}
              />
            </ButtonAndDrawer>
          )}
        </ControlsContainer>
      </UserManualAndButtons>
      <PageInstructions>
        Use this page to take attendance for student appointments in each
        period. Select the period tab, and then enter an attendance value for
        all students in the period, or individual students. Select tardy and add
        a comment if you like for each student, and be sure to click 'Save'.
      </PageInstructions>
      <Typography sx={{ fontSize: 16, fontWeight: 300, marginBottom: 1 }}>
        {moment(attendanceDate).format('dddd, MMMM Do, YYYY')}
      </Typography>
      <Tabs
        labels={getTabLabels()}
        scrollable
        selectedTab={selectedTab}
        setSelectedTab={changeTab}
      />
      <Paper>
        <PaperTitleAndControls>
          <PaperTitleContainer>
            <PaperTitle>Students</PaperTitle>
          </PaperTitleContainer>
          <AttendanceTypeContainer>
            <SelectField
              disabled={isFutureDate}
              label='Set Attendance For All'
              labelId='set-attendance-for-all'
              menuItems={activeAttendanceTypes}
              onChange={(e) => handleAttendanceTypeForAllChange(e.target.value)}
              selectedValue={selectedAttendanceTypeForAll}
            />
          </AttendanceTypeContainer>
          <SaveContainer>
            <Button
              dataTestId='save-attendance'
              disabled={isFutureDate}
              label='Save'
              onClick={saveAttendance}
            />
          </SaveContainer>
        </PaperTitleAndControls>
        <Subheading />
        <List
          appointments={appointmentsState}
          attendanceDate={attendanceDate}
          attendanceTypes={activeAttendanceTypes}
          Component={AttendanceListItem}
          data={appointmentsBySelectedPeriod}
          emptyArrayMessage='There are no students for this period.'
          freezeAppointments={freezeAppointments}
          handleAttendanceCommentChange={handleAttendanceCommentChange}
          handleAttendanceTypeChange={handleAttendanceTypeChange}
          handleTardyChange={handleTardyChange}
          isFutureDate={isFutureDate}
          keyValue='appointmentId'
          removeComment={removeComment}
          selectedStaffer={selectedStaffer}
        />
      </Paper>
    </div>
  )
}

export default TakeAttendanceScreen
