import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'

import ReactGA from 'react-ga'

import { Layout } from './layout'

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
          marginRight: theme.spacing(),

          width: '100%',

          [theme.breakpoints.up('sm')]: {
            width: 250
          }
        })
      }
    }
  },
  palette: {
    primary: {
      main: '#253686'
    },
    secondary: {
      main: '#EB8003'
    },
    error: { main: '#C90008' },
    neutralLight: { main: '#ddd' },
    success: { main: '#039003', dark: '#036903' }
  }
})

const trackingId = 'UA-856873-5'
ReactGA.initialize(trackingId)

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            style={{ maxWidth: '800px' }}
            maxSnack={5}>
            <CssBaseline />
            <Layout />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
