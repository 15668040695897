import { useEffect, useState } from 'react'

export const useLocalStateFromCachedData = (
  initialState,
  cachedData,
  status
) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (status !== 'success') return

    setState(cachedData)
  }, [cachedData, status])

  return [state, setState]
}
