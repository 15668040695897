import React from 'react'
import { Box, Typography } from '@mui/material'
import {
  PaperTitle,
  SubheadingContainer,
  SubheadingText,
  VirtualList,
  VirtualListRowStudent
} from '../shared'

const BlockedStudents = ({ onChange, students }) => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 81px - 228px)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      }}>
      <PaperTitle sx={{ display: { xs: 'none', sm: 'block' } }}>
        Blocked Students
      </PaperTitle>
      <SubheadingContainer hideMobile={false}>
        <SubheadingText sx={{ gridColumn: '1/11' }} text='Student' />
        <SubheadingText
          sx={{
            gridColumn: '11/13',
            textAlign: 'center'
          }}
          text='Remove'
        />
      </SubheadingContainer>
      {students.length > 0 ? (
        <Box sx={{ display: 'flex', flex: 1, flexGrow: 1 }}>
          <VirtualList
            Component={VirtualListRowStudent}
            data={students}
            dataTestId='blocked-student-item'
            onChange={onChange}
          />
        </Box>
      ) : (
        <Typography>There are no unblocked students.</Typography>
      )}
    </Box>
  )
}

export default BlockedStudents
