import moment from 'moment'

export const getNearestWeekdayFromDate = (date) => {
  let result = date
  const dayOfWeek = moment(date).day()

  if (dayOfWeek === 0) {
    // Sunday
    result = moment(date).add(1, 'days')
  }

  if (dayOfWeek === 6) {
    // Saturday
    result = moment(date).add(2, 'days')
  }

  const rv = moment(result).format()

  return rv
}
