import React, { forwardRef } from 'react'
import { MenuItem } from '@mui/material'
import ExternalLink from './ExternalLink'

/*
Material UI is using forwardRef and so this component needs to account for that
https://stackoverflow.com/questions/62935533/ho-to-fix-react-forwardrefmenu-material-ui
*/
const MenuItemExternalLink = forwardRef(
  ({ children, className, dataTestId, href }, ref) => {
    return (
      <MenuItem ref={ref}>
        <ExternalLink className={className} dataTestId={dataTestId} href={href}>
          {children}
        </ExternalLink>
      </MenuItem>
    )
  }
)

export default MenuItemExternalLink
