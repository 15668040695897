import React, { useRef, useState } from 'react'
import { CircularProgress, MenuItem } from '@mui/material'
import moment from 'moment'

import { useLoggedInUser } from '../../context'
import { useCoursesForDatePeriodStafferQuery } from './hooks'
import { DeleteModal, ListSubheader, Menu, MenuItemLink } from '../shared'

import { useAppointments } from './AppointmentsContext'
import { onSuccessClearAppointments } from './onSuccessClearAppointments'
import { useQueryClient } from 'react-query'
import { appointmentSaveEnums } from '../../enums'

const AppointmentDetailMenu = ({
  adjustedById,
  appointmentDate,
  appointmentId,
  courseId,
  courseName,
  dateIsBlockedForLoggedInUser,
  firstName,
  instructorId,
  isHomeroomCourse,
  isLocked,
  lastName,
  selectedStudentIds,
  setQuickScheduleOpen,
  studentDisplayName,
  userId
}) => {
  const [fetchCourses, setFetchCourses] = useState(false)

  const {
    clearAppointments,
    dateSp,
    getAppointmentsAfterSave,
    maxDateAdjusted,
    numberOfDays,
    periodIdSp,
    saveAppointments,
    searchValueSp,
    staffIdSp,
    studentGroupIdSp
  } = useAppointments()
  const { isAdmin, loggedInUserId } = useLoggedInUser()
  const queryClient = useQueryClient()
  const menuRef = useRef()

  const closeMenu = () => {
    menuRef.current.closeMenuFromParent()
  }

  const {
    data: courses = [],
    isFetching: isFetchingCourses,
    isLoading: isLoadingCourses
  } = useCoursesForDatePeriodStafferQuery({
    appointmentDate,
    enabled: fetchCourses && !dateIsBlockedForLoggedInUser,
    periodId: periodIdSp,
    stafferId: loggedInUserId
  })

  const handleDeleteClick = () => {
    clearAppointments({
      appointmentIds: [appointmentId],
      optionalCallback: () => {
        closeMenu()

        onSuccessClearAppointments({
          appointmentDate,
          appointmentIds: [appointmentId],
          numberOfDaysToRetrieve: numberOfDays,
          periodId: periodIdSp,
          queryClient,
          rosterId: studentGroupIdSp,
          searchTerm: searchValueSp,
          startDate: dateSp,
          studentIds: [userId],
          userId
        })
      }
    })
  }

  const displayDeleteOption = () => {
    if (courseId === 0) {
      return false
    } else if (
      isAdmin ||
      !isLocked ||
      loggedInUserId === adjustedById ||
      loggedInUserId === instructorId
    ) {
      return true
    } else return false
  }

  const scheduleStudentToLoggedInStaffer = (stafferCourseId) => {
    saveAppointments({
      appointmentId,
      courseId: stafferCourseId,
      date: appointmentDate,
      optionalCallback: () => {
        closeMenu()

        getAppointmentsAfterSave({ maxDateAdjusted })
      },
      overrideAppointments: true,
      processThatModifiedAppointment: appointmentSaveEnums.myCoursesSingle,
      periodId: periodIdSp,
      studentIds: [userId],
      students: [
        {
          firstName,
          lastName,
          userId
        }
      ]
    })
  }

  const setupCourseStyles = () => {
    let homeroomCourseStyle
    let subjectMatterCourseStyle

    if (courseId === 0) {
      // Open Appointment Slot
      homeroomCourseStyle = { display: 'flex' }
      subjectMatterCourseStyle = { display: 'flex' }
    } else {
      if (isHomeroomCourse) {
        if (instructorId === loggedInUserId) {
          // display subject matter course only
          homeroomCourseStyle = { display: 'none' }
          subjectMatterCourseStyle = { display: 'flex' }
        } else {
          // display subject matter course and homeroom course
          homeroomCourseStyle = { display: 'flex' }
          subjectMatterCourseStyle = { display: 'flex' }
        }
      } else {
        // don't display the logged in user courses
        homeroomCourseStyle = { display: 'flex' }
        subjectMatterCourseStyle = { display: 'flex' }
      }
    }

    return { homeroomCourseStyle, subjectMatterCourseStyle }
  }

  const styles = setupCourseStyles()

  const canDelete = displayDeleteOption()

  return (
    <Menu
      esButtonClass={courseName ? 'greyButton' : 'greenButton'}
      label={courseName ? 'Actions' : 'Create Appointment'}
      onClick={() => setFetchCourses(true)}
      ref={menuRef}>
      {courses.length > 0 && <ListSubheader>My Courses</ListSubheader>}
      {isFetchingCourses || isLoadingCourses ? (
        <div style={{ marginLeft: 16 }}>
          <CircularProgress data-testid='loading-staffer-courses' />
        </div>
      ) : (
        <div>
          {courses.length === 0 && (
            <MenuItem>
              This student cannot be scheduled to your courses for this date and
              period
            </MenuItem>
          )}
          {courses.map((course) => {
            if (course.isActive)
              return (
                <MenuItem
                  key={course.courseId}
                  onClick={() =>
                    scheduleStudentToLoggedInStaffer(course.courseId)
                  }
                  style={
                    course.isHomeroom
                      ? styles.homeroomCourseStyle
                      : styles.subjectMatterCourseStyle
                  }>
                  {course.courseName} -{' '}
                  {course.maxSeatCount - course.numberOfAppointmentsByStaffer}
                </MenuItem>
              )

            return null
          })}
        </div>
      )}

      <ListSubheader>Other Options</ListSubheader>
      <MenuItemLink
        state={{
          date: moment(dateSp).format('YYYY-MM-DD'),
          periodId: periodIdSp,
          searchValue: searchValueSp,
          staffId: staffIdSp,
          studentGroupId: studentGroupIdSp
        }}
        text='Advanced Appointments'
        to={`/advancedAppointments/${userId}/${moment(appointmentDate).format(
          'YYYY-MM-DD'
        )}/${periodIdSp}/${studentDisplayName}`}
      />
      {canDelete && (
        <DeleteModal
          confirmDeleteCallback={handleDeleteClick}
          confirmDeleteLabel='Delete Appointment'
          confirmDeleteMessage='Are you sure you want to delete this Appointment?'
          itemName={courseName}
          label='Delete Appointment'
        />
      )}
      <MenuItem
        onClick={() => {
          setQuickScheduleOpen(true)
          closeMenu()
        }}>
        Quick Schedule
      </MenuItem>
    </Menu>
  )
}

export default AppointmentDetailMenu
