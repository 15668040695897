import React from 'react'
import Pagination from 'rc-pagination'
import locale from 'rc-pagination/lib/locale/en_US'
import { styled } from '@mui/material/styles'

import '../../assets/pagination.css'

import SelectField from './SelectField'

const StyledPaginationOptions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
}))

const PaginationOptions = ({
  defaultCurrent = 0,
  handlePaginationChange,
  handlePageSizeChange,
  pageSize,
  total
}) => {
  return (
    <StyledPaginationOptions>
      <Pagination
        current={defaultCurrent + 1}
        locale={locale}
        pageSize={pageSize}
        total={total}
        onChange={(e) => handlePaginationChange(e - 1)}
      />
      <SelectField
        formControlSx={{
          marginLeft: 1.5
        }}
        sx={{
          width: '140px !important'
        }}
        label='Number of Rows'
        onChange={(e) => handlePageSizeChange(e.target.value)}
        menuItems={[
          { description: '25', value: 25 },
          { description: '50', value: 50 },
          { description: '100', value: 100 }
        ]}
        selectedValue={pageSize}
      />
    </StyledPaginationOptions>
  )
}

export default PaginationOptions
