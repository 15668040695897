import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getStudentRequestsQueryKey,
  sortByCollatingField
} from '../../../helpers'

export const useAppointmentRequestsQuery = () => {
  return useQuery(
    getStudentRequestsQueryKey(),
    async () => {
      const response = await axios.get(endpoints.APPOINTMENT_REQUESTS, {
        withCredentials: true
      })

      return response.data.sort(sortByCollatingField(false, 'dateRequested'))
    },
    {
      refetchInterval: 1000 * 10 * 6,
      staleTime: 1000 * 30
    }
  )
}
