import React from 'react'
import { Button, Popover } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useAnchorEl } from '../../hooks'

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(),
  paddingLeft: theme.spacing(2),

  maxWidth: 800
}))

const EsPopover = ({
  children,
  content,
  dataTestId,
  shouldDangerouslySetInnerHtml = false,
  sx
}) => {
  const { anchorEl, handleClose, handleOpen, open } = useAnchorEl()

  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        aria-describedby={id}
        data-testid={dataTestId}
        onClick={handleOpen}
        sx={sx}>
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        {shouldDangerouslySetInnerHtml ? (
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <Content>{content}</Content>
        )}
      </Popover>
    </>
  )
}

export default EsPopover
