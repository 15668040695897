import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useAppointmentsBulkScheduleStudentsMutation = ({
  queryKey,
  setStudentsIds,
  students
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ details, resetFormStateOnError }) => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/save`,
        details,
        {
          withCredentials: true
        }
      )

      let hasErrors = false

      response.data.forEach((r) => {
        if (r.appointmentEditorResponse !== 1) {
          hasErrors = true
        }
      })

      return { data: response.data, details, hasErrors, resetFormStateOnError }
    },
    {
      onSuccess: ({ data, details, hasErrors, resetFormStateOnError }) => {
        queryClient.invalidateQueries(['appointments', 'get'])

        if (hasErrors) {
          let studentsCopy = students

          for (let item1 of studentsCopy) {
            for (let item2 of data) {
              if (item1.userId === item2.studentId) {
                item1.errorMessage = item2.errorMessage
              }
            }
          }

          const studentsSavedSuccessfully = studentsCopy.filter((student) => {
            return data.some((d) => {
              return (
                d.studentId === student.userId &&
                d.appointmentEditorResponse === 1
              )
            })
          })

          studentsSavedSuccessfully.forEach((student) => {
            studentsCopy = studentsCopy.filter(
              (sc) => sc.userId !== student.userId
            )
          })

          let studentIdsWithErrors = []
          data.forEach((item) => {
            if (item.errorMessage) {
              studentIdsWithErrors.push(item.studentId)
            }
          })

          queryClient.setQueryData(queryKey, () => {
            return studentsCopy
          })

          setStudentsIds(studentIdsWithErrors)

          resetFormStateOnError()

          enqueueSnackbar(
            'Some students could not be scheduled into the selected course. More details can be found on each Student Item.',
            {
              variant: 'error'
            }
          )
        } else {
          queryClient.setQueryData(queryKey, (prevStudents) => {
            return prevStudents.filter(
              (prevStudent) => !details.studentIds.includes(prevStudent.userId)
            )
          })

          setStudentsIds([])

          enqueueSnackbar('Students scheduled successfully', {
            variant: 'success'
          })
        }
      }
    }
  )
}
