import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import moment from 'moment'
import { Grid } from '@mui/material'

import { BLOCKED_DATE_TYPES, esuUrls } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  useAppointmentsCountsForBlockingDatesQuery,
  useBlockedDatesMutation,
  useDepartmentsQuery,
  useHeaderTitle,
  useLookupValuesRepeatOptionsQuery,
  usePeriodsMinimalActiveOnlyQuery,
  useStaffQuery,
  useStartAndEndDate
} from '../../hooks'
import { Loading, RadioGroup, UserManualWithInternalLink } from '../shared'

import {
  AppointmentCounts,
  CreateBlockedDateForm,
  useExcludedCourses,
  useGetAppointmentsWithCounts,
  useSelectedBlockOption
} from '../CreateBlockedDate'

const CreateBlockedDate = () => {
  useHeaderTitle('Create Blocked Date')
  const { control, errors, handleSubmit, register, reset, setValue, watch } =
    useForm()
  const { isAdmin, loggedInUserId } = useLoggedInUser()
  const [startDate, endDate, updateDates] = useStartAndEndDate()

  const { selectedBlockOption, updateSelectedBlockOptionValue } =
    useSelectedBlockOption()

  const { data: departments, isLoading: isLoadingDepartments } =
    useDepartmentsQuery()
  const { data: periods, isLoading: isLoadingPeriods } =
    usePeriodsMinimalActiveOnlyQuery({
      includeActiveOnly: true
    })
  const { data: repeatOptions, isLoading: isLoadingRepeatOptions } =
    useLookupValuesRepeatOptionsQuery()
  const { data: staffers, isLoading: isLoadingStaffers } = useStaffQuery()

  const { isLoading: isSaving, mutate: createBlockedDate } =
    useBlockedDatesMutation({
      departments,
      loggedInUserId,
      periods,
      reset,
      staffers
    })

  register('saveAndClose', false)

  const selectedDepartment = watch('selectedDepartment')
  const selectedStaffer = watch('selectedStaffer')

  const selectedPeriodsWatch = useWatch({
    control,
    name: 'selectedPeriods',
    defaultValue: []
  })

  const selectedRepeatsOptionWatch = useWatch({
    control,
    name: 'selectedRepeatsOption'
  })

  const selectedWeekdaysWatch = useWatch({
    control,
    name: 'selectedWeekdays',
    defaultValue: []
  })

  const shouldRenderEndDate =
    selectedRepeatsOptionWatch !== -1 &&
    selectedRepeatsOptionWatch !== undefined
      ? true
      : false

  const shouldRenderWeekdayControls =
    selectedRepeatsOptionWatch === 3 || selectedRepeatsOptionWatch === 5
      ? true
      : false

  const {
    data: appointmentCounts = [],
    isLoading: isLoadingAppointmentCounts,
    mutate: getAppointmentsWithCounts
  } = useAppointmentsCountsForBlockingDatesQuery()

  const { excludedCourses, setExcludedCourses, updateExcludedCourses } =
    useExcludedCourses()

  const filterByReference = (arr1, arr2) => {
    let res = []
    res = arr1.filter((el) => {
      return !arr2.find((element) => {
        return (
          moment(element.appointmentDate).format() ===
            moment(el.appointmentDate).format() &&
          parseInt(element.periodId) === parseInt(el.periodId)
        )
      })
    })
    return res
  }

  const isAnyIncludedAppointmentCountAboveZero = () => {
    let response = false

    const includedDatesAndPeriods = filterByReference(
      appointmentCounts,
      excludedCourses
    )

    includedDatesAndPeriods.some(function (el) {
      if (el.appointmentCount > 0) {
        response = true
      }

      return null
    })

    return response
  }

  useGetAppointmentsWithCounts({
    countType: parseInt(selectedBlockOption),
    endDate,
    id:
      parseInt(selectedBlockOption) === BLOCKED_DATE_TYPES.bySchool
        ? 0
        : parseInt(selectedBlockOption) === BLOCKED_DATE_TYPES.byDepartment
        ? selectedDepartment
        : selectedStaffer,
    periodIds: selectedPeriodsWatch,
    repeatOption: selectedRepeatsOptionWatch,
    startDate,
    weekdaysToInclude: selectedWeekdaysWatch,
    getAppointmentsWithCounts,
    selectedPeriodsWatch,
    selectedWeekdaysWatch,
    shouldRenderWeekdayControls
  })

  const setupBlockOptions = () => {
    let blockOptions = [
      { label: 'Staff', value: BLOCKED_DATE_TYPES.byStaff.toString() },
      { label: 'Department', value: BLOCKED_DATE_TYPES.byDepartment.toString() }
    ]

    if (isAdmin) {
      blockOptions.push({
        label: 'School',
        value: BLOCKED_DATE_TYPES.bySchool.toString()
      })
    }

    return blockOptions
  }

  const submitForm = (formValues) => {
    const datesAndPeriods = filterByReference(
      appointmentCounts,
      excludedCourses
    )

    let formattedDatesAndPeriods = []

    datesAndPeriods.forEach((dateAndPeriod) => {
      formattedDatesAndPeriods.push({
        dateToRun: dateAndPeriod.appointmentDate,
        periodId: dateAndPeriod.periodId
      })
    })

    createBlockedDate({
      details: {
        blockedDateType: parseInt(selectedBlockOption),
        blockedReason: formValues.blockedReason,
        datesAndPeriods: formattedDatesAndPeriods,
        departmentId: formValues.selectedDepartment
          ? parseInt(formValues.selectedDepartment)
          : 0,
        stafferId: formValues.selectedStaffer ? formValues.selectedStaffer : 0
      },
      redirectAfterSave: formValues.saveAndClose
    })
  }

  if (
    isLoadingDepartments ||
    isLoadingPeriods ||
    isLoadingRepeatOptions ||
    isLoadingStaffers
  )
    return <Loading text='Loading Details' />
  if (isSaving) return <Loading text='Creating Blocked Date' />

  return (
    <div>
      <UserManualWithInternalLink
        href={esuUrls.CREATE_BLOCKED_DATE}
        label='Back to Block Date'
        to='/blockDate'
      />
      <RadioGroup
        sx={{ display: 'flex', flexDirection: 'row' }}
        data={setupBlockOptions()}
        formLabel='Block For'
        name='selectedBlockOptions'
        onChange={(e) => {
          setExcludedCourses([])
          updateSelectedBlockOptionValue(e)
        }}
        selectedValue={selectedBlockOption}
      />
      <Grid container>
        <CreateBlockedDateForm
          control={control}
          departments={departments}
          endDate={endDate}
          errors={errors}
          handleSubmit={handleSubmit}
          isAnyIncludedAppointmentCountAboveZero={isAnyIncludedAppointmentCountAboveZero()}
          periods={periods}
          register={register}
          repeatOptions={repeatOptions}
          selectedBlockOption={selectedBlockOption}
          selectedPeriodsWatch={selectedPeriodsWatch}
          selectedWeekdaysWatch={selectedWeekdaysWatch}
          setValue={setValue}
          shouldRenderEndDate={shouldRenderEndDate}
          shouldRenderWeekdayControls={shouldRenderWeekdayControls}
          staffers={staffers}
          startDate={startDate}
          submitForm={submitForm}
          updateDates={updateDates}
        />
        <AppointmentCounts
          appointmentCounts={appointmentCounts}
          excludedCourses={excludedCourses}
          isLoadingAppointmentCounts={isLoadingAppointmentCounts}
          periods={periods}
          updateExcludedCourses={updateExcludedCourses}
        />
      </Grid>
    </div>
  )
}

export default CreateBlockedDate
