import React from 'react'
import moment from 'moment'

import { InternalLink, ListItemContainer, ListItemText } from '../shared'

import { useLoggedInUser } from '../../context'

const UpcomingAdjustedCourseItem = ({
  adjustedCourseName,
  adjustedRoom,
  courseAdjustedId,
  courseId,
  isLastItem,
  scheduleDate
}) => {
  const { loggedInUserId } = useLoggedInUser()

  return (
    <ListItemContainer dataTestId='student-item' isLastItem={isLastItem}>
      <ListItemText
        labelText='Date:'
        sx={{ gridColumn: { xs: '1/13', lg: '1/3' } }}>
        {moment(scheduleDate).format('MM/DD/YYYY')}
      </ListItemText>
      <ListItemText
        labelText='Offering Name:'
        sx={{
          gridColumn: { xs: '1/13', lg: '3/11' }
        }}>
        <InternalLink
          text={adjustedCourseName}
          to={`createAdjustedOffering/${loggedInUserId}/${courseAdjustedId}/false`}
        />
      </ListItemText>
      <ListItemText
        labelText='Location:'
        sx={{
          gridColumn: { xs: '1/13', lg: '11/13' }
        }}>
        {adjustedRoom}
      </ListItemText>
    </ListItemContainer>
  )
}

export default UpcomingAdjustedCourseItem
