import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getStudentDisplayName, sortByCollatingField } from '../../../helpers'

export const useStudentsByRosterIdQuery = ({ rosterId }) => {
  return useQuery(
    ['students', 'by-roster-id', { rosterId: parseInt(rosterId) }],
    async () => {
      const response = await axios.get(
        `${endpoints.STUDENTS}/byrosterid/${rosterId}`,
        { withCredentials: true }
      )

      response.data.forEach((r) => {
        r.displayName = getStudentDisplayName(
          r.firstName,
          r.lastName,
          r.nickname
        )
      })

      return response.data.sort(sortByCollatingField(true, 'displayName'))
    },
    {
      enabled: !!rosterId,
      staleTime: 1000 * 30
    }
  )
}
