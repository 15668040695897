import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const fetchStudentGroupDetails = async (rosterId) => {
  const response = await axios.get(
    `${endpoints.ROSTERS}/byrosterid/${rosterId}`,
    {
      withCredentials: true
    }
  )

  return response.data
}

export const useRosterByIdQuery = (rosterId) => {
  return useQuery(
    ['rosters', 'by-roster-id', { rosterId: parseInt(rosterId) }],
    () => fetchStudentGroupDetails(rosterId),
    {
      enabled: parseInt(rosterId) !== 0,
      staleTime: 1000 * 30
    }
  )
}
