import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useDeleteStudentFromStudentGroupMutation = ({ staffId }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ rosterId, studentId, rosteredId, lastCommaFirst, rosterName }) => {
      await axios.delete(`${endpoints.ROSTERS}/rosteredstudent/${rosteredId}`, {
        withCredentials: true
      })

      return { lastCommaFirst, rosterId, rosterName, studentId }
    },
    {
      onSuccess: ({ lastCommaFirst, rosterId, rosterName, studentId }) => {
        enqueueSnackbar(
          `${lastCommaFirst} has been removed from ${rosterName}`,
          {
            variant: 'success'
          }
        )

        queryClient.setQueryData(
          ['rosters', 'by-staff-id', { staffId: parseInt(staffId) }],
          (prevStudentGroups) => {
            return [
              ...prevStudentGroups.map((group) => {
                if (group.rosterId !== rosterId) return group
                return {
                  ...group,
                  students: [
                    ...group.students.filter(
                      (student) => student.studentId !== studentId
                    )
                  ]
                }
              })
            ]
          }
        )
      }
    }
  )
}
