import React from 'react'

import { getStudentDisplayName } from '../../helpers'
import { Checkbox, ListItemContainer, ListItemText } from '../shared'

const StudentItem = ({
  firstName,
  isExcluded,
  isLastItem,
  lastName,
  nickname,
  updateExcludedStudentIds,
  userId
}) => {
  const fullName = getStudentDisplayName(firstName, lastName, nickname)

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '1/5' } }}
        displayColumn
        labelText='Name:'>
        {fullName}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '5/7' },
          textAlign: { lg: 'center' }
        }}
        labelText='Exclude:'>
        <Checkbox
          checked={isExcluded}
          onChange={(e) => updateExcludedStudentIds(e.target.checked, userId)}
        />
      </ListItemText>
    </ListItemContainer>
  )
}

export default StudentItem
