import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortArrayByTwoFields } from '../../../helpers'

export const useRostersAllBySchoolQuery = () => {
  return useQuery(['rosters', 'all-by-school'], async () => {
    const response = await axios.get(`${endpoints.ROSTERS}/allbyschool`, {
      withCredentials: true
    })

    response.data.forEach((item) => {
      item.staffDisplayName = item.staffLastName + ', ' + item.staffFirstName
    })

    return sortArrayByTwoFields(response.data, 'rosterName', 'staffDisplayName')
  })
}
