import { styled } from '@mui/material/styles'

const StudentNameContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  left: 0,
  backgroundColor: '#d9d9d9',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  paddingRight: theme.spacing(),
  paddingLeft: theme.spacing(),

  borderRadius: '4px 4px 0 0',
  boxShadow: theme.shadows[1]
}))

export default StudentNameContainer
