import React from 'react'

import { getParsedValueFromStringifiedObject } from '../../helpers'
import { SearchField } from '../shared'

import { useAppointments } from './AppointmentsContext'
import CalendarButton from './CalendarButton'
import ControlsActionsMenu from './ControlsActionsMenu'
import {
  CalendarContainer,
  ControlInput,
  ControlsContainer,
  Item1,
  Item2,
  Item3,
  Item4
} from './styledComponents'

const ControlsContent = () => {
  const {
    emailStudentSchedule,
    isSubstitute,
    pageSettings,
    periodIdSp,
    periods,
    searchValueSp,
    staffers,
    staffIdSp,
    studentGroupIdSp,
    studentGroups,
    updateSearchParamsByNameValue
  } = useAppointments()

  const setupSelectedStudentGroup = () => {
    if (searchValueSp !== null) return ''
    if (studentGroupIdSp !== null) return studentGroupIdSp

    if (studentGroups.length === 0) return ''

    const defaultStudentGroup = studentGroups.find((group) => group.isDefault)
    if (defaultStudentGroup === undefined) return ''

    return defaultStudentGroup.rosterId
  }

  const setupSearchValue = () => {
    if (studentGroupIdSp !== null) return ''
    if (searchValueSp !== null) return searchValueSp
  }

  return (
    <ControlsContainer>
      <CalendarContainer>
        <CalendarButton />
      </CalendarContainer>
      <Item1>
        <ControlInput
          dataTestId='selected-period-input'
          label='Select Period'
          menuItems={periods}
          name='periodId'
          onChange={updateSearchParamsByNameValue}
          selectedValue={
            periodIdSp === null ? '' : periods.length > 0 ? periodIdSp : ''
          }
        />
      </Item1>
      {isSubstitute ? null : (
        <Item2>
          {getParsedValueFromStringifiedObject(
            'showSelectedStaffer',
            pageSettings
          ) && (
            <ControlInput
              dataTestId='selected-staffer-input'
              label='Select Staffer'
              menuItems={staffers}
              name='staffId'
              onChange={updateSearchParamsByNameValue}
              selectedValue={staffers.length > 1 ? staffIdSp : ''}
            />
          )}
        </Item2>
      )}
      <Item3 isSubstitute={isSubstitute}>
        <ControlInput
          dataTestId='selected-roster-input'
          label='Select Group'
          menuItems={studentGroups}
          name='studentGroupId'
          onChange={updateSearchParamsByNameValue}
          selectedValue={setupSelectedStudentGroup()}
        />
      </Item3>
      <Item4 isSubstitute={isSubstitute}>
        {getParsedValueFromStringifiedObject(
          'showSearchByName',
          pageSettings
        ) && (
          <SearchField
            sx={{
              width: { sm: 170, lg: 250 },
              marginRight: { sm: 1 }
            }}
            handleSearch={(value) =>
              updateSearchParamsByNameValue({
                target: { name: 'searchValue', value }
              })
            }
            initialValue={setupSearchValue()}
          />
        )}
      </Item4>
      <ControlsActionsMenu
        emailStudentSchedule={emailStudentSchedule}
        hideXsDown={true}
        isSubstitute={isSubstitute}
      />
    </ControlsContainer>
  )
}

export default ControlsContent
