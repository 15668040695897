import { useMutation } from 'react-query'
import axios from 'axios'
import { useCallback, useState } from 'react'

import { endpoints } from '../../../constants'

export const useCourseList = () => {
  const [courseList, setCourseList] = useState([])

  const selectedCourses = courseList.filter((course) => course.isSelected)

  const getLowestNumberOfAppointmentsForSelectedCourses = () => {
    if (selectedCourses.length < 1) return 0

    let array = []

    selectedCourses.forEach((course) => {
      array.push(course.numberOfAppointmentsByStaffer)
    })

    array.sort((a, b) => b - a)

    return array[0]
  }

  const { isFetching, isLoading, mutate } = useMutation(
    async ({
      endDate,
      periodIds,
      repeatOptions,
      stafferId,
      startDate,
      weekDaysToInclude
    }) => {
      const response = await axios.post(
        `${endpoints.COURSES}/withadjustments`,
        {
          endDate,
          periodIds,
          repeatOptions,
          stafferId,
          startDate,
          weekDaysToInclude
        },
        { withCredentials: true }
      )

      response.data.forEach((r) => {
        r.isSelected = true
      })

      setCourseList(response.data)
    }
  )

  const getCourses = useCallback(
    ({
      endDate,
      periodIds,
      repeatOptions,
      stafferId,
      startDate,
      weekDaysToInclude
    }) => {
      mutate({
        endDate,
        periodIds,
        repeatOptions,
        stafferId,
        startDate,
        weekDaysToInclude
      })
    },
    [mutate]
  )

  const updateCourseList = (date, periodId, checked) => {
    setCourseList(
      courseList.map((course) => {
        if (course.appointmentDate === date && course.periodId === periodId)
          return { ...course, isSelected: checked }
        return course
      })
    )
  }

  return {
    courseList,
    getCourses,
    getLowestNumberOfAppointmentsForSelectedCourses,
    isLoadingCourses: isFetching || isLoading,
    selectedCourses,
    updateCourseList
  }
}
