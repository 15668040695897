import React from 'react'
import { Typography } from '@mui/material'
import { Chat } from '@mui/icons-material'

import { Button, Popover } from '../shared'

import { useAppointments } from './AppointmentsContext'
import {
  RequestItemInfo,
  RequestItemContainer,
  RequestItemLabel
} from './styledComponents'

const RequestItem = ({
  // DTO Props
  appointmentMessage,
  courseId,
  courseName,
  dateRequested,
  periodId,
  stafferFirstName,
  stafferLastName,
  studentFirstName,
  studentLastName,
  studentSisId,
  userId,

  // Other
  isLastItem,
  setOpen
}) => {
  const { getAppointmentsAfterSave, maxDateAdjusted, saveAppointments } =
    useAppointments()

  const onScheduleClick = () => {
    saveAppointments({
      appointmentId: 0,
      courseId,
      date: dateRequested,
      isLocked: false,
      optionalCallback: () => {
        setOpen(false)
        getAppointmentsAfterSave({ maxDateAdjusted })
      },
      overrideAppointments: false,
      periodId,
      schedulerComment: '',
      studentIds: [userId],
      students: [
        {
          firstName: studentFirstName,
          lastName: studentLastName,
          userId: parseInt(studentSisId)
        }
      ]
    })
  }

  return (
    <RequestItemContainer isLastItem={isLastItem}>
      <RequestItemInfo>
        <RequestItemLabel>Course:&nbsp;</RequestItemLabel>
        <Typography>{courseName}</Typography>
      </RequestItemInfo>
      <RequestItemInfo>
        <RequestItemLabel>Instructor:&nbsp;</RequestItemLabel>
        <Typography>
          {stafferLastName}, {stafferFirstName}
        </Typography>
      </RequestItemInfo>
      {appointmentMessage && (
        <RequestItemInfo>
          <RequestItemLabel>Comment:&nbsp;</RequestItemLabel>
          <Popover content={appointmentMessage}>
            <Chat />
          </Popover>
        </RequestItemInfo>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button label='Schedule' onClick={onScheduleClick} />
      </div>
    </RequestItemContainer>
  )
}

export default RequestItem
