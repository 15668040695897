import { useState } from 'react'
import moment from 'moment'

const DATE_CONSTANTS = {
  endDate: 'endDate',
  startDate: 'startDate'
}

/*
  How to use:
  const [startDate, endDate, updateDates] = useStartAndEndDate()

  <DatePicker
    label='Test DatePicker'
    onChange={(v) => updateDates(v, 'startDate')}
    value={startDate}
  />
*/

const setCorrectStartDate = (startDate) => {
  // If initialStartDate is a Sunday, advance by 1 day
  if (moment(startDate).isoWeekday() === 7) {
    return moment(startDate).add(1, 'days').format()
  }

  // If initialStartDate is a Saturday, advance by 1 day
  if (moment(startDate).isoWeekday() === 6) {
    return moment(startDate).add(2, 'days').format()
  }

  return startDate
}

const setCorrectEndDate = (endDate) => {
  // If initialEndDate is a Sunday, advance by 1 day
  if (moment(endDate).isoWeekday() === 7) {
    return moment(endDate).add(1, 'days').format()
  }

  // If initialEndDate is a Saturday, advance by 1 day
  if (moment(endDate).isoWeekday() === 6) {
    return moment(endDate).add(2, 'days').format()
  }

  return endDate
}

const useStartAndEndDate = (
  initialStartDate = moment(),
  initialEndDate = moment()
) => {
  const [startDate, setStartDate] = useState(() =>
    setCorrectStartDate(initialStartDate)
  )
  const [endDate, setEndDate] = useState(() =>
    setCorrectEndDate(initialEndDate)
  )

  const [isoWeekdays, setIsoWeekdays] = useState(() => [
    moment(setCorrectStartDate(initialStartDate)).isoWeekday()
  ])

  const reducedWeekdays = isoWeekdays.reduce((unique, item) => {
    return unique.includes(item) ? unique : [...unique, item]
  }, [])

  const updateDates = (newDate, valueChanged) => {
    if (valueChanged === 'endDate') {
      if (moment(newDate).format() < moment(startDate).format()) {
        setStartDate(newDate)
        setEndDate(newDate)
        setIsoWeekdays([moment(newDate).isoWeekday()])
      } else {
        setEndDate(newDate)
        setIsoWeekdays([
          moment(newDate).isoWeekday(),
          moment(startDate).isoWeekday()
        ])
      }
    }

    if (valueChanged === 'startDate') {
      if (moment(newDate).format() > moment(endDate).format()) {
        setStartDate(newDate)
        setEndDate(newDate)
        setIsoWeekdays([moment(newDate).isoWeekday()])
      } else {
        setStartDate(newDate)
        setIsoWeekdays([
          moment(newDate).isoWeekday(),
          moment(endDate).isoWeekday()
        ])
      }
    }
  }

  return [
    startDate,
    endDate,
    updateDates,
    DATE_CONSTANTS,
    reducedWeekdays,
    setIsoWeekdays
  ]
}

export default useStartAndEndDate
