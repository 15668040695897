import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { esuUrls, headerHeight } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  useHeaderTitle,
  useStaffQuery,
  useRostersByStaffIdQuery
} from '../../hooks'
import {
  LinkButton,
  Loading,
  PageInstructions,
  SelectField,
  UserManual
} from '../shared'

import {
  Roster,
  useDeleteStudentGroup,
  useDeleteStudentFromStudentGroupMutation,
  ScrollToMenu
} from '../ManageRosters'

const SelectFieldAndLinkButton = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const UserManualAndOptions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const ManageRosters = () => {
  useHeaderTitle('Manage Student Groups')

  const { isAdmin, loggedInUserId } = useLoggedInUser()
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedStaffId =
    searchParams.get('selectedStaffId') === null
      ? loggedInUserId
      : searchParams.get('selectedStaffId')

  const { data: staffers = [], isLoading: isLoadingStaffers } = useStaffQuery()
  const { data: rosters = [], isLoading: isLoadingStudentGroups } =
    useRostersByStaffIdQuery({ staffId: selectedStaffId })

  const rostersWithStudents = rosters.filter((r) => r.students.length > 0)

  const refs =
    rostersWithStudents.length < 1
      ? []
      : rostersWithStudents.reduce((acc, value) => {
          acc[value.rosterId] = React.createRef()
          return acc
        }, {})

  const { isLoading: isDeletingStudentGroup, mutate: deleteStudentGroup } =
    useDeleteStudentGroup({ staffId: selectedStaffId })

  const { isLoading: isDeletingStudent, mutate: deleteStudent } =
    useDeleteStudentFromStudentGroupMutation({ staffId: selectedStaffId })

  const deleteRoster = (rosterId) => {
    const selectedRoster = rosters.find(
      (roster) => roster.rosterId === rosterId
    )

    deleteStudentGroup({ rosterId, selectedRoster })
  }

  const deleteStudentFromRoster = (
    rosterId,
    studentId,
    rosteredId,
    lastCommaFirst,
    rosterName
  ) => {
    deleteStudent({
      rosterId,
      studentId,
      rosteredId,
      lastCommaFirst,
      rosterName
    })
  }

  const handleStaffChange = (e) => {
    searchParams.set('selectedStaffId', e.target.value)
    setSearchParams(searchParams)
  }

  const scrollToRoster = (rosterId) => {
    refs[rosterId].current.style.scrollMargin = headerHeight

    refs[rosterId].current.scrollIntoView({
      behavior: 'smooth'
    })
  }

  if (isLoadingStaffers || isLoadingStudentGroups)
    return <Loading text='Loading Student Groups' />
  if (isDeletingStudent || isDeletingStudentGroup)
    return <Loading text='Adjusting Student Groups' />

  return (
    <div>
      <UserManualAndOptions>
        <UserManual href={esuUrls.MANAGE_ROSTERS} />
        <SelectFieldAndLinkButton>
          {isAdmin && (
            <SelectField
              sx={{
                marginBottom: 1,
                marginRight: { sm: 1 }
              }}
              labelId='get-roster-for-staffer'
              label='Get Groups For Staffer'
              menuItems={staffers}
              onChange={handleStaffChange}
              selectedValue={selectedStaffId}
            />
          )}
          <LinkButton
            label='Create Group'
            state={{
              selectedStaffId
            }}
            to={`/createRoster/0/${selectedStaffId}`}
          />
        </SelectFieldAndLinkButton>
      </UserManualAndOptions>
      <PageInstructions>
        {isAdmin
          ? 'Use this page to create, edit, or delete groups of students assigned to you, or another staffer. You will be able to select these groups on the Appointments page to schedule them.'
          : 'Use this page to create, edit, or delete groups of students assigned to you. You will be able to select these groups on the Appointments page to schedule them.'}
      </PageInstructions>
      <ScrollToMenu
        rosters={rostersWithStudents}
        scrollToRoster={scrollToRoster}
      />
      {rostersWithStudents.map((roster) => {
        return (
          <Roster
            deleteRoster={deleteRoster}
            deleteStudentFromRoster={deleteStudentFromRoster}
            key={roster.rosterId}
            ref={refs[roster.rosterId]}
            selectedStaffer={selectedStaffId}
            {...roster}
          />
        )
      })}
    </div>
  )
}

export default ManageRosters
