import React from 'react'
import { Typography } from '@mui/material'
import { Person, PersonAdd, PersonOutline } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  marginBottom: theme.spacing(),

  [theme.breakpoints.up('lg')]: {
    marginBottom: 0
  }
}))

const LegendItem = styled('div')(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing()
  }
}))

const SoftDeletedUser = styled(PersonOutline)(({ theme }) => ({
  color: 'grey'
}))

const Legend = () => {
  return (
    <Container>
      <LegendItem>
        <Person />
        <Typography>= Staffer</Typography>
      </LegendItem>
      <LegendItem>
        <PersonAdd />
        <Typography>= Admin</Typography>
      </LegendItem>
      <LegendItem>
        <SoftDeletedUser />
        <Typography>= Soft-Deleted User</Typography>
      </LegendItem>
    </Container>
  )
}

export default Legend
