import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useCoteacherDeleteMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ coTeacherId, selectedStafferDetails, userId }) => {
      await axios.delete(
        `${endpoints.COTEACHER}/delete/${userId}/${coTeacherId}`,
        {
          withCredentials: true
        }
      )

      return { coTeacherId, selectedStafferDetails, userId }
    },
    {
      onSuccess: ({ coTeacherId, selectedStafferDetails, userId }) => {
        enqueueSnackbar(
          `${selectedStafferDetails.lastName}, ${selectedStafferDetails.firstName} has been removed as a Co-Teacher`,
          {
            variant: 'success'
          }
        )

        queryClient.setQueryData(
          [
            'coteacher',
            'where-primary-and-secondary',
            { useLoggedInUserDetails: false, userId: parseInt(userId) }
          ],
          (prevCoTeachers) => {
            return [
              ...prevCoTeachers.filter(
                (teacher) => teacher.userId !== coTeacherId
              )
            ]
          }
        )
      }
    }
  )
}
