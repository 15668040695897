import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStudentContactsQueryKey } from '../../../helpers'

export const useStudentContactsSaveMutation = ({ resetContactState }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ endUserStudentContactId, formData, studentId }) => {
      const response = await axios.post(
        `${endpoints.STUDENT_CONTACTS}/save`,
        {
          ...formData,
          endUserStudentContactId,
          studentId: parseInt(studentId)
        },
        { withCredentials: true }
      )

      return {
        data: response.data,
        endUserStudentContactId,
        formData,
        studentId
      }
    },
    {
      onSuccess: ({ data, endUserStudentContactId, formData, studentId }) => {
        enqueueSnackbar(
          endUserStudentContactId === 0
            ? `${formData.lastName}, ${formData.firstName} has been added as an Emergency Contact`
            : `${formData.lastName}, ${formData.firstName} has been edited`,
          {
            variant: 'success'
          }
        )

        resetContactState()

        const isNewRecord = endUserStudentContactId === 0

        const contactInfo = {
          ...formData,
          endUserStudentContactId:
            endUserStudentContactId === 0 ? data : endUserStudentContactId,
          isNewRecord: endUserStudentContactId === 0 ? true : false,
          studentId
        }

        if (isNewRecord) {
          queryClient.setQueryData(
            getStudentContactsQueryKey(studentId),
            (prevStudentContacts) => {
              return [...prevStudentContacts, contactInfo]
            }
          )
        }

        if (!isNewRecord) {
          queryClient.setQueriesData(
            getStudentContactsQueryKey(studentId),
            (prevStudentContacts) => {
              return [
                ...prevStudentContacts.map((contact) => {
                  if (
                    contact.endUserStudentContactId !==
                    contactInfo.endUserStudentContactId
                  )
                    return contact
                  return { ...contact, ...contactInfo }
                })
              ]
            }
          )
        }
      }
    }
  )
}
