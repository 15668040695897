import { useMutation } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { appointmentSaveEnums } from '../../../enums'

/* This API request is called from mulitple locations with very different onSuccess outcomes */
export const useAppointmentsClearByIdMutation = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ appointmentIds, optionalCallback = () => {} }) => {
      await axios.post(
        `${endpoints.APPOINTMENTS}/clearbyid`,
        {
          appointmentIds,
          processThatModifiedAppointment:
            appointmentIds.length > 1
              ? appointmentSaveEnums.deleteAppointmentsGroup
              : appointmentSaveEnums.deleteAppointmentsSingle
        },
        { withCredentials: true }
      )

      return { appointmentIds, optionalCallback }
    },
    {
      onError: () => {
        enqueueSnackbar(
          'The Appointments are frozen for today and could not be deleted.',
          {
            variant: 'error'
          }
        )
      },
      onSuccess: ({ appointmentIds, optionalCallback }) => {
        optionalCallback(appointmentIds)
      }
    }
  )
}
