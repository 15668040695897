import React, { useRef } from 'react'
import moment from 'moment'

import { DeleteModal, ListItemContainer, ListItemText, Menu } from '../shared'

import StaffDrawer from './StaffDrawer'

const BlockDateItem = ({
  isAdmin,
  blockedDateDetailId,
  blockedDateId,
  blockedReason,
  blockType,
  deleteBlock,
  deleteSeries,
  isInSeries,
  onlyAdminsCanBlock,
  periodDescription,
  selectedDepartment,
  selectedTab,
  scheduleDate
}) => {
  const drawerRef = useRef()
  const menuRef = useRef()

  const formattedDate = moment(scheduleDate).format('MM/DD/YYYY')

  const closeMenu = () => {
    menuRef.current.closeMenuFromParent()
  }

  return (
    <ListItemContainer dataTestId='block-date-item'>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/3' }
        }}
        labelText='Date:'>
        {formattedDate}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '3/7' }
        }}
        labelText='Reason:'>
        {blockedReason}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '7/10' }
        }}
        labelText='Period:'>
        {periodDescription}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '10/12' }
        }}
        labelText='Block Type:'>
        {blockType}
      </ListItemText>
      {/* Staffer has menu on Blocked For Staff or Blocked For Department ONLY if they onlyAdminsCanBlock permissions */}
      {((selectedTab !== 2 && !onlyAdminsCanBlock) || isAdmin) && (
        <Menu ref={menuRef}>
          {(blockType === 'Department' || selectedTab === 1) && (
            <StaffDrawer
              blockedDateDetailId={blockedDateDetailId}
              blockedReason={blockedReason}
              closeParentMenu={closeMenu}
              departmentId={selectedDepartment}
              ref={drawerRef}
              scheduleDate={formattedDate}
            />
          )}
          <DeleteModal
            confirmDeleteCallback={() =>
              deleteBlock({ blockedDateId, blockedDateDetailId })
            }
            confirmDeleteLabel='Remove Block'
            confirmDeleteMessage={`You are about to delete: ${blockedReason} on ${moment(
              scheduleDate
            ).format('MM/DD/YYYY')} for period ${periodDescription}`}
            itemName={blockedReason}
            label={`Remove Block For ${blockType}`}
          />
          {isInSeries && (
            <DeleteModal
              confirmDeleteCallback={() => deleteSeries({ blockedDateId })}
              confirmDeleteLabel='Remove Block'
              confirmDeleteMessage='Are you sure you want to remove this blocked series?'
              itemName={blockedReason}
              label='Remove Blocked Date Series'
            />
          )}
        </Menu>
      )}
    </ListItemContainer>
  )
}

export default BlockDateItem
