import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStudentRequestsQueryKey } from '../../../helpers'

export const useAppointmentRequestsMarkCancelledMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ appointmentRequestId }) => {
      await axios.post(
        `${endpoints.APPOINTMENT_REQUESTS}/markcancelled`,
        { appointmentRequestId },
        { withCredentials: true }
      )

      return { appointmentRequestId }
    },
    {
      onSuccess: ({ appointmentRequestId }) => {
        enqueueSnackbar('The Appointment Request has been cancelled', {
          variant: 'success'
        })

        queryClient.setQueryData(
          getStudentRequestsQueryKey(),
          (prevAppointmentRequests) => {
            return prevAppointmentRequests.filter(
              (request) => request.appointmentRequestId !== appointmentRequestId
            )
          }
        )
      }
    }
  )
}
