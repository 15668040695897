import React from 'react'

import { esuUrls } from '../../constants'
import { useHeaderTitle } from '../../hooks'
import { Loading, UserManual } from '../shared'

import { useAppointments } from './AppointmentsContext'
import AppointmentsList from './AppointmentsList'
import Controls from './Controls'
import PageSettingsMenu from './PageSettingsMenu'

const AppointmentsMain = () => {
  const { isClearingAppointments, isLoading, isSaving, isSubstitute } =
    useAppointments()
  useHeaderTitle('Appointments')

  return (
    <div>
      {isClearingAppointments && <Loading text='Clearing Appointments' />}
      {isLoading && <Loading text='Loading Appointments' />}
      {isSaving && <Loading text='Saving Appointments' />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}>
        <UserManual
          href={isSubstitute ? esuUrls.APPOINTMENTS_SUB : esuUrls.APPOINTMENTS}
        />
        <PageSettingsMenu />
      </div>
      <Controls />
      <AppointmentsList />
    </div>
  )
}

export default AppointmentsMain
