import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStaffersByDepartmentIdQueryKey } from '../../../helpers/queryKeys'

export const useStaffMoveStaffMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ currentDepartmentId, newDepartmentId, staffIds }) => {
      await axios.post(
        `${endpoints.STAFF}/movestaff`,
        {
          departmentId: newDepartmentId,
          staffIds
        },
        {
          withCredentials: true
        }
      )

      return { currentDepartmentId, newDepartmentId, staffIds }
    },
    {
      onSuccess: ({ currentDepartmentId, newDepartmentId, staffIds }) => {
        enqueueSnackbar(`Staffers have been moved`, {
          variant: 'success'
        })

        staffIds.forEach((staffId) => {
          // remove users from list of staff by currentDepartmentId
          queryClient.setQueryData(
            getStaffersByDepartmentIdQueryKey(currentDepartmentId),
            (prevStaff) => {
              return [...prevStaff.filter((staff) => staff.userId !== staffId)]
            }
          )
        })

        // subtract number of staffIds from current department
        // add number of staffIds to newDepartment number of users
        queryClient.setQueryData(
          [
            'departments',
            {
              includeInactive: true,
              includeUnassigned: true,
              withCounts: true
            }
          ],
          (prevDepartments) => {
            return [
              ...prevDepartments.map((department) => {
                if (
                  department.departmentId !== currentDepartmentId &&
                  department.departmentId !== newDepartmentId
                )
                  return department

                if (department.departmentId === currentDepartmentId) {
                  return {
                    ...department,
                    numberOfUsers: department.numberOfUsers - staffIds.length
                  }
                }

                return {
                  ...department,
                  numberOfUsers: department.numberOfUsers + staffIds.length
                }
              })
            ]
          }
        )

        // invalidate new department info in cache
        queryClient.invalidateQueries(
          getStaffersByDepartmentIdQueryKey(newDepartmentId)
        )
      }
    }
  )
}
