import moment from 'moment'
import { useState } from 'react'

export const useExcludedCourses = () => {
  const [excludedCourses, setExcludedCourses] = useState([])

  const updateExcludedCourses = ({ appointmentDate, checked, periodId }) => {
    if (!checked) {
      setExcludedCourses([...excludedCourses, { appointmentDate, periodId }])
    } else {
      setExcludedCourses(
        excludedCourses.filter(
          (course) =>
            moment(course.appointmentDate).format() !==
              moment(appointmentDate).format() ||
            parseInt(course.periodId) !== parseInt(periodId)
        )
      )
    }
  }

  return { excludedCourses, setExcludedCourses, updateExcludedCourses }
}
