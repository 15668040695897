import React from 'react'
import { Box, Typography } from '@mui/material'

const AppointmentDetailInfo = ({ description, label }) => {
  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Typography
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: 15
        }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: 14 }}>{description}</Typography>
    </Box>
  )
}

export default AppointmentDetailInfo
