import React from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { styled } from '@mui/material/styles'

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: 250,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.only('xs')]: {
    width: '100%'
  }
}))

function YearPicker({
  label,
  maxDate,
  minDate,
  onChange,
  value,
  ...otherProps
}) {
  return (
    <StyledDatePicker
      label={label}
      minDate={minDate && moment().set({ year: minDate })}
      maxDate={maxDate && moment().set({ year: maxDate })}
      onChange={(e) => onChange(moment(e).format('YYYY'))}
      value={moment().set({ year: value })}
      views={['year']}
      {...otherProps}
    />
  )
}

export default YearPicker
