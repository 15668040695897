import React, { useState } from 'react'
import { Collapse } from '@mui/material'

import { LabeledCheckbox, ResponsiveFabButton, SelectField } from '../shared'

const MENU_ITEMS = [
  { description: '(select)', value: -10 },
  {
    description: 'Yes',
    value: true
  },
  {
    description: 'No',
    value: false
  }
]

const BulkEdit = ({
  selectedStudentIds,
  bulkEditing,
  handleSelectAllChange,
  selectAll,
  staffers,
  saveBulkEditChanges
}) => {
  const [state, setState] = useState({
    selectedHomeroomStaffer: '',
    selectedOverwriteOption: '',
    selectedSelfScheduleOption: ''
  })

  const {
    selectedHomeroomStaffer,
    selectedOverwriteOption,
    selectedSelfScheduleOption
  } = state

  const save = () => {
    const object = {
      canScheduleSelf:
        selectedSelfScheduleOption === '' || selectedSelfScheduleOption === -10
          ? '-10'
          : selectedSelfScheduleOption.toString(),
      canOverrideAppointments:
        selectedOverwriteOption === '' || selectedOverwriteOption === -10
          ? '-10'
          : selectedOverwriteOption.toString(),
      homeroomStafferId:
        selectedHomeroomStaffer === '' || selectedHomeroomStaffer === -10
          ? -10
          : selectedHomeroomStaffer,
      studentIds: selectedStudentIds
    }

    saveBulkEditChanges({
      object,
      selectedHomeroomStaffer,
      selectedSelfScheduleOption,
      selectedOverwriteOption
    })
  }

  const updateState = (e) => {
    const { name, value } = e.target

    setState({ ...state, [name]: value })
  }

  return (
    <Collapse in={bulkEditing} timeout='auto' unmountOnExit>
      <div>
        <SelectField
          sx={{ marginRight: 1 }}
          label='Homebase Staffer'
          onChange={updateState}
          menuItems={staffers}
          name='selectedHomeroomStaffer'
          selectedValue={selectedHomeroomStaffer}
        />
        <SelectField
          label='Can Schedule Self'
          onChange={updateState}
          menuItems={MENU_ITEMS}
          name='selectedSelfScheduleOption'
          selectedValue={selectedSelfScheduleOption}
        />
        {selectedSelfScheduleOption === true ? (
          <SelectField
            label='Can Overwrite Appointments'
            onChange={updateState}
            menuItems={MENU_ITEMS}
            name='selectedOverwriteOption'
            selectedValue={selectedOverwriteOption}
          />
        ) : null}

        <LabeledCheckbox
          checked={selectAll}
          formControlSx={{ display: { md: 'none' } }}
          label='Select All'
          onChange={handleSelectAllChange}
        />

        <ResponsiveFabButton
          disabled={
            selectedStudentIds.length < 1 ||
            (selectedHomeroomStaffer === '' &&
              selectedSelfScheduleOption === '')
          }
          onClick={save}
        />
      </div>
    </Collapse>
  )
}

export default BulkEdit
