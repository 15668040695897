import React from 'react'
import { Box, Typography } from '@mui/material'

import { useIsXsBreakpoint } from '../../hooks'
import { SelectField } from '../shared'

import { navOptions } from './navOptions'

const NavMenu = ({ selectedSettings, setSelectedSettings }) => {
  const isXs = useIsXsBreakpoint()

  return (
    <>
      {isXs ? (
        <SelectField
          label='Select Settings'
          onChange={(e) => setSelectedSettings(e.target.value)}
          menuItems={navOptions}
          selectedValue={selectedSettings}
        />
      ) : (
        <Box
          sx={{
            width: 200,
            borderRight: '1px solid #d9d9d9',
            padding: 1,
            marginRight: 2,
            maxWidth: { md: 200 },
            minWidth: { md: 200 }
          }}>
          {navOptions.map((option) => (
            <Typography
              key={option.value}
              onClick={() => setSelectedSettings(option.value)}
              sx={{ marginBottom: 2, cursor: 'pointer' }}>
              {option.description}
            </Typography>
          ))}
        </Box>
      )}
    </>
  )
}

export default NavMenu
