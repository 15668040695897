import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getImportKeyQueryKey } from '../../../helpers/queryKeys'

export const useSchoolImportKey = () => {
  return useQuery(
    getImportKeyQueryKey(),
    async () => {
      const response = await axios.get(`${endpoints.SCHOOL}/importkey`, {
        withCredentials: true
      })

      return response.data
    },
    {
      staleTime: 1000 * 60
    }
  )
}
