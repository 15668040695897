import React from 'react'

import { List, Paper, PaperTitle } from '../shared'

import SummarySubheading from './SummarySubheading'
import WeekdayCard from './WeekdayCard'
import WeekdayListItem from './WeekdayListItem'

import { HideSummaries } from './styledComponents'

const ClassicView = ({ summaries }) => {
  return (
    <Paper>
      <PaperTitle>Summaries</PaperTitle>
      <SummarySubheading />
      <List
        data={summaries}
        Component={WeekdayListItem}
        keyValue='appointmentDate'
      />
    </Paper>
  )
}

const SummariesContainer = ({ displayClassicSummaries, summaries }) => {
  return (
    <>
      <HideSummaries displayClassicSummaries={displayClassicSummaries} hideXs>
        {displayClassicSummaries ? (
          <List
            data={summaries}
            Component={WeekdayCard}
            keyValue='appointmentDate'
          />
        ) : (
          <ClassicView summaries={summaries} />
        )}
      </HideSummaries>
      <HideSummaries
        displayClassicSummaries={displayClassicSummaries}
        hideXs={false}>
        <ClassicView summaries={summaries} />
      </HideSummaries>
    </>
  )
}

export default SummariesContainer
