import { Typography } from '@mui/material'
import React from 'react'

import { useHeaderTitle } from '../../hooks'

const NoMobile = () => {
  useHeaderTitle('Not Available')

  return (
    <div>
      <Typography>This page is not available on mobile browsers.</Typography>
    </div>
  )
}

export default NoMobile
