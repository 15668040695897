import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getAttendanceTypeQueryKey } from '../../../helpers'

// Export to use for pre-fetching
export const fetchAttendanceTypeDetails = async (attendanceTypeId) => {
  const response = await axios.get(
    `${endpoints.ATTENDANCE_TYPES}/${attendanceTypeId}`,
    { withCredentials: true }
  )

  return response.data
}

export const useAttendanceTypesByIdQuery = (attendanceTypeId) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getAttendanceTypeQueryKey(attendanceTypeId),
    () => fetchAttendanceTypeDetails(attendanceTypeId),
    {
      enabled: attendanceTypeId !== '0',
      onError: () => {
        // Invalidate cached Attendance Types
        queryClient.invalidateQueries(['attendance-types'])

        enqueueSnackbar(
          `We are unable to find this record, it's likely that it has been deleted by another user.`,
          {
            variant: 'error'
          }
        )
      },
      staleTime: 1000 * 30
    }
  )
}
