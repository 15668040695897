import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getDepartmentsQueryKey, sortByCollatingField } from '../../../helpers'

export const useDepartmentsQuery = ({
  includeInactive = false,
  includeUnassigned = false,
  withCounts = false
} = {}) => {
  return useQuery(
    getDepartmentsQueryKey({ includeInactive, includeUnassigned, withCounts }),
    async () => {
      const urlParams = new URLSearchParams()

      if (includeInactive) {
        urlParams.append('includeInactive', includeInactive)
      }

      if (includeUnassigned) {
        urlParams.append('includeUnassigned', includeUnassigned)
      }

      if (withCounts) {
        urlParams.append('withCounts', withCounts)
      }

      const urlParamsString = urlParams.toString()

      const response = await axios.get(
        `${endpoints.DEPARTMENTS}${
          urlParamsString ? `?${urlParamsString}` : ''
        }`,
        {
          withCredentials: true
        }
      )

      response.data.sort(sortByCollatingField(true, 'departmentDescription'))

      response.data.forEach((r) => {
        r.description = r.departmentDescription
        r.value = r.departmentId
      })

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
