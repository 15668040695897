import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useAppointmentsUpdateAttendanceMutation = ({
  attendanceDate,
  userId
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ attendanceDetails, feedbackMessage }) => {
      let values = []

      attendanceDetails.forEach((appointment) => {
        if (appointment.dataIsDirty) {
          values.push({
            appointmentId: appointment.appointmentId,
            attendanceTypeId: appointment.currentAttendanceTypeId,
            attendanceComment: appointment.attendanceComment,
            studentWasTardy: appointment.studentWasTardy,
            wasSetByImport: false
          })
        }
      })

      await axios.post(`${endpoints.APPOINTMENTS}/updateattendance`, values, {
        withCredentials: true
      })

      return { feedbackMessage, newAttendanceDetails: attendanceDetails }
    },
    {
      onSuccess: ({ feedbackMessage, newAttendanceDetails }) => {
        enqueueSnackbar(feedbackMessage, {
          variant: 'success'
        })

        queryClient.invalidateQueries([
          'appointments',
          'summary-for-attendance'
        ])

        queryClient.setQueryData(
          [
            'appointments',
            'detail-for-attendance',
            { attendanceDate, userId: parseInt(userId) }
          ],
          (prevAttendanceDetails) => {
            return newAttendanceDetails.concat(
              prevAttendanceDetails.filter((prevDetail) =>
                newAttendanceDetails.every(
                  (newDetail) =>
                    newDetail.appointmentId !== prevDetail.appointmentId
                )
              )
            )
          }
        )
      }
    }
  )
}
