import React from 'react'
import { Box, Typography } from '@mui/material'
import { Chat, School } from '@mui/icons-material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { NOTIFICATION_TYPES } from '../../constants'

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasBeenRead' && prop !== 'isLastItem'
})(({ hasBeenRead, isLastItem, theme }) => ({
  display: 'flex',

  background: !hasBeenRead && 'rgba(3, 144, 3, 0.2)',
  borderBottom: !isLastItem && '1px solid #d9d9d9',
  padding: theme.spacing(2),
  marginRight: 0,

  '&:hover': {
    background: !hasBeenRead && 'rgba(3, 144, 3, 0.4)',
    cursor: !hasBeenRead && 'pointer'
  }
}))

const OrangeDot = styled(Box)(({ theme }) => ({
  height: '10px',
  width: '10px',
  minHeight: '10px',
  minWidth: '10px',
  background: 'orange',
  borderRadius: '50%',
  marginRight: '13px',
  marginLeft: '5px'
}))

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  textDecoration: 'none',
  color: 'inherit',

  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main
  }
}))

const Notification = ({
  body,
  bodyClean,
  dateCreated,
  dateRequested,
  hasBeenRead,
  handleCloseAndResetFilter,
  id,
  isLastItem,
  markAsRead,
  periodDescription,
  senderFirstName,
  senderId,
  senderLastName,
  senderNickname,
  title,
  type
}) => {
  const message =
    type === NOTIFICATION_TYPES.schoolMessage
      ? bodyClean.length > 250
        ? bodyClean.substring(0, 250) + '...'
        : bodyClean
      : `${senderLastName}, ${
          senderNickname === null || senderNickname === ''
            ? senderFirstName
            : senderNickname
        } is requesting to be scheduled into ${periodDescription} on ${moment(
          dateRequested
        ).format('MMM Do')}`

  return (
    <Container
      hasBeenRead={hasBeenRead}
      isLastItem={isLastItem}
      onClick={
        hasBeenRead
          ? () => {}
          : () => markAsRead({ messageId: id, messageType: type })
      }>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1
          }}>
          <Typography
            sx={{
              color: '#888',
              fontSize: 12,
              fontWeight: 600,
              textTransform: 'uppercase',

              display: 'flex',
              alignItems: 'center'
            }}>
            {type === NOTIFICATION_TYPES.schoolMessage ? (
              <School sx={{ color: '#888', fontSize: 20, marginRight: 1 }} />
            ) : (
              <Chat sx={{ color: '#888', fontSize: 20, marginRight: 1 }} />
            )}
            {type === NOTIFICATION_TYPES.schoolMessage
              ? 'School Notification'
              : 'Student Request'}
          </Typography>
          <Typography sx={{ color: '#959292', fontStyle: 'italic' }}>
            {moment(dateCreated).format('MMM Do')}
          </Typography>
        </div>
        {type === NOTIFICATION_TYPES.schoolMessage && (
          <>
            <StyledLink
              to={
                type === NOTIFICATION_TYPES.schoolMessage
                  ? '/schoolNotifications'
                  : '/studentRequests'
              }
              onClick={handleCloseAndResetFilter}>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 'bold',
                  paddingLeft: '28px'
                }}>
                {title}
              </Typography>
            </StyledLink>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!hasBeenRead && <OrangeDot />}
              <Typography>{message}</Typography>
            </Box>
          </>
        )}
        {type === NOTIFICATION_TYPES.studentRequest && (
          <StyledLink to='/studentRequests' onClick={handleCloseAndResetFilter}>
            {!hasBeenRead && <OrangeDot />}
            <Typography>{message}</Typography>
          </StyledLink>
        )}
      </div>
    </Container>
  )
}

export default Notification
