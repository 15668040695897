import { Grid } from '@mui/material'
import React from 'react'

import LabeledCheckbox from '../LabeledCheckbox'
import List from '../List'

import ListHeading from './ListHeading'
import StudentItem from './StudentItem'

const UnscheduledStudentsList = ({
  listHeading,
  moveAllStudents,
  moveStudent,
  students,
  studentType
}) => {
  return (
    <Grid item xs={12} sm={4} lg={3}>
      <ListHeading text={`${listHeading} (${students.length})`} />
      <div>
        {students.length > 0 && (
          <LabeledCheckbox
            checked={false}
            label={studentType === 'unscheduled' ? 'All' : 'Remove All'}
            onChange={() => moveAllStudents(studentType)}
          />
        )}
        <List
          data={students}
          Component={StudentItem}
          emptyArrayMessage={
            studentType === 'unscheduled'
              ? 'There are no students for this date and period.'
              : 'No students have been selected'
          }
          keyValue='userId'
          moveStudent={moveStudent}
          studentType={studentType}
          textAlign='initial'
        />
      </div>
    </Grid>
  )
}

export default UnscheduledStudentsList
