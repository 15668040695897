import { useState } from 'react'
import axios from 'axios'

import {
  emailAddressStatuses as eas,
  endpoints,
  regExValidEmail,
  userTypes
} from '../constants'

function useEmailValidation({
  clearErrors,
  emailAddress,
  setEmailValidationResponseData,
  setError,
  userId = 0,
  userToSchoolId = 0,
  userType,
  watch,
  watchValue
}) {
  const [isValidatingEmail, setIsValidatingEmail] = useState(false)
  const [isUnique, setIsUnique] = useState(null)

  const emailAddressValue = watch(watchValue)

  const handleEmailValidationResponse = ({ emailAddressUsageStatus }) => {
    switch (emailAddressUsageStatus) {
      case eas.alreadyInUseInSameSchool:
        setError('serverErrorValidateEmail', {
          type: 'string',
          message: `${emailAddressValue} is already in use for another user in this school`
        })
        setIsUnique(false)
        break
      case eas.emailAddressExistsInDifferentSchoolDistrict:
        setError('serverErrorValidateEmail', {
          type: 'string',
          message: `${emailAddressValue} is already in use in another school district`
        })
        setIsUnique(false)
        break
      case eas.inUseInADifferentSchool:
        /* setError('serverErrorValidateEmail', {
          type: 'string',
          message: `${emailAddressValue} is already in use in another school`
        })
        setIsUnique(false) */
        clearErrors('serverErrorValidateEmail')
        setIsUnique(true)
        break
      case eas.tryingToCreateStudentWithStafferEmailAddress:
        setError('serverErrorValidateEmail', {
          type: 'string',
          message: `${emailAddressValue} is associated with a staffer`
        })
        setIsUnique(false)
        break
      case eas.tryingToCreateStafferWithStudentEmailAddress:
        setError('serverErrorValidateEmail', {
          type: 'string',
          message: `${emailAddressValue} is associated with a student`
        })
        setIsUnique(false)
        break
      default:
        clearErrors('serverErrorValidateEmail')
        setIsUnique(true)
        break
    }

    setIsValidatingEmail(false)
  }

  const validateChangedEmailAddress = async () => {
    if (emailAddressValue === undefined || emailAddress === emailAddressValue) {
      clearErrors('serverErrorValidateEmail')
      setIsUnique(null)
      return
    }

    if (regExValidEmail.test(emailAddressValue) === false) {
      setError('serverErrorValidateEmail', {
        type: 'string',
        message: 'This email address is invalid'
      })
      return
    }

    setIsValidatingEmail(true)

    axios
      .post(
        `${endpoints.BASE_USER}/getemailaddressstatus`,
        {
          emailAddress: emailAddressValue,
          userId,
          userToSchoolId,
          userType: userType === '' ? userTypes.STAFFER.value : userType
        },
        { withCredentials: true }
      )
      .then((response) => {
        handleEmailValidationResponse(response.data)
        setEmailValidationResponseData(response.data)
      })
  }

  return { isUnique, isValidatingEmail, validateChangedEmailAddress }
}

export default useEmailValidation
