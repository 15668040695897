import React from 'react'
import { Typography } from '@mui/material'

import { useSortedArray } from '../../hooks'
import {
  ExternalLink,
  List,
  MobileSorting,
  Paper,
  PaperTitle,
  Popover
} from '../shared'

import StrugglingStudentItem from './StrugglingStudentItem'
import StrugglingStudentsSubheading from './StrugglingStudentsSubheading'

import {
  NoContent,
  StyledHelpIcon,
  StyledPaperContent
} from './styledComponents'

const StrugglingStudentsContainer = ({
  grades,
  hideStrugglingStudents,
  strugglingStudents
}) => {
  const [
    { sortedArray: sortedStudents, lastSortedBy, sortAscending },
    setSortedStudents
  ] = useSortedArray(strugglingStudents, 'lastName', false)

  const cutOffGrade = grades.find((grade) => grade.isCutOff)

  return (
    <>
      {!hideStrugglingStudents && (
        <Paper
          sx={{
            maxHeight: { sm: 475 },
            minHeight: { sm: 475 }
          }}>
          <StyledPaperContent>
            <PaperTitle>My At Risk Students</PaperTitle>
            <Popover
              content={
                <Typography>
                  This box shows students with a grade below{' '}
                  {cutOffGrade.gradeValue}, as set by your school.{' '}
                  <ExternalLink href='https://enrichingstudents.zendesk.com/hc/en-us/articles/360057611651'>
                    Learn more.
                  </ExternalLink>
                </Typography>
              }>
              <StyledHelpIcon />
            </Popover>
          </StyledPaperContent>
          <MobileSorting
            initialValue={lastSortedBy}
            menuItems={[
              {
                description: 'Name',
                value: 'lastName'
              }
            ]}
            onClick={setSortedStudents}
          />
          <StrugglingStudentsSubheading
            lastSortedBy={lastSortedBy}
            onClick={setSortedStudents}
            sortAscending={sortAscending}
          />
          {strugglingStudents.length > 0 ? (
            <List
              data={sortedStudents}
              Component={StrugglingStudentItem}
              keyValue='userId'
            />
          ) : (
            <NoContent>
              <Typography>You currently have no At Risk Students.</Typography>
              <ExternalLink href='https://enrichingstudents.zendesk.com/hc/en-us/articles/360057611651'>
                Please click here to learn more
              </ExternalLink>
            </NoContent>
          )}
        </Paper>
      )}
    </>
  )
}

export default StrugglingStudentsContainer
