export const userTypes = {
  ADMIN: {
    description: 'Admin',
    value: 2
  },
  STAFFER: {
    description: 'Staffer',
    value: 1
  },
  STUDENT: {
    description: 'Student',
    value: 4
  },
  SUBSTITUTE: {
    description: 'Substitute',
    value: 3
  }
}

export const userTypeIds = {
  admin: 2,
  staffer: 1,
  student: 4,
  substitute: 3
}
