import moment from 'moment'

export function returnIntervalTime() {
  const dayOfTheWeek = moment().day()
  const startTime = moment('7:00am', 'h:mma').format('h:mma')
  const endTime = moment('11:00am', 'h:mma').format('h:mma')
  const nowTime = moment().format('h:mma')

  if (dayOfTheWeek === 1) {
    if (nowTime >= startTime || nowTime <= endTime) {
      //return 300000
      return 600000
    }
  }

  //return 120000
  return 600000
}
