import React from 'react'
import { Help, Person, PersonAdd, PersonOutline } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { userTypeIds } from '../../constants'
import { getStafferQueryKey } from '../../helpers'
import { fetchStaffDetails, usePrefetchData } from '../../hooks'
import {
  ActiveIcon,
  Checkbox,
  DeleteModal,
  ListItemContainer,
  ListItemText,
  Menu,
  MenuItemExternalLink,
  MenuItemLink,
  Popover
} from '../shared'
import { useSearchParams } from 'react-router-dom'

const MenuContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),

  gridColumn: '11/12',

  [theme.breakpoints.down('md')]: {
    gridRow: '1 / span 3',
    gridColumn: 12
  },

  [theme.breakpoints.only('xs')]: {
    display: 'flex',
    gridColumn: '1/13',
    gridRow: '4',
    justifyContent: 'center'
  }
}))

const NameIcon = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

const StyledIcon = styled(PersonAdd, {
  shouldForwardProp: (prop) => prop !== 'userIsDeleted'
})(({ theme, userIsDeleted }) => ({
  marginRight: theme.spacing(),

  color: userIsDeleted && 'grey'
}))

const StaffListItem = ({
  bulkEditing,
  deleteStaffer,
  departmentId,
  departments,
  emailAddress,
  firstName,
  hasBeenScheduled,
  isLastItem,
  lastName,
  selectedStaffIds,
  updateStafferIsSelected,
  userId,
  userIsActive,
  userIsDeleted,
  userType
}) => {
  const [prefetchStaffDetails] = usePrefetchData()
  let [searchParams] = useSearchParams()

  const isChecked = () => {
    const result = selectedStaffIds.find((id) => id === userId)

    return result === undefined ? false : true
  }

  const departmentInfo = departments.find(
    (department) => department.departmentId === departmentId
  )
  const departmentDescription =
    departmentInfo === undefined ? '' : departmentInfo.departmentDescription

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1 / 12', lg: '1/5' },
          display: 'flex',
          alignItems: 'center'
        }}
        dataTestId='staffer-name'
        labelText='Name:'>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {bulkEditing && (
            <Checkbox
              ariaLabel={`select-user-${userId}`}
              checked={isChecked()}
              dataTestId={`select-staffer-for-bulk-editing`}
              id={`select-user-${userId}`}
              name={`select-user-${userId}`}
              onChange={(e) =>
                updateStafferIsSelected(userId, e.target.checked)
              }
            />
          )}
          <NameIcon>
            {userIsDeleted ? (
              <StyledIcon as={PersonOutline} userIsDeleted={userIsDeleted} />
            ) : userType === userTypeIds.admin ? (
              <StyledIcon userIsDeleted={userIsDeleted} />
            ) : (
              <StyledIcon as={Person} userIsDeleted={userIsDeleted} />
            )}
            {userIsDeleted && (
              <Popover
                content={`<a
                    href='https://enrichingstudents.zendesk.com/hc/en-us/articles/360032135592-What-Is-A-Soft-Deleted-User'
                    target='__blank'>
                    This user has been soft deleted. Click here to learn more.
                  </a>`}
                dataTestId='user-is-deleted'
                shouldDangerouslySetInnerHtml={true}>
                <Help />
              </Popover>
            )}
            {lastName}, {firstName}
          </NameIcon>
        </span>
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/12', lg: '5/7' },
          display: 'flex'
        }}
        labelText='Department:'>
        {departmentDescription}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/12', lg: '7/10' },
          display: 'flex'
        }}
        labelText='Email:'>
        {emailAddress}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/12', lg: '10/11' },
          display: 'flex',
          justifyContent: { lg: 'center' }
        }}
        labelText='Active:'>
        <ActiveIcon active={userIsActive} />
      </ListItemText>
      <MenuContainer>
        <Menu>
          {hasBeenScheduled && (
            <MenuItemExternalLink
              href='https://enrichingstudents.zendesk.com/hc/en-us/articles/360032137672-The-Difference-Between-An-Inactive-User-A-Soft-Deleted-User'
              dataTestId='user-deleted-message'>
              This staffer has completed appointments and cannot be deleted.
              They can be marked as inactive. Click here to learn more.
            </MenuItemExternalLink>
          )}
          {!userIsDeleted && !hasBeenScheduled && (
            <DeleteModal
              label='Delete Staffer'
              confirmDeleteCallback={() => deleteStaffer(userId)}
              confirmDeleteLabel='Delete Staffer'
              confirmDeleteMessage='Are you sure you want to delete this staffer?'
              itemName={`${lastName}, ${firstName}`}
            />
          )}
          <MenuItemLink
            onMouseEnter={() =>
              prefetchStaffDetails(getStafferQueryKey(userId), () =>
                fetchStaffDetails(userId)
              )
            }
            state={{
              currentPageSearchParam: searchParams.get('currentPage'),
              departmentIdSearchParam: searchParams.get('departmentId'),
              filterSearchParam: searchParams.get('filter'),
              scrollPosition: window.scrollY,
              userTypeSearchParam: searchParams.get('userType')
            }}
            text='Edit Staffer'
            to={`/admin/addStaff/${userId}`}
          />
        </Menu>
      </MenuContainer>
    </ListItemContainer>
  )
}

export default StaffListItem
