import { getAppointmentsQueryKey } from '../../helpers'

export const onSuccessClearAppointments = ({
  appointmentDate,
  appointmentIds,
  numberOfDaysToRetrieve,
  periodId,
  queryClient,
  rosterId,
  searchTerm,
  startDate,
  studentIds,
  userId
}) => {
  if (userId !== null) {
    // Single
    queryClient.setQueryData(
      getAppointmentsQueryKey({
        numberOfDaysToRetrieve,
        periodId,
        rosterId,
        searchTerm,
        startDate
      }),
      (cachedAppointmentDetails) => {
        cachedAppointmentDetails.studentsAndAppointments =
          cachedAppointmentDetails.studentsAndAppointments.map((student) => {
            if (student.userId !== userId) return student
            return {
              ...student,
              appointments: student.appointments.map((appointment) => {
                if (appointment.appointmentId !== appointmentIds[0])
                  return appointment
                return { ...appointment, courseId: 0, courseName: '' }
              })
            }
          })

        return cachedAppointmentDetails
      }
    )
  } else {
    // Multi
    queryClient.setQueryData(
      getAppointmentsQueryKey({
        numberOfDaysToRetrieve,
        periodId,
        rosterId,
        searchTerm,
        startDate
      }),
      (cachedAppointmentDetails) => {
        studentIds.forEach((id) => {
          cachedAppointmentDetails.studentsAndAppointments =
            cachedAppointmentDetails.studentsAndAppointments.map((student) => {
              if (student.userId !== id) return student
              return {
                ...student,
                appointments: student.appointments.map((appointment) => {
                  if (appointment.appointmentDate !== appointmentDate) {
                    return appointment
                  }

                  return {
                    ...appointment,
                    adjustedByFirstName: null,
                    adjustedById: 0,
                    adjustedByLastName: null,
                    adjustedByRoom: null,
                    appointmentTypeDescription: null,
                    courseId: 0,
                    courseName: null,
                    instructorFirstName: null,
                    instructorId: 0,
                    instructorLastName: null,
                    instructorRoom: null,
                    schedulerComment: null
                  }
                })
              }
            })
        })
        return cachedAppointmentDetails
      }
    )
  }
}
