import React, { useRef, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import moment from 'moment'

import { useSendEmailNotifyMyAppointmentsMutation } from '../../hooks'
import { Button, Drawer, PaperTitle, TextArea, TextField } from '../shared'

const INITIAL_STATE = {
  message: '',
  subject: ''
}

const SendNotificationDrawer = ({
  appointmentDate,
  appointmentsToNotify,
  periodId,
  periodToNotify
}) => {
  const [state, setState] = useState(INITIAL_STATE)

  const sendNotificationMenuRef = useRef()

  const { isLoading: isSendingNotification, mutate } =
    useSendEmailNotifyMyAppointmentsMutation({
      resetStateAfterSave: () => {
        setState(INITIAL_STATE)
        sendNotificationMenuRef.current.closeDrawerFromParent()
      }
    })

  const sendNotifications = () => {
    let array = []

    appointmentsToNotify.forEach((appointment) => {
      array.push(appointment.userId)
    })

    mutate({
      details: {
        appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
        message: state.message,
        periodId,
        studentIds: array,
        subject: state.subject
      },
      periodToNotify
    })
  }

  return (
    <Drawer
      anchor='right'
      label='Send Notification'
      ref={sendNotificationMenuRef}>
      <Box
        sx={{
          padding: 1,
          minWidth: 250
        }}>
        {isSendingNotification ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 16px)'
            }}
            data-testid='posting-notifications-ui'>
            <CircularProgress sx={{ marginBottom: 1 }} />
            <Typography>Sending Notification</Typography>
          </Box>
        ) : (
          <>
            <PaperTitle>Send Notification</PaperTitle>
            <TextField
              id='subject'
              label='Subject'
              name='subject'
              onChange={(e) => setState({ ...state, subject: e.target.value })}
              value={state.subject}
            />
            <TextArea
              id='comment'
              name='comment'
              placeholder='Add a comment...'
              onChange={(e) => setState({ ...state, message: e.target.value })}
              style={{ width: '100%' }}
              value={state.message}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                esButtonClass='cancelButton'
                marginRight
                onClick={() =>
                  sendNotificationMenuRef.current.closeDrawerFromParent()
                }
                label='Cancel'
              />
              <Button
                disabled={!state.subject || !state.message}
                onClick={() => sendNotifications()}
                label='Save'
              />
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  )
}

export default SendNotificationDrawer
