import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints, importTypes } from '../../../constants'

export const useFileUploadByTypeMutation = () => {
  return useMutation(async (formData) => {
    const response = await axios.post(
      `${endpoints.FILE_UPLOAD}/uploadbytype?importType=${importTypes.massScheduleStudent}`,
      formData,
      { withCredentials: true }
    )

    return response.data
  })
}
