import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useStudentsCanOverrideAppointmentsQuery = ({ studentId }) => {
  return useQuery(
    [
      'students',
      'can-override-appointments',
      { studentId: parseInt(studentId) }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.STUDENTS}/canoverrideappointments/${studentId}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      enabled: !!studentId,
      staleTime: 1000 * 30
    }
  )
}
