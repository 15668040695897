import { styled } from '@mui/material/styles'

const Item4 = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSubstitute'
})(({ isSubstitute, theme }) => ({
  gridColumn: '1/13',
  [theme.breakpoints.up('sm')]: {
    gridColumn: isSubstitute ? '7/10' : '10/13'
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing()
  }
}))

export default Item4
