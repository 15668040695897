import { styled } from '@mui/material/styles'

const RequestItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  marginTop: theme.spacing(),
  marginBottom: theme.spacing()
}))

export default RequestItem
