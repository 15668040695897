import React from 'react'
import { Typography } from '@mui/material'

import { styled } from '@mui/material/styles'

const StyledLink = styled('a')(({ theme }) => ({
  color: 'blue',

  '&:visited': {
    color: 'blue'
  }
}))

const ExternalLink = ({ children, dataTestId, href, sx }) => {
  return (
    <StyledLink href={href} sx={sx} target='__blank'>
      <Typography data-testid={dataTestId}>{children}</Typography>
    </StyledLink>
  )
}

export default ExternalLink
