import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const StyledInternalLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'noStyling'
})(({ noStyling, theme }) => ({
  color: noStyling ? theme.palette.text.primary : 'blue',
  textDecoration: noStyling && 'none',

  '&:visited': {
    color: noStyling ? theme.palette.text.primary : 'blue'
  }
}))

const InternalLink = ({ noStyling, state, sx, text, to }) => {
  return (
    <StyledInternalLink noStyling={noStyling} state={state} sx={sx} to={to}>
      {text}
    </StyledInternalLink>
  )
}

export default InternalLink
