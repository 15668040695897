import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStaffersIncludeAllQueryKey } from '../../../helpers'

export const useDeleteStaffMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (userId) => {
      await axios.delete(`${endpoints.STAFF}/delete/${userId}`, {
        withCredentials: true
      })

      return userId
    },
    {
      onSuccess: (userId) => {
        enqueueSnackbar(`Staffer has been deleted.`, { variant: 'success' })

        queryClient.setQueryData(
          getStaffersIncludeAllQueryKey(),
          (prevStaffers) => {
            return [
              ...prevStaffers.filter((staffer) => staffer.userId !== userId)
            ]
          }
        )
      }
    }
  )
}
