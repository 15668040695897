import React, { useEffect } from 'react'
import moment from 'moment'

import { DatePicker, FlexColumn, MultiSelect, SelectField } from '../shared'

import StepNavButtons from './StepNavButtons'

const SetupSchedulingDateStep = ({
  endDate,
  handleBack,
  handleNext,
  periods,
  reducedWeekdays,
  schedulingOptions,
  setIsoWeekdays,
  setSchedulingOptions,
  startDate,
  updateDates
}) => {
  useEffect(() => {
    if (schedulingOptions.selectedPeriodId === '') {
      setSchedulingOptions({
        ...schedulingOptions,
        selectedPeriodId: periods.reduce(function (prev, curr) {
          return prev.periodId < curr.periodId ? prev : curr
        }).periodId
      })
    }
  }, [periods, schedulingOptions, setSchedulingOptions])

  return (
    <FlexColumn>
      <DatePicker
        label='Start Date'
        onChange={(v) => updateDates(v, 'startDate')}
        value={startDate}
      />
      <DatePicker
        label='End Date'
        maxDate={moment(startDate).add(90, 'days')}
        onChange={(v) => updateDates(v, 'endDate')}
        value={endDate}
      />
      <MultiSelect
        label='Repeats On'
        onChange={(e) => setIsoWeekdays(e.target.value)}
        name='blockedGraduationYears'
        selectedValues={reducedWeekdays}
        values={[
          {
            description: 'Monday',
            value: 1
          },
          {
            description: 'Tuesday',
            value: 2
          },
          {
            description: 'Wednesday',
            value: 3
          },
          {
            description: 'Thursday',
            value: 4
          },
          {
            description: 'Friday',
            value: 5
          }
        ]}
      />
      <SelectField
        label='Selected Period'
        labelId='selected-period'
        onChange={(e) =>
          setSchedulingOptions({
            ...schedulingOptions,
            selectedPeriodId: e.target.value
          })
        }
        menuItems={periods}
        selectedValue={schedulingOptions.selectedPeriodId}
      />
      <StepNavButtons
        onClickNext={handleNext}
        onClickPrev={handleBack}
        stepNumber='2'
      />
    </FlexColumn>
  )
}

export default SetupSchedulingDateStep
