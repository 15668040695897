import { useEffect, useRef } from 'react'

/**
 * Custom hook that sets up an interval to repeatedly call a callback function.
 *
 * @param {Function} callback - The function to be called at each interval.
 * @param {number|null} delay - The delay (in milliseconds) between each interval. Pass null to stop the interval.
 */
function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
