import React, { useState } from 'react'
import moment from 'moment'
import { Chat } from '@mui/icons-material'
import { useQueryClient } from 'react-query'

import {
  getStudentDisplayName,
  getStudentRequestsQueryKey
} from '../../helpers'
import {
  useAppointmentRequestsAutoScheduleMutation,
  useAppointmentRequestsMarkCancelledMutation,
  useAppointmentsGetMutation,
  useAppointmentsSaveMutation
} from '../../hooks'
import {
  ActiveIcon,
  Checkbox,
  ListItemContainer,
  ListItemText,
  Loading,
  Popover
} from '../shared'

import RequestItemMenu from './RequestItem/RequestItemMenu'
import RequestItemScheduledDetails from './RequestItem/RequestItemScheduledDetails'

const RequestItem = ({
  appointmentMessage,
  appointmentRequestId,
  courseId,
  dateRequestWasMade,
  dateRequested,
  hasAppointmentForRequestedDate,
  isCancellingMultipleRequests,
  isLastItem,
  isScheduled,
  periodDescription,
  periodId,
  selectedAppointmentRequestIdsToCancel,
  selectedTab,
  studentFirstName,
  studentLastName,
  studentNickname,
  updateSelectedAppointmentRequestIdsToCancel,
  userId
}) => {
  const queryClient = useQueryClient()
  const [showDetails, setShowDetails] = useState(false)
  const { data, isLoading, mutate } = useAppointmentsGetMutation()

  const { isLoading: isAutoSchedulingRequest, mutate: autoSchedule } =
    useAppointmentRequestsAutoScheduleMutation()

  const { isLoading: isCancellingRequest, mutate: markRequestAsCancelled } =
    useAppointmentRequestsMarkCancelledMutation()

  const { isLoading: isSchedulingAppointments, mutate: scheduleAppointments } =
    useAppointmentsSaveMutation()

  const isChecked = () => {
    const result = selectedAppointmentRequestIdsToCancel.find(
      (id) => id === appointmentRequestId
    )

    return result === undefined ? false : true
  }

  const handleScheduleClick = () => {
    scheduleAppointments({
      appointmentId: 0,
      courseId,
      date: dateRequested,
      isLocked: false,
      optionalCallback: () => updateQueryClientAfterScheduling(),
      overrideAppointments: true,
      periodId,
      schedulerComment: '',
      studentIds: [userId],
      students: [
        {
          firstName: studentFirstName,
          lastName: studentLastName,
          nickname: studentNickname,
          userId
        }
      ]
    })
  }

  const updateQueryClientAfterScheduling = () => {
    queryClient.setQueryData(getStudentRequestsQueryKey(), (prevRequests) => {
      return prevRequests.map((request) => {
        if (request.appointmentRequestId !== appointmentRequestId)
          return request

        return { ...request, isScheduled: true }
      })
    })
  }

  const formattedDateRequest = moment(dateRequested).format('MM/DD/YYYY')
  const fullName = getStudentDisplayName(
    studentFirstName,
    studentLastName,
    studentNickname
  )
  const accesibleRecordLabel = `select-appointment-request-${appointmentRequestId}-for-${fullName}-on-${formattedDateRequest}`

  if (isAutoSchedulingRequest)
    return <Loading text={'Scheduling Appointment Request'} />
  if (isCancellingRequest)
    return <Loading text={'Cancelling Appointment Request'} />
  if (isSchedulingAppointments) return <Loading text='Scheduling Request' />

  return (
    <ListItemContainer
      dataTestId={
        selectedTab === 0
          ? 'unscheduled-student-request'
          : 'scheduled-student-request'
      }
      isLastItem={isLastItem}>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '1/3', xl: '1/3' } }}
        labelText='Requested Date:'>
        {isCancellingMultipleRequests && selectedTab === 0 && (
          <Checkbox
            ariaLabel={accesibleRecordLabel}
            checked={isChecked()}
            dataTestId={`select-appointment-request-for-bulk-cancelling`}
            id={accesibleRecordLabel}
            name={accesibleRecordLabel}
            onChange={(e) =>
              updateSelectedAppointmentRequestIdsToCancel({
                appointmentRequestId,
                checked: e.target.checked
              })
            }
          />
        )}
        {formattedDateRequest}
      </ListItemText>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '3/5' } }}
        displayColumn
        labelText='Requested Period:'>
        {periodDescription}
      </ListItemText>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '5/7' } }}
        displayColumn
        labelText='Student:'>
        {fullName}
      </ListItemText>
      <ListItemText
        sx={{
          alignItems: 'center',
          gridColumn: { xs: '1/13', lg: '7/8' },
          textAlign: { lg: 'center' }
        }}
        labelText='Comment:'>
        {appointmentMessage && (
          <Popover content={appointmentMessage}>
            <Chat />
          </Popover>
        )}
      </ListItemText>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '8/10' } }}
        labelText='Date Submitted:'>
        {moment(dateRequestWasMade).format('MM/DD/YYYY')}
      </ListItemText>
      {selectedTab === 1 && (
        <ListItemText
          sx={{
            gridColumn: { xs: '1/13', lg: '10/11' },
            textAlign: 'center'
          }}
          labelText='Scheduled:'>
          <ActiveIcon active={isScheduled} />
        </ListItemText>
      )}
      <RequestItemMenu
        appointmentRequestId={appointmentRequestId}
        autoSchedule={autoSchedule}
        dateRequested={dateRequested}
        fullName={fullName}
        markRequestAsCancelled={markRequestAsCancelled}
        periodId={periodId}
        selectedTab={selectedTab}
        userId={userId}
      />
      <RequestItemScheduledDetails
        data={data}
        dateRequested={dateRequested}
        handleScheduleClick={handleScheduleClick}
        hasAppointmentForRequestedDate={hasAppointmentForRequestedDate}
        isLoading={isLoading}
        mutate={mutate}
        periodId={periodId}
        selectedTab={selectedTab}
        setShowDetails={setShowDetails}
        showDetails={showDetails}
        userId={userId}
      />
    </ListItemContainer>
  )
}

export default RequestItem
