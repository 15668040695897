import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import loginPicture from '../../../images/ES-Login.jpg'
import Triangles from '../../../images/blue_triangles.png'

export const Left = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'fixed',
  zIndex: 1,
  top: 0,
  overflowX: 'hidden',

  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'initial',
    backgroundImage: `url(${loginPicture})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    width: '50%',

    left: 0
  }
}))

export const Right = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'fixed',
  zIndex: 1,
  top: 0,
  overflowX: 'hidden',

  background: `url(${Triangles}) left bottom no-repeat`,
  backgroundSize: '50%',

  right: 0,
  backgroundColor: theme.palette.primary.main,
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: '50%'
  }
}))

export const TextContainer = styled('div')(() => ({
  backgroundColor: 'rgba(173,173,173,.61)',
  bottom: 100,
  left: 0,
  position: 'absolute',

  padding: 15,
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const TextContent = styled(Typography)(() => ({
  color: '#fff',
  fontSize: 28,
  fontWeight: 500,
  textAlign: 'center'
}))
