import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useAppointmentsMassSchedulingSetupStudentsMutation = ({
  handleNext
}) => {
  return useMutation(
    async ({ graduationYears, rosterId, studentIdsToExclude }) => {
      await axios.post(
        `${endpoints.APPOINTMENTS}/massschedulingsetupstudents`,
        {
          graduationYears,
          rosterId,
          studentIdsToExclude
        },
        { withCredentials: true }
      )
    },
    {
      onSuccess: () => {
        handleNext()
      }
    }
  )
}
