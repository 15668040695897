import { createRef, useEffect, useState } from 'react'

const useShouldDownloadCsv = () => {
  const csvRef = createRef()

  const [shouldDownloadCsv, setShouldDownloadCsv] = useState(false)

  useEffect(() => {
    if (shouldDownloadCsv) {
      setShouldDownloadCsv(false)
      csvRef.current.link.click()
    }
  }, [shouldDownloadCsv, csvRef])

  return { csvRef, setShouldDownloadCsv }
}

export default useShouldDownloadCsv
