export const ROOT = process.env.REACT_APP_ROOT_URL

export const endpoints = {
  // This is the format to use going forward
  ADJUSTED_COURSES: `${ROOT}adjustedcourses`,
  ADJUSTED_OFFERINGS: 'https://jsonplaceholder.typicode.com/todos/1',
  ANALYSIS: `${ROOT}analysis`,
  APPOINTMENT_EXPORT_DATA_FIELDS: `${ROOT}appointmentexportdatafields`,
  APPOINTMENT_EXPORT_DEFINITIONS: `${ROOT}appointmentexportdefinitions`,
  APPOINTMENT_REQUESTS: `${ROOT}appointmentrequests`,
  APPOINTMENTS: `${ROOT}appointments`,
  APPOINTMENT_TYPES: `${ROOT}appointmenttypes`,
  ATTENDANCE_TYPES: `${ROOT}attendancetypes`,
  AUTO_SCHEDULE_STAFF: `${ROOT}autoschedulestaff`,
  BASE_USER: `${ROOT}baseuser`,
  BLOCKED_DATES: `${ROOT}blockeddates`,
  CONFIGURATION: `${ROOT}configuration`,
  COURSES: `${ROOT}courses`,
  COTEACHER: `${ROOT}coteacher`,
  DAY_TYPES: `${ROOT}daytypes`,
  DEPARTMENTS: `${ROOT}departments`,
  EMAIL_LOGS: `${ROOT}emaillogs`,
  EMAIL_SUBSCRIPTION: `${ROOT}emailsubscription`,
  FILE_UPLOAD: `${ROOT}fileupload`,
  GRADES: `${ROOT}grades`,
  GRADE_CUTOFF: `${ROOT}gradecutoff`,
  INVALID_EMAILS: `${ROOT}invalidemails`,
  LOGIN: `${ROOT}login`,
  LOOK_UP_VALUES: `${ROOT}lookupvalues`,
  MANAGE_EMAIL_TEMPLATE_ADMIN: `${ROOT}manageemailtemplateadmin`,
  MEMBERSHIP: `${ROOT}membership`,
  MESSAGES: `${ROOT}messages`,
  NOTIFICATIONS: `${ROOT}notifications`,
  PERIODS: `${ROOT}periods`,
  REPORTS: `${ROOT}reports`,
  RECURRING_DATE: `${ROOT}recurringdate`,
  ROSTERS: `${ROOT}rosters`,
  SCHOOL: `${ROOT}school`,
  SCHOOL_DATES: `${ROOT}schooldates`,
  SCHOOL_MASTER_GRADES: `${ROOT}schoolmastergrades`,
  SCHOOLS_FTP: `${ROOT}schoolsftp`,
  SECURITY: `${ROOT}security`,
  SELF_SCHEDULING_ADJUSTOR: `${ROOT}selfschedulingadjustor`,
  SEND_EMAIL: `${ROOT}sendemail`,
  STAFF: `${ROOT}staff`,
  STRUGGLING_STUDENTS: `${ROOT}strugglingstudents`,
  STUDENT_CONTACTS: `${ROOT}studentcontacts`,
  STUDENTS: `${ROOT}students`,
  STUDENTS_BLOCKED_BY_STAFFER: `${ROOT}studentsblockedbystaffer`,
  STUDENTS_BLOCKED_FOR_STUDENT: `${ROOT}studentsblockedforstudent`,
  SUBSTITUTES: `${ROOT}substitute`,
  TIMEZONE: `${ROOT}timezone`,
  UNSCHEDULED_STUDENTS: `${ROOT}unschedulestudents`
}
