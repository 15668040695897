import { styled } from '@mui/material/styles'

const Item1 = styled('div')(({ theme }) => ({
  gridColumn: '1/13',
  [theme.breakpoints.up('sm')]: {
    gridColumn: '1/4'
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing()
  }
}))

export default Item1
