export const importTypes = {
  unknown: 0,
  grade: 1,
  roster: 2,
  staff: 3,
  student: 4,
  attendance: 5,
  oneRosterAcademicSessions: 6,
  oneRosterClasses: 7,
  oneRosterCourses: 8,
  oneRosterDemographics: 9,
  oneRosterEnrollments: 10,
  oneRosterManifest: 11,
  oneRosterOrgs: 12,
  oneRosterUsers: 13,
  massScheduleStudent: 14
}
