export const appointmentSaveEnums = {
  unknown: 0,
  myCoursesSingle: 1, // wired up
  quickScheduleSingle: 2, // wired up
  advancedAppointmentsSingle: 3, //  wired up
  deleteAppointmentsSingle: 4, // wired up
  myCoursesGroup: 5, // wired up

  deleteAppointmentsGroup: 7 // wired up
}
