import { useState } from 'react'
import { useQueryClient } from 'react-query'
import moment from 'moment'

export const usePrefetchData = () => {
  const [lastFetched, setLastFetched] = useState(null)
  const queryClient = useQueryClient()

  const prefetchData = (queryKey, queryFunction) => {
    let now = moment()
    const diff = moment(now).diff(lastFetched, 'seconds')

    if (isNaN(diff) || diff > 10) {
      queryClient.prefetchQuery(queryKey, queryFunction)

      setLastFetched(moment())
    }
  }

  return [prefetchData]
}
