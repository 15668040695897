import React, { useState } from 'react'
import { Box } from '@mui/material'

import HomeroomItem from './HomeroomItem'

import {
  Button,
  List,
  PaginationOptions,
  SubheadingContainer,
  SubheadingText
} from '../shared'

const ManageRoomNumbers = ({
  currentPageRoomNumbers,
  pageSizeRoomNumbers,
  saveHomerooms,
  setCurrentPageRoomNumbers,
  setPageSizeRoomNumbers,
  staffers
}) => {
  const [state, setState] = useState(staffers)

  const adjustedHomerooms = state.filter((staffer) => staffer.hasBeenAdjusted)

  const updateState = (e, userId) => {
    setState([
      ...state.map((staffer) => {
        if (staffer.userId !== userId) return staffer
        return { ...staffer, homeRoom: e.target.value, hasBeenAdjusted: true }
      })
    ])
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={adjustedHomerooms.length > 0 ? false : true}
          onClick={() => saveHomerooms(adjustedHomerooms)}
          label='Save'
          marginBottom
        />
      </Box>
      <SubheadingContainer>
        <SubheadingText
          sx={{ gridColumn: { xs: '1/13', md: '1/4' } }}
          text='Name'
        />
        <SubheadingText
          sx={{ gridColumn: { xs: '1/13', md: '4/7' } }}
          text='Default Room Number'
        />
      </SubheadingContainer>
      <List
        Component={HomeroomItem}
        data={state.slice(
          currentPageRoomNumbers * pageSizeRoomNumbers,
          (currentPageRoomNumbers + 1) * pageSizeRoomNumbers
        )}
        keyValue='userId'
        updateHomeroom={updateState}
      />
      <PaginationOptions
        defaultCurrent={currentPageRoomNumbers}
        handlePaginationChange={setCurrentPageRoomNumbers}
        handlePageSizeChange={setPageSizeRoomNumbers}
        pageSize={pageSizeRoomNumbers}
        total={state.length}
      />
    </>
  )
}

export default ManageRoomNumbers
