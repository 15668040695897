import React from 'react'

import { Checkbox, ListItemContainer, ListItemText } from '../shared'

const DrawerItem = ({
  firstName,
  isBlocked,
  isLastItem,
  lastName,
  updateStaffBlock,
  userId
}) => {
  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', md: '1/7' } }}
        labelText='Staffer:'>
        {lastName}, {firstName}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', md: '7/13' },
          display: { xs: 'flex', md: 'block' },
          alignItems: 'center',
          textAlign: { md: 'center' }
        }}
        labelText='Blocked:'>
        <Checkbox
          checked={isBlocked}
          onChange={() => updateStaffBlock(userId)}
        />
      </ListItemText>
    </ListItemContainer>
  )
}

export default DrawerItem
