import React, { useRef, useState } from 'react'
import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Button, PaperTitle, TextArea } from '../shared'

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))

const CommentContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing()
}))

const CreateCommentDrawer = ({
  attendanceComment,
  closeDrawer,
  fullName,
  saveComment
}) => {
  const [comment, setComment] = useState(attendanceComment)
  const createCommentMenuRef = useRef()

  return (
    <Drawer
      anchor='right'
      label={attendanceComment ? 'Edit Comment' : 'Add Comment'}
      open={true}
      ref={createCommentMenuRef}>
      <CommentContainer>
        <PaperTitle>
          {attendanceComment
            ? `Edit Comment for: ${fullName}`
            : `Add a Comment for ${fullName}`}
        </PaperTitle>

        <TextArea
          onChange={(e) => setComment(e.target.value)}
          style={{ width: '100%' }}
          value={comment}
        />
        <ButtonsContainer>
          <Button
            esButtonClass='cancelButton'
            marginRight
            onClick={() => closeDrawer()}
            label='Cancel'
          />
          <Button onClick={() => saveComment(comment)} label='Save' />
        </ButtonsContainer>
      </CommentContainer>
    </Drawer>
  )
}

export default CreateCommentDrawer
