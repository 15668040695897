import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useSaveStudentGroupMutation = ({ resetStudents }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ shouldRedirect, values }) => {
      await axios.post(`${endpoints.ROSTERS}/save`, values, {
        withCredentials: true
      })

      return { shouldRedirect, values }
    },
    {
      onSuccess: ({ values }) => {
        queryClient.invalidateQueries(['rosters'])

        if (values.rosterId === 0) {
          resetStudents()
        }

        enqueueSnackbar(`${values.rosterName} has been saved`, {
          variant: 'success'
        })
      }
    }
  )
}
