import React, { useEffect } from 'react'
import { Cancel, CheckCircleRounded } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

import { useSisValidation } from '../../../hooks'
import TextField from '../TextField'

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

const StyledCheck = styled(CheckCircleRounded)(({ theme }) => ({
  color: theme.palette.success.main,
  marginLeft: theme.spacing()
}))

const StyledCancel = styled(Cancel)(({ theme }) => ({
  color: theme.palette.error.main,
  marginLeft: theme.spacing()
}))

const SisIdValidationField = ({
  clearErrors,
  sisId,
  sisErrors,
  register,
  serverErrors,
  setError,
  setParentValidating,
  userId,
  userType,
  watch
}) => {
  const { isUnique, isValidating, validateSisId } = useSisValidation({
    clearErrors,
    setError,
    sisId,
    userId,
    userType,
    watch,
    watchValue: 'sisId'
  })

  useEffect(() => {
    setParentValidating(isValidating)
  }, [isValidating, setParentValidating])

  return (
    <Container>
      <TextField
        dataTestId='sis-id'
        defaultValue={sisId}
        error={Boolean(sisErrors || serverErrors)}
        helperText={
          (sisErrors && sisErrors.message) ||
          (serverErrors && serverErrors.message)
        }
        inputRef={register({
          required: 'Please enter a SIS Id'
        })}
        label='SIS Id'
        name='sisId'
        onBlur={validateSisId}
      />
      {isValidating && (
        <CircularProgress data-testid='validating-sis-id-progress' size={20} />
      )}
      {isUnique === true && !isValidating && (
        <StyledCheck data-testid='sis-id-success-icon' />
      )}
      {isUnique === false && !isValidating && <StyledCancel />}
    </Container>
  )
}

export default SisIdValidationField
