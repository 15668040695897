import React, { useState } from 'react'
import { Collapse, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useAdjustedOfferings } from '../../context'
import {
  Button,
  LabeledCheckbox,
  MultiSelect,
  ResponsiveFabButton,
  SelectField,
  TextField
} from '../shared'

const OptionsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const TRUE_FALSE_MENU_ITEMS = [
  { description: '(select)', value: -10 },
  { description: 'True', value: true },
  { description: 'False', value: false }
]

const BulkEdit = ({ bulkEditing, updateBulkEditing }) => {
  const {
    appointmentTypes,
    graduationYears,
    isAdmin,
    maxNumberOfStudents,
    saveBulkEdits,
    selectedOfferingIds,
    selectAll,
    updateAllOfferingsIsSelected
  } = useAdjustedOfferings()

  const [state, setState] = useState({
    dataHasBeenUpdated: false,

    adjustedCourseName: '',
    adjustedRoom: '',
    adjustedSeatCount: '',
    appointmentTypeId: '',
    blockedGraduationYears: [],
    clearCurrentGraduationYears: false,
    clearCurrentMeetingLink: false,
    meetingLink: '',
    mustUseDefaultAppointmentType: '',
    preventStudentRequesting: '',
    preventStudentSelfScheduling: '',
    preventOtherStaffFromScheduling: ''
  })

  const [defaultSeatCountError, setDefaultSeatCountError] = useState('')

  const {
    adjustedCourseName,
    adjustedRoom,
    adjustedSeatCount,
    appointmentTypeId,
    blockedGraduationYears,
    clearCurrentGraduationYears,
    clearCurrentMeetingLink,
    dataHasBeenUpdated,
    meetingLink,
    mustUseDefaultAppointmentType,
    preventStudentRequesting,
    preventStudentSelfScheduling,
    preventOtherStaffFromScheduling
  } = state

  const disableSave = () => {
    if (selectedOfferingIds.length < 1) {
      return true
    }

    if (!dataHasBeenUpdated) {
      return true
    }

    if (defaultSeatCountError !== '') {
      return true
    }

    return false
  }

  const handleSaveClick = () => {
    const details = {
      adjustedCourseName: adjustedCourseName,
      adjustedOfferingIds: selectedOfferingIds,
      adjustedRoom: adjustedRoom,
      adjustedSeatCount:
        adjustedSeatCount === '' ? '-10' : parseInt(adjustedSeatCount),
      appointmentTypeId:
        appointmentTypeId === '' || appointmentTypeId === -10
          ? -10
          : appointmentTypeId,
      meetingLink: clearCurrentMeetingLink
        ? ''
        : meetingLink.trim() === ''
        ? null
        : meetingLink,
      blockedGraduationYears: clearCurrentGraduationYears
        ? []
        : blockedGraduationYears.length < 1
        ? null
        : blockedGraduationYears,
      mustUseDefaultAppointmentType:
        mustUseDefaultAppointmentType === '' ||
        mustUseDefaultAppointmentType === -10
          ? '-10'
          : mustUseDefaultAppointmentType.toString(),
      preventOtherStaffFromScheduling:
        preventOtherStaffFromScheduling === '' ||
        preventOtherStaffFromScheduling === -10
          ? '-10'
          : preventOtherStaffFromScheduling.toString(),
      preventStudentRequesting:
        preventStudentRequesting === '' || preventStudentRequesting === -10
          ? '-10'
          : preventStudentRequesting.toString(),
      preventStudentSelfScheduling:
        preventStudentSelfScheduling === '' ||
        preventStudentSelfScheduling === -10
          ? '-10'
          : preventStudentSelfScheduling.toString()
    }

    saveBulkEdits({ details })
  }

  const validateSeatCount = () => {
    if (
      maxNumberOfStudents !== '' &&
      parseInt(adjustedSeatCount) < maxNumberOfStudents &&
      !isAdmin
    ) {
      setDefaultSeatCountError(
        `This value cannot be lower then the Staffers Default Seat Count: ${maxNumberOfStudents}`
      )
    } else {
      setDefaultSeatCountError('')
    }
  }

  const updateState = (e) => {
    const { name, value } = e.target

    setState({ ...state, dataHasBeenUpdated: true, [name]: value })
  }

  return (
    <div sx={{ marginBottom: 1 }}>
      <Button
        esButtonClass='greyButton'
        label='Bulk edit'
        onClick={updateBulkEditing}
      />
      <Collapse in={bulkEditing} timeout='auto' unmountOnExit>
        <div>
          <Grid container>
            <OptionsContainer item xs={12} sm={6} md={3}>
              <TextField
                id='offering-name'
                label='Offering Name'
                name='adjustedCourseName'
                onChange={updateState}
                value={adjustedCourseName}
              />
              <TextField
                error={defaultSeatCountError !== ''}
                helperText={defaultSeatCountError}
                id='default-number-of-students'
                label='Max Number of Students'
                name='adjustedSeatCount'
                onBlur={validateSeatCount}
                onChange={updateState}
                value={adjustedSeatCount}
              />
              <SelectField
                fullWidth={false}
                label='Appointment Type'
                labelId='appointment-type'
                onChange={updateState}
                menuItems={appointmentTypes}
                name='appointmentTypeId'
                selectedValue={appointmentTypeId}
              />
            </OptionsContainer>
            <OptionsContainer item xs={12} sm={6} md={3}>
              <TextField
                id='offering-room'
                label='Offering Room'
                name='adjustedRoom'
                onChange={updateState}
                value={adjustedRoom}
              />
              <MultiSelect
                label='Block Graduation Years'
                onChange={updateState}
                name='blockedGraduationYears'
                selectedValues={blockedGraduationYears}
                values={graduationYears}
              />
              <TextField
                label='Virtual Meeting Link'
                name='meetingLink'
                onChange={updateState}
                value={meetingLink}
              />
            </OptionsContainer>
            <OptionsContainer item xs={12} sm={6} md={3}>
              <SelectField
                fullWidth={false}
                label='Prevent Student Requests'
                onChange={updateState}
                menuItems={TRUE_FALSE_MENU_ITEMS}
                name='preventStudentRequesting'
                selectedValue={preventStudentRequesting}
              />
              <SelectField
                fullWidth={false}
                label='Prevent Self Scheduling'
                onChange={updateState}
                menuItems={TRUE_FALSE_MENU_ITEMS}
                name='preventStudentSelfScheduling'
                selectedValue={preventStudentSelfScheduling}
              />
              <SelectField
                fullWidth={false}
                label='Block Other Staff'
                onChange={updateState}
                menuItems={TRUE_FALSE_MENU_ITEMS}
                name='preventOtherStaffFromScheduling'
                selectedValue={preventOtherStaffFromScheduling}
              />
              <SelectField
                fullWidth={false}
                label='Lock Appointment Type'
                onChange={updateState}
                menuItems={TRUE_FALSE_MENU_ITEMS}
                name='mustUseDefaultAppointmentType'
                selectedValue={mustUseDefaultAppointmentType}
              />
            </OptionsContainer>
            <OptionsContainer item xs={12} sm={6} md={3}>
              <LabeledCheckbox
                checked={clearCurrentGraduationYears}
                label='Clear Existing Graduation Years'
                name='clearCurrentGraduationYears'
                onChange={(e) =>
                  setState({
                    ...state,
                    clearCurrentGraduationYears: e.target.checked,
                    dataHasBeenUpdated: true
                  })
                }
              />
              <LabeledCheckbox
                checked={clearCurrentMeetingLink}
                label='Clear Existing Meeting Link'
                name='clearCurrentMeetingLink'
                onChange={(e) =>
                  setState({
                    ...state,
                    clearCurrentMeetingLink: e.target.checked,
                    dataHasBeenUpdated: true
                  })
                }
              />
              <LabeledCheckbox
                checked={selectAll}
                label='Select All'
                onChange={updateAllOfferingsIsSelected}
                formControlSx={{ display: { md: 'none' } }}
              />
              <ResponsiveFabButton
                disabled={disableSave()}
                onClick={handleSaveClick}
              />
            </OptionsContainer>
          </Grid>
        </div>
      </Collapse>
    </div>
  )
}

export default BulkEdit
