import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getPeriodsQueryKey } from '../../../helpers'

export const usePeriodsQuery = ({ includeActiveOnly = false } = {}) => {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getPeriodsQueryKey({ activeOnly: includeActiveOnly }),
    async () => {
      const urlArg = includeActiveOnly ? '?activeOnly=true' : ''

      const response = await axios.get(`${endpoints.PERIODS}${urlArg}`, {
        withCredentials: true
      })

      if (response.data.length < 1) {
        enqueueSnackbar(
          'There are no periods setup for your school. You must contact an Admin to fix this for Enriching Students to work properly.',
          {
            variant: 'error'
          }
        )
      }

      response.data.forEach((r) => {
        r.description = r.periodDescription
        r.value = r.periodId
      })

      return response.data
    },
    {
      onError: (error) => {
        enqueueSnackbar('There has been an unexpected network error.', {
          variant: 'error'
        })
      },
      staleTime: 1000 * 30
    }
  )
}
