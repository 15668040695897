import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useStudentsBlockedByStafferQuery = (stafferId) => {
  return useQuery(
    ['students-blocked-by-staffer', { stafferId: parseInt(stafferId) }],
    async () => {
      const response = await axios.get(
        `${endpoints.STUDENTS_BLOCKED_BY_STAFFER}/${stafferId}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      enabled: !!stafferId
    }
  )
}
