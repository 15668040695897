import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'

import { Checkbox, ListItemContainer } from '../shared'
import { useAdvancedAppointments } from './AdvancedAppointmentsContext'

const CourseListItem = styled(Typography)(({ theme }) => ({
  gridColumn: '1/13',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),

  fontSize: 14,

  span: {
    fontWeight: 'bold'
  }
}))

const CourseItem = ({
  appointmentDate,
  blockedReason,
  courseDescription,
  courseName,
  isBlockedForSchool,
  isBlockedForStaffer,
  isLastItem,
  mustUseDefaultAppointmentType,
  periodId
}) => {
  const { excludedCourses, periods, updateCoursesToExclude } =
    useAdvancedAppointments()

  const isExcluded = excludedCourses.find(
    (course) =>
      course.appointmentDate === appointmentDate && course.periodId === periodId
  )
  const period = periods.find((period) => period.value === periodId)

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <CourseListItem>
        <span>Date:&nbsp;</span>
        {moment(appointmentDate).format('MM/DD/YYYY')}
      </CourseListItem>
      <CourseListItem>
        <span>Course:&nbsp;</span>
        {courseName}
      </CourseListItem>
      {courseDescription && (
        <CourseListItem>
          <span>Course Description:&nbsp;</span>
          {courseDescription}
        </CourseListItem>
      )}
      <CourseListItem>
        <span>Period:&nbsp;</span>
        {period.description}
      </CourseListItem>
      {blockedReason && (
        <CourseListItem>
          <span>Blocked Reason:&nbsp;</span>
          {blockedReason}
        </CourseListItem>
      )}
      {mustUseDefaultAppointmentType && (
        <CourseListItem>
          <span style={{ color: 'red' }}>Please Note:&nbsp;</span>
          The selected course's appointment type cannot be changed.
        </CourseListItem>
      )}
      <CourseListItem>
        <span>Exclude:&nbsp;</span>
        <Checkbox
          checked={isExcluded === undefined ? false : true}
          sx={{ padding: 0 }}
          disabled={isBlockedForSchool || isBlockedForStaffer}
          onChange={(e) =>
            updateCoursesToExclude({
              appointmentDate,
              checked: e.target.checked,
              periodId
            })
          }
        />
      </CourseListItem>
    </ListItemContainer>
  )
}

export default CourseItem
