import React from 'react'
import { Typography } from '@mui/material'

import {
  PaperTitle,
  SubheadingContainer,
  SubheadingText,
  TextField,
  VirtualList,
  VirtualListRowStudent
} from '../shared'

import { ListContainer } from './styledComponents'

const UnrosteredStudents = ({
  filterTerm,
  moveStudentToRoster,
  students,
  updateFilterTerm
}) => {
  return (
    <ListContainer>
      <PaperTitle sx={{ display: { xs: 'none', sm: 'block' } }}>
        Add Students To Group
      </PaperTitle>
      <SubheadingContainer hideMobile={false}>
        <SubheadingText sx={{ gridColumn: '1/11' }} text='Student' />
        <SubheadingText
          sx={{
            gridColumn: '11/13',
            textAlign: 'center'
          }}
          text='Add'
        />
      </SubheadingContainer>
      <TextField
        sx={{
          paddingRight: 1,
          paddingLeft: 1
        }}
        id='filter-unrostered-students-by-name'
        onChange={updateFilterTerm}
        placeholder='Filter Students by Name'
        value={filterTerm}
      />
      {students.length > 0 ? (
        <div style={{ display: 'flex', flex: 1, flexGrow: 1 }}>
          <VirtualList
            Component={VirtualListRowStudent}
            data={students}
            dataTestId='unrostered-student'
            onChange={moveStudentToRoster}
          />
        </div>
      ) : (
        <Typography>
          There are no ungrouped students that match the selected filters.
        </Typography>
      )}
    </ListContainer>
  )
}

export default UnrosteredStudents
