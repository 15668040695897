import React from 'react'
import { styled } from '@mui/material/styles'

import { Checkbox, SubheadingContainer, SubheadingText } from '../shared'

const BulkContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gridColumn: '1/5'
}))

const ManageStaffSubheading = ({
  bulkEditing,
  handleSelectAllChange,
  lastSortedBy,
  onClick,
  selectAll,
  sortAscending
}) => {
  return (
    <SubheadingContainer>
      <BulkContainer>
        {bulkEditing && (
          <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
        )}
        <SubheadingText
          ascValue={sortAscending}
          isSelected={lastSortedBy === 'lastName'}
          isSortable
          onClick={() => onClick('lastName')}
          text='Name'
        />
      </BulkContainer>
      <SubheadingText sx={{ gridColumn: '5/7' }} text='Department' />
      <SubheadingText sx={{ gridColumn: '7/10' }} text='Email' />
      <SubheadingText
        ascValue={sortAscending}
        sx={{ gridColumn: '10/11', display: 'flex', justifyContent: 'center' }}
        isSelected={lastSortedBy === 'userIsActive'}
        isSortable
        onClick={() => onClick('userIsActive')}
        text='Active'
      />
    </SubheadingContainer>
  )
}

export default ManageStaffSubheading
