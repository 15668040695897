import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { useSnackbar } from 'notistack'
import { getStafferQueryKey } from '../../../helpers/queryKeys'

export const useStaffSaveNotificationAndPermissionMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (values) => {
      await axios.post(
        `${endpoints.STAFF}/savenotificationandpermission`,
        values,
        { withCredentials: true }
      )

      return { values }
    },
    {
      onSuccess: ({ values }) => {
        enqueueSnackbar(
          'Notification & Permission Settings changes have been saved',
          {
            variant: 'success'
          }
        )

        // Update query cache for edited staffer
        queryClient.setQueryData(
          getStafferQueryKey(values.userId),
          (prevStafferDetails) => {
            return {
              courses: prevStafferDetails.courses,
              staffer: { ...prevStafferDetails.staffer, ...values }
            }
          }
        )

        // Invalidate query cache for list of staffers
        queryClient.invalidateQueries(['staffers'])
      }
    }
  )
}
