import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { Button, Typography } from '@mui/material'
import { useState } from 'react'

export const useAppointmentsMassSchedulingSaveAppointments = ({
  acceptableNumberOfSchedulingErrors,
  resetDataAfterSave
}) => {
  const queryClient = useQueryClient()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  const [errors, setErrors] = useState([])

  const { isLoading, mutate } = useMutation(
    async () => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/massschedulingsaveappointments`,
        {},
        { withCredentials: true }
      )

      setErrors(response.data)

      return response.data
    },
    {
      onSuccess: (data) => {
        if (data.length > acceptableNumberOfSchedulingErrors) {
          enqueueSnackbar(
            'There have been multiple errors scheduling these appointments',
            {
              variant: 'error'
            }
          )

          resetDataAfterSave()

          queryClient.invalidateQueries(['appointments'])

          return
        }

        if (data.length > 1) {
          const action = (key) => (
            <>
              <Button
                onClick={() => closeSnackbar(key)}
                style={{ color: '#fff' }}>
                Dismiss
              </Button>
            </>
          )

          enqueueSnackbar(
            <div>
              <Typography style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                Scheduling Errors:
              </Typography>
              {data.map((err, i) => (
                <Typography key={i}>{err}</Typography>
              ))}
            </div>,
            {
              action,
              variant: 'error',
              persist: true
            }
          )
        } else {
          enqueueSnackbar('Appointments have been scheduled', {
            variant: 'success'
          })
        }

        resetDataAfterSave()

        queryClient.invalidateQueries(['appointments'])
      }
    }
  )

  return { errors, isLoading, mutate, setErrors }
}
