import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const StrugglingStudentsSubheading = ({
  lastSortedBy,
  onClick,
  sortAscending
}) => {
  return (
    <SubheadingContainer>
      <SubheadingText
        sx={{ gridColumn: '1/6' }}
        ascValue={sortAscending}
        isSortable
        isSelected={lastSortedBy === 'lastName'}
        onClick={() => onClick('lastName')}
        text='Name'
      />
      <SubheadingText
        sx={{
          gridColumn: { lg: '6/11' }
        }}
        text='Course'
      />
      <SubheadingText
        sx={{
          gridColumn: { lg: '11/13' },
          textAlign: 'center'
        }}
        text='Grade'
      />
    </SubheadingContainer>
  )
}

export default StrugglingStudentsSubheading
