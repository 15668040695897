import React from 'react'
import { MenuItem } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { setupAlphabetMenuItems } from '../../helpers'
import { Menu, SelectField } from '../shared'
import { StyledListSubheader } from './styledComponents'

const GetStudentsMenu = ({ graduationYears, staffers }) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const selectedGraduationYear =
    searchParams.get('searchKey') === 'selectedGraduationYear'
      ? searchParams.get('searchValue')
      : ''

  const selectedLastName =
    searchParams.get('searchKey') === 'selectedLastName'
      ? searchParams.get('searchValue')
      : ''

  const selectedStaffer =
    searchParams.get('searchKey') === 'selectedStaffer'
      ? searchParams.get('searchValue')
      : ''

  const handleOptionsChange = (e) => {
    const { name, value } = e.target

    setSearchParams({ searchKey: name, searchValue: value })
  }

  return (
    <div>
      <Menu
        buttonSx={{ marginRight: { md: 1 } }}
        buttonAriaLabel='get students by'
        label='Find Students By'>
        <StyledListSubheader>Find Students By</StyledListSubheader>
        <MenuItem>
          <SelectField
            label='Graduation Year'
            labelId='get-by-graduation-year'
            menuItems={graduationYears}
            name='selectedGraduationYear'
            onChange={handleOptionsChange}
            selectedValue={selectedGraduationYear}
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='Homebase'
            labelId='get-by-homeroom'
            menuItems={staffers}
            name='selectedStaffer'
            onChange={handleOptionsChange}
            selectedValue={selectedStaffer}
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='Last Name'
            labelId='get-by-last-name'
            menuItems={setupAlphabetMenuItems()}
            name='selectedLastName'
            onChange={handleOptionsChange}
            selectedValue={selectedLastName}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default GetStudentsMenu
