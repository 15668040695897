import React from 'react'

import { AppointmentsMain, AppointmentsProvider } from '../Appointments'

const AppointmentsWithProvider = () => {
  return (
    <AppointmentsProvider>
      <AppointmentsMain />
    </AppointmentsProvider>
  )
}

export default AppointmentsWithProvider
