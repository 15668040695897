import React, { useEffect, useRef } from 'react'
import { Popover } from '..'
import { Info } from '@mui/icons-material'

import StudentInfoWithCheckbox from '../StudentInfoWithCheckbox'

const VirtualListRowStudent = (props) => {
  const student = props.data.items[props.index]

  const rowRef = useRef()

  useEffect(() => {
    props.data.setSize(
      props.index,
      rowRef.current.getBoundingClientRect().height
    )
  }, [props.index, props.data])

  return (
    <div style={props.style}>
      <div ref={rowRef}>
        {student.errorMessage && (
          <Popover
            content={student.errorMessage}
            sx={{ padding: 0, minWidth: 0 }}>
            <Info
              sx={{ color: 'error.main' }}
              data-testid='student-error-icon'
            />
          </Popover>
        )}
        <StudentInfoWithCheckbox
          dataTestId={props.data.dataTestId}
          onChange={props.data.onChange}
          {...student}
        />
      </div>
    </div>
  )
}

export default VirtualListRowStudent
