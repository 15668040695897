import { styled } from '@mui/material/styles'

const CourseItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isLastItem'
})(({ isLastItem, theme }) => ({
  borderRight: !isLastItem && '1px solid #d9d9d9',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  padding: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    minWidth: `calc(150.4px - 16px)`
  }
}))

export default CourseItemContainer
