import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'

import {
  Button,
  FlexColumn,
  InternalLink,
  LabeledCheckbox,
  SelectField,
  TextField
} from '../shared'

import { useGroupTitleMutation } from './hooks'

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const RosterForm = ({
  isAdmin,
  rosterDetails,
  save,
  staffers,
  studentsInRoster
}) => {
  const { stafferId } = useParams()

  const { rosterId, rosterIsDefault, rosterName } = rosterDetails

  const [defaultValue, setDefaultValue] = useState(rosterIsDefault)
  const [rosterTitleError, setRosterTitleError] = useState('')
  const [rosterTitle, setRosterTitle] = useState(rosterName)
  const [selectedStafferValue, setSelectedStafferValue] = useState(stafferId)

  const { data, mutate } = useGroupTitleMutation()

  useEffect(() => {
    if (data !== undefined && data !== 0) {
      setRosterTitleError('This Group Title is currently in use')
    }
  }, [data])

  const handleGroupTitleChange = (e) => {
    setRosterTitle(e.target.value)
  }

  const handleSelectedStafferChange = (e) => {
    setSelectedStafferValue(e.target.value)

    validateGroupTitle(rosterTitle, e.target.value)
  }

  const saveRoster = (saveAndClose) => {
    save({
      isDefault: defaultValue,
      rosterName: rosterTitle,
      saveAndClose,
      selectedStaffer: selectedStafferValue
    })
  }

  const validateGroupTitle = (rosterValue, stafferValue) => {
    if (rosterValue === rosterName) {
      return
    }

    setRosterTitleError('')

    mutate({ rosterValue, stafferValue })
  }

  return (
    <FlexColumn>
      <TextField
        error={Boolean(rosterTitleError)}
        helperText={rosterTitleError ? rosterTitleError : null}
        id='group-title'
        label='Group Title'
        name='rosterTitle'
        onBlur={() => validateGroupTitle(rosterTitle, selectedStafferValue)}
        onChange={(e) => handleGroupTitleChange(e)}
        value={rosterTitle}
      />
      {data !== undefined && data !== 0 && (
        <>
          <Typography>
            It looks like you are trying to recreate an existing group called{' '}
            {rosterTitle}.
          </Typography>
          <InternalLink
            text='Would you like to edit that group instead?'
            to={`/createRoster/${data}/${selectedStafferValue}`}
          />
        </>
      )}
      {isAdmin && (
        <SelectField
          disabled={rosterId === 0 ? false : true}
          id='selected-staffer'
          label='Selected Staffer'
          menuItems={staffers}
          name='selectedStaffer'
          onChange={(e) => handleSelectedStafferChange(e)}
          selectedValue={selectedStafferValue}
        />
      )}
      <LabeledCheckbox
        checked={defaultValue}
        disabled={rosterIsDefault}
        label='Default'
        name='isDefault'
        onChange={() => setDefaultValue(!defaultValue)}
      />
      {defaultValue && (
        <Typography>
          Please note: Only one Group can be marked as Default. A Group marked
          as Default cannot be unselected. A different Group must be marked as
          Default.
        </Typography>
      )}
      <ButtonsContainer>
        <Button
          disabled={!studentsInRoster || !rosterTitle}
          label={rosterId !== 0 ? 'Save' : 'Save & Close'}
          marginTop
          onClick={() => saveRoster(true)}
          type='submit'
        />
        {rosterId === 0 && (
          <Button
            disabled={!studentsInRoster || !rosterTitle}
            label='Save & Continue'
            marginTop
            onClick={() => saveRoster(false)}
            type='submit'
          />
        )}
      </ButtonsContainer>
    </FlexColumn>
  )
}

export default RosterForm
