import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { useLoggedInUser } from '../../../context'

export const useSaveAppointmentTypesMutation = () => {
  const { loggedInUserId } = useLoggedInUser()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (course) => {
      await axios.post(
        `${endpoints.COURSES}/updateappointmenttype`,
        { ...course, stafferId: loggedInUserId },
        { withCredentials: true }
      )

      return { course }
    },
    {
      onSuccess: ({ course }) => {
        enqueueSnackbar(
          `The default appointment type for ${course.courseName} has been changed`,
          {
            variant: 'success'
          }
        )

        queryClient.invalidateQueries([
          'staffer',
          { userId: parseInt(loggedInUserId) }
        ])
      }
    }
  )
}
