import React from 'react'
import { IconButton } from '@mui/material'
import moment from 'moment'
import { CalendarMonth } from '@mui/icons-material'

import { useSchoolDatesStartAndEnd } from '../../hooks'
import { DatePicker } from '../shared'

import { useAppointments } from './AppointmentsContext'

const CalendarButton = () => {
  const { dateSp, updateSearchParamsByNameValue } = useAppointments()

  const [isOpen, setIsOpen] = React.useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const {
    data = {
      endDate: '',
      startDate: ''
    }
  } = useSchoolDatesStartAndEnd()

  const dateToUse = dateSp === null ? moment() : dateSp

  function handleChange(value) {
    updateSearchParamsByNameValue({
      target: {
        name: 'date',
        value: moment(value).format('YYYY-MM-DD')
      }
    })
  }

  return (
    <>
      <DatePicker
        label='Date'
        maxDate={moment(data.endDate)}
        minDate={moment(data.startDate)}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={isOpen}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          width: '100%'
        }}
        value={moment(dateToUse)}
      />
      <IconButton
        onClick={handleOpen}
        label='Test'
        sx={{
          display: { xs: 'none', sm: 'initial' }
        }}>
        <CalendarMonth />
      </IconButton>
    </>
  )
}

export default CalendarButton
