import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getStudentDisplayName, sortByCollatingField } from '../../../helpers'

export const useStudentsMinimalQuery = () => {
  return useQuery(
    ['students', 'minimal'],
    async () => {
      const response = await axios.get(`${endpoints.STUDENTS}/minimal`, {
        withCredentials: true
      })

      response.data.forEach((r) => {
        r.description = getStudentDisplayName(
          r.firstName,
          r.lastName,
          r.nickname
        )
        r.value = r.userId
      })

      return response.data.sort(sortByCollatingField(true, 'description'))
    },
    {
      staleTime: 1000 * 30
    }
  )
}
