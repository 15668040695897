import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getMessagesQueryKey, sortByCollatingField } from '../../../helpers'

export const useMessagesQuery = () => {
  return useQuery(
    getMessagesQueryKey(),
    async () => {
      const response = await axios.get(endpoints.MESSAGES, {
        withCredentials: true
      })

      response.data.sort(sortByCollatingField(false, 'expiresOnUtc'))

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
