import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemText } from '@mui/material'
import { ArrowRight } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledLink = styled(Link)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none'
}))

const SubNavMenuLink = ({ label, onClick, to }) => {
  return (
    <StyledLink onClick={onClick} to={to}>
      <ListItem button key={label} sx={{ paddingLeft: 2 }}>
        <ArrowRight sx={{ color: '#959292' }} />
        <ListItemText
          sx={{
            color: '#959292',
            fontSize: 15
          }}
          primary={label}
          style={{ paddingLeft: 0 }}
        />
      </ListItem>
    </StyledLink>
  )
}

export default SubNavMenuLink
