import { styled } from '@mui/material/styles'

const ControlsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridAutoRows: 'auto',
  alignItems: 'baseline',

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(2)
  },

  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export default ControlsContainer
