import React, { forwardRef, useState } from 'react'
import { Drawer, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSearchParams } from 'react-router-dom'

import { Button, PaperTitle, TextField } from '../shared'
import { useStudentsUpdateEmailAddressMutation } from '../../hooks'

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',

  marginTop: theme.spacing()
}))

const ContentContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    width: '100vw'
  },

  minWidth: '25vw',
  padding: theme.spacing()
}))

const ChangeEmailDrawer = forwardRef(({ emailAddress, name, userId }, ref) => {
  let [searchParams] = useSearchParams()

  const findByKey = searchParams.get('searchKey')
  const findByValue = searchParams.get('searchValue')

  const { mutate } = useStudentsUpdateEmailAddressMutation({
    findByKey,
    findByValue
  })

  const [state, setState] = useState({
    emailAddress,
    open: false
  })

  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, open: !state.open })
  }

  const save = () => {
    mutate({
      name,
      emailAddress: state.emailAddress,
      userId
    })

    setState({ ...state, open: false })
  }

  const updateEmailAddress = (e) => {
    setState({ ...state, emailAddress: e.target.value })
  }

  return (
    <div>
      <MenuItem onClick={toggleDrawer()}>Change Email</MenuItem>
      <Drawer anchor={'right'} open={state.open} onClose={toggleDrawer()}>
        <ContentContainer>
          <PaperTitle sx={{ borderBottom: '1px solid #d9d9d9' }}>
            {name}
          </PaperTitle>
          <TextField
            label='Email Address'
            onChange={updateEmailAddress}
            value={state.emailAddress}
          />
          <ButtonContainer>
            <Button
              esButtonClass='cancelButton'
              label='Cancel'
              marginRight
              onClick={toggleDrawer()}
            />
            <Button label='Save' onClick={save} />
          </ButtonContainer>
        </ContentContainer>
      </Drawer>
    </div>
  )
})

export default ChangeEmailDrawer
