import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getSoftDeletedUsersQueryKey } from '../../../helpers'

export const useBaseUserGetSoftDeletedQuery = () => {
  return useQuery(
    getSoftDeletedUsersQueryKey(),
    async () => {
      const response = await axios.get(
        `${endpoints.BASE_USER}/getsoftdeleted`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
