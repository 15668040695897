import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { sortByCollatingField } from '../helpers'

const useSortedCachedData = ({
  initialSortedValue,
  initialSortAscending = true
}) => {
  const queryClient = useQueryClient()

  const [lastSortedDetails, setLastSortedDetails] = useState({
    value: initialSortedValue,
    sortAscending: initialSortAscending
  })

  const sortDataBy = ({ queryKey, value }) => {
    const newSortValue =
      lastSortedDetails.value === value
        ? !lastSortedDetails.sortAscending
        : true

    queryClient.setQueryData(queryKey, (prevValues) => {
      setLastSortedDetails({ value, sortAscending: newSortValue })

      return prevValues.sort(sortByCollatingField(newSortValue, value))
    })
  }

  return { lastSortedDetails, sortDataBy }
}

export default useSortedCachedData
