import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getStaffersIncludeAllQueryKey,
  sortArrayByLastNameFirstName
} from '../../../helpers'

export const useStaffIncludeAllQuery = () => {
  return useQuery(
    getStaffersIncludeAllQueryKey(),
    async () => {
      const response = await axios.get(`${endpoints.STAFF}/includeall`, {
        withCredentials: true
      })

      response.data = sortArrayByLastNameFirstName(response.data)

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
