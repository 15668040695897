import React from 'react'

import StudentList from './StudentList'

const StudentsMainList = ({
  listHeading = 'Unscheduled Students',
  moveAllStudents,
  moveStudent,
  students
}) => {
  return (
    <StudentList
      listHeading={listHeading}
      moveAllStudents={moveAllStudents}
      moveStudent={moveStudent}
      students={students}
      studentType='unscheduled'
    />
  )
}

export default StudentsMainList
