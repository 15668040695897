import { useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getConfigurationsQueryKey } from '../../../helpers'

export const useConfigurationQuery = () => {
  const [configurations, setConfigurations] = useState({})
  const [dataIsAltered, setDataIsAltered] = useState(false)

  const updateConfigurationsByNameAndChecked = (e) => {
    const { checked, name } = e.target

    setDataIsAltered(true)
    setConfigurations({ ...configurations, [name]: checked })
  }

  const updateConfigurationsByNameAndValue = (e) => {
    const { name, value } = e.target

    setDataIsAltered(true)
    setConfigurations((s) => ({
      ...s,
      [name]:
        name === 'selfSchedulingOpensAtBeginningOfNDaysOut' ||
        name === 'selfSchedulingClosesAfterNDaysOut' ||
        name === 'numberOfDaysOnStudentSchedulePage' ||
        name === 'numberOfDaysBeforeNotificationsStart'
          ? parseInt(value)
          : value
    }))
  }

  const { isFetching, isLoading } = useQuery(
    getConfigurationsQueryKey(),
    async () => {
      const response = await axios.get(endpoints.CONFIGURATION, {
        withCredentials: true
      })

      setConfigurations(response.data)
    }
  )

  return {
    configurations,
    dataIsAltered,
    isLoading: isFetching || isLoading,
    setDataIsAltered,
    updateConfigurationsByNameAndChecked,
    updateConfigurationsByNameAndValue
  }
}
