import { useQuery } from 'react-query'
import axios from 'axios'
import moment from 'moment'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useAppointmentsGetAppointmentsStudentProfileQuery = ({
  enabled = true,
  selectedNumberOfDays,
  studentId
}) => {
  return useQuery(
    [
      'appointments',
      'get-appointments-student-profile',
      { selectedNumberOfDays, studentId: parseInt(studentId) }
    ],
    async () => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/getappointmentsstudentprofile`,
        {
          numberOfDaysToRetrieve: selectedNumberOfDays,
          startDate: moment().format('YYYY-MM-DD'),
          studentIds: [parseInt(studentId)]
        },
        { withCredentials: true }
      )

      // console.log(response.data, 'response.data')
      // response.data.forEach((r) => {
      //   let unitTotal = response.data.filter(
      //     (item) => item.courseId === r.courseId
      //   ).length
      //   let unitGrandTotal = response.data.length
      //   const percentage = (unitTotal / unitGrandTotal) * 100

      //   r.percentage = isNaN(percentage) ? 0 : percentage
      //   r.unitTotal = unitTotal
      //   r.unitGrandTotal = unitGrandTotal
      // })

      let courseNames = []

      response.data.forEach((item) => {
        courseNames.push(item.courseName)
      })

      return {
        appointments: response.data.sort(
          sortByCollatingField(false, 'appointmentDate')
        ),
        courses: [...new Set(courseNames)]
      }
    },
    {
      enabled,
      staleTime: 1000 * 60
    }
  )
}
