import React from 'react'
import { Cancel, CheckCircleRounded } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledCheck = styled(CheckCircleRounded)(({ theme }) => ({
  color: theme.palette.success.main
}))

const StyledCancel = styled(Cancel)(() => ({
  color: 'gray'
}))

const ActiveIcon = ({
  active,
  activeTitleAccess = 'active',
  inactiveTitleAccess = 'inactive'
}) => {
  return (
    <>
      {active ? (
        <StyledCheck titleAccess={activeTitleAccess} />
      ) : (
        <StyledCancel titleAccess={inactiveTitleAccess} />
      )}
    </>
  )
}

export default ActiveIcon
