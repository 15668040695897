import React from 'react'
import { Box } from '@mui/material'

import Checkbox from './Checkbox'
import StudentInfo from './StudentInfo'

const StudentInfoWithCheckbox = ({
  dataTestId,
  firstName,
  lastName,
  nickname,
  onChange,
  sisId,
  userId,
  yearOfGraduation
}) => {
  return (
    <Box
      data-testid={dataTestId}
      sx={{
        display: { xs: 'flex', sm: 'grid' },
        justifyContent: 'space-between',
        gridTemplateColumns: { sm: 'repeat(12, 1fr)' },
        gridAutoRows: { sm: 'auto' },
        alignItems: { sm: 'center' },
        paddingLeft: { sm: 1 },
        paddingRight: { sm: 1 }
      }}>
      <Box sx={{ gridColumn: { xs: '1/6', sm: '1/11' } }}>
        <StudentInfo
          firstName={firstName}
          lastName={lastName}
          nickName={nickname}
          sisId={sisId}
          userId={userId}
          yearOfGraduation={yearOfGraduation}
        />
      </Box>
      <Box
        sx={{
          gridColumn: { xs: '6/7', sm: '11/13' },
          textAlign: 'center'
        }}>
        <Checkbox
          checked={false}
          dataTestId={`${dataTestId}-checkbox`}
          onChange={() => onChange(userId)}
        />
      </Box>
    </Box>
  )
}

export default StudentInfoWithCheckbox
