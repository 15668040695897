import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getDashboardStrugglingStudentsQueryKey,
  sortArrayByLastNameNickNameFirstName,
  sortByCollatingField
} from '../../../helpers'

export const useStrugglingStudentsQuery = (enabled = true) => {
  return useQuery(
    getDashboardStrugglingStudentsQueryKey(),
    async () => {
      const response = await axios.get(endpoints.STRUGGLING_STUDENTS, {
        withCredentials: true
      })

      const result = response.data.reduce(function (objectOfArrays, obj) {
        objectOfArrays[obj.userId] = objectOfArrays[obj.userId] || []
        objectOfArrays[obj.userId].push(obj)
        return objectOfArrays
      }, Object.create(null))

      const arrayed = Object.values(result)
      let final = []

      arrayed.forEach((array) => {
        let object = {}
        let courses = []

        array.forEach((obj) => {
          courses.push({
            description: obj.course,
            grade: obj.gradeValue,
            isBelowCutOff: obj.isBelowCutOff,
            stafferDisplayName:
              obj.stafferLastName + ', ' + obj.stafferFirstName
          })
        })

        object.courses = courses.sort(sortByCollatingField(true, 'description'))
        object.firstName = array[0].firstName
        object.lastName = array[0].lastName
        object.nickname = array[0].nickname
        object.sisId = array[0].sisId
        object.userId = array[0].userId
        object.yearOfGraduation = array[0].yearOfGraduation

        final.push(object)
      })

      return sortArrayByLastNameNickNameFirstName(final)
    },
    {
      enabled,
      staleTime: 1000 * 30
    }
  )
}
