import { useState } from 'react'

const useOpen = () => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }

  return { open, handleClose, handleOpen, toggleOpen }
}

export default useOpen
