import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const Subheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText sx={{ gridColumn: '1/4' }} text='Name' />
      <SubheadingText sx={{ gridColumn: '4/7' }} text='Attendance Type' />
      <SubheadingText sx={{ gridColumn: '7/8' }} text='Tardy' />
      <SubheadingText
        sx={{ gridColumn: '8/10', textAlign: 'center' }}
        text='Attendance Comment'
      />
      <SubheadingText
        sx={{ gridColumn: '10/12', textAlign: 'center' }}
        text='Scheduler Comment'
      />
    </SubheadingContainer>
  )
}

export default Subheading
