import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'
import moment from 'moment'

export const useCoursesForDatePeriodStudentIdQuery = ({
  date,
  periodId,
  studentId
}) => {
  const formattedDate = moment(date).format('YYYY-MM-DD')

  return useQuery(
    [
      'courses',
      'for-date-and-period',
      {
        date: formattedDate,
        periodId: parseInt(periodId),
        studentId: parseInt(studentId)
      }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.COURSES}/fordateandperiod/${formattedDate}/${periodId}/${studentId}`,
        { withCredentials: true }
      )

      response.data.forEach((r) => {
        const availableSeats = r.maxSeatCount - r.numberOfAppointmentsByStaffer

        const blockedDescription = `Course: ${r.courseName} - Blocked: ${r.blockedReason}`
        const nonBlockedDescription = `Course: ${r.courseName} - Seats Available: ${availableSeats}`

        r.availableSeats = availableSeats
        r.description =
          r.isBlockedForSchool || r.isBlockedForStaffer
            ? blockedDescription
            : nonBlockedDescription
        r.value = r.courseId
      })

      return response.data.sort(sortByCollatingField(true, 'description'))
    },
    {
      enabled:
        studentId !== null &&
        studentId !== undefined &&
        !!periodId &&
        !!date &&
        periodId !== '0',
      staleTime: 1000 * 30
    }
  )
}
