import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { Typography } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import {
  BLOCKED_DATE_SAVE_STATUS,
  BLOCKED_DATE_TYPES,
  endpoints
} from '../../../constants'
import { Button } from '../../../components/shared'

export const useBlockedDatesMutation = ({
  departments,
  loggedInUserId,
  periods,
  reset,
  staffers
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ details, redirectAfterSave }) => {
      const response = await axios.post(endpoints.BLOCKED_DATES, details, {
        withCredentials: true
      })

      return { data: response.data, details, redirectAfterSave }
    },
    {
      onSuccess: ({ data, details, redirectAfterSave }) => {
        queryClient.invalidateQueries(['blocked-dates'])
        queryClient.invalidateQueries(['appointments'])
        if (
          data[0].saveStatus ===
          BLOCKED_DATE_SAVE_STATUS.accountDoesntHavePermissions
        ) {
          enqueueSnackbar(
            'This account has been blocked from creating a blocked date. An Admin will have to adjust this account.',
            {
              variant: 'error'
            }
          )
        } else if (
          data[0].saveStatus === BLOCKED_DATE_SAVE_STATUS.noDatesToSave
        ) {
          enqueueSnackbar(
            'The provided date is outside of the current school year and cannot be saved.',
            {
              variant: 'error'
            }
          )
        } else if (
          data[0].saveStatus === BLOCKED_DATE_SAVE_STATUS.noStafferToBlock
        ) {
          if (details.blockedDateType === BLOCKED_DATE_TYPES.byDepartment) {
            const selectedDepartment = departments.find(
              (d) => d.value === details.departmentId
            )

            enqueueSnackbar(
              `There are no Staffers associated with ${selectedDepartment.description}.  A Blocked Date was not created.`,
              {
                variant: 'error'
              }
            )
          }

          if (details.blockedDateType === BLOCKED_DATE_TYPES.byStaff) {
            enqueueSnackbar(
              `Unable to create a blocked date for this Staffer.  Please ensure that the staffer is active.`,
              {
                variant: 'error'
              }
            )
          }
        } else {
          let errors = []

          data.forEach((r) => {
            if (r.saveStatus !== BLOCKED_DATE_SAVE_STATUS.success) {
              errors.push(r)
            }
          })

          if (errors.length > 0) {
            const action = (key) => (
              <>
                <Button
                  esButtonClass='greyButton'
                  label='Dismiss'
                  onClick={() => closeSnackbar(key)}
                />
              </>
            )

            enqueueSnackbar(
              <div>
                <Typography style={{ fontWeight: 'bold' }}>Errors:</Typography>
                {errors.map((err, i) => {
                  const selectedPeriod = periods.find(
                    (period) => period.value === err.periodToBlock
                  )

                  return (
                    <Typography key={i}>
                      {err.stafferLastName}, {err.stafferFirstName} could not be
                      blocked for Period: {selectedPeriod.description} on{' '}
                      {moment(err.dateToBlock).format('MM/DD/YYYY')}
                    </Typography>
                  )
                })}
              </div>,
              {
                action,
                variant: 'error',
                persist: true
              }
            )
          } else {
            if (details.blockedDateType === BLOCKED_DATE_TYPES.byDepartment) {
              const selectedDepartment = departments.find(
                (d) => d.value === details.departmentId
              )

              enqueueSnackbar(
                `Blocked Date has been created for the ${selectedDepartment.description} department`,
                {
                  variant: 'success'
                }
              )
            }

            if (details.blockedDateType === BLOCKED_DATE_TYPES.bySchool) {
              enqueueSnackbar('Blocked Date has been created for the School', {
                variant: 'success'
              })
            }

            if (details.blockedDateType === BLOCKED_DATE_TYPES.byStaff) {
              const selectedStaffer = staffers.find(
                (s) => s.value === details.stafferId
              )

              if (selectedStaffer.value === loggedInUserId) {
                enqueueSnackbar(`Blocked Date has been created for you`, {
                  variant: 'success'
                })
              } else {
                enqueueSnackbar(
                  `Blocked Date has been created for ${selectedStaffer.description}`,
                  {
                    variant: 'success'
                  }
                )
              }
            }
          }

          if (!redirectAfterSave) {
            reset()
          } else {
            navigate('/blockDate')
          }
        }
      }
    }
  )
}
