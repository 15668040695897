export const handleOnDragEnd = ({ data, result }) => {
  const { destination, source } = result

  // dropped outside the list
  if (!destination) {
    return null
  }

  // item hasn't moved
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return null
  }

  const sourceIndex = result.source.index
  const destinationIndex = result.destination.index

  let array = data.slice()
  const [removed] = array.splice(sourceIndex, 1)
  array.splice(destinationIndex, 0, removed)

  return array
}
