import React from 'react'
import { Box, InputAdornment, Typography } from '@mui/material'
import { FilterList } from '@mui/icons-material'

import {
  Button,
  List,
  MobileSorting,
  PaginationOptions,
  Paper,
  PaperTitle,
  TextField
} from '../shared'

import BulkEdit from './BulkEdit'
import FiltersMenu from './FiltersMenu'
import Legend from './Legend'
import StaffListItem from './StaffListItem'
import Subheading from './Subheading'
import {
  ButtonAndLicenseInfo,
  LegendInfoContainer,
  TitleAndFilter
} from './styledComponents'

const Staffers = ({
  bulkEditing,
  configurations,
  currentPage,
  deleteStaffer,
  departmentIdSearchParam,
  departments,
  filteredStaffers,
  filterTerm,
  handleFilterChange,
  handleSelectAllChange,
  isActiveSearchParam,
  lastSortedDetails,
  numberOfUsers,
  pageSize,
  saveBulkEdit,
  selectAll,
  selectedStaffIds,
  setPageSize,
  sortStaffersBy,
  updateBulkEditing,
  updateSearchParams,
  updateStafferIsSelected,
  userTypeSearchParam
}) => {
  const handleUpdateSearchParams = () => {
    updateSearchParams({
      target: { name: 'filter', value: filterTerm }
    })
  }

  const licenseBalance = (
    configurations.numberOfLicenses - numberOfUsers
  ).toString()

  return (
    <>
      <LegendInfoContainer>
        <Legend />
        <ButtonAndLicenseInfo>
          <Box sx={{ flex: 1 }}>
            <Button
              disableElevation
              esButtonClass='greyButton'
              label='Bulk edit'
              onClick={updateBulkEditing}
            />
          </Box>
          <Typography sx={{ flex: 2 }}>
            Your school has purchased <b>{configurations.numberOfLicenses}</b>{' '}
            licenses and has activated <b>{numberOfUsers}</b> of them, leaving{' '}
            <b>{licenseBalance}</b> available for use.
          </Typography>
          <FiltersMenu
            departmentIdSearchParam={departmentIdSearchParam}
            departments={departments}
            isActiveSearchParam={isActiveSearchParam}
            updateSearchParams={updateSearchParams}
            userTypeSearchParam={userTypeSearchParam}
          />
        </ButtonAndLicenseInfo>
      </LegendInfoContainer>
      <BulkEdit
        bulkEditing={bulkEditing}
        departments={departments}
        handleSelectAllChange={handleSelectAllChange}
        saveBulkEdit={saveBulkEdit}
        selectAll={selectAll}
        selectedStaffIds={selectedStaffIds}
      />
      <Paper>
        <TitleAndFilter>
          <PaperTitle>Staffers</PaperTitle>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment
                  onClick={handleUpdateSearchParams}
                  position='start'
                  sx={{ cursor: 'pointer' }}>
                  <FilterList />
                </InputAdornment>
              )
            }}
            name='filter'
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleUpdateSearchParams()
              }
            }}
            placeholder='Search for Staffer'
            onChange={handleFilterChange}
            value={filterTerm}
          />
        </TitleAndFilter>
        <Subheading
          bulkEditing={bulkEditing}
          handleSelectAllChange={handleSelectAllChange}
          lastSortedBy={lastSortedDetails.value}
          onClick={sortStaffersBy}
          selectAll={selectAll}
          sortAscending={lastSortedDetails.sortAscending}
        />
        <MobileSorting
          initialValue={lastSortedDetails.value}
          menuItems={[
            {
              description: 'Active',
              value: 'userIsActive'
            },
            {
              description: 'Last Name',
              value: 'lastName'
            }
          ]}
          onClick={sortStaffersBy}
        />
        <List
          bulkEditing={bulkEditing}
          Component={StaffListItem}
          data={filteredStaffers.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
          deleteStaffer={deleteStaffer}
          departments={departments}
          keyValue={'userId'}
          selectedStaffIds={selectedStaffIds}
          updateStafferIsSelected={updateStafferIsSelected}
        />
        <PaginationOptions
          defaultCurrent={currentPage}
          handlePaginationChange={(value) =>
            updateSearchParams({ target: { name: 'currentPage', value } })
          }
          handlePageSizeChange={setPageSize}
          pageSize={pageSize}
          total={filteredStaffers.length}
        />
      </Paper>
    </>
  )
}

export default Staffers
