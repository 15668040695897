import { styled } from '@mui/material/styles'

const StyledDateInput = styled('input')(({ theme }) => ({
  width: 0,
  border: 'none',
  cursor: 'none',
  background: 'transparent',
  paddingLeft: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    paddingLeft: 0
  }
}))

export default StyledDateInput
