import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useStudentsBulkEditMutation = ({
  options,
  resetLocalState,
  staffers
}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      object,
      selectedHomeroomStaffer,
      selectedSelfScheduleOption,
      selectedOverwriteOption
    }) => {
      await axios.post(`${endpoints.STUDENTS}/bulkedit`, object, {
        withCredentials: true
      })

      return {
        selectedHomeroomStaffer,
        selectedSelfScheduleOption,
        selectedOverwriteOption
      }
    },
    {
      onSuccess: (props) => {
        const {
          selectedHomeroomStaffer,
          selectedSelfScheduleOption,
          selectedOverwriteOption
        } = props

        let staffer

        if (selectedHomeroomStaffer !== '' && selectedHomeroomStaffer !== -10) {
          staffer = staffers.find(
            (staff) => staff.userId === selectedHomeroomStaffer
          )
        }

        queryClient.setQueryData(
          ['students', options.key, options.value],
          (prevStudents) => {
            return [
              ...prevStudents.map((student) => {
                if (!student.isSelected) return student
                return {
                  ...student,
                  isSelected: false,
                  canOverrideAppts:
                    selectedOverwriteOption !== ''
                      ? selectedOverwriteOption
                      : student.canOverrideAppts,
                  canScheduleSelf:
                    selectedSelfScheduleOption !== ''
                      ? selectedSelfScheduleOption
                      : student.canSelfSchedule,
                  stafferFirstName:
                    selectedHomeroomStaffer !== '' &&
                    selectedHomeroomStaffer !== -10
                      ? staffer.firstName
                      : student.stafferFirstName,
                  stafferLastName:
                    selectedHomeroomStaffer !== '' &&
                    selectedHomeroomStaffer !== -10
                      ? staffer.lastName
                      : student.stafferLastName
                }
              })
            ]
          }
        )

        queryClient.invalidateQueries(['students'])

        resetLocalState()
      }
    }
  )
}
