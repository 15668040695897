import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { useLoggedInUser } from '../../../context'
import { sortByCollatingField } from '../../../helpers'

export const useCoteacherWherePrimaryAndSecondaryQuery = ({
  enabled,
  useLoggedInUserDetails,
  userId
}) => {
  const { loggedInUserFirstName, loggedInUserId, loggedInUserLastName } =
    useLoggedInUser()

  return useQuery(
    [
      'coteacher',
      'where-primary-and-secondary',
      { useLoggedInUserDetails, userId: parseInt(userId) }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.COTEACHER}/whereprimaryandsecondary/${userId}`,
        {
          withCredentials: true
        }
      )

      response.data.forEach((staffer) => {
        staffer.description = `${staffer.lastName}, ${staffer.firstName}`
        staffer.value = staffer.userId
      })

      if (useLoggedInUserDetails) {
        response.data.push({
          description: `${loggedInUserLastName}, ${loggedInUserFirstName}`,
          firstName: loggedInUserFirstName,
          lastName: loggedInUserLastName,
          userId: loggedInUserId,
          value: loggedInUserId
        })
      }

      return response.data.sort(sortByCollatingField(true, 'description'))
    },
    {
      enabled,
      staleTime: 1000 * 30
    }
  )
}
