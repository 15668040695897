import React from 'react'
import { styled } from '@mui/material/styles'

import LinkButton from './LinkButton'
import UserManual from './UserManual'

export const StyledUserManualWithLinkButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  marginBottom: theme.spacing(2)
}))
const UserManualWithLinkButton = ({
  className,
  hideButton = false,
  href,
  label,
  state = {},
  to
}) => {
  return (
    <StyledUserManualWithLinkButton>
      <UserManual href={href} />
      {!hideButton && <LinkButton label={label} state={state} to={to} />}
    </StyledUserManualWithLinkButton>
  )
}

export default UserManualWithLinkButton
