import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useStudentsDeleteMutation = ({ key, value }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutation(
    async (userId) => {
      await axios.delete(`${endpoints.STUDENTS}/delete/${userId}`, {
        withCredentials: true
      })

      return userId
    },
    {
      onSuccess: (userId) => {
        queryClient.setQueryData(['students', key, value], (prevStudents) => {
          const studentToRemove = prevStudents.find(
            (student) => student.userId === userId
          )

          enqueueSnackbar(
            `${studentToRemove.lastName}, ${studentToRemove.firstName} has been deleted`,
            {
              variant: 'success'
            }
          )

          return [
            ...prevStudents.filter(
              (student) => student.userId !== studentToRemove.userId
            )
          ]
        })
      }
    }
  )
}
