import React, { useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { esuUrls } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  useConfigurationGraduationYearsQuery,
  useHeaderTitle,
  useStaffQuery,
  useStudentsBulkEditMutation,
  useStudentsQuery
} from '../../hooks'
import {
  Button,
  LinkButton,
  List,
  Loading,
  PaginationOptions,
  PageInstructions,
  Paper,
  PaperTitle,
  SearchField,
  UserManual
} from '../shared'

import {
  BulkEdit,
  FiltersMenu,
  GetStudentsMenu,
  Legend,
  LegendAndBulkEdit,
  SearchDetails,
  SearchOptions,
  StudentItem,
  Subheading,
  TitleAndOptions,
  UserManualAndLinkButton
} from '../Students'
import { useScrollToPosition } from '../ManageStaff'
import { filteredStudentsMapping } from '../Students/filteredStudentsMapping'

const Students = () => {
  useHeaderTitle('Students')

  const location = useLocation()
  const { isAdmin } = useLoggedInUser()
  let [searchParams, setSearchParams] = useSearchParams()

  const searchKey = searchParams.get('searchKey')
  const searchValue = searchParams.get('searchValue')

  const [bulkEditing, setBulkEditing] = useState(false)
  const [pageSize, setPageSize] = useState(25)
  const [selectedStudentIds, setSelectedStudentIds] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const { data: graduationYears, isLoading: isLoadingGraduationYears } =
    useConfigurationGraduationYearsQuery({ useAll: true })

  const { data: students = [], isLoading: isLoadingStudents } =
    useStudentsQuery({
      key: searchParams.get('searchKey'),
      value: searchParams.get('searchValue')
    })

  const { data: staffers = [], isLoading: isLoadingStaffers } = useStaffQuery({
    mustHaveActiveHomeroomCourse: false
  })

  const { isLoading: isBulkEditing, mutate: saveBulkEditChanges } =
    useStudentsBulkEditMutation({
      resetLocalState: () => {
        setSelectAll(false)
        setSelectedStudentIds([])
      },
      staffers,
      options: {
        key: searchParams.get('searchKey'),
        value: searchParams.get('searchValue')
      }
    })

  const stafferDetails =
    searchParams.get('searchKey') !== 'selectedStaffer'
      ? undefined
      : staffers.find(
          (staffer) =>
            staffer.value === parseInt(searchParams.get('searchValue'))
        )

  useScrollToPosition({
    isLoading:
      isLoadingStudents || isLoadingGraduationYears || isLoadingStaffers,
    location
  })

  const getFilteredStudents = () => {
    let copy = [...students]

    for (let filter in filteredStudentsMapping) {
      const filterValue = searchParams.get(filter)

      // '0' is the default value for all filters
      if (filterValue && filterValue !== '0') {
        copy = copy.filter(filteredStudentsMapping[filter][filterValue])
      }
    }

    return copy
  }

  const handleSelectAllChange = (e) => {
    const { checked } = e.target
    setSelectAll(checked)

    if (!checked) {
      setSelectedStudentIds([])
    }

    if (checked) {
      let array = []

      students.forEach((student) => array.push(student.userId))

      setSelectedStudentIds(array)
    }
  }

  const updateBulkEditing = () => {
    setBulkEditing(!bulkEditing)
  }

  const updateFiltersByNameValue = (e) => {
    const { name, value } = e.target
    const stringedValue = value.toString()

    const filterMap = {
      currentPage: 'currentPage',
      selectedActiveStatus: 'activeFilter',
      selectedGradeCutOffStatus: 'gradeCutOffFilter',
      selectedCanOverrideStatus: 'overrideFilter',
      selectedCanScheduleStatus: 'selfScheduleFilter'
    }

    Object.keys(filterMap).forEach((key) => {
      let filterValue =
        name === key ? stringedValue : searchParams.get(filterMap[key])
      if (filterValue !== null) {
        searchParams.set(filterMap[key], filterValue)
      }
    })

    setSearchParams(searchParams)
  }

  const updateStudentIsSelected = (userId, checked) => {
    if (!checked) {
      setSelectedStudentIds([
        ...selectedStudentIds.filter((id) => id !== userId)
      ])
    }

    if (checked) {
      setSelectedStudentIds([...selectedStudentIds, userId])
    }
  }

  let currentPage =
    searchParams.get('currentPage') === null
      ? 0
      : parseInt(searchParams.get('currentPage'))

  if (isLoadingGraduationYears || isLoadingStudents || isLoadingStaffers)
    return <Loading text='Loading Students' />
  if (isBulkEditing) return <Loading text='Saving Changes' />

  return (
    <div>
      <UserManualAndLinkButton>
        <UserManual
          href={isAdmin ? esuUrls.STUDENTS_ADMIN : esuUrls.STUDENTS}
        />
        {isAdmin && (
          <LinkButton
            label='Add Student'
            to='/admin/addStudent/0'
            state={{
              activeFilter: searchParams.get('activeFilter'),
              currentPage: searchParams.get('currentPage'),
              overrideFilter: searchParams.get('overrideFilter'),
              searchKey: searchParams.get('searchKey'),
              searchValue: searchParams.get('searchValue'),
              selfScheduleFilter: searchParams.get('selfScheduleFilter')
            }}
          />
        )}
      </UserManualAndLinkButton>
      <PageInstructions>
        {isAdmin
          ? "On this page, you can add, edit, or remove students from your Enriching Students account. Use the filter icon to get students by graduation year, homeroom teacher, or last name. Or, type at least one letter in the student's name in ‘Search by Name’ to find the student you are looking for."
          : "On this page, you can search for students in your Enriching Students account, and click on their name to view their profile. Use the filter icon to get students by graduation year, homeroom teacher, or last name. Or, type at least one letter in the student's name in ‘Search by Name’ to find the student you are looking for."}
      </PageInstructions>
      <Paper>
        <TitleAndOptions>
          <PaperTitle sx={{ marginRight: 2 }}>Students</PaperTitle>
          <SearchOptions>
            <GetStudentsMenu
              graduationYears={graduationYears}
              staffers={staffers}
            />
            <SearchField
              handleSearch={(value) =>
                setSearchParams({ searchKey: 'searchTerm', searchValue: value })
              }
              initialValue={searchKey === 'searchTerm' ? searchValue : ''}
              sx={{ marginRight: 1 }}
            />
            {students.length > 0 && (
              <FiltersMenu
                updateFiltersByNameValue={updateFiltersByNameValue}
              />
            )}
          </SearchOptions>
        </TitleAndOptions>
        <SearchDetails stafferDetails={stafferDetails} />
        <LegendAndBulkEdit>
          <Legend />
          {isAdmin && (
            <Button
              esButtonClass='greyButton'
              label='Bulk edit'
              onClick={updateBulkEditing}
            />
          )}
        </LegendAndBulkEdit>
        {isAdmin && (
          <BulkEdit
            bulkEditing={bulkEditing}
            handleSelectAllChange={handleSelectAllChange}
            selectAll={selectAll}
            selectedStudentIds={selectedStudentIds}
            staffers={staffers}
            saveBulkEditChanges={saveBulkEditChanges}
          />
        )}
        <Subheading
          bulkEditing={bulkEditing}
          handleSelectAllChange={handleSelectAllChange}
          selectAll={selectAll}
        />
        <List
          bulkEditing={bulkEditing}
          Component={StudentItem}
          data={getFilteredStudents().slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
          emptyArrayMessage='There are no students to display. Please use the filters above.'
          isAdmin={isAdmin}
          keyValue='userId'
          selectedStudentIds={selectedStudentIds}
          updateStudentIsSelected={updateStudentIsSelected}
        />
        <PaginationOptions
          defaultCurrent={currentPage}
          handlePaginationChange={(value) =>
            updateFiltersByNameValue({ target: { name: 'currentPage', value } })
          }
          handlePageSizeChange={setPageSize}
          pageSize={pageSize}
          total={getFilteredStudents().length}
        />
      </Paper>
    </div>
  )
}

export default Students
