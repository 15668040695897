import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const CourseItemDate = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontSize: 14,
  textAlign: 'center'
}))

export default CourseItemDate
