import React, { forwardRef, useImperativeHandle } from 'react'
import { Button as MuiButton, Menu } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useAnchorEl } from '../../hooks'

import Button from './Button'

const Container = styled('div')(() => ({}))

const EsMenu = forwardRef(
  (
    {
      buttonAriaLabel,
      buttonClass,
      children,
      classes,
      className,
      dataTestId = 'menu',
      disabled = false,
      esButtonClass = 'greyButton',
      label = 'Actions',
      onClick,
      buttonSx,
      sx
    },
    ref
  ) => {
    const { anchorEl, handleClose, setAnchorEl } = useAnchorEl()

    const handleClick = (e) => {
      onClick && onClick()

      setAnchorEl(e.currentTarget)
    }

    useImperativeHandle(ref, () => ({
      closeMenuFromParent() {
        handleClose()
      }
    }))

    return (
      <Container sx={sx} className={className}>
        {typeof label === 'string' && (
          <Button
            sx={buttonSx}
            className={buttonClass}
            dataTestId={dataTestId}
            disabled={disabled}
            disableElevation
            esButtonClass={esButtonClass}
            label={label}
            onClick={handleClick}
            style={{ marginRight: 8 }}
          />
        )}
        {typeof label === 'object' && (
          <MuiButton
            sx={buttonSx}
            aria-label={buttonAriaLabel}
            data-testid={dataTestId}
            label={label}
            onClick={handleClick}>
            {label}
          </MuiButton>
        )}
        <Menu
          sx={{ zIndex: 1000 }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          classes={classes}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          {children}
        </Menu>
      </Container>
    )
  }
)

export default EsMenu
