import React from 'react'
import { Button, FormControlLabel, Menu, MenuItem, Switch } from '@mui/material'
import { Settings } from '@mui/icons-material'

import {
  getParsedValueFromStringifiedObject,
  toggleStringifiedObjectProp,
  updateStringifiedObjectProp
} from '../../helpers'
import { useAnchorEl } from '../../hooks'
import { SelectField } from '../shared'

import { useAppointments } from './AppointmentsContext'

const PageSettingsMenu = () => {
  const { pageSettings, numberOfDays, setPageSettings } = useAppointments()

  const { anchorEl, handleClose, handleOpen } = useAnchorEl()

  return (
    <div>
      <Button onClick={handleOpen}>
        <Settings sx={{ color: '#757575' }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        elevation={0}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={getParsedValueFromStringifiedObject(
                  'useMinifiedView',
                  pageSettings
                )}
                onChange={() =>
                  setPageSettings(
                    toggleStringifiedObjectProp('useMinifiedView', pageSettings)
                  )
                }
                name='listView'
                color='primary'
              />
            }
            label='Toggle View'
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={
                  !getParsedValueFromStringifiedObject(
                    'showSearchByName',
                    pageSettings
                  )
                }
                onChange={() =>
                  setPageSettings(
                    toggleStringifiedObjectProp(
                      'showSearchByName',
                      pageSettings
                    )
                  )
                }
                name='listView'
                color='primary'
              />
            }
            label='Hide Search By Name or SIS Id'
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={
                  !getParsedValueFromStringifiedObject(
                    'showSelectedStaffer',
                    pageSettings
                  )
                }
                onChange={() =>
                  setPageSettings(
                    toggleStringifiedObjectProp(
                      'showSelectedStaffer',
                      pageSettings
                    )
                  )
                }
                name='listView'
                color='primary'
              />
            }
            label='Hide Selected Staffer'
          />
        </MenuItem>
        <MenuItem sx={{ display: { xs: 'none', sm: 'block' } }}>
          <SelectField
            label='Number of Days'
            menuItems={[
              { description: '5', value: '5' },
              { description: '6', value: '6' },
              { description: '7', value: '7' },
              { description: '8', value: '8' },
              { description: '9', value: '9' }
            ]}
            onChange={(e) =>
              setPageSettings(
                updateStringifiedObjectProp(
                  'selectedNumberOfDays',
                  e.target.value,
                  pageSettings
                )
              )
            }
            selectedValue={numberOfDays}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default PageSettingsMenu
