import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const CollisionSubheading = ({ disableExclude }) => {
  return (
    <SubheadingContainer>
      <SubheadingText
        sx={{
          gridColumn: '1/5'
        }}
        text='Period'
      />
      <SubheadingText
        sx={{
          gridColumn: '5/7'
        }}
        text='Date'
      />
      <SubheadingText
        sx={{
          gridColumn: '7/11'
        }}
        text='Reason'
      />
      {!disableExclude && (
        <SubheadingText
          sx={{
            gridColumn: '11/13',
            textAlign: 'center'
          }}
          text='Exclude'
        />
      )}
    </SubheadingContainer>
  )
}

export default CollisionSubheading
