import { useSnackbar } from 'notistack'
import moment from 'moment'
import React, { createContext, useContext, useState } from 'react'
import { Button, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import axios from 'axios'

import { useLoggedInUser } from '.'
import { ADJUSTED_OFFERING_SAVE_STATUS_IDS, ROOT } from '../constants'
import { sortByCollatingField } from '../helpers'
import {
  useAppointmentTypesQuery,
  useConfigurationGraduationYearsQuery,
  useStaffByIdQuery,
  useStaffQuery
} from '../hooks'

const AdjustedOfferingsContext = createContext()

const AdjustedOfferingsProvider = ({ children }) => {
  const { isAdmin, loggedInUserId } = useLoggedInUser()
  const { seriesId, staffId } = useParams()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  const staffIdToUse = staffId === '0' ? loggedInUserId : staffId

  const [adjustedOfferings, setAdjustedOfferings] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectedStaffer, setSelectedStaffer] = useState(staffIdToUse)

  const [selectedOfferingIds, setSelectedOfferingIds] = useState([])

  const {
    isFetching: isFetchingAdjustedOfferings,
    isLoading: isLoadingAdjustedOfferings
  } = useQuery(
    ['adjusted-offerings', { staffId: parseInt(selectedStaffer) }],
    async () => {
      const response = await axios.get(
        `${ROOT}${selectedStaffer}/adjustedofferings/`,
        {
          withCredentials: true
        }
      )

      // Setting up isSelected prop for Bulk Edit
      response.data.forEach((r) => {
        r.isSelected = false
      })

      setAdjustedOfferings(
        response.data.sort(sortByCollatingField(false, 'scheduleDate'))
      )
    },
    {
      enabled: !seriesId
      // Todo: I don't think we can make this data stale, since we are serving from state
      // I've got to think about this and come back to it
      //staleTime: 1000 * 30
    }
  )

  const {
    isFetching: isFetchingAdjustedOfferingsSeries,
    isLoading: isLoadingAdjustedOfferingsSeries
  } = useQuery(
    ['adjusted-offerings', { seriesId }],
    async () => {
      const response = await axios.get(
        `${ROOT}${staffId}/adjustedofferings/byseries/${seriesId}`,
        {
          withCredentials: true
        }
      )

      // Setting up isSelected prop for Bulk Edit
      response.data.forEach((r) => {
        r.isSelected = false
      })

      setAdjustedOfferings(
        response.data.sort(sortByCollatingField(false, 'scheduleDate'))
      )
    },
    {
      enabled: !!seriesId
    }
  )

  const { data: appointmentTypes = [], isLoading: isLoadingAppointmentTypes } =
    useAppointmentTypesQuery({ returnAsMenuItems: true })

  const { data: graduationYears = [], isLoading: isLoadingGraduationYears } =
    useConfigurationGraduationYearsQuery()

  const {
    data: stafferDetails = [],
    isFetching: isFetchingStafferDetails,
    isLoading: isLoadingStafferDetails
  } = useStaffByIdQuery(selectedStaffer)

  const maxNumberOfStudents =
    stafferDetails.length < 1 ? 0 : stafferDetails.staffer.maxNumberStudents

  const { data: staffers = [], isLoading: isLoadingStaffers } = useStaffQuery({
    isEnabled: isAdmin
  })

  const {
    isLoading: isDeletingAdjustedOffering,
    mutate: deleteAdjustedOffering
  } = useMutation(
    async (courseAdjustedId) => {
      await axios.delete(
        `${ROOT}${selectedStaffer}/adjustedofferings/${courseAdjustedId}`,
        { withCredentials: true }
      )

      return { courseAdjustedId }
    },
    {
      onSuccess: ({ courseAdjustedId }) => {
        enqueueSnackbar('The Adjusted Offering has been deleted.', {
          variant: 'success'
        })

        const filteredOfferings = adjustedOfferings.filter(
          (offering) => offering.courseAdjustedId !== courseAdjustedId
        )

        setAdjustedOfferings(filteredOfferings)

        // Todo: Similar to above, since we aren't caching the data,
        // we don't need to manipulate the cached data
        /* queryClient.setQueryData(
          ['adjusted-offerings', { staffId: parseInt(selectedStaffer) }],
          () => {
            return filteredOfferings
          }
        ) */
      }
    }
  )

  const {
    isLoading: isDeletingAdjustedOfferingSeries,
    mutate: deleteAdjustedOfferingSeries
  } = useMutation(
    async (groupId) => {
      await axios.delete(
        `${ROOT}${selectedStaffer}/adjustedofferings/deleteseries/${groupId}`,
        { withCredentials: true }
      )

      return { groupId }
    },
    {
      onSuccess: ({ groupId }) => {
        enqueueSnackbar('The Adjusted Offering Series has been deleted.', {
          variant: 'success'
        })

        setAdjustedOfferings(
          adjustedOfferings.filter(
            (offering) =>
              offering.groupId !== groupId ||
              (offering.groupId === groupId &&
                moment(offering.scheduleDate).isBefore(
                  moment().subtract(1, 'days')
                ))
          )
        )
      }
    }
  )

  const { isLoading: isSavingBulkEdits, mutate: saveBulkEdits } = useMutation(
    async ({ details }) => {
      const response = await axios.post(
        `${ROOT}${selectedStaffer}/adjustedofferings/bulkedit`,
        details,
        { withCredentials: true }
      )

      return { details, response: response.data }
    },
    {
      onSuccess: ({ details, response: errors }) => {
        let appointmentType = { description: '' }

        if (details.appointmentTypeId !== -10) {
          appointmentType = appointmentTypes.find(
            (type) => type.value === details.appointmentTypeId
          )
        }

        let listOfErrors = []

        const returnErrorMessage = (error) => {
          switch (error.adjustedOfferingSaveStatus) {
            case ADJUSTED_OFFERING_SAVE_STATUS_IDS.adjustedSeatCountTooLow:
              return `The Max Number of Students provided: ${error.adjustedSeatCount} is lower then the minimum seat count: ${error.currentMaxSeatCount}`
            case ADJUSTED_OFFERING_SAVE_STATUS_IDS.currentNumberOfAppointmentsGreaterThanNewAdjustedSeatCount:
              return `The Max Number of Students provided: ${error.adjustedSeatCount} is lower then the current number of Appointments: ${error.currentNumberOfAppointments}`
            case ADJUSTED_OFFERING_SAVE_STATUS_IDS.noActivateSubjectMatterCourse:
              return `The selected Staffer does not have an active Subject Matter course. Please contact an Admin in your school to activate the Subject Matter Course.`
            default:
              return
          }
        }

        errors.forEach((error) => {
          listOfErrors.push({
            scheduleDate: error.scheduleDate,
            errorMessage: returnErrorMessage(error)
          })
        })

        const adjustedOfferingsIdsWithNoErrors =
          errors.length > 0
            ? details.adjustedOfferingIds.filter((el) => {
                return listOfErrors.some((f) => {
                  return f.adjustedOfferingId === el
                })
              })
            : details.adjustedOfferingIds

        let copy = adjustedOfferings

        adjustedOfferingsIdsWithNoErrors.forEach((id) => {
          copy.forEach((offering) => {
            offering.isSelected = false
            if (offering.courseAdjustedId === id) {
              offering.adjustedCourseName = details.adjustedCourseName
                ? details.adjustedCourseName
                : offering.adjustedCourseName
              offering.adjustedRoom = details.adjustedRoom
                ? details.adjustedRoom
                : offering.adjustedRoom
              offering.adjustedSeatCount =
                details.adjustedSeatCount !== '-10'
                  ? details.adjustedSeatCount
                  : offering.adjustedSeatCount
              offering.appointmentTypeDescription =
                details.appointmentTypeId !== -10
                  ? appointmentType.description
                  : offering.appointmentTypeDescription
              offering.mustUseDefaultAppointmentType =
                details.mustUseDefaultAppointmentType !== -10
                  ? details.mustUseDefaultAppointmentType
                  : offering.mustUseDefaultAppointmentType
              offering.preventOtherStaffFromScheduling =
                details.preventOtherStaffFromScheduling !== '-10'
                  ? details.preventOtherStaffFromScheduling
                  : offering.preventOtherStaffFromScheduling
              offering.preventStudentRequesting =
                details.preventStudentRequesting !== '-10'
                  ? details.preventStudentRequesting
                  : offering.preventStudentRequesting
              offering.preventStudentSelfScheduling =
                details.preventStudentSelfScheduling !== '-10'
                  ? details.preventStudentSelfScheduling
                  : offering.preventStudentSelfScheduling
            }
          })
        })

        setAdjustedOfferings([...copy])
        setSelectedOfferingIds([])
        setSelectAll(false)

        if (listOfErrors.length !== 0) {
          const action = (key) => (
            <>
              <Button
                onClick={() => closeSnackbar(key)}
                style={{ color: '#fff' }}>
                Dismiss
              </Button>
            </>
          )

          enqueueSnackbar(
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                These Adjusted Offerings could not be saved:
              </Typography>
              {listOfErrors.map((err) => (
                <Typography>
                  {moment(err.scheduleDate).format('MM/DD/YYYY')} -{' '}
                  {err.errorMessage}
                </Typography>
              ))}
            </div>,
            {
              action,
              variant: 'error',
              persist: true
            }
          )
        } else {
          enqueueSnackbar('Adjusted Offerings have been saved', {
            variant: 'success'
          })
        }
      }
    }
  )

  const updateAllOfferingsIsSelected = (checked) => {
    setSelectAll(checked)

    if (checked) {
      let array = []

      adjustedOfferings.forEach((offering) => {
        if (
          moment(offering.scheduleDate).format('YYYY-MM-DD') >=
            moment().format('YYYY-MM-DD') &&
          !offering.isInUseAndAttendanceHasBeenTaken
        ) {
          array.push(offering.courseAdjustedId)
        }

        setSelectedOfferingIds(array)
      })
    } else {
      setSelectedOfferingIds([])
    }
  }

  const updateSingleOfferingIsSelected = (courseAdjustedId, checked) => {
    if (checked) {
      setSelectedOfferingIds((prevOfferings) => [
        ...prevOfferings,
        courseAdjustedId
      ])
    } else {
      setSelectedOfferingIds((prevOfferings) => [
        ...prevOfferings.filter((offering) => offering !== courseAdjustedId)
      ])
    }
  }

  return (
    <AdjustedOfferingsContext.Provider
      value={{
        adjustedOfferings,
        appointmentTypes,
        deleteAdjustedOffering,
        deleteAdjustedOfferingSeries,
        graduationYears,
        isAdmin,
        isDeleting:
          isDeletingAdjustedOffering || isDeletingAdjustedOfferingSeries,
        isLoading:
          isFetchingAdjustedOfferings ||
          isFetchingAdjustedOfferingsSeries ||
          isFetchingStafferDetails ||
          isLoadingAdjustedOfferings ||
          isLoadingAdjustedOfferingsSeries ||
          isLoadingAppointmentTypes ||
          isLoadingGraduationYears ||
          isLoadingStafferDetails ||
          isLoadingStaffers,
        isSaving: isSavingBulkEdits,
        loggedInUserId,
        maxNumberOfStudents,
        saveBulkEdits,
        selectAll,
        selectedOfferingIds,
        selectedStaffer,
        setSelectedStaffer,
        staffers,
        updateAllOfferingsIsSelected,
        updateSingleOfferingIsSelected
      }}>
      {children}
    </AdjustedOfferingsContext.Provider>
  )
}

const useAdjustedOfferings = () => {
  const context = useContext(AdjustedOfferingsContext)

  if (!context) {
    throw new Error(
      'useAdjustedOfferings must be used with a AdjustedOfferingsProvider'
    )
  }

  return context
}

export {
  AdjustedOfferingsContext,
  AdjustedOfferingsProvider,
  useAdjustedOfferings
}
