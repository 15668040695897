import React from 'react'
import { Button, FormControlLabel, Menu, Switch } from '@mui/material'

import { HiddenXsOnly } from '../shared'

import {
  StyledPageSettingsIcon,
  StyledPageSettingsOptionsMenu
} from './styledComponents'
import { useAnchorEl } from '../../hooks'

const PageSettingsMenu = ({
  displayClassicSummaries,
  hideStrugglingStudents,
  hideUpcomingAdjustedCourses,
  toggleCoursesDisplay,
  toggleStudentsDisplay,
  toggleSummaries
}) => {
  const { anchorEl, handleClose, handleOpen } = useAnchorEl()

  return (
    <div>
      <Button onClick={handleOpen}>
        <StyledPageSettingsIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        elevation={0}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <StyledPageSettingsOptionsMenu>
          <HiddenXsOnly>
            <FormControlLabel
              control={
                <Switch
                  checked={!displayClassicSummaries}
                  onChange={toggleSummaries}
                  name='listView'
                  color='primary'
                />
              }
              label='List View'
            />
          </HiddenXsOnly>
          <FormControlLabel
            control={
              <Switch
                checked={hideUpcomingAdjustedCourses}
                onChange={toggleCoursesDisplay}
                name='hideAdjustedCourses'
                color='primary'
              />
            }
            label='Hide Adjusted Courses'
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideStrugglingStudents}
                onChange={toggleStudentsDisplay}
                name='hideStrugglingStudents'
                color='primary'
              />
            }
            label='Hide My At Risk Students'
          />
        </StyledPageSettingsOptionsMenu>
      </Menu>
    </div>
  )
}

export default PageSettingsMenu
