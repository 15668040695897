import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getSubstiuteQueryKey } from '../../../helpers'

export const fetchSubstituteDetails = async (userId) => {
  const response = await axios.get(`${endpoints.SUBSTITUTES}/${userId}`, {
    withCredentials: true
  })

  return response.data
}

export const useSubstitutesByIdQuery = (userId) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getSubstiuteQueryKey(userId),
    () => fetchSubstituteDetails(userId),
    {
      enabled: parseInt(userId) !== 0,
      onError: () => {
        // Invalidate cached Substitutes
        queryClient.invalidateQueries(getSubstiuteQueryKey())

        enqueueSnackbar(
          `We are unable to find this record, it's likely that it has been deleted by another user.`,
          {
            variant: 'error'
          }
        )
      },
      staleTime: 1000 * 30
    }
  )
}
