import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useReportCategoriesQuery = () => {
  return useQuery(
    ['reports', 'categories'],
    async () => {
      const response = await axios.get(`${endpoints.REPORTS}/categories`, {
        withCredentials: true
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 10
    }
  )
}
