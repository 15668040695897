import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortArrayByLastNameNickNameFirstName } from '../../../helpers'
import moment from 'moment'

export const useAppointmentsUnscheduledStudentsByDatePeriodQuery = ({
  date,
  periodId
}) => {
  return useQuery(
    [
      'unscheduled-students',
      {
        date: moment(date).format('YYYY-MM-DD'),
        periodId: parseInt(periodId)
      }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.APPOINTMENTS}/unscheduledstudents/${date}/${periodId}`,
        { withCredentials: true }
      )

      return sortArrayByLastNameNickNameFirstName(response.data)
    },
    {
      enabled: !!periodId && !!date
    }
  )
}
