import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStafferQueryKey } from '../../../helpers/queryKeys'

export const useStaffSaveMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (values) => {
      const response = await axios.post(`${endpoints.STAFF}/save`, values, {
        withCredentials: true
      })

      return {
        id: response.data,
        shouldRedirect:
          values.isAddingExistingStafferFromAnotherSchool ||
          parseInt(values.userId) === 0,
        values
      }
    },
    {
      onSuccess: ({ id, shouldRedirect, values }) => {
        // Invalidate query cache for list of staffers
        queryClient.invalidateQueries(['staff'])

        if (!shouldRedirect) {
          enqueueSnackbar(`General Info changes have been saved`, {
            variant: 'success'
          })

          // Update query cache for edited staffer
          queryClient.setQueryData(
            getStafferQueryKey(values.userId),
            (prevStafferDetails) => {
              return {
                courses: prevStafferDetails.courses,
                staffer: { ...prevStafferDetails.staffer, ...values }
              }
            }
          )
        } else {
          enqueueSnackbar(
            `New Staffer: ${values.lastName}, ${values.firstName} has been created`,
            {
              variant: 'success'
            }
          )
        }
      }
    }
  )
}
