import { useState } from 'react'
import {
  useEmailSubscriptionQuery,
  useEmailSubscriptionSaveMutation
} from '../../../hooks'

import { useLocalStateFromCachedData } from './useLocalStateFromCachedData'

export const useEmailPermissions = () => {
  const [hasEmailPermissionsBeenAltered, setHasEmailPermissionsBeenAltered] =
    useState(false)

  const {
    data = [],
    isLoading: isLoadingEmails,
    status
  } = useEmailSubscriptionQuery()

  const { isLoading: isSavingEmailPermissions, mutate: saveEmailPermissions } =
    useEmailSubscriptionSaveMutation()

  const [emails, setEmails] = useLocalStateFromCachedData([], data, status)

  const updateEmails = (e) => {
    const { id } = e.target
    const parsedId = parseInt(id)

    setEmails([
      ...emails.map((email) => {
        if (email.emailTemplateId !== parsedId) return email
        return { ...email, hasOptedOut: !email.hasOptedOut }
      })
    ])

    setHasEmailPermissionsBeenAltered(true)
  }

  return {
    emails,
    hasEmailPermissionsBeenAltered,
    isLoadingEmails,
    isSavingEmailPermissions,
    saveEmailPermissions,
    updateEmails
  }
}
