import { useEffect, useState } from 'react'
import { useSaveAppointmentTypesMutation } from './useSaveAppointmentTypesMutation'
import { useAppointmentTypesQuery } from '../../../hooks'

export const useAppointmentTypes = (courses) => {
  const [homeroomCourseUpdated, setHomeroomCourseUpdated] = useState(false)
  const [selectedHomeroomAppointmentType, setSelectedHomeroomAppointmentType] =
    useState('')
  const [
    selectedSubjectMatterAppointmentType,
    setSelectedSubjectMatterAppointmentType
  ] = useState('')
  const [subjectMatterCourseUpdated, setSubjectMatterCourseUpdated] =
    useState(false)

  const { data: appointmentTypes = [], isLoading: isLoadingAppointmentTypes } =
    useAppointmentTypesQuery()

  const { isLoading: isSavingAppointmentType, mutate: saveAppointmentType } =
    useSaveAppointmentTypesMutation()

  useEffect(() => {
    if (!courses) return

    const homeroomCourseInfo = courses.find((course) => course.isHomeroom)
    const subjectMatterCourseInfo = courses.find((course) => !course.isHomeroom)

    if (!!homeroomCourseInfo) {
      setSelectedHomeroomAppointmentType(
        homeroomCourseInfo.defaultAppointmentTypeId
      )
    }

    if (!!subjectMatterCourseInfo) {
      setSelectedSubjectMatterAppointmentType(
        subjectMatterCourseInfo.defaultAppointmentTypeId
      )
    }
  }, [courses])

  const changeAppointmentType = (e, courseId) => {
    const { name, value } = e.target

    if (name === 'selectedHomeroomAppointmentType') {
      setSelectedHomeroomAppointmentType(value)
      setHomeroomCourseUpdated(true)
    }

    if (name === 'selectedSubjectMatterAppointmentType') {
      setSelectedSubjectMatterAppointmentType(value)
      setSubjectMatterCourseUpdated(true)
    }
  }

  const saveAppointmentTypes = () => {
    const homeroomCourse = courses.find((course) => course.isHomeroom)
    const subjectMatterCourse = courses.find((course) => !course.isHomeroom)

    let array = []

    if (homeroomCourseUpdated) {
      array.push({
        ...homeroomCourse,
        defaultAppointmentTypeId: selectedHomeroomAppointmentType
      })
    }

    if (subjectMatterCourseUpdated) {
      array.push({
        ...subjectMatterCourse,
        defaultAppointmentTypeId: selectedSubjectMatterAppointmentType
      })
    }

    setHomeroomCourseUpdated(false)
    setSubjectMatterCourseUpdated(false)

    array.forEach((course) => saveAppointmentType(course))
  }

  return {
    appointmentTypes,
    changeAppointmentType,
    homeroomCourseUpdated,
    isLoadingAppointmentTypes,
    isSavingAppointmentType,
    saveAppointmentTypes,
    selectedHomeroomAppointmentType,
    selectedSubjectMatterAppointmentType,
    subjectMatterCourseUpdated
  }
}
