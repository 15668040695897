import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'
import { useState } from 'react'

export const useStaffByDeptDetailQuery = ({
  blockedDateDetailId,
  departmentId,
  enabled = true,
  resetAfterSave
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [staffers, setStaffers] = useState([])

  const updatedStaffers = staffers.filter((staffer) => staffer.isUpdated)

  const { isLoading } = useQuery(
    [
      'staff-by-dept-detail',
      {
        blockedDateDetailId: parseInt(blockedDateDetailId),
        departmentId: parseInt(departmentId)
      }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.BLOCKED_DATES}/staffbydeptdetail/${departmentId}/${blockedDateDetailId}`,
        { withCredentials: true }
      )

      setStaffers(response.data.sort(sortByCollatingField(true, 'lastName')))
    },
    {
      enabled: enabled
    }
  )

  const { isLoading: isSaving, mutate: save } = useMutation(
    async () => {
      let array = []
      updatedStaffers.forEach((staffer) => {
        array.push({
          add: staffer.isBlocked,
          blockedDateDetailId,
          stafferId: staffer.userId
        })
      })

      const response = await axios.post(
        `${endpoints.BLOCKED_DATES}/adjuststaffer`,
        array,
        {
          withCredentials: true
        }
      )

      return response.data
    },
    {
      onSuccess: (data) => {
        const generateMessage = (variant) => {
          const stafferInfos = []

          staffers.forEach((staffer) => {
            data.forEach((id) => {
              if (staffer.userId === id) {
                stafferInfos.push(staffer)
              }
            })
          })

          return enqueueSnackbar(
            <span>
              Please note, the following Staffers have been scheduled and could
              not blocked:
              <ul>
                {stafferInfos.map((staffer) => {
                  return (
                    <li>
                      {staffer.lastName}, {staffer.firstName}
                    </li>
                  )
                })}
              </ul>
              {variant === 'warning' &&
                'All other Staffers were edited correctly'}
            </span>,
            {
              variant
            }
          )
        }

        if (data.length > 0 && data.length !== updatedStaffers.length) {
          // Errors & Successes
          generateMessage('warning')
        } else if (data.length > 0 && data.length === updatedStaffers.length) {
          // All Errors
          generateMessage('error')
        } else {
          // All Successes
          enqueueSnackbar(
            updatedStaffers.length > 1
              ? 'Changes to Staffers blocked by department have been saved'
              : `Changes to ${updatedStaffers[0].lastName}, ${updatedStaffers[0].firstName} have been saved`,
            {
              variant: 'success'
            }
          )
        }

        resetAfterSave()
      }
    }
  )

  const updateStaffBlock = (userId) => {
    setStaffers(
      staffers.map((staffer) => {
        if (staffer.userId !== userId) return staffer
        return { ...staffer, isBlocked: !staffer.isBlocked, isUpdated: true }
      })
    )
  }

  return {
    isLoading,
    isSaving,
    save,
    staffers,
    updatedStaffers,
    updateStaffBlock
  }
}
