import { APPOINTMENT_EDITOR_RESPONSES } from '../../constants'

export const getErrorMessage = (appointmentEditorResponse) => {
  switch (appointmentEditorResponse) {
    case APPOINTMENT_EDITOR_RESPONSES.classFullyBookedAndCannotAutoIncrease:
      return 'This class is fully booked and the seat count cannot be increased.'
    case APPOINTMENT_EDITOR_RESPONSES.invalidStaffId:
      return 'The staff id is no longer valid.'
    case APPOINTMENT_EDITOR_RESPONSES.currentCourseIsHomeroomAndNoAutoOverride:
      return 'The course is a Homebase and the overwrite option was not selected.'
    case APPOINTMENT_EDITOR_RESPONSES.outsideYearDateRange:
      return 'This date is outside of the school year.'
    case APPOINTMENT_EDITOR_RESPONSES.invalidCourseId:
      return 'The selected course is no longer valid.'
    case APPOINTMENT_EDITOR_RESPONSES.dateIsBlockedForEntireSchool:
      return 'This date has been blocked for the entire school.'
    case APPOINTMENT_EDITOR_RESPONSES.schoolHasFrozenAppointment:
      return 'This date has been frozen for the entire school.'
    case APPOINTMENT_EDITOR_RESPONSES.appointmentLockedAndUserCannotOverride:
      return 'This appointment is locked and cannot be overwritten.'
    case APPOINTMENT_EDITOR_RESPONSES.appointmentExistsAndNotOverriden:
      return 'There is an existing appointment for this date and period. Please select the overwrite appointment option.'
    case APPOINTMENT_EDITOR_RESPONSES.appointmentHasBeenCompleted:
      return 'Attendance has already been taken for this appointment.'
    case APPOINTMENT_EDITOR_RESPONSES.classFullyBookedCanBeAutoIncrease:
      return 'This class is fully booked but the number of seats can be increased.'
    case APPOINTMENT_EDITOR_RESPONSES.dateIsBlocked:
      return 'This date is blocked.'
    default:
      return 'Unknown error. We apologize for the confusion.'
  }
}
