import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'

import {
  List,
  Loading,
  Paper,
  SubheadingContainer,
  SubheadingText,
  Tabs
} from '../shared'

import { useAdvancedAppointments } from './AdvancedAppointmentsContext'
import CourseItem from './CourseItem'
import GradeItem from './GradeItem'

const InfoSection = () => {
  const {
    coursesBySelections,
    errorNoAvailableCourses,
    grades,
    isLoadingCoursesBySelections
  } = useAdvancedAppointments()

  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (errorNoAvailableCourses === true) {
      if (selectedTab !== 0) {
        setSelectedTab(0)
      }
    }
  }, [errorNoAvailableCourses, selectedTab])

  return (
    <Grid item xs={12} md={4} lg={5}>
      <div>
        <Tabs
          labels={['Courses', 'Grades']}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Paper
          sx={{
            height: '75vh',
            border: errorNoAvailableCourses && '1px solid red'
          }}>
          {selectedTab === 1 && (
            <div>
              <SubheadingContainer>
                <SubheadingText
                  sx={{
                    gridColumn: '1/6'
                  }}
                  text='Course'
                />
                <SubheadingText
                  sx={{
                    gridColumn: '6/13'
                  }}
                  text='Grade'
                />
              </SubheadingContainer>
              <List
                data={grades}
                Component={GradeItem}
                emptyArrayMessage='There are no grades to display for this student.'
                keyValue='course'
              />
            </div>
          )}
          {selectedTab === 0 && (
            <div>
              {isLoadingCoursesBySelections ? (
                <Loading
                  sx={{
                    position: 'relative',
                    background: '#fff',
                    height: { sm: 200 }
                  }}
                  text='Loading Courses'
                />
              ) : (
                <>
                  {errorNoAvailableCourses && (
                    <Typography
                      sx={{
                        padding: 1,
                        color: 'error.main'
                      }}>
                      You must include at least one course
                    </Typography>
                  )}
                  <List
                    data={coursesBySelections}
                    Component={CourseItem}
                    emptyArrayMessage='There are no courses for the selected date, periods and weekdays.'
                  />
                </>
              )}
            </div>
          )}
        </Paper>
      </div>
    </Grid>
  )
}

export default InfoSection
