import React from 'react'
import { Checkbox, ListItemContainer, ListItemText } from '../shared'
import { Typography } from '@mui/material'

const PermissionItem = ({
  emailTemplateDescription,
  emailTemplateId,
  emailTemplateName,
  hasOptedOut,
  isAdminOnly,
  isLastItem,
  schoolAllowsOptOut,
  stafferIsAdmin,
  updateEmails
}) => {
  if (isAdminOnly && !stafferIsAdmin) return null

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        labelText='Name:'
        sx={{ gridColumn: { xs: '1/13', lg: '1/6' } }}>
        {emailTemplateName}
      </ListItemText>
      <ListItemText
        labelText='Subscribe:'
        sx={{ gridColumn: { xs: '1/13', lg: '6/8' }, textAlign: 'center' }}>
        <Checkbox
          checked={!schoolAllowsOptOut ? true : !hasOptedOut}
          dataTestId={`opt-out-for-${emailTemplateName}`}
          disabled={!schoolAllowsOptOut}
          id={emailTemplateId.toString()}
          onChange={updateEmails}
        />
      </ListItemText>
      {!schoolAllowsOptOut && (
        <Typography sx={{ gridColumn: '1/13', fontSize: 10 }}>
          Your school doesn't allow staffers to unsubscribe from this email.
        </Typography>
      )}
    </ListItemContainer>
  )
}

export default PermissionItem
