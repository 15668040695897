import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { groupBy, sortByCollatingField } from '../../../helpers'

export const useRostersByStaffIdQuery = ({ staffId } = {}) => {
  return useQuery(
    ['rosters', 'by-staff-id', { staffId: parseInt(staffId) }],
    async () => {
      const response = await axios.get(
        `${endpoints.ROSTERS}/bystaffid/${staffId}`,
        { withCredentials: true }
      )

      const groupedData = groupBy(response.data, 'rosterName')
      const keys = Object.keys(groupedData)

      let arrayByKeys = []
      keys.forEach((key) => arrayByKeys.push(groupedData[key]))

      let final = []
      arrayByKeys.forEach((arr) => {
        final.push({
          isDefault: arr[0].isDefault,
          isHomeroom: arr[0].isHomeroom,
          rosterId: arr[0].rosterId,
          rosterName: arr[0].rosterName,
          students: arr.sort(function (a, b) {
            if (a.studentLastName < b.studentLastName) {
              return -1
            }
            if (a.studentLastName > b.studentLastName) {
              return 1
            }

            if (a.studentNickname === null) {
              if (a.studentFirstName < b.studentFirstName) {
                return -1
              }
            } else {
              if (a.studentNickname < b.studentNickname) {
                return -1
              }
            }

            return 1
          })
        })
      })

      return final.sort(sortByCollatingField(true, 'rosterName'))
    },
    {
      enabled: !!staffId,
      staleTime: 1000 * 30
    }
  )
}
