import useLocalStorage from './useLocalStorage'

const usePageSettings = ({ initialValues, localStorageKey }) => {
  const [pageSettings, setPageSettings] = useLocalStorage(
    localStorageKey,
    JSON.stringify(initialValues)
  )

  const getParsedPageSetting = (pageSetting) => {
    const parsed = JSON.parse(pageSettings)
    return parsed[pageSetting]
  }

  const togglePageSetting = (settingName) => {
    const parsedPageSettings = JSON.parse(pageSettings)

    const updatedPageSettings = {
      ...parsedPageSettings,
      [settingName]: !parsedPageSettings[settingName]
    }

    const stringifiedPageSettings = JSON.stringify(updatedPageSettings)

    setPageSettings(stringifiedPageSettings)
  }

  return { getParsedPageSetting, togglePageSetting }
}

export default usePageSettings
