import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { ROOT } from '../../../constants'
import { getAdjustedOfferingQueryKey } from '../../../helpers'

export const useSaveAdjustedOfferingMutation = ({
  adjustedOfferingId,
  staffId
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (details) => {
      await axios.post(
        `${ROOT}${details.staffId}/adjustedofferings/save`,
        details,
        {
          withCredentials: true
        }
      )

      return { shouldRedirect: true }
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Adjusted Offering has been saved', {
          variant: 'success'
        })

        queryClient.invalidateQueries(['adjusted-offerings'])

        if (parseInt(adjustedOfferingId) !== 0) {
          // This is an existing Offering and we will clear the cache
          queryClient.invalidateQueries(
            getAdjustedOfferingQueryKey({ adjustedOfferingId, staffId })
          )
        }
      }
    }
  )
}
