const ESU_ROOT_URL = 'https://www.enrichingstudents.com/university'

export const esuUrls = {
  ABSENT_STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608052',
  ADD_STAFF:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057063791',
  ADD_STUDENT:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/4959189744397',
  ADD_SUBSTITUTE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5430358556045',
  ADJUSTED_OFFERINGS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062551',
  ADJUSTED_SERIES: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062551`,
  ADVANCED_APPOINTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/4824205729677',
  ADVANCED_APPOINTMENTS_SUB:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5619631121165',
  ANALYSIS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/13955764065037',
  APPOINTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056534132',
  APPOINTMENTS_SUB:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5618222155917',
  BLOCKED_DATE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608212',
  CONFIGURATION:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5431562656525',
  CREATE_ADJUSTED_OFFERINGS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062551',
  CREATE_APPOINTMENT_TYPE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057897172',
  CREATE_ATTENDANCE_TYPE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360058369811',
  CREATE_BLOCKED_DATE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608212',
  CREATE_DEPARTMENT:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057897552',
  CREATE_MESSAGE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057155071',
  CREATE_ROSTER:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5076644019469',
  DASHBOARD: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360057156411`,
  DELETE_MULTIPLE_APPOINTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/4966440844557',
  EDIT_PERIOD:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360058370491',
  EXPORT_DATA:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5133657264781',
  EMAIL_LOGS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057357411',
  EMAIL_NOTIFICATIONS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056534092',
  IMPORT_DATA:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360030971611-Manually-Import-Data-using-our-Admin-tools',
  MANAGE_APPOINTMENT_TYPES: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360057897172`,
  MANAGE_APPOINTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/12573084762893',
  MANAGE_ATTENDANCE_TYPES: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360058369811`,
  MANAGE_DAY_TYPES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056993131',
  MANAGE_DEPARTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057897552',
  MANAGE_HOMEROOM_ROSTERS: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360058370291`,
  MANAGE_INVALID_EMAILS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056533932',
  MANAGE_PERIODS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360058370491',
  MANAGE_MESSAGES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057155071',
  MANAGE_REPORTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056890192',
  MANAGE_ROSTERS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5076644019469',
  MANAGE_SOFT_DELETED_USERS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/13932773529357',
  MANAGE_STAFF:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057063791',
  MANAGE_STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056993011',
  MANAGE_SUBSTITUTES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5430358556045',
  MASS_SCHEDULE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/4827541888397',
  MY_ACCOUNT:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5131278717709',
  MY_ACCOUNT_SUB:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5617096246413',
  ORDER_GRADES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056992951',
  SCHOOL_CALENDAR: `${ESU_ROOT_URL}`,
  SCHOOL_NOTIFICATIONS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5132080589453',
  SELF_SCHEDULE_ADJUSTOR:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/4445992024845',
  STUDENT_PROFILE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/5075364135821',
  STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056993011',
  STUDENTS_ADMIN:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/4959189744397',
  STUDENT_REQUESTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608312',
  TAKE_ATTENDANCE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062731',
  UNSCHEDULED_STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608332'
}
