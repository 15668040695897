import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getGraduationYearsQueryKey } from '../../../helpers'

export const useConfigurationGraduationYearsQuery = ({
  enabled = true,
  useAll = false,
  useSelect = false
} = {}) => {
  return useQuery(
    getGraduationYearsQueryKey(useAll, useSelect),
    async () => {
      const response = await axios.get(
        `${endpoints.CONFIGURATION}/graduationyears`,
        { withCredentials: true }
      )

      let array = []

      if (useAll) {
        array.push({
          description: 'All',
          value: 0
        })
      }

      if (useSelect) {
        array.push({
          description: '(select)',
          value: -1
        })
      }

      response.data.forEach((item) => {
        array.push({
          description: item,
          value: item
        })
      })

      return array
    },
    {
      enabled,
      staleTime: 1000 * 30
    }
  )
}
