import { useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import moment from 'moment'

export const useCoursesByCourseDatePeriodQuery = ({
  courseId,
  endDate,
  periodIds,
  startDate,
  studentId,
  weekdaysToInclude
}) => {
  const [excludedCourses, setExcludedCourses] = useState([])

  const { data, isFetching, isLoading } = useQuery(
    [
      'courses',
      'for-date-period-course',
      {
        courseId: parseInt(courseId),
        endDate,
        periodIds,
        startDate,
        studentId: parseInt(studentId),
        weekdaysToInclude
      }
    ],
    async () => {
      if (courseId === '') return

      const response = await axios.post(
        `${endpoints.COURSES}/fordatesperiodcourse`,
        {
          courseId,
          endDate: moment(endDate).format('YYYY-MM-DD'),
          periodIds,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          studentId,
          weekdaysToInclude
        },
        { withCredentials: true }
      )

      let array = []

      response.data.forEach((r) => {
        if (r.isBlockedForSchool || r.isBlockedForStaffer) {
          array.push({
            appointmentDate: r.appointmentDate,
            periodId: r.periodId
          })
        }
      })

      setExcludedCourses(array)

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )

  return {
    coursesBySelections: data,
    excludedCourses,
    isLoadingCoursesBySelections: isFetching || isLoading,
    setExcludedCourses
  }
}
