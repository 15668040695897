import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getStudentContactsQueryKey } from '../../../helpers'

export const useStudentContactsQuery = ({ userId }) => {
  return useQuery(
    getStudentContactsQueryKey(userId),
    async () => {
      const response = await axios.get(
        `${endpoints.STUDENT_CONTACTS}/${userId}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
