import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

function usePageViews() {
  let location = useLocation()

  useEffect(() => {
    ReactGA.send('pageview', location.pathname)
  }, [location])
}

export default usePageViews
