import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'

const Chips = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap'
}))

const StyledFormControl = styled(FormControl)(() => ({
  width: 250
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const RhfMultiSelectFormControl = ({
  control,
  defaultValue,
  errors,
  inputId,
  label,
  labelId,
  menuItems,
  name,
  ref,
  rules,
  selectedWatch,
  setValue,
  useAllOption = false,
  ...props
}) => {
  useEffect(() => {
    let array = []

    if (
      selectedWatch !== undefined &&
      selectedWatch.length > 0 &&
      selectedWatch.includes(-10)
    ) {
      if (selectedWatch.length > menuItems.length) {
        setValue(name, [])
      } else {
        menuItems.forEach((period) => {
          array.push(period.value)
        })

        setValue(name, array)
      }
    }
  }, [menuItems, name, selectedWatch, setValue])

  const getDescriptionByValue = (value) => {
    if (value === -10 || value === undefined || menuItems.length < 1) {
      return
    }

    const item = menuItems.find((item) => item.value === value)

    if (item === undefined) {
      return null
    }

    return item.description
  }

  return (
    <StyledFormControl error={Boolean(errors)} variant='standard' {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select
            labelId={labelId}
            multiple
            renderValue={(selected) => (
              <Chips>
                {selected.map((value) => {
                  return (
                    <Chip key={value} label={getDescriptionByValue(value)} />
                  )
                })}
              </Chips>
            )}>
            {useAllOption && <MenuItem value={-10}>All</MenuItem>}
            {menuItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
        defaultValue={defaultValue}
        name={name}
        MenuProps={MenuProps}
        rules={rules}
      />
      <FormHelperText>{errors && errors.message}</FormHelperText>
    </StyledFormControl>
  )
}

export default RhfMultiSelectFormControl
