import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

const ExistingOfferingDetails = ({
  courseTitle,
  periodDescription,
  scheduleDate
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography>
          Date: {moment(scheduleDate).format('MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Period: {periodDescription} </Typography>
      </Grid>
      {courseTitle !== null && (
        <Grid item xs={12}>
          <Typography>Series: {courseTitle}</Typography>
        </Grid>
      )}
    </>
  )
}

export default ExistingOfferingDetails
