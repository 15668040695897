import { styled } from '@mui/material/styles'

const HiddenXsOnly = styled('div')(({ theme }) => ({
  gridColumn: '4/13',

  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}))

export default HiddenXsOnly
