export const WEEKDAYS_FOR_MENU = [
  {
    description: 'Monday',
    value: 1
  },
  {
    description: 'Tuesday',
    value: 2
  },
  {
    description: 'Wednesday',
    value: 3
  },
  {
    description: 'Thursday',
    value: 4
  },
  {
    description: 'Friday',
    value: 5
  }
]
