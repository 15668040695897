import { styled } from '@mui/material/styles'

const CalendarContainer = styled('div')(({ theme }) => ({
  gridColumn: '1/13',

  [theme.breakpoints.up('sm')]: {
    gridColumn: '1'
  }
}))

export default CalendarContainer
