import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useStaffForListQuery = ({
  includeDefaultStudentGroupId = false
} = {}) => {
  return useQuery(
    ['staff', 'for-list', { includeDefaultStudentGroupId }],
    async () => {
      const response = await axios.get(
        `${endpoints.STAFF}/forlist/${includeDefaultStudentGroupId}`,
        { withCredentials: true }
      )

      response.data.forEach((d) => {
        d.description = d.lastName + ', ' + d.firstName
        d.value = d.userId
      })

      return response.data.sort(sortByCollatingField(true, 'description'))
    }
  )
}
