import { useMutation } from 'react-query'
import axios from 'axios'
import moment from 'moment'

import { endpoints } from '../../../constants'

/* 
  This is a special one-off that's being used on the Student Requests.
  This is a mutation that's being run off of an onClick method
*/
export const useAppointmentsGetMutation = () => {
  return useMutation(async ({ periodId, startDate, studentIds }) => {
    const response = await axios.post(
      `${endpoints.APPOINTMENTS}/get`,
      {
        numberOfDaysToRetrieve: 1,
        periodId: parseInt(periodId),
        rosterId: 0,
        searchTerm: '',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        studentIds
      },
      { withCredentials: true }
    )

    return response.data.studentsAndAppointments[0]
  })
}
