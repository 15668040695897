import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const CoursesSubheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText sx={{ gridColumn: '1/5' }} text='Date' />
      <SubheadingText sx={{ gridColumn: '5/10' }} text='Course' />
      <SubheadingText
        sx={{ gridColumn: '10/13', textAlign: 'center' }}
        text='Exclude'
      />
    </SubheadingContainer>
  )
}

export default CoursesSubheading
