import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { useLoggedInUser } from '../../../context'
import { getStaffersIncludeAllQueryKey } from '../../../helpers'

export const useBulkEditStaffMutation = ({ resetStateAfterSave }) => {
  const { loggedInUserId } = useLoggedInUser()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({
      canCreateAdjustedCourseBlockedToOthers,
      canViewAllGrades,
      canViewAnalysis,
      canViewGrades,
      departmentId,
      homeroomIsActive,
      maxNumberStudents,
      notifiyWhenAutomatedImportRuns,
      notifiyWhenEmailBounces,
      staffIds,
      subjectMatterCourseIsActive,
      userIsActive,
      userType
    }) => {
      await axios.post(
        `${endpoints.STAFF}/bulkedit`,
        {
          canCreateAdjustedCourseBlockedToOthers:
            canCreateAdjustedCourseBlockedToOthers === '' ||
            canCreateAdjustedCourseBlockedToOthers === -10
              ? '-10'
              : canCreateAdjustedCourseBlockedToOthers.toString(),
          canViewAllGrades:
            canViewAllGrades === '' || canViewAllGrades === -10
              ? '-10'
              : canViewAllGrades.toString(),
          canViewAnalysis:
            canViewAnalysis === '' || canViewAnalysis === -10
              ? '-10'
              : canViewAnalysis.toString(),
          canViewGrades:
            canViewGrades === '' || canViewGrades === -10
              ? '-10'
              : canViewGrades.toString(),
          departmentId:
            departmentId === '' || departmentId === -10
              ? -10
              : departmentId.toString(),
          homeroomIsActive:
            homeroomIsActive === '' || homeroomIsActive === -10
              ? '-10'
              : homeroomIsActive.toString(),
          maxNumberStudents:
            maxNumberStudents === '' ? null : parseInt(maxNumberStudents),
          notifiyWhenAutomatedImportRuns:
            notifiyWhenAutomatedImportRuns === '' ||
            notifiyWhenAutomatedImportRuns === -10
              ? '-10'
              : notifiyWhenAutomatedImportRuns.toString(),
          notifiyWhenEmailBounces:
            notifiyWhenEmailBounces === '' || notifiyWhenEmailBounces === -10
              ? '-10'
              : notifiyWhenEmailBounces.toString(),
          staffIds,
          subjectMatterCourseIsActive:
            subjectMatterCourseIsActive === '' ||
            subjectMatterCourseIsActive === -10
              ? '-10'
              : subjectMatterCourseIsActive.toString(),
          userIsActive:
            userIsActive === '' || userIsActive === -10
              ? '-10'
              : userIsActive.toString(),
          userType: userType === '' || userType === -10 ? -10 : userType
        },
        { withCredentials: true }
      )

      return { departmentId, staffIds, userIsActive, userType }
    },
    {
      onSuccess: ({ departmentId, staffIds, userIsActive, userType }) => {
        enqueueSnackbar(`Changes have been saved`, { variant: 'success' })

        resetStateAfterSave()

        queryClient.setQueryData(
          getStaffersIncludeAllQueryKey(),
          (prevStaffers) => {
            return [
              ...prevStaffers.map((staffer) => {
                let filteredStafferHasBeenUpdated = staffIds.find(
                  (id) => id === staffer.userId
                )

                return filteredStafferHasBeenUpdated
                  ? {
                      ...staffer,
                      departmentId:
                        departmentId === '' || departmentId === -10
                          ? staffer.departmentId
                          : departmentId,
                      isSelected: false,
                      userIsActive:
                        staffer.userId === loggedInUserId
                          ? true
                          : userIsActive === '' || userIsActive === -10
                          ? staffer.userIsActive
                          : userIsActive,
                      userType:
                        userType === '' || userType === -10
                          ? staffer.userType
                          : userType
                    }
                  : staffer
              })
            ]
          }
        )
      }
    }
  )
}
