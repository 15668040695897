import { useQuery } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import {
  getAppointmentTypesQueryKey,
  setupMenuItems,
  sortByCollatingField
} from '../../../helpers'

export const useAppointmentTypesQuery = ({
  checkHasBeenUsed = false,
  includeInactive = false,
  returnAsMenuItems = true
} = {}) => {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getAppointmentTypesQueryKey({
      checkHasBeenUsed: checkHasBeenUsed,
      includeInactive: includeInactive,
      returnAsMenuItems: returnAsMenuItems
    }),
    async () => {
      const urlParams = new URLSearchParams()

      if (checkHasBeenUsed) {
        urlParams.append('checkHasBeenUsed', checkHasBeenUsed)
      }

      if (includeInactive) {
        urlParams.append('includeInactive', includeInactive)
      }

      const urlParamsString = urlParams.toString()

      const response = await axios.get(
        `${endpoints.APPOINTMENT_TYPES}${
          urlParamsString ? `?${urlParamsString}` : ''
        }`,
        {
          withCredentials: true
        }
      )

      response.data.sort(sortByCollatingField(true, 'description'))

      const activeAppointmentTypes = response.data.filter(
        (type) => type.isActive
      )

      if (!includeInactive && activeAppointmentTypes.length < 1) {
        return { noActiveAppointmentTypes: true }
      }

      if (!returnAsMenuItems) {
        return response.data
      }

      return setupMenuItems({
        data: response.data,
        descriptionField: 'description',
        valueField: 'appointmentTypeId'
      })
    },
    {
      onSuccess: (data) => {
        if (data.noActiveAppointmentTypes) {
          enqueueSnackbar(
            'Warning: There are no active Appointment Types setup for your school. This page is not going work without an active Appointment Type.',
            {
              variant: 'warning'
            }
          )
        }
      },
      staleTime: 1000 * 30
    }
  )
}
