import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useForm, useWatch } from 'react-hook-form'
import { CircularProgress, Typography } from '@mui/material'

import { esuUrls } from '../../constants'
import {
  useAppointmentsClearByIdMutation,
  useConfigurationGraduationYearsQuery,
  useHeaderTitle,
  usePeriodsMinimalActiveOnlyQuery,
  useStaffQuery,
  useStartAndEndDate,
  useStudentsMinimalQuery
} from '../../hooks'
import {
  Button,
  DatePicker,
  DeleteModal,
  FlexColumn,
  Loading,
  PageInstructions,
  RadioGroup,
  ReactHookFormSelect,
  RhfMultiSelectFormControl,
  UserManual
} from '../shared'

import {
  CLEANER_TYPES,
  Container,
  SearchResultsContainer,
  useAppointmentsMassDeleteAppointmentsRequestMutation,
  useCoursesForDateAndPeriodMutation
} from '../DeleteMultipleAppointments'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'

const DeleteMultipleAppointments = () => {
  useHeaderTitle('Delete Multiple Appointments')
  const { enqueueSnackbar } = useSnackbar()

  const location = useLocation()
  const navigate = useNavigate()
  const [selectedByOption, setSelectedByOption] = useState(
    CLEANER_TYPES.ByCourse
  )

  const queryClient = useQueryClient()

  const { control, errors, handleSubmit, reset, setValue } = useForm()
  const [startDate, endDate, updateDates, DATE_CONSTANTS] = useStartAndEndDate()

  const selectedWeekdaysWatch = useWatch({
    control,
    name: 'selectedWeekdays',
    defaultValue: []
  })

  const datesMatch =
    moment(startDate).format('YYYY-MM-DD') !==
    moment(endDate).format('YYYY-MM-DD')
      ? false
      : true
  const selectedPeriodWatch = useWatch({
    control,
    name: 'selectedPeriod',
    defaultValue: ''
  })

  const { data: graduationYears = [], isLoading: isLoadingGraduationYears } =
    useConfigurationGraduationYearsQuery()

  const { data: periods = [], isLoading: isLoadingPeriods } =
    usePeriodsMinimalActiveOnlyQuery({
      includeActiveOnly: true
    })

  const { data: students = [], isLoading: isLoadingStudents } =
    useStudentsMinimalQuery()

  const { data: staff = [], isLoading: isLoadingStaff } = useStaffQuery()

  const { data: courses = [], mutate: getCoursesForDateAndPeriod } =
    useCoursesForDateAndPeriodMutation()

  const {
    data: massDeleteAppointmentRequest = {},
    isLoading: isLoadingMassDeleteAppointmentRequests,
    isSuccess,
    mutate: getMassDeleteAppointmentRequests,
    reset: resetAppoinmentClearByIdMutation
  } = useAppointmentsMassDeleteAppointmentsRequestMutation()

  const { isLoading: isDeletingAppointments, mutate } =
    useAppointmentsClearByIdMutation()

  const {
    appointmentIds = [],
    messages = [],
    warnings = []
  } = massDeleteAppointmentRequest

  useEffect(() => {
    if (selectedPeriodWatch === undefined || selectedPeriodWatch === '') return

    getCoursesForDateAndPeriod({
      date: startDate,
      periodId: selectedPeriodWatch
    })
  }, [getCoursesForDateAndPeriod, selectedPeriodWatch, startDate])

  const deleteAppointments = () => {
    mutate({
      appointmentIds,
      optionalCallback: () => {
        queryClient.invalidateQueries(['appointments'])

        enqueueSnackbar('Appointments have been cleared', {
          variant: 'success'
        })

        reset()
        resetAppoinmentClearByIdMutation()
      }
    })
  }

  const goBack = () => {
    if (location.state === null) {
      navigate('/appointments')
    } else {
      navigate(-1)
    }
  }

  const onSubmit = (formData) => {
    const getDeleteKey = () => {
      switch (selectedByOption) {
        case CLEANER_TYPES.ByCourse:
          return formData.selectedCourse
        case CLEANER_TYPES.ByGraduationYear:
          return formData.selectedGraduationYear
        case CLEANER_TYPES.ByStaffer:
          return formData.selectedStaffer
        case CLEANER_TYPES.ByStudent:
          return formData.selectedStudent
        default:
          return null
      }
    }

    getMassDeleteAppointmentRequests({
      cleanerType: parseInt(selectedByOption),
      deleteKey: getDeleteKey(),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      periodId: formData.selectedPeriod,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      weekDaysToInclude: formData.selectedWeekdays
    })

    window.scrollTo(0, document.body.scrollHeight)
  }

  if (
    isLoadingGraduationYears ||
    isLoadingPeriods ||
    isLoadingStaff ||
    isLoadingStudents
  )
    return <Loading text='Loading Details' />
  if (isDeletingAppointments) return <Loading text='Clearing Appointments' />

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'space-between'
        }}>
        <UserManual href={esuUrls.DELETE_MULTIPLE_APPOINTMENTS} />
        <Typography
          onClick={goBack}
          sx={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}>
          Back to Appointments
        </Typography>
      </div>
      <PageInstructions>
        Use this page to delete appointments for a selected date range, days of
        the week, and period. Delete appointments by specific course, graduation
        year, staff member or student. Please note that deleting appointments is
        permanent, and will delete the student's attendance for the specific
        appointment, if attendance has been taken.
      </PageInstructions>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexColumn>
            <DatePicker
              label='Start Date'
              onChange={(v) => updateDates(v, DATE_CONSTANTS.startDate)}
              value={startDate}
            />
            <DatePicker
              label='End Date'
              onChange={(v) => updateDates(v, DATE_CONSTANTS.endDate)}
              value={endDate}
            />
            {!datesMatch && (
              <RhfMultiSelectFormControl
                control={control}
                defaultValue={[]}
                errors={errors.selectedWeekdays}
                inputId='select-multiple-weekdays'
                label='Select Weekdays'
                labelId='select-weekdays'
                menuItems={[
                  { description: 'Monday', value: 1 },
                  { description: 'Tuesday', value: 2 },
                  { description: 'Wednesday', value: 3 },
                  { description: 'Thursday', value: 4 },
                  { description: 'Friday', value: 5 }
                ]}
                name='selectedWeekdays'
                rules={{
                  validate: (data) =>
                    data.length > 0 || 'Please select at least one weekday'
                }}
                selectedWatch={selectedWeekdaysWatch}
                setValue={setValue}
                useAllOption
              />
            )}
            <ReactHookFormSelect
              control={control}
              defaultValue={''}
              errors={errors.selectedPeriod}
              id='periods'
              label='Select Period'
              menuItems={periods}
              name='selectedPeriod'
              rules={{ required: 'Please select a Period' }}
            />
            <RadioGroup
              data={[
                {
                  label: 'By Course',
                  value: CLEANER_TYPES.ByCourse
                },
                {
                  label: 'By Graduation Year',
                  value: CLEANER_TYPES.ByGraduationYear
                },
                {
                  label: 'By Staff',
                  value: CLEANER_TYPES.ByStaffer
                },
                {
                  label: 'By Student',
                  value: CLEANER_TYPES.ByStudent
                }
              ]}
              name='options-to-pull-data'
              onChange={(e) => setSelectedByOption(e.target.value)}
              selectedValue={selectedByOption}
            />
            {selectedByOption === CLEANER_TYPES.ByCourse && (
              <ReactHookFormSelect
                control={control}
                defaultValue={''}
                errors={errors.selectedCourse}
                id='courses'
                label='Courses'
                menuItems={courses}
                name='selectedCourse'
                rules={{ required: 'Please select a Course' }}
              />
            )}
            {selectedByOption === CLEANER_TYPES.ByStudent && (
              <ReactHookFormSelect
                control={control}
                defaultValue={''}
                errors={errors.selectedStudent}
                id='students'
                label='Students'
                menuItems={students}
                name='selectedStudent'
                rules={{ required: 'Please select a Student' }}
              />
            )}
            {selectedByOption === CLEANER_TYPES.ByGraduationYear && (
              <ReactHookFormSelect
                control={control}
                defaultValue={''}
                errors={errors.selectedGraduationYear}
                id='graduationYears'
                label='Graduation Years'
                menuItems={graduationYears}
                name='selectedGraduationYear'
                rules={{ required: 'Please select a Graduation Year' }}
              />
            )}
            {selectedByOption === CLEANER_TYPES.ByStaffer && (
              <ReactHookFormSelect
                control={control}
                defaultValue={''}
                errors={errors.selectedStaffer}
                id='staff'
                label='Staff'
                menuItems={staff}
                name='selectedStaffer'
                rules={{ required: 'Please select a Staffer' }}
              />
            )}
            <Button label='Search Appointments' marginTop type='submit' />
          </FlexColumn>
        </form>
        <SearchResultsContainer>
          {isLoadingMassDeleteAppointmentRequests && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress />
              <Typography style={{ marginTop: 8 }}>
                Loading Appointments
              </Typography>
            </div>
          )}
          {isSuccess && (
            <>
              <h3>Appointment Search Results</h3>
              {warnings &&
                warnings.map((warning, i) => {
                  return <Typography key={i}>{warning}</Typography>
                })}
              {messages &&
                messages.map((message, i) => {
                  return <Typography key={i}>{message}</Typography>
                })}
              {appointmentIds.length > 0 && (
                <DeleteModal
                  confirmDeleteCallback={deleteAppointments}
                  confirmDeleteLabel='Delete Appointments'
                  confirmDeleteMessage='Are you sure you want to delete these Appointments?'
                  itemName={
                    <>
                      {warnings &&
                        warnings.map((warning, i) => {
                          return <Typography key={i}>{warning}</Typography>
                        })}
                      {messages &&
                        messages.map((message, i) => {
                          return <Typography key={i}>{message}</Typography>
                        })}
                    </>
                  }
                  label='Delete'
                  useButton
                />
              )}
              {warnings.length === 0 &&
                messages.length === 0 &&
                appointmentIds.length === 0 && (
                  <Typography>No Appointments Found</Typography>
                )}
            </>
          )}
        </SearchResultsContainer>
      </Container>
    </div>
  )
}

export default DeleteMultipleAppointments
