import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getStudentQueryKey } from '../../../helpers/queryKeys'

// exporting to use for pre-fetching
export const fetchStudentDetails = async (studentId) => {
  const response = await axios.get(`${endpoints.STUDENTS}/${studentId}`, {
    withCredentials: true
  })

  return response.data
}

export const useStudentsById = (studentId) => {
  return useQuery(
    getStudentQueryKey(studentId),
    () => fetchStudentDetails(studentId),
    {
      enabled: studentId !== '0',
      staleTime: 1000 * 60 // 1 minute
    }
  )
}
