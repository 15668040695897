import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const Subheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText
        sx={{
          gridColumn: '1/3'
        }}
        text='Date'
      />
      <SubheadingText
        sx={{
          gridColumn: '3/6'
        }}
        text='Period'
      />
      <SubheadingText
        sx={{
          gridColumn: { lg: '6/10', xl: '6/8' },
          textAlign: 'center'
        }}
        text='Appointment Count'
      />
      <SubheadingText
        sx={{
          gridColumn: { lg: '10/11', xl: '8/9' },
          textAlign: 'center'
        }}
        text='Include'
      />
    </SubheadingContainer>
  )
}

export default Subheading
