export const navOptions = [
  {
    description: 'Appointment Types',
    value: 'appointment-types'
  },
  {
    description: 'Email Notifications',
    value: 'email-permissions'
  },
  { description: 'Password', value: 'password' }
]
