import React from 'react'

import { FlexColumn, LabeledCheckbox } from '../shared'

import StepNavButtons from './StepNavButtons'

const SetOverwritePermissionsStep = ({
  handleBack,
  schedulingOptions,
  startPreprocess,
  updateSchedulingOptionsByChecked
}) => {
  return (
    <FlexColumn>
      {schedulingOptions.scheduleToStudentsHomeroom === 'true' ? (
        <LabeledCheckbox
          checked={schedulingOptions.allowHomeroomToBeOverbooked}
          formControlSx={{ marginBottom: { xs: 1, sm: 0 } }}
          label='Allow Homebase To Be Overbooked'
          name='allowHomeroomToBeOverbooked'
          onChange={updateSchedulingOptionsByChecked}
        />
      ) : (
        <LabeledCheckbox
          checked={
            schedulingOptions.autoIncreaseSeatCountForSubjectMatterCourse
          }
          formControlSx={{ marginBottom: { xs: 1, sm: 0 } }}
          label='Auto Increase Seat Count for Course'
          name='autoIncreaseSeatCountForSubjectMatterCourse'
          onChange={updateSchedulingOptionsByChecked}
        />
      )}
      <LabeledCheckbox
        checked={schedulingOptions.allowStudentsToModify}
        formControlSx={{ marginBottom: { xs: 1, sm: 0 } }}
        label='Allow Students to Modify Appointments'
        name='allowStudentsToModify'
        onChange={updateSchedulingOptionsByChecked}
      />
      <LabeledCheckbox
        checked={schedulingOptions.lockAppointments}
        formControlSx={{ marginBottom: { xs: 1, sm: 0 } }}
        label='Lock Appointments'
        name='lockAppointments'
        onChange={updateSchedulingOptionsByChecked}
      />
      <LabeledCheckbox
        checked={schedulingOptions.overwriteHomeroomAppointments}
        formControlSx={{ marginBottom: { xs: 1, sm: 0 } }}
        label='Overwrite Homebase Appointments'
        name='overwriteHomeroomAppointments'
        onChange={updateSchedulingOptionsByChecked}
      />
      <LabeledCheckbox
        checked={schedulingOptions.overwriteLockedAppointments}
        formControlSx={{ marginBottom: { xs: 1, sm: 0 } }}
        label='Overwrite Locked Appointments'
        name='overwriteLockedAppointments'
        onChange={updateSchedulingOptionsByChecked}
      />
      <LabeledCheckbox
        checked={schedulingOptions.overwriteSubjectMatterAppointments}
        formControlSx={{ marginBottom: { xs: 1, sm: 0 } }}
        label='Overwrite Subject Matter Appointments'
        name='overwriteSubjectMatterAppointments'
        onChange={updateSchedulingOptionsByChecked}
      />
      <StepNavButtons
        onClickNext={startPreprocess}
        onClickPrev={handleBack}
        stepNumber='4'
      />
    </FlexColumn>
  )
}

export default SetOverwritePermissionsStep
