import React from 'react'
import { styled } from '@mui/material/styles'

import InternalLink from './InternalLink'
import UserManual from './UserManual'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'top',
  justifyContent: 'space-between'
}))

const UserManualWithInternalLink = ({ href, label, state = {}, to }) => {
  return (
    <Container>
      <UserManual href={href} />
      <InternalLink
        state={state}
        sx={{
          display: { sm: 'none' },
          fontSize: 16
        }}
        text='Go Back'
        to={to}
      />
      <InternalLink
        sx={{
          display: { xs: 'none', sm: 'block' }
        }}
        state={state}
        text={label}
        to={to}
      />
    </Container>
  )
}

export default UserManualWithInternalLink
