import React from 'react'
import { Checkbox } from '@mui/material'

const EsCheckbox = ({
  ariaLabel,
  checked,
  dataTestId,
  disabled,
  id,
  name,
  onChange,
  sx
}) => {
  return (
    <Checkbox
      checked={checked}
      sx={sx}
      color='primary'
      disabled={disabled}
      id={id}
      inputProps={{ 'aria-label': ariaLabel, 'data-testid': dataTestId }}
      name={name}
      onChange={onChange}
    />
  )
}

export default EsCheckbox
