import { useState } from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getStudentsByDateAttendanceQueryKey,
  sortArrayByTwoFields
} from '../../../helpers'

export const useAppointmentsStudentsByDateAttendanceQuery = ({
  appointmentDate,
  attendanceTypeId,
  periodId
}) => {
  const [studentsByDateAttendance, setStudentsByDateAttendance] =
    useState(undefined)

  const { data, ...queryInfo } = useQuery(
    getStudentsByDateAttendanceQueryKey({
      appointmentDate,
      attendanceTypeId,
      periodId
    }),
    async () => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/studentsbydateattendance`,
        {
          appointmentDate,
          attendanceTypeId,
          periodId
        },
        { withCredentials: true }
      )

      response.data.forEach((r) => (r.isIncluded = false))

      const sorted = sortArrayByTwoFields(
        response.data,
        'studentLastName',
        'studentFirstName'
      )

      setStudentsByDateAttendance(sorted)

      return sorted
    },
    { enabled: !!attendanceTypeId && !!periodId }
  )

  return {
    value: studentsByDateAttendance ?? data,
    setStudentsByDateAttendance,
    queryInfo
  }
}
