import React from 'react'

import {
  DeleteModal,
  ListItemContainer,
  ListItemText,
  Menu,
  MenuItemLink,
  StudentInfo
} from '../shared'

import RosterDrawer from './RosterDrawer'
import { getStudentDisplayName } from '../../helpers'

const RosterItem = ({
  deleteStudentFromRoster,
  isLastItem,
  rosterId,
  rosterName,
  rosteredStudentId,
  stafferId,
  studentEmailAddress,
  studentFirstName,
  studentId,
  studentLastName,
  studentNickname,
  studentSisId,
  studentYearOfGraduation
}) => {
  const lastCommaFirst = getStudentDisplayName(
    studentFirstName,
    studentLastName,
    studentNickname
  )

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/10', lg: '1/5' }
        }}
        dataTestId='student-name'>
        <StudentInfo
          firstName={studentFirstName}
          lastName={studentLastName}
          nickName={studentNickname}
          sisId={studentSisId}
          userId={studentId}
          yearOfGraduation={studentYearOfGraduation}
        />
      </ListItemText>
      <ListItemText
        displayColumn
        sx={{
          wordBreak: 'break-all',
          gridColumn: { xs: '1/13', lg: '5/8' }
        }}
        labelText='Email Address:'>
        {studentEmailAddress}
      </ListItemText>
      <Menu
        sx={{ gridColumn: { xs: '1/10', lg: 9 } }}
        dataTestId='student-menu-button'>
        <DeleteModal
          confirmDeleteCallback={() =>
            deleteStudentFromRoster(
              rosterId,
              studentId,
              rosteredStudentId,
              lastCommaFirst,
              rosterName
            )
          }
          confirmDeleteLabel='Remove Student'
          confirmDeleteMessage='Are you sure you want to remove this student?'
          itemName={lastCommaFirst}
          label='Remove Student From Group'
        />
        <RosterDrawer
          staffId={stafferId}
          studentEmailAddress={studentEmailAddress}
          studentFirstName={studentFirstName}
          studentId={studentId}
          studentLastName={studentLastName}
          studentName={lastCommaFirst}
          studentYearOfGraduation={studentYearOfGraduation}
        />
        <MenuItemLink
          text='View Student Profile'
          to={`/studentProfile/${studentId}/${lastCommaFirst}`}
        />
      </Menu>
    </ListItemContainer>
  )
}

export default RosterItem
