import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStudentQueryKey } from '../../../helpers/queryKeys'

export const useStudentsMutation = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutation(
    async (objectToPost) => {
      const response = await axios.post(
        `${endpoints.STUDENTS}/save`,
        objectToPost,
        { withCredentials: true }
      )

      return {
        studentId: response.data,
        formData: objectToPost,
        shouldRedirect: objectToPost.userId === response.data ? false : true
      }
    },
    {
      onSuccess: (response) => {
        const { formData, shouldRedirect, studentId } = response

        if (location.state !== null) {
          // Invalidate the cache for the previously pulled students
          // ex. students with specific graduation year
          queryClient.invalidateQueries([
            'students',
            location.state.searchKey,
            location.state.searchValue
          ])
        }

        if (!shouldRedirect) {
          // NOTE: Invalidate the cache for the existing student
          queryClient.invalidateQueries(getStudentQueryKey(studentId))

          enqueueSnackbar(`General Info changes have been saved`, {
            variant: 'success'
          })
        } else {
          // new student
          enqueueSnackbar(
            `New Student: ${formData.lastName}, ${formData.firstName} has been created`,
            {
              variant: 'success'
            }
          )
        }
      }
    }
  )
}
