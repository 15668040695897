import React, { forwardRef } from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { styled } from '@mui/material/styles'

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: 250,
  fontFamily: 'Roboto',

  marginTop: theme.spacing(),
  marginBottom: theme.spacing()
}))

const TextArea = forwardRef(
  ({ name, onChange, placeholder, value, ...otherProps }, ref) => {
    return (
      <StyledTextArea
        minRows={10}
        maxRows={20}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value === null ? '' : value}
        {...otherProps}
      />
    )
  }
)

export default TextArea
