import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import moment from 'moment'

export const useCoursesForDatePeriodStafferQuery = ({
  appointmentDate,
  enabled = true,
  periodId,
  stafferId
}) => {
  return useQuery(
    [
      'courses',
      'for-date-period-staffer',
      {
        appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
        stafferId: parseInt(stafferId),
        periodId: parseInt(periodId)
      }
    ],
    async () => {
      const response = await axios.post(
        `${endpoints.COURSES}/fordatesperiodstaffer`,
        {
          endDate: moment(appointmentDate).format('YYYY-MM-DD'),
          includeHomeroom: true,
          periodIds: [parseInt(periodId)],
          stafferId,
          startDate: moment(appointmentDate).format('YYYY-MM-DD'),
          studentId: 0
        },
        { withCredentials: true }
      )

      return response.data
    },
    {
      enabled
      //staleTime: 1000 * 60
    }
  )
}
