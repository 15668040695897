export const setupAlphabetMenuItems = (useAllOption = false) => {
  const generateAlphabetArray = () => {
    const c1 = 'A'
    const c2 = 'Z'

    const list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

    return list.slice(list.indexOf(c1), list.indexOf(c2) + 1)
  }

  let array = []

  if (useAllOption) {
    array.push({
      description: 'All',
      value: 'All'
    })
  }

  const alphabetArray = generateAlphabetArray()

  alphabetArray.forEach((item) => {
    array.push({
      description: item,
      value: item
    })
  })

  return array
}
