import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const Subheading = ({ lastSortedBy, onClick, sortAscending }) => {
  return (
    <SubheadingContainer>
      <SubheadingText
        sx={{ gridColumn: '1/3' }}
        ascValue={sortAscending}
        isSortable
        isSelected={lastSortedBy === 'scheduleDate'}
        onClick={() => onClick('scheduleDate')}
        text='Date'
      />
      <SubheadingText sx={{ gridColumn: '3/7' }} text='Reason' />
      <SubheadingText
        ascValue={sortAscending}
        sx={{ gridColumn: '7/10' }}
        isSortable
        isSelected={lastSortedBy === 'periodId'}
        onClick={() => onClick('periodId')}
        text='Period'
      />
      <SubheadingText sx={{ gridColumn: '10/12' }} text='Block Type' />
    </SubheadingContainer>
  )
}

export default Subheading
