import React from 'react'
import { Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  overflowX: 'auto',

  paddingRight: theme.spacing(),
  paddingLeft: theme.spacing()
}))

const EsPaper = ({ children, id, sx }) => {
  return (
    <StyledPaper id={id} sx={sx}>
      {children}
    </StyledPaper>
  )
}

export default EsPaper
