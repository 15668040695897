import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Cached } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import Button from './Button'
import SelectField from './SelectField'

const MobileSorting = ({ initialValue, menuItems, onClick }) => {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const [selectedValue, setSelectedValue] = useState(initialValue)

  const handleSelectionChange = (value) => {
    setSelectedValue(value)
    onClick(value)
  }

  if (isLarge) return null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 1
      }}>
      {menuItems.length > 1 ? (
        <SelectField
          fullWidth={false}
          label='Sort By'
          labelId='sort-by'
          menuItems={menuItems}
          onChange={(e) => handleSelectionChange(e.target.value)}
          selectedValue={selectedValue}
        />
      ) : (
        <Typography>Sort By: {menuItems[0].description}</Typography>
      )}
      <Button
        dataTestId='reverse-sort'
        label={<Cached />}
        marginLeft
        onClick={() => onClick(selectedValue)}
      />
    </Box>
  )
}

export default MobileSorting
