import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useSecurityGetAccountQuery = ({ isAuthenticated }) => {
  return useQuery(
    ['security', 'get-account'],
    async () => {
      const response = await axios.get(`${endpoints.SECURITY}/getaccount`, {
        withCredentials: true
      })

      return response.data
    },
    {
      enabled: isAuthenticated
    }
  )
}
