import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { headerHeight } from '../../constants'

const StyledLoading = styled('div')(() => ({
  alignItems: 'center',
  backgroundColor: '#eee',
  display: 'flex',
  height: 'calc(100vh - 81px)',
  flexDirection: 'column',
  justifyContent: 'center',
  left: { xs: 0, lg: 240 },
  marginLeft: -16,
  minHeight: '100vh',
  position: 'fixed',
  width: '100%',
  top: headerHeight,
  zIndex: 10000
}))

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const Loading = ({ dataTestId = 'loading-component', sx, text }) => {
  return (
    <StyledLoading data-testid={dataTestId} sx={sx}>
      <StyledCircularProgress size={80} />
      <StyledTypography variant='h4' align='center'>
        {text}
      </StyledTypography>
    </StyledLoading>
  )
}

export default Loading
