import React from 'react'
import memoize from 'memoize-one'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { useWindowResize } from '../../../hooks'

// This helper function memoizes incoming props,
// To avoid causing unnecessary re-renders pure Row components.
// This is only needed since we are passing multiple props with a wrapper object.
// If we were only passing a single, stable value (e.g. items),
// We could just pass the value directly.
const createItemData = memoize(
  ({ data, setSize, windowWidth, ...otherProps }) => {
    return {
      items: data,
      setSize,
      windowWidth,
      ...otherProps
    }
  }
)

const VirtualList = (props) => {
  const listRef = React.useRef()
  const sizeMap = React.useRef({})
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    listRef.current.resetAfterIndex(index)
  }, [])
  const getSize = (index) => sizeMap.current[index] + 8 || 55
  const [windowWidth] = useWindowResize()

  // Bundle additional data to list items using the "itemData" prop.
  // It will be accessible to item renderers as props.data.
  // Memoize this data to avoid bypassing shouldComponentUpdate().
  const itemData = createItemData({
    data: props.data,
    setSize,
    windowWidth,
    ...props
  })

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <List
            className='List'
            height={height}
            itemCount={props.data.length}
            itemData={itemData}
            itemSize={getSize}
            ref={listRef}
            width={width}>
            {props.Component}
          </List>
        )
      }}
    </AutoSizer>
  )
}

export default VirtualList
