import { useMutation } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useSendEmailNotifyMyAppointmentsMutation = ({
  resetStateAfterSave
}) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ details, periodToNotify }) => {
      await axios.post(
        `${endpoints.SEND_EMAIL}/notifymyappointments`,
        details,
        {
          withCredentials: true
        }
      )

      return periodToNotify
    },
    {
      onSuccess: (periodToNotify) => {
        enqueueSnackbar(
          `All of your ${periodToNotify} Appointments have been notified`,
          {
            variant: 'success'
          }
        )

        resetStateAfterSave()
      }
    }
  )
}
