import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useGroupTitleMutation = () => {
  return useMutation(async ({ rosterValue, stafferValue }) => {
    const response = await axios.get(
      `${endpoints.ROSTERS}/idbystaffandname/${stafferValue}/${rosterValue}`,
      { withCredentials: true }
    )

    return response.data
  })
}
