import axios from 'axios'
import { useMutation } from 'react-query'

import { BLOCKED_DATE_TYPES, endpoints } from '../../../constants'

export const useAppointmentsCountsForBlockingDatesQuery = () => {
  return useMutation(
    async ({
      countType,
      endDate,
      id,
      periodIds,
      repeatOption,
      startDate,
      weekdaysToInclude
    }) => {
      if (
        (countType === BLOCKED_DATE_TYPES.byDepartment ||
          countType === BLOCKED_DATE_TYPES.byStaff) &&
        !id
      )
        return

      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/countsForBlockingDates`,
        {
          countType,
          endDate,
          id,
          periodIds,
          repeatOption,
          startDate,
          weekdaysToInclude
        },
        { withCredentials: true }
      )

      return response.data
    }
  )
}
