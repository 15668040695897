import React from 'react'
import PropTypes from 'prop-types'
import { Info } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledUserManual = styled('a')(({ theme }) => ({
  display: 'flex',
  color: 'blue',

  paddingBottom: theme.spacing(3)
}))

const StyledInfoIcon = styled(Info)(({ theme }) => ({
  color: '#959292',
  marginRight: theme.spacing()
}))

const UserManual = ({ href, sx }) => {
  return (
    <StyledUserManual
      data-testid='user-manual'
      href={href}
      target='__blank'
      sx={sx}>
      <StyledInfoIcon />
      <Typography>User Manual</Typography>
    </StyledUserManual>
  )
}

UserManual.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired
}

export default UserManual
