import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStudentContactsQueryKey } from '../../../helpers'

export const useStudentContactsDeleteMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ endUserStudentContactId, studentId }) => {
      await axios.delete(
        `${endpoints.STUDENT_CONTACTS}/${studentId}/${endUserStudentContactId}`,
        { withCredentials: true }
      )

      return { endUserStudentContactId, studentId }
    },
    {
      onSuccess: ({ endUserStudentContactId, studentId }) => {
        enqueueSnackbar(`Emergency Contact has been deleted`, {
          variant: 'success'
        })

        queryClient.setQueryData(
          getStudentContactsQueryKey(studentId),
          (prevStudentContacts) => {
            return prevStudentContacts.filter(
              (contact) =>
                contact.endUserStudentContactId !== endUserStudentContactId
            )
          }
        )
      }
    }
  )
}
