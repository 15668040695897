import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import {
  getAppointmentExportDefinitionsByIdQueryKey,
  getAppointmentExportDefinitionsQueryKey
} from '../../../helpers/queryKeys'

// Exporting to use for pre-fetching
export const fetchExportDefinitionDetails = async (
  appointmentExportDefinitionId
) => {
  const response = await axios.get(
    `${endpoints.APPOINTMENT_EXPORT_DEFINITIONS}/byId/${appointmentExportDefinitionId}`,
    { withCredentials: true }
  )

  return response.data
}

export const useAppointmentExportDefinitionsByIdQuery = ({
  appointmentExportDefinitionId
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getAppointmentExportDefinitionsByIdQueryKey(appointmentExportDefinitionId),
    () => fetchExportDefinitionDetails(appointmentExportDefinitionId),
    {
      onError: () => {
        // Invalidate cached Appointment Types
        queryClient.invalidateQueries(getAppointmentExportDefinitionsQueryKey())

        enqueueSnackbar(
          `We are unable to find this record, it's likely that it has been deleted by another user.`,
          {
            variant: 'error'
          }
        )
      },
      enabled: parseInt(appointmentExportDefinitionId) !== 0,
      staleTime: 1000 * 30
    }
  )
}
