import { Typography } from '@mui/material'
import React from 'react'

import { getStudentDisplayName } from '../../helpers'

import FlexColumn from './FlexColumn'
import InternalLink from './InternalLink'

const StudentInfo = ({
  firstName,
  lastName,
  nickName,
  sisId,
  useLink = false,
  userId,
  yearOfGraduation
}) => {
  const studentDisplayName = getStudentDisplayName(
    firstName,
    lastName,
    nickName
  )

  return (
    <FlexColumn>
      {useLink ? (
        <InternalLink
          text={studentDisplayName}
          to={`/studentProfile/${userId}/${studentDisplayName}`}
        />
      ) : (
        <Typography>{studentDisplayName}</Typography>
      )}
      <Typography
        sx={{
          fontWeight: 400,
          fontStyle: 'italic',
          fontSize: '13px'
        }}>
        Graduation Year: {yearOfGraduation}&nbsp;&nbsp;|&nbsp;&nbsp;SIS Id:{' '}
        {sisId}
      </Typography>
    </FlexColumn>
  )
}

export default StudentInfo
