import React, { useEffect, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: { xs: '100%', sm: 250 },
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5)
}))

const SearchField = ({ handleSearch, initialValue, sx }) => {
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if (initialValue === undefined) {
      setSearchTerm('')
      return
    }

    setSearchTerm(initialValue)
  }, [initialValue])

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && searchTerm !== '') {
      handleSearch(searchTerm)
    }
  }

  return (
    <StyledTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position='start'>
            <Search
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => handleKeyDown({ keyCode: 13 })}
            />
          </InputAdornment>
        )
      }}
      label='Search by Name or SIS Id'
      name='searchValue'
      onChange={(e) => setSearchTerm(e.target.value)}
      onKeyDown={handleKeyDown}
      sx={sx}
      value={searchTerm}
      variant='standard'
    />
  )
}

export default SearchField
