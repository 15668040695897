import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getDayTypesQueryKey } from '../../../helpers'

export const useDayTypesQuery = () => {
  return useQuery(
    getDayTypesQueryKey(),
    async () => {
      const response = await axios.get(endpoints.DAY_TYPES, {
        withCredentials: true
      })

      return response.data
    },
    { staleTime: 1000 * 30 }
  )
}
