import React, { useState } from 'react'
import axios from 'axios'
import { useMutation } from 'react-query'
import { Box } from '@mui/material'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { endpoints } from '../constants'
import {
  HeaderTitleContext,
  LoggedInUserProvider,
  NotificationsProvider,
  useLoggedInUser
} from '../context'
import { useSecurityGetAccountQuery } from '../hooks'

import Header from './Header'
import Main from './Main'
import MainUnauthenticated from './MainUnauthenticated'
import Nav from './Nav'

const useSecuritySwitchAccountMutation = ({ setLoggedInUserDetails }) => {
  return useMutation(
    async ({ schoolId, userId }) => {
      const response = await axios.get(
        `${endpoints.SECURITY}/switchaccount/${schoolId}/${userId}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      onSuccess: (data) => {
        setLoggedInUserDetails(data)
      }
    }
  )
}

const Layout = () => {
  const { isAuthenticated, loggedInUserToSchoolId, setLoggedInUserDetails } =
    useLoggedInUser()

  const [headerTitle, setHeaderTitle] = useState('Loading...')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState(loggedInUserToSchoolId)

  const { mutate } = useSecuritySwitchAccountMutation({
    setLoggedInUserDetails
  })

  const { data: schools = [] } = useSecurityGetAccountQuery({ isAuthenticated })

  const updateSelectedSchool = (e) => {
    const { value } = e.target

    // set local state
    setSelectedSchool(value)

    const selectedSchoolDetails = schools.find(
      (school) => school.userToSchoolId === value
    )

    mutate({
      schoolId: selectedSchoolDetails.schoolId,
      userId: selectedSchoolDetails.userId
    })
  }

  return (
    <>
      {isAuthenticated ? (
        <Box sx={{ display: { lg: 'flex' } }}>
          <Header
            headerTitle={headerTitle}
            mobileMenuOpen={mobileMenuOpen}
            schools={schools}
            selectedSchool={selectedSchool}
            setMobileMenuOpen={setMobileMenuOpen}
            updateSelectedSchool={updateSelectedSchool}
          />
          <Nav
            mobileMenuOpen={mobileMenuOpen}
            schools={schools}
            selectedSchool={selectedSchool}
            setMobileMenuOpen={setMobileMenuOpen}
            updateSelectedSchool={updateSelectedSchool}
          />
          <HeaderTitleContext.Provider
            value={{ setHeaderTitle: setHeaderTitle }}>
            <Main />
          </HeaderTitleContext.Provider>
        </Box>
      ) : (
        <MainUnauthenticated />
      )}
    </>
  )
}

const LayoutWithProviders = () => {
  return (
    <LoggedInUserProvider>
      <QueryClientAndNotificationsProviders />
    </LoggedInUserProvider>
  )
}

const QueryClientAndNotificationsProviders = () => {
  const { logout } = useLoggedInUser()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (error) => {
        console.log(error, 'error in queryCache')
        console.log(error.response, 'error.response')
        console.log(
          'error response status in queryCache: ' + error.response.status
        )

        //from here we can handle logouts
        if (error.response.status === 401) {
          // logout logic will go here
          logout()
        }
      }
    })
  })

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider>
        <Layout />
      </NotificationsProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default LayoutWithProviders
