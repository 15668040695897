import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { useLoggedInUser } from '../../../context'
import {
  getNotificationsQueryKey,
  sortByCollatingField
} from '../../../helpers'
import { useLocation } from 'react-router-dom'

export const useNotificationsQuery = () => {
  const { isAuthenticated } = useLoggedInUser()

  const location = useLocation()

  return useQuery(
    getNotificationsQueryKey(),
    async () => {
      const response = await axios.get(
        `${endpoints.NOTIFICATIONS}/allunexpired`,
        { withCredentials: true }
      )

      return response.data.sort(sortByCollatingField(false, 'dateCreated'))
    },
    {
      enabled: isAuthenticated && !location.pathname.includes('validateToken')
    }
  )
}
