import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button as MuiButton, Modal, Typography } from '@mui/material'

import { APPOINTMENT_EDITOR_RESPONSES } from '../../constants'
import {
  Button,
  FlexColumn,
  LabeledCheckbox,
  List,
  Paper,
  PaperTitle
} from '../shared'

import { useAdvancedAppointments } from './AdvancedAppointmentsContext'
import CollisionItem from './CollisionItem'
import CollisionSubheading from './CollisionSubheading'

const ScheduleCollisionsSummary = () => {
  const {
    autoIncreaseSeatCount,
    canBeOverwrittenByStudent,
    collisions,
    isLocked,
    loggedInUserId,
    periods,
    schedulerComment,
    scheduleStudent,
    selectedAppointmentType,
    selectedCourseId,
    setCollisions
  } = useAdvancedAppointments()
  const location = useLocation()
  const navigate = useNavigate()

  const [excludedCollisions, setExcludedCollisions] = useState([])
  const [increaseSeatCount, setIncreaseSeatCount] = useState(
    autoIncreaseSeatCount
  )

  const actionableItems = collisions.filter(
    (item) =>
      item.appointmentEditorResponse ===
        APPOINTMENT_EDITOR_RESPONSES.classFullyBookedCanBeAutoIncrease ||
      item.appointmentEditorResponse ===
        APPOINTMENT_EDITOR_RESPONSES.appointmentExistsAndNotOverriden
  )

  const unactionableItems = collisions.filter(
    (r) =>
      r.appointmentEditorResponse !==
        APPOINTMENT_EDITOR_RESPONSES.appointmentExistsAndNotOverriden &&
      r.appointmentEditorResponse !==
        APPOINTMENT_EDITOR_RESPONSES.classFullyBookedCanBeAutoIncrease &&
      r.appointmentEditorResponse !==
        APPOINTMENT_EDITOR_RESPONSES.appointmentSaved
  )

  const excludedCount = excludedCollisions.length

  const goBack = () => {
    if (location.state === null) {
      navigate('/appointments')
    } else {
      navigate(-1)
    }
  }

  const setupReschedule = () => {
    const datesAndPeriods = collisions.filter(
      ({ appointmentDate, periodId }) =>
        !excludedCollisions.some(
          (e) =>
            e.appointmentDate === appointmentDate && e.periodId === periodId
        )
    )

    const details = {
      adjustedById: loggedInUserId,
      appointmentId: 0,
      appointmentTypeId: selectedAppointmentType,
      autoIncreaseSeatCount: increaseSeatCount,
      canBeOverwrittenByStudent,
      courseId: selectedCourseId,
      datesAndPeriods,
      isLocked,
      overrideAppointments: true,
      schedulerComment,
      studentIds: [collisions[0].studentId]
    }

    scheduleStudent({ details, shouldRedirectAfterSave: true })
    setCollisions([])
  }

  const updateExcludedCollisions = ({ appointmentDate, checked, periodId }) => {
    if (checked) {
      setExcludedCollisions([
        ...excludedCollisions,
        { appointmentDate, periodId }
      ])
    }

    if (!checked) {
      setExcludedCollisions(
        excludedCollisions.filter(
          (collision) =>
            collision.appointmentDate !== appointmentDate ||
            collision.periodId !== periodId
        )
      )
    }
  }

  if (collisions.length < 1) return null

  return (
    <Modal
      open={true}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}>
        <Paper
          sx={{
            height: { xs: '100vh', sm: 'auto' },
            marginBottom: 0,
            width: { sm: 'auto' },
            paddingBottom: { sm: 2 },
            minWidth: { lg: 800 }
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'top'
            }}>
            <PaperTitle>Collisions:</PaperTitle>
            <MuiButton onClick={() => setCollisions([])} variant='text'>
              X
            </MuiButton>
          </div>
          {unactionableItems.length > 0 && (
            <div>
              <Typography>
                The following Appointments cannot be scheduled:
              </Typography>
              <CollisionSubheading disableExclude={true} />
              <List
                Component={CollisionItem}
                data={unactionableItems}
                disableExclude={true}
                keyValue='appointmentId'
                periods={periods}
              />
            </div>
          )}
          {actionableItems.length > 0 && (
            <div>
              <Typography>
                The following Appointments can be scheduled but need more input:
              </Typography>
              <CollisionSubheading disableExclude={false} />
              <List
                Component={CollisionItem}
                data={actionableItems}
                disableExclude={false}
                keyValue='appointmentId'
                periods={periods}
                updateExclusions={updateExcludedCollisions}
              />
            </div>
          )}
          {unactionableItems.length < collisions.length && (
            <FlexColumn>
              <LabeledCheckbox
                checked={increaseSeatCount}
                label='Auto Increase Seat Count'
                onChange={(e) => setIncreaseSeatCount(e.target.checked)}
              />
            </FlexColumn>
          )}
          {unactionableItems.length < collisions.length && (
            <Button
              disabled={excludedCount >= actionableItems.length}
              onClick={setupReschedule}
              label='Reschedule'
              marginRight
            />
          )}
          <Button label='Back to Appointments' onClick={goBack} />
        </Paper>
      </Box>
    </Modal>
  )
}

export default ScheduleCollisionsSummary
