import React from 'react'
import moment from 'moment'

import { LinkButton, ListItemContainer, ListItemText } from '../shared'

const WeekdayListItem = ({
  appointmentDate,
  isLastItem,
  totalAttendanceTakenAllPeriods,
  totalSeatsAllPeriods,
  totalStudentsScheduledAllPeriods
}) => {
  const isFutureDate =
    moment().format('YYYY-MM-DD') < moment(appointmentDate).format('YYYY-MM-DD')

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        labelText='Date:'
        sx={{
          gridColumn: { xs: '1/10', lg: '1/3' }
        }}>
        {moment(appointmentDate).format('MM/DD/YYYY')}
      </ListItemText>
      <ListItemText
        labelText='Total Seats:'
        sx={{
          display: { lg: 'grid' },
          gridColumn: { xs: '1/10', lg: '3/5' },
          textAlign: { lg: 'center' }
        }}>
        {totalSeatsAllPeriods}
      </ListItemText>
      <ListItemText
        labelText='Students Scheduled:'
        sx={{
          display: { lg: 'grid' },
          gridColumn: { xs: '1/10', lg: '5/7' },
          textAlign: { lg: 'center' }
        }}>
        {totalStudentsScheduledAllPeriods}
      </ListItemText>
      <ListItemText
        labelText='Final Attendance:'
        sx={{
          display: { lg: 'grid' },
          gridColumn: { xs: '1/10', lg: '7/9' },
          textAlign: { lg: 'center' }
        }}>
        {totalAttendanceTakenAllPeriods}
      </ListItemText>
      <ListItemText
        sx={{
          display: { xs: 'flex', sm: 'grid' },
          gridColumn: { xs: '1/13', sm: '10/13', lg: '9/11' },
          gridRow: { sm: '1 / span 4' },
          justifyContent: { xs: 'center', sm: 'end' },
          textAlign: { lg: 'center' }
        }}>
        <LinkButton
          esButtonClass={isFutureDate ? 'greyButton' : 'greenButton'}
          label={isFutureDate ? 'View Appointments' : 'Take Attendance'}
          to={`/takeAttendance/${moment(appointmentDate).format('YYYY-MM-DD')}`}
        />
      </ListItemText>
    </ListItemContainer>
  )
}

export default WeekdayListItem
