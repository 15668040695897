import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import {
  getBlockedDatesByDepartmentQueryKey,
  getBlockedDatesBySchoolQueryKey,
  getBlockedDatesByStaffQueryKey
} from '../../../helpers'

export const useDeleteBlockMutation = ({
  departmentId,
  month,
  selectedTab,
  staffId
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ blockedDateDetailId, blockedDateId }) => {
      await axios.delete(
        `${endpoints.BLOCKED_DATES}/${blockedDateId}/details/${blockedDateDetailId}`,
        { withCredentials: true }
      )

      return blockedDateDetailId
    },
    {
      onSuccess: (blockedDateDetailId) => {
        enqueueSnackbar('Blocked Date has been deleted', {
          variant: 'success'
        })

        let queryKey

        const parsedSelectedTab = parseInt(selectedTab)

        if (parsedSelectedTab === 0) {
          queryKey = getBlockedDatesByStaffQueryKey({
            month,
            selectedTab,
            staffId
          })
        }

        if (parsedSelectedTab === 1) {
          queryKey = getBlockedDatesByDepartmentQueryKey({
            departmentId,
            month,
            selectedTab
          })
        }

        if (parsedSelectedTab === 2) {
          queryKey = getBlockedDatesBySchoolQueryKey({ month, selectedTab })
        }

        queryClient.setQueryData(queryKey, (prevBlockedDates) => {
          return prevBlockedDates.filter(
            (blockedDate) =>
              blockedDate.blockedDateDetailId !== blockedDateDetailId
          )
        })

        queryClient.invalidateQueries(['appointments'])
      }
    }
  )
}
