import React from 'react'

import StudentList from './StudentList'

const StudentMoveList = ({
  listHeading = 'Scheduled Students',
  moveAllStudents,
  moveStudent,
  students
}) => {
  return (
    <StudentList
      listHeading={listHeading}
      moveAllStudents={moveAllStudents}
      moveStudent={moveStudent}
      students={students}
      studentType='scheduled'
    />
  )
}

export default StudentMoveList
