import React from 'react'
import { Navigate } from 'react-router-dom'

import { useLoggedInUser } from '../../context'

import NoMobile from './NoMobile'

const RequireAdmin = ({ children, hideMobile = false }) => {
  const { isAdmin } = useLoggedInUser()

  if (hideMobile) return <NoMobile />

  return isAdmin ? children : <Navigate to='/dashboard' />
}

export default RequireAdmin
