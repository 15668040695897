import React from 'react'
import { Checkbox, ListItemContainer, ListItemText } from '../shared'
import moment from 'moment'

const AppointmentCountItem = ({
  appointmentCount,
  appointmentDate,
  excludedCourses,
  isLastItem,
  periodId,
  periods,
  updateExcludedCourses
}) => {
  const isChecked = () => {
    const result = excludedCourses.find(
      (course) =>
        course.appointmentDate === appointmentDate &&
        course.periodId === periodId
    )

    return result === undefined ? true : false
  }

  const periodDescription = () => {
    const result = periods.find((period) => period.periodId === periodId)

    return result.periodDescription
  }

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/3' }
        }}
        labelText='Date'>
        {moment(appointmentDate).format('MM/DD/YYYY')}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '3/6' }
        }}
        labelText='Period:'>
        {periodDescription()}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '6/10', xl: '6/8' },
          textAlign: 'center'
        }}
        labelText='Appointment Count:'>
        {appointmentCount}
      </ListItemText>
      <ListItemText
        sx={{
          textAlign: 'center',
          gridColumn: { xs: '1/13', lg: '10/11', xl: '8/9' }
        }}
        labelText='Include:'>
        <Checkbox
          checked={isChecked()}
          onChange={(e) =>
            updateExcludedCourses({
              appointmentDate,
              periodId,
              checked: e.target.checked
            })
          }
        />
      </ListItemText>
    </ListItemContainer>
  )
}

export default AppointmentCountItem
