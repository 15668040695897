import React from 'react'
import moment from 'moment'

import { LinkButton } from '../shared'

import {
  StyledCard,
  SummaryButtonContainer,
  SummaryDate,
  SummaryInfo,
  SummaryText,
  SummaryTitle
} from './styledComponents'

const WeekdayCard = ({
  appointmentDate,
  totalAttendanceTakenAllPeriods,
  totalSeatsAllPeriods,
  totalStudentsScheduledAllPeriods
}) => {
  const isFutureDate =
    moment().format('YYYY-MM-DD') < moment(appointmentDate).format('YYYY-MM-DD')

  return (
    <StyledCard>
      <SummaryInfo>
        <SummaryTitle>Summary For:&nbsp;</SummaryTitle>
        <SummaryDate>
          {moment(appointmentDate).format('MM/DD/YYYY')}
        </SummaryDate>
      </SummaryInfo>
      <SummaryText data-testid='summary-text'>
        You have a total of <b>{totalSeatsAllPeriods}</b> seats across all
        periods. Attendance has been taken for{' '}
        <b>{totalAttendanceTakenAllPeriods}</b> out of{' '}
        <b>{totalStudentsScheduledAllPeriods}</b> total appointments.
      </SummaryText>
      <SummaryButtonContainer>
        <LinkButton
          esButtonClass={isFutureDate ? 'greyButton' : 'greenButton'}
          label={isFutureDate ? 'View Appointments' : 'Take Attendance'}
          to={`/takeAttendance/${moment(appointmentDate).format('YYYY-MM-DD')}`}
        />
      </SummaryButtonContainer>
    </StyledCard>
  )
}

export default WeekdayCard
