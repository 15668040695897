import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { esuUrls } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  useAdjustedOfferingQuery,
  useAppointmentTypesQuery,
  useConfigurationGraduationYearsQuery,
  useHeaderTitle,
  useLookupValuesRepeatOptionsQuery,
  usePeriodsMinimalActiveOnlyQuery,
  useStaffByIdQuery
} from '../../hooks'
import {
  Loading,
  NetworkErrorMessage,
  UserManualWithInternalLink
} from '../shared'

import {
  CreateAdjustedOfferingForm,
  ExistingOfferingDetails
} from '../CreateAdjustedOffering'

const CreateAdjustedOffering = () => {
  const { adjustedOfferingId, cameFromSeries, staffId } = useParams()

  const { isAdmin } = useLoggedInUser()

  useHeaderTitle(
    adjustedOfferingId === '0'
      ? 'Create Adjusted Offering'
      : 'Edit Adjusted Offering'
  )

  const isEditing = adjustedOfferingId === '0' ? false : true

  const { data: appointmentTypes = [], isLoading: isLoadingAppointmentTypes } =
    useAppointmentTypesQuery()
  const { data: graduationYears = [], isLoading: isLoadingGraduationYears } =
    useConfigurationGraduationYearsQuery()
  const { data: periods = [], isLoading: isLoadingPeriods } =
    usePeriodsMinimalActiveOnlyQuery({
      includeActiveOnly: true
    })
  const { data: repeatOptions = [], isLoading: isLoadingRepeatOptions } =
    useLookupValuesRepeatOptionsQuery()
  const {
    data: staffer = {
      courses: [],
      staffer: {}
    },
    isLoading: isLoadingStaffer
  } = useStaffByIdQuery(staffId)

  const doesStafferHaveAnActiveSubjectMatterCourse = () => {
    if (isLoadingStaffer) return false

    if (staffer.courses.length < 1) return false

    const subjectMatterCourse = staffer.courses.find(
      (course) => !course.isHomeroom
    )

    if (!subjectMatterCourse) return false

    if (subjectMatterCourse.isActive === false) return false

    return true
  }

  const stafferHasActiveSubjectMatterCourse =
    doesStafferHaveAnActiveSubjectMatterCourse()

  const maxNumberOfStudents = isLoadingStaffer
    ? 0
    : staffer.staffer.maxNumberStudents
  const canCreateAdjustedCourseBlockedToOthers = isLoadingStaffer
    ? false
    : staffer.staffer.canCreateAdjustedCourseBlockedToOthers

  const subjectMatterCourse = staffer.courses.find(
    (course) => !course.isHomeroom
  )

  const {
    data: offeringDetails,
    error: errorLoadingOffering,
    isLoading: isLoadingOfferingDetails
  } = useAdjustedOfferingQuery({
    adjustedOfferingId,
    staffId
  })

  const setupBackLink = () => {
    if (cameFromSeries === 'true')
      return `/adjustedSeries/${offeringDetails.stafferId}/${offeringDetails.groupId}`

    if (isAdmin) return `/adjustedOfferings/${staffId}`

    return `/adjustedOfferings/0`
  }

  if (
    isLoadingAppointmentTypes ||
    isLoadingGraduationYears ||
    isLoadingOfferingDetails ||
    isLoadingPeriods ||
    isLoadingRepeatOptions ||
    isLoadingStaffer
  )
    return <Loading text='Loading Adjusted Offering Details' />

  if (errorLoadingOffering)
    return (
      <NetworkErrorMessage
        error={'Unable to find record with this id'}
        linkText='Back to Adjusted Offerings'
        to={setupBackLink()}
      />
    )

  return (
    <Grid container>
      <Grid item xs={12}>
        <UserManualWithInternalLink
          href={esuUrls.CREATE_ADJUSTED_OFFERINGS}
          label='Back to Adjusted Offerings'
          to={setupBackLink()}
        />
      </Grid>
      {adjustedOfferingId !== '0' && (
        <ExistingOfferingDetails
          courseTitle={offeringDetails.courseTitle}
          periodDescription={offeringDetails.periodDescription}
          scheduleDate={offeringDetails.scheduleDate}
        />
      )}
      {stafferHasActiveSubjectMatterCourse ? (
        <CreateAdjustedOfferingForm
          adjustedOfferingId={adjustedOfferingId}
          appointmentTypes={appointmentTypes}
          backLink={setupBackLink()}
          canCreateAdjustedCourseBlockedToOthers={
            canCreateAdjustedCourseBlockedToOthers
          }
          graduationYears={graduationYears}
          homeRoom={staffer.staffer.homeRoom}
          isAdmin={isAdmin}
          isEditing={isEditing}
          maxNumberOfStudents={maxNumberOfStudents}
          periods={periods}
          repeatOptions={repeatOptions}
          staffId={staffId}
          subjectMatterCourse={subjectMatterCourse}
          {...offeringDetails}
        />
      ) : (
        <Typography>
          The selected Staffer does not have an active Subject Matter Course.
          Because of this, no Adjusted Offerings can be created for this
          Staffer. An Admin in your school will need to adjust this Staffers
          record.
        </Typography>
      )}
    </Grid>
  )
}

export default CreateAdjustedOffering
