export const MONTHS = [
  {
    description: 'July',
    value: 7
  },
  {
    description: 'August',
    value: 8
  },
  {
    description: 'September',
    value: 9
  },
  {
    description: 'October',
    value: 10
  },
  {
    description: 'November',
    value: 11
  },
  {
    description: 'December',
    value: 12
  },
  {
    description: 'January',
    value: 1
  },
  {
    description: 'February',
    value: 2
  },
  {
    description: 'March',
    value: 3
  },
  {
    description: 'April',
    value: 4
  },
  {
    description: 'May',
    value: 5
  },
  {
    description: 'June',
    value: 6
  }
]
