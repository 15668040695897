import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'

import { List, Paper, PaperTitle } from '../shared'

import CourseDatePeriodItem from './CourseDatePeriodItem'
import Subheading from './Subheading'

const CourseList = ({ courseList, isLoadingCourses, updateCourseList }) => {
  return (
    <Grid item xs={12} sm={7} lg={8}>
      <Paper>
        <PaperTitle>Courses for Selection</PaperTitle>
        {isLoadingCourses ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
            <Typography>Loading Courses</Typography>
          </Box>
        ) : (
          <div>
            <Subheading />
            <List
              data={courseList}
              Component={CourseDatePeriodItem}
              emptyArrayMessage='Please select a Date and Period combination to see a list of courses'
              updateCourseWithAdjustment={updateCourseList}
            />
          </div>
        )}
      </Paper>
    </Grid>
  )
}

export default CourseList
