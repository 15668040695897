import React, { useEffect } from 'react'
import moment from 'moment'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import { Grid } from '@mui/material'

import { weekdayMenuItems } from '../../constants'
import { useStartAndEndDate } from '../../hooks'
import {
  Button,
  DatePicker,
  FlexColumn,
  Loading,
  ReactHookFormCheckbox,
  ReactHookFormSelect,
  RhfMultiSelectFormControl,
  TextArea,
  TextField
} from '../shared'

import { useCourseList, useSaveAdjustedOfferingMutation } from './hooks'
import CourseList from './CourseList'
import EditingNote from './EditingNote'

const Form = ({
  // Offering Details
  adjustedCourseName,
  adjustedRoom,
  adjustedSeatCount,
  appointmentTypeId = '',
  blockedGraduationYears,
  courseDescription = '',
  courseId,
  courseTitle,
  groupId,
  meetingLink,
  periodId,
  mustUseDefaultAppointmentType = false,
  preventOtherStaffFromScheduling = false,
  preventStudentRequesting = false,
  preventStudentSelfScheduling = false,
  scheduleDate,

  // Additional
  adjustedOfferingId,
  appointmentTypes,
  backLink,
  canCreateAdjustedCourseBlockedToOthers,
  graduationYears,
  homeRoom,
  isAdmin,
  isEditing,
  maxNumberOfStudents,
  periods,
  repeatOptions,
  staffId,
  subjectMatterCourse
}) => {
  const parsedAdjustedOfferingId = parseInt(adjustedOfferingId)
  const isNewRecord = parsedAdjustedOfferingId === 0

  const { control, errors, handleSubmit, register, setValue } = useForm()

  const [startDate, endDate, updateDates] = useStartAndEndDate(
    moment().format(),
    moment().format()
  )

  const selectedPeriodsWatch = useWatch({
    control,
    name: 'selectedPeriods',
    defaultValue: []
  })

  const selectedRepeatingOptionWatch = useWatch({
    control,
    name: 'selectedRepeatingOption'
  })

  const selectedWeekdaysWatch = useWatch({
    control,
    name: 'selectedWeekdays',
    defaultValue: []
  })

  const shouldDisplayWeekdays =
    selectedRepeatingOptionWatch === 3 || selectedRepeatingOptionWatch === 5
      ? true
      : false

  const {
    courseList,
    getCourses,
    getLowestNumberOfAppointmentsForSelectedCourses,
    isLoadingCourses,
    selectedCourses,
    updateCourseList
  } = useCourseList()

  useEffect(() => {
    if (selectedPeriodsWatch.length < 1) return
    if (selectedPeriodsWatch.includes(-10)) return
    if (shouldDisplayWeekdays && selectedWeekdaysWatch.length < 1) return

    getCourses({
      endDate: moment(endDate).format('YYYY-MM-DD'),
      periodIds: selectedPeriodsWatch === undefined ? [] : selectedPeriodsWatch,
      repeatOptions:
        selectedRepeatingOptionWatch === undefined
          ? []
          : selectedRepeatingOptionWatch,
      stafferId: parseInt(staffId),
      startDate: moment(startDate).format('YYYY-MM-DD'),
      weekDaysToInclude:
        selectedWeekdaysWatch === undefined ? [] : selectedWeekdaysWatch,
      shouldDisplayWeekdays
    })
  }, [
    endDate,
    getCourses,
    selectedPeriodsWatch,
    selectedRepeatingOptionWatch,
    selectedWeekdaysWatch,
    shouldDisplayWeekdays,
    staffId,
    startDate
  ])

  const {
    data: saveResponseData,
    isLoading: isSavingAdjustedOffering,
    mutate: save
  } = useSaveAdjustedOfferingMutation({
    adjustedOfferingId,
    staffId
  })

  const onSubmit = (formData) => {
    const setupDatePeriods = () => {
      let array = []

      selectedCourses.forEach((course) => {
        array.push({
          appointmentDate: moment(course.appointmentDate).format('YYYY-MM-DD'),
          periodId: course.periodId
        })
      })

      return array
    }

    const datesAndPeriods = setupDatePeriods()

    const details = {
      adjustedCourseName: formData.adjustedCourseName,
      adjustedRoom: formData.adjustedRoom,
      adjustedSeatCount: parseInt(formData.adjustedSeatCount),
      appointmentTypeId: formData.appointmentTypeId,
      blockedGraduationYears: formData.selectedGraduationYears,
      blockOtherStaffer: formData.preventOtherStaffScheduling,
      blockStudentRequesting: formData.preventStudentRequesting,
      blockStudentSelfScheduling: formData.preventStudentSelfScheduling,
      courseDescription: formData.courseDescription,
      courseId: formData.courseId,
      courseTitle: isEditing
        ? courseTitle
        : datesAndPeriods.length > 1
        ? formData.adjustedCourseName
        : null,
      datesAndPeriodsToAdjust: isEditing
        ? [
            {
              appointmentDate: moment(scheduleDate).format('YYYY-MM-DD'),
              periodId: periodId
            }
          ]
        : datesAndPeriods,
      groupId: formData.groupId,
      meetingLink: formData.meetingLink === '' ? null : formData.meetingLink,
      mustUseDefaultAppointmentType: formData.mustUseDefaultAppointmentType,
      staffId: parseInt(staffId)
    }

    save(details)
  }

  const setupBlockedGraduationYears = () => {
    let array = []

    if (!!blockedGraduationYears) {
      let arrayed = blockedGraduationYears.split(',')

      arrayed.forEach((item) => {
        array.push(parseInt(item))
      })
    }

    return array
  }

  if (!!saveResponseData && saveResponseData.shouldRedirect)
    return <Navigate to={backLink} />

  if (isSavingAdjustedOffering)
    return <Loading text='Saving Adjusted Offering Details' />

  return (
    <>
      <Grid item xs={12} sm={5} lg={4} xl={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexColumn>
            {isEditing ? null : (
              <>
                <ReactHookFormSelect
                  control={control}
                  defaultValue={-1}
                  errors={errors.selectedRepeatingOption}
                  id='selected-repeating-option'
                  label='Repeats'
                  menuItems={repeatOptions}
                  name='selectedRepeatingOption'
                  rules={{ required: 'Please select a Repeating Option' }}
                />
                <DatePicker
                  label={
                    selectedRepeatingOptionWatch === -1
                      ? 'Adjusted Date'
                      : 'Start Date'
                  }
                  minDate={moment()}
                  onChange={(v) => updateDates(v, 'startDate')}
                  value={startDate}
                />
                {selectedRepeatingOptionWatch !== -1 &&
                  selectedRepeatingOptionWatch !== undefined && (
                    <DatePicker
                      label='End Date'
                      minDate={moment()}
                      onChange={(v) => updateDates(v, 'endDate')}
                      value={endDate}
                    />
                  )}
                {shouldDisplayWeekdays && (
                  <RhfMultiSelectFormControl
                    control={control}
                    defaultValue={[]}
                    errors={errors.selectedWeekdays}
                    inputId='select-weekdays'
                    label='Select Weekday'
                    labelId='select-weekdays'
                    menuItems={weekdayMenuItems}
                    name='selectedWeekdays'
                    rules={{
                      validate: (data) =>
                        data.length > 0 || 'Please select at least one weekday'
                    }}
                  />
                )}
                <RhfMultiSelectFormControl
                  control={control}
                  defaultValue={[]}
                  errors={errors.selectedPeriods}
                  inputId='select-multiple-periods'
                  label='Select Period'
                  labelId='select-periods'
                  menuItems={periods}
                  name='selectedPeriods'
                  rules={{
                    validate: (data) =>
                      data.length > 0 || 'Please select at least one period'
                  }}
                  selectedWatch={selectedPeriodsWatch}
                  setValue={setValue}
                  useAllOption
                />
              </>
            )}
            <RhfMultiSelectFormControl
              control={control}
              defaultValue={setupBlockedGraduationYears()}
              errors={errors.selectedGraduationYears}
              inputId='select-graduation-years'
              label='Blocked Graduation Years'
              labelId='blocked-graduation-years'
              menuItems={graduationYears}
              name='selectedGraduationYears'
            />
            <TextField
              defaultValue={adjustedCourseName}
              error={Boolean(errors.adjustedCourseName)}
              helperText={
                errors.adjustedCourseName
                  ? errors.adjustedCourseName.message
                  : null
              }
              id='adjusted-course-name'
              inputRef={register({
                required: 'Please enter an Offering Name'
              })}
              label='Offering Name'
              name='adjustedCourseName'
            />
            <TextField
              defaultValue={
                parsedAdjustedOfferingId === 0
                  ? subjectMatterCourse.maxSeatCount
                  : adjustedSeatCount
              }
              error={Boolean(errors.adjustedSeatCount)}
              helperText={
                errors.adjustedSeatCount
                  ? errors.adjustedSeatCount.message
                  : null
              }
              id='max-number-students'
              inputRef={register({
                required: 'Please enter the Max Number of Students',
                validate: {
                  existingRecordLoweredSeatCount: (value) => {
                    if (
                      parseInt(value) < adjustedSeatCount &&
                      !isNewRecord &&
                      !isAdmin
                    )
                      return `Max Number of Students can't be lowered`
                  },
                  lessThanMaxNumberOfStudents: (value) => {
                    if (
                      !isAdmin &&
                      parseInt(value) < maxNumberOfStudents &&
                      isNewRecord
                    )
                      return `Max Number of Students can't be less than this Staffers Default Seat Count of ${maxNumberOfStudents}`
                  },
                  lessThanHighestNumberOfScheduledStudentsForRange: (value) => {
                    if (
                      parseInt(value) <
                      getLowestNumberOfAppointmentsForSelectedCourses()
                    )
                      return `Max Number of Students can't be less than the highest number of Scheduled Students: ${getLowestNumberOfAppointmentsForSelectedCourses()}`
                  }
                }
              })}
              label='Max Number of Students'
              name='adjustedSeatCount'
            />
            <ReactHookFormSelect
              control={control}
              defaultValue={
                parsedAdjustedOfferingId === 0
                  ? subjectMatterCourse.defaultAppointmentTypeId
                  : appointmentTypeId
              }
              errors={errors.appointmentTypeId}
              id='selected-appointment-type'
              label='Appointment Type'
              menuItems={appointmentTypes}
              name='appointmentTypeId'
              rules={{ required: 'Please select an Appointment Type' }}
            />
            <TextField
              defaultValue={
                parsedAdjustedOfferingId === 0 ? homeRoom : adjustedRoom
              }
              error={Boolean(errors.adjustedRoom)}
              helperText={
                errors.adjustedRoom ? errors.adjustedRoom.message : null
              }
              id='offering-room'
              inputRef={register({
                required: 'Please enter the Offering Room'
              })}
              label='Offering Room'
              name='adjustedRoom'
            />
            <TextField
              defaultValue={meetingLink}
              inputRef={register({})}
              label='Virtual Meeting Link'
              name='meetingLink'
            />
            <ReactHookFormCheckbox
              control={control}
              defaultChecked={preventStudentSelfScheduling}
              label='Block Self Scheduling'
              name='preventStudentSelfScheduling'
            />
            {canCreateAdjustedCourseBlockedToOthers && (
              <ReactHookFormCheckbox
                control={control}
                defaultChecked={preventOtherStaffFromScheduling}
                label='Block Other Staff Scheduling'
                name='preventOtherStaffScheduling'
              />
            )}
            <ReactHookFormCheckbox
              control={control}
              defaultChecked={mustUseDefaultAppointmentType}
              label='Lock Appointment Type'
              name='mustUseDefaultAppointmentType'
            />{' '}
            <ReactHookFormCheckbox
              control={control}
              defaultChecked={preventStudentRequesting}
              label='Prevent Student Requests'
              name='preventStudentRequesting'
            />
            <Controller
              as={TextArea}
              control={control}
              defaultValue={courseDescription}
              placeholder='comment...'
              name='courseDescription'
            />
            <Button label='Save' marginTop type='submit' />
          </FlexColumn>
        </form>
      </Grid>
      {isEditing ? (
        <Grid item xs={12} sm={3}>
          <EditingNote />
        </Grid>
      ) : (
        <CourseList
          courseList={courseList}
          isLoadingCourses={isLoadingCourses}
          updateCourseList={updateCourseList}
        />
      )}
    </>
  )
}

export default Form
