import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import { esuUrls } from '../../constants'
import { useHeaderTitle, useIsMdUpBreakpoint } from '../../hooks'
import { Loading, Tabs, UserManual } from '../shared'

import { useAdvancedAppointments } from './AdvancedAppointmentsContext'
import DepartmentsSection from './DepartmentsSection'
import FormSection from './FormSection'
import InfoSection from './InfoSection'
import ScheduleCollisionsSummary from './ScheduleCollisionsSummary'

const AdvancedAppointmentsMain = () => {
  const {
    coursesForDatePeriodStudentIdError,
    isLoading,
    isSchedulingAdvancedAppointment,
    isSubstitute,
    studentName
  } = useAdvancedAppointments()
  const location = useLocation()
  const navigate = useNavigate()
  const isMdUp = useIsMdUpBreakpoint()

  useHeaderTitle(`Advanced Appointments: ${studentName}`)

  const [selectedTab, setSelectedTab] = useState(0)

  const goBack = () => {
    if (location.state === null) {
      navigate('/appointments')
    } else {
      navigate(-1)
    }
  }

  if (isLoading) return <Loading text='Loading Appointment Details' />
  if (isSchedulingAdvancedAppointment)
    return <Loading text='Scheduling Appointments' />

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'space-between'
        }}>
        <UserManual
          href={
            isSubstitute
              ? esuUrls.ADVANCED_APPOINTMENTS_SUB
              : esuUrls.ADVANCED_APPOINTMENTS
          }
        />
        <Typography
          onClick={goBack}
          sx={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}>
          Back to Appointments
        </Typography>
      </div>
      {coursesForDatePeriodStudentIdError?.response?.status === 404 ? (
        <Typography>
          There are no courses available for this date and period. It's possible
          that there is a Blocked Date for this Staffer, Department or for the
          School.
        </Typography>
      ) : (
        <Grid container>
          {!isMdUp ? (
            <>
              <Tabs
                labels={[
                  'Departments',
                  'Appointment Details',
                  'Grades & Courses'
                ]}
                scrollable
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              {selectedTab === 0 && <DepartmentsSection />}
              {selectedTab === 1 && <FormSection />}
              {selectedTab === 2 && <InfoSection />}
            </>
          ) : (
            <>
              <DepartmentsSection />
              <FormSection />
              <InfoSection />
            </>
          )}
          <ScheduleCollisionsSummary />
        </Grid>
      )}
    </div>
  )
}

export default AdvancedAppointmentsMain
