import { useState } from 'react'
import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints } from '../constants'

function useSisValidation({
  clearErrors,
  setError,
  sisId,
  userId,
  userType,
  watch,
  watchValue
}) {
  const [isUnique, setIsUnique] = useState(null)

  const sisIdValue = watch(watchValue)

  const { isFetching, isLoading, mutate } = useMutation(
    async ({ sisIdValue, userId, userType }) => {
      const response = await axios.post(
        `${endpoints.BASE_USER}/getsisidstatus`,
        {
          sisId: sisIdValue,
          userId: parseInt(userId),
          userType
        },
        { withCredentials: true }
      )

      return response.data
    },
    {
      onSuccess: (data) => {
        switch (data.usageStatus) {
          case -3:
            setError('serverErrorValidateSisId', {
              type: 'string',
              message: 'Please select a User Type to validate this SIS ID'
            })
            setIsUnique(false)
            break
          case -2:
            setError('serverErrorValidateSisId', {
              type: 'string',
              message: 'This SIS ID is being used by a Student in this school'
            })
            setIsUnique(false)
            break
          case -1:
            setError('serverErrorValidateSisId', {
              type: 'string',
              message: 'This SIS ID is being used by a Staffer in this school'
            })
            setIsUnique(false)
            break
          default:
            clearErrors('serverErrorValidateSisId')
            setIsUnique(true)
            break
        }
      }
    }
  )

  const validateSisId = () => {
    if (sisIdValue === undefined || sisId === sisIdValue) {
      clearErrors('serverErrorValidateSisId')
      setIsUnique(null)
      return
    }

    mutate({ sisIdValue, userId, userType })
  }

  return { isUnique, isValidating: isFetching || isLoading, validateSisId }
}

export default useSisValidation
