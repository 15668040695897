import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLink = styled(Link)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none'
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: '#959292',

  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),

    minWidth: 0
  }
}))

const NavLink = ({ label, onClick, Icon, pathName, to }) => {
  return (
    <StyledLink
      onClick={onClick}
      to={{ pathname: to, state: { from: pathName } }}>
      <ListItem button key={label}>
        <StyledListItemIcon>{Icon}</StyledListItemIcon>
        <ListItemText
          sx={{ color: '#959292', fontSize: 17 }}
          primary={label}
          style={{ paddingLeft: 0 }}
        />
      </ListItem>
    </StyledLink>
  )
}

export default NavLink
