import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getSchoolMasterGradesQueryKey,
  sortByCollatingField
} from '../../../helpers'

export const useSchoolMasterGradesQuery = () => {
  return useQuery(
    getSchoolMasterGradesQueryKey(),
    async () => {
      const response = await axios.get(endpoints.SCHOOL_MASTER_GRADES, {
        withCredentials: true
      })

      response.data.sort(sortByCollatingField(true, 'sortOrder'))

      return response.data
    },
    {
      staleTime: 1000 * 60
    }
  )
}
