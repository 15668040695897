import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
//import { useAppointmentsPrebookMutation } from '../appointments'

export const useRostersModifyHomeroomMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  //const { mutate } = useAppointmentsPrebookMutation()

  return useMutation(
    async (studentGroupIds) => {
      await axios.post(
        `${endpoints.ROSTERS}/modifyhomerooms`,
        studentGroupIds,
        {
          withCredentials: true
        }
      )

      return { studentGroupIds }
    },
    {
      onSuccess: ({ studentGroupIds }) => {
        queryClient.setQueryData(
          ['rosters', 'all-by-school'],
          (prevRosters) => {
            return prevRosters.map((prevRoster) => {
              if (!studentGroupIds.includes(prevRoster.rosterId))
                return prevRoster

              return { ...prevRoster, isHomeroom: true }
            })
          }
        )

        //mutate()

        enqueueSnackbar('Homebase Group changes have been saved', {
          variant: 'success'
        })
      }
    }
  )
}
