import React from 'react'
import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginRight: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    width: 250
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function compare(arr1, arr2) {
  let results = []
  arr1.forEach((e1) =>
    arr2.forEach((e2) => {
      if (e1.value === e2) {
        results.push(e1)
      }
    })
  )

  return (
    <div>
      {results.map((r) => (
        <Chip key={r.value} label={r.description} />
      ))}
    </div>
  )
}

const MultiSelect = ({
  dataTestId,
  id,
  label,
  labelId,
  name,
  onClose,
  onChange,
  selectedValues,
  sx,
  values
}) => {
  return (
    <StyledFormControl variant='standard'>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        id={id}
        input={<Input id='select-multiple-chip' />}
        inputProps={{ 'data-testid': `${dataTestId}` }}
        labelId={labelId}
        MenuProps={MenuProps}
        multiple
        name={name}
        onClose={onClose}
        onChange={onChange}
        renderValue={() => compare(values, selectedValues)}
        sx={sx}
        value={selectedValues}>
        {values.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.description}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}

export default MultiSelect
