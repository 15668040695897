import { useQuery } from 'react-query'
import axios from 'axios'

import { BLOCKED_DATE_TYPES, endpoints } from '../../../constants'
import {
  getBlockedDatesBySchoolQueryKey,
  sortByCollatingField
} from '../../../helpers'

import { blockedDatesBy } from '../enums'

export const useBlockedDatesBySchoolQuery = ({ month, selectedTab }) => {
  return useQuery(
    getBlockedDatesBySchoolQueryKey({ month, selectedTab }),
    async () => {
      const response = await axios.get(
        `${endpoints.BLOCKED_DATES}/${BLOCKED_DATE_TYPES.bySchool}/${month}/0`,
        { withCredentials: true }
      )

      response.data.forEach((item) => (item.blockType = 'School'))

      return response.data.sort(sortByCollatingField(true, 'scheduleDate'))
    },
    {
      enabled: parseInt(selectedTab) === blockedDatesBy.school,
      staleTime: 1000 * 30
    }
  )
}
