import React from 'react'
import { MenuItem } from '@mui/material'

import { Menu, SelectField } from '../shared'
import { useSearchParams } from 'react-router-dom'

import { StyledListSubheader } from './styledComponents'

const FiltersMenu = ({ updateFiltersByNameValue }) => {
  let [searchParams] = useSearchParams()

  const activeFilter = searchParams.get('activeFilter')
  const gradeCutOffFilter = searchParams.get('gradeCutOffFilter')
  const overrideFilter = searchParams.get('overrideFilter')
  const selfScheduleFilter = searchParams.get('selfScheduleFilter')

  return (
    <div>
      <Menu buttonAriaLabel='filter students by' label='Filter'>
        <StyledListSubheader>Filter Students By</StyledListSubheader>
        <MenuItem>
          <SelectField
            label='Active'
            labelId='filter-students-by-active'
            menuItems={[
              {
                description: 'All',
                value: 0
              },
              {
                description: 'Active',
                value: 1
              },
              {
                description: 'Inactive',
                value: 2
              }
            ]}
            name='selectedActiveStatus'
            onChange={(e) => updateFiltersByNameValue(e)}
            selectedValue={activeFilter === null ? '0' : activeFilter}
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='Can Schedule Self'
            labelId='filter-students-by-can-schedule-self'
            menuItems={[
              {
                description: 'All',
                value: 0
              },
              {
                description: 'Can Schedule Self',
                value: 1
              },
              {
                description: 'Cannot Schedule Self',
                value: 2
              }
            ]}
            name='selectedCanScheduleStatus'
            onChange={(e) => updateFiltersByNameValue(e)}
            selectedValue={selfScheduleFilter === null ? 0 : selfScheduleFilter}
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='Can Override Appointments'
            labelId='filter-students-by-can-override-appointments'
            menuItems={[
              {
                description: 'All',
                value: 0
              },
              {
                description: 'Can Override Appointments',
                value: 1
              },
              {
                description: 'Cannot Override Appointments',
                value: 2
              }
            ]}
            name='selectedCanOverrideStatus'
            onChange={(e) => updateFiltersByNameValue(e)}
            selectedValue={overrideFilter === null ? 0 : overrideFilter}
          />
        </MenuItem>

        <MenuItem>
          <SelectField
            label='Grade Cut Off'
            labelId='filter-students-by-grade-cut-off-value'
            menuItems={[
              {
                description: 'All',
                value: 0
              },
              {
                description: 'At Risk',
                value: 2
              },
              {
                description: 'Not At Risk',
                value: 1
              }
            ]}
            name='selectedGradeCutOffStatus'
            onChange={(e) => updateFiltersByNameValue(e)}
            selectedValue={gradeCutOffFilter === null ? 0 : gradeCutOffFilter}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default FiltersMenu
