import { useQuery } from 'react-query'
import axios from 'axios'
import { useState } from 'react'
import moment from 'moment'

import { endpoints } from '../../../constants'
import {
  getAppointmentsQueryKey,
  getStudentDisplayName,
  sortByCollatingField
} from '../../../helpers'

export const useAppointmentsGetQuery = ({
  numberOfDaysToRetrieve,
  periodId = -1,
  rosterId = -1,
  searchTerm = '',
  startDate = moment().format('YYYY-MM-DD'),
  studentIds = []
}) => {
  const [selectedStudentIds, setSelectedStudentIds] = useState([])

  const updateSelectedStudentIds = (e) => {
    const { checked, id } = e.target
    const parsedId = parseInt(id)

    if (checked) {
      setSelectedStudentIds((prevIds) => [...prevIds, parsedId])
    } else {
      setSelectedStudentIds((prevIds) =>
        prevIds.filter((prevId) => prevId !== parsedId)
      )
    }
  }

  const { data, isFetching, isLoading } = useQuery(
    getAppointmentsQueryKey({
      numberOfDaysToRetrieve,
      periodId,
      rosterId,
      searchTerm,
      startDate
    }),
    async () => {
      if (parseInt(rosterId) === -1000) {
        return []
      }

      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/get`,
        {
          numberOfDaysToRetrieve: parseInt(numberOfDaysToRetrieve),
          periodId: periodId === null ? -1 : parseInt(periodId),
          rosterId:
            searchTerm === null && rosterId === null
              ? -1
              : !searchTerm
              ? parseInt(rosterId)
              : 0,
          searchTerm: searchTerm === null ? '' : searchTerm,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          studentIds
        },
        { withCredentials: true }
      )

      let array = []

      response?.data?.studentsAndAppointments?.forEach((student) => {
        array.push(student.userId)

        student.displayName = getStudentDisplayName(
          student.firstName,
          student.lastName,
          student.nickname
        )
      })

      response.data.studentsAndAppointments.sort(
        sortByCollatingField(true, 'displayName')
      )

      setSelectedStudentIds(array)

      return response.data
    },
    {
      enabled: !!periodId || (!!rosterId && !!searchTerm),
      staleTime: 1000 * 60
    }
  )

  return {
    data,
    isFetching,
    isLoading,
    selectedStudentIds,
    updateSelectedStudentIds
  }
}
