import { styled } from '@mui/material/styles'

export const SearchOptions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'baseline'
  }
}))
