import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const usePeriodsEitherUsedBySchoolOrCurrentlyActiveQuery = () => {
  return useQuery(
    ['periods', 'either-used-by-school-or-currently-active'],
    async () => {
      const response = await axios.get(
        `${endpoints.PERIODS}/eitherUsedBySchoolOrCurrentlyActive`,
        { withCredentials: true }
      )

      response.data.forEach((r) => {
        r.description = r.periodDescription
        r.value = r.periodId
      })

      return response.data.sort(sortByCollatingField(true, 'description'))
    }
  )
}
