import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getPeriodsMinimalActiveOnlyQueryKey } from '../../../helpers'

export const usePeriodsMinimalActiveOnlyQuery = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getPeriodsMinimalActiveOnlyQueryKey(),
    async () => {
      const response = await axios.get(
        `${endpoints.PERIODS}/periodsminimalactiveonly`,
        {
          withCredentials: true
        }
      )

      if (response.data.length < 1) {
        enqueueSnackbar(
          'There are no periods setup for your school. You must contact an Admin to fix this for Enriching Students to work properly.',
          {
            variant: 'error'
          }
        )
      }

      response.data.forEach((r) => {
        r.description = r.periodDescription
        r.value = r.periodId
      })

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
