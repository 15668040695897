import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { endpoints } from '../../../constants'
import { useSnackbar } from 'notistack'
import { getEmailSubscriptionQueryKey } from '../../../helpers'

export const useEmailSubscriptionSaveMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (emailPermissions) => {
      await axios.post(
        `${endpoints.EMAIL_SUBSCRIPTION}/save`,
        emailPermissions,
        { withCredentials: true }
      )

      return emailPermissions
    },
    {
      onSuccess: (emailPermissions) => {
        enqueueSnackbar('Changes have been saved', { variant: 'success' })

        queryClient.setQueryData(getEmailSubscriptionQueryKey(), () => {
          return emailPermissions
        })
      }
    }
  )
}
