import axios from 'axios'
import React, { createContext, useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { endpoints } from '../constants'
import { getNotificationsQueryKey } from '../helpers'

import { useNotificationsQuery } from '../hooks'

const NotificationsContext = createContext()

const useNotificationsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ messageId, messageType }) => {
      await axios.post(
        `${endpoints.NOTIFICATIONS}/markasread`,
        {
          messageId,
          messageType
        },
        { withCredentials: true }
      )

      return messageId
    },
    {
      onSuccess: (messageId) => {
        queryClient.setQueryData(
          getNotificationsQueryKey(),
          (prevNotifications) => {
            return prevNotifications.map((notification) => {
              if (notification.id !== messageId) return notification
              return { ...notification, hasBeenRead: true }
            })
          }
        )
      }
    }
  )
}

const NotificationsProvider = ({ children }) => {
  const { data: unexpiredNotifications = [], refetch } = useNotificationsQuery()

  const hasUnreadNotifications = unexpiredNotifications.some(
    (notification) => !notification.hasBeenRead
  )

  const unreadNotifications = unexpiredNotifications.filter(
    (n) => !n.hasBeenRead
  )

  const { mutate: markAsRead } = useNotificationsMutation()

  return (
    <NotificationsContext.Provider
      value={{
        hasUnreadNotifications,
        markAsRead,
        refetchNotifications: refetch,
        unexpiredNotifications,
        unreadNotificationsLength: unreadNotifications.length
      }}>
      {children}
    </NotificationsContext.Provider>
  )
}

const useNotifications = () => {
  const context = useContext(NotificationsContext)

  if (!context) {
    throw new Error(
      'useNotifications muse be used with a NotificationsProvider'
    )
  }

  return context
}

export { NotificationsContext, NotificationsProvider, useNotifications }
