import { styled } from '@mui/material/styles'
import { SkipPrevious } from '@mui/icons-material'

const SkipIcon = styled(SkipPrevious)(() => ({
  '&:hover': {
    cursor: 'pointer'
  }
}))

export default SkipIcon
