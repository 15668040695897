import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import {
  getSubstitutesQueryKey,
  getSubstiuteQueryKey
} from '../../../helpers/queryKeys'

export const useSubstitutesSaveMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ userId, userToSchoolId, values }) => {
      const response = await axios.post(
        `${endpoints.SUBSTITUTES}/save`,
        {
          ...values,
          impersonatedUserId: values.substitutingFor,
          userId,
          userToSchoolId
        },
        { withCredentials: true }
      )

      return {
        id: response.data.userId,
        shouldRedirect: parseInt(userId) === 0,
        userId,
        values
      }
    },
    {
      onSuccess: ({ userId, values }) => {
        // Invalidate cached list of substitutes
        queryClient.invalidateQueries(getSubstitutesQueryKey())

        if (userId !== 0) {
          enqueueSnackbar(`General Info changes have been saved`, {
            variant: 'success'
          })

          queryClient.setQueryData(
            getSubstiuteQueryKey(userId),
            (prevDetails) => {
              return {
                ...prevDetails,
                ...values,
                impersonatedUserId: values.substitutingFor
              }
            }
          )
        } else {
          enqueueSnackbar(
            `New Substitute: ${values.lastName}, ${values.firstName} has been created`,
            {
              variant: 'success'
            }
          )
        }
      }
    }
  )
}
