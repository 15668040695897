import React, { useState } from 'react'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography
} from '@mui/material'
import moment from 'moment'

import { esuUrls } from '../../constants'
import { useHeaderTitle, useStartAndEndDate } from '../../hooks'
import csvExample from '../../images/gradeFile.png'
import {
  Button,
  List,
  Loading,
  PageInstructions,
  Paper,
  UserManual
} from '../shared'

import {
  CourseItem,
  CoursesSubheading,
  MassAppointmentsProvider,
  SchedulingErrorItem,
  SelectSchedulingOptionsStep,
  SetOverwritePermissionsStep,
  SetupSchedulingDateStep,
  SetupSchedulingOptionsStep,
  StepNavButtons,
  StudentItem,
  StudentSubheading,
  SummaryMessages,
  useActiveStep,
  useAppointmentsMassSchedulingRunPreprocessMutation,
  useAppointmentsMassSchedulingSaveAppointments,
  useCoursesForDatesPeriodStaffQuery,
  useMassAppointments
} from '../MassAppointments'

const acceptableNumberOfSchedulingErrors = 12

function getSteps() {
  return [
    'Select Scheduling Option',
    'Setup Scheduling Date',
    'Setup Scheduling Options',
    'Set Overwrite Permissions',
    'Review & Schedule'
  ]
}

const INITIAL_SCHEDULING_OPTIONS_STATE = {
  selectedPeriodId: '',

  comment: '',
  scheduleToStudentsHomeroom: 'true',
  selectedAppointmentType: '',
  selectedDepartmentId: '',
  selectedStafferId: '',

  allowHomeroomToBeOverbooked: false,
  allowStudentsToModify: false,
  autoIncreaseSeatCountForSubjectMatterCourse: false,
  coursesForDateRange: [],
  lockAppointments: false,
  overwriteHomeroomAppointments: false,
  overwriteLockedAppointments: false,
  overwriteSubjectMatterAppointments: false
}

const MassAppointments = () => {
  useHeaderTitle('Mass Appointments')

  const {
    appointmentTypes,
    dateParam,
    departments,
    groupIdParam,
    isAdmin,
    isLoading,
    isXs,
    loggedInUserId,
    periods,
    staffIdParam,
    staffers,
    studentsByGroupId
  } = useMassAppointments()

  const { activeStep, handleBack, handleNext, setActiveStep } = useActiveStep()

  const [excludedCourseDates, setExcludedCourseDates] = useState([])
  const [excludedStudentsIds, setExcludedStudentIds] = useState([])
  const [selectedSchedulingOption, setSelectedSchedulingOption] = useState(
    groupIdParam !== '0' ? 'group' : isAdmin ? 'graduation-year' : 'file'
  )

  const [selectedFile, setSelectedFile] = useState({ name: '' })
  const [selectedGraduationYears, setSelectedGraduationYears] = useState([])
  const [selectedSchedulingStaffer, setSelectedSchedulingStaffer] = useState(
    staffIdParam === '0' ? loggedInUserId : staffIdParam
  )

  const steps = getSteps()

  const [
    startDate,
    endDate,
    updateDates,
    DATE_CONSTANTS,
    reducedWeekdays,
    setIsoWeekdays
  ] = useStartAndEndDate(moment(dateParam).format(), moment(dateParam).format())

  const [schedulingOptions, setSchedulingOptions] = useState(
    INITIAL_SCHEDULING_OPTIONS_STATE
  )

  const selectedDepartment = departments.find(
    (department) => department.value === schedulingOptions.selectedDepartmentId
  )

  const staffersByDepartment = staffers.filter(
    (staffer) => staffer.departmentId === schedulingOptions.selectedDepartmentId
  )

  const { data: coursesByDatePeriodStaffer = [] } =
    useCoursesForDatesPeriodStaffQuery({
      endDate,
      periodIds: [schedulingOptions.selectedPeriodId],
      stafferId: schedulingOptions.selectedStafferId,
      startDate,
      weekdaysToInclude: reducedWeekdays
    })

  const {
    data: preProcessResults = {
      errorMessages: [],
      numberOfApptToCreate: '',
      summaryMessages: []
    },
    isLoading: isRunningPreprocess,
    mutate: runPreprocess
  } = useAppointmentsMassSchedulingRunPreprocessMutation({
    handleNext
  })

  const {
    errors,
    isLoading: isScheduling,
    mutate: scheduleStudents,
    setErrors
  } = useAppointmentsMassSchedulingSaveAppointments({
    acceptableNumberOfSchedulingErrors,
    resetDataAfterSave: () => {
      setActiveStep(0)
      setExcludedCourseDates([])
      setExcludedStudentIds([])
      setSelectedGraduationYears([])
      setSelectedSchedulingOption('graduation-year')
      setSchedulingOptions(INITIAL_SCHEDULING_OPTIONS_STATE)

      updateDates(moment(dateParam).format(), 'startDate')
      updateDates(moment(dateParam).format(), 'endDate')
    }
  })

  const startPreprocess = () => {
    let datesToSchedule = []

    const filtered = coursesByDatePeriodStaffer.filter(
      (course) =>
        !excludedCourseDates.some(
          (ecd) =>
            moment(ecd).format('YYYY-MM-DD') ===
            moment(course.appointmentDate).format('YYYY-MM-DD')
        )
    )

    filtered.forEach((course) => {
      datesToSchedule.push(moment(course.appointmentDate).format('YYYY-MM-DD'))
    })

    runPreprocess({
      allowHomeroomToBeOverbooked:
        schedulingOptions.allowHomeroomToBeOverbooked,
      allowStudentsToModify: schedulingOptions.allowStudentsToModify,
      appointmentTypeId: schedulingOptions.selectedAppointmentType,
      autoIncreaseSeatCountForSubjectMatterCourse:
        schedulingOptions.autoIncreaseSeatCountForSubjectMatterCourse,
      schedulerComment: schedulingOptions.comment,
      datesToSchedule,
      endDate: moment(endDate).format('YYYY-MM-DD'),
      lockAppointments: schedulingOptions.lockAppointments,
      overwriteHomeroomAppointments:
        schedulingOptions.overwriteHomeroomAppointments,
      overwriteLockedAppointments:
        schedulingOptions.overwriteLockedAppointments,
      overwriteSubjectMatterAppointments:
        schedulingOptions.overwriteSubjectMatterAppointments,
      periodId: schedulingOptions.selectedPeriodId,
      scheduleToStudentsHomeroom:
        schedulingOptions.scheduleToStudentsHomeroom === 'true' ? true : false,
      selectedStaffer:
        schedulingOptions.scheduleToStudentsHomeroom === 'true'
          ? 0
          : schedulingOptions.selectedStafferId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      weekDaysToInclude: reducedWeekdays
    })
  }

  const updateExcludedCourseDates = ({ appointmentDate, checked }) => {
    const formattedDate = moment(appointmentDate).format('MM/DD/YYYY')

    if (checked) {
      setExcludedCourseDates([...excludedCourseDates, formattedDate])
    } else {
      setExcludedCourseDates([
        ...excludedCourseDates.filter((date) => date !== formattedDate)
      ])
    }
  }

  const updateExcludedStudentIds = (checked, id) => {
    const parsedId = parseInt(id)

    if (checked) {
      setExcludedStudentIds([...excludedStudentsIds, parsedId])
    } else {
      setExcludedStudentIds([
        ...excludedStudentsIds.filter((id) => id !== parsedId)
      ])
    }
  }

  const updateSchedulingOptionsByValue = (e) => {
    setSchedulingOptions({
      ...schedulingOptions,
      [e.target.name]: e.target.value
    })
  }

  const updateSchedulingOptionsByChecked = (e) => {
    setSchedulingOptions({
      ...schedulingOptions,
      [e.target.name]: e.target.checked
    })
  }

  if (isLoading) return <Loading text='Loading Appointment Options' />
  if (isScheduling) return <Loading text={'Scheduling Appointments'} />
  if (isRunningPreprocess) return <Loading text={'Running Import Preprocess'} />

  return (
    <div>
      <UserManual href={esuUrls.MASS_SCHEDULE} />
      <PageInstructions>
        Note: Because multiple dates and periods can be selected, adjusted
        courses are NOT displayed. Simply select the non-adjusted course name,
        set the date range and selected the appropriate periods. When you save
        the appointments the process will automatically schedule students to an
        adjusted course if one exists.
      </PageInstructions>
      {errors.length > acceptableNumberOfSchedulingErrors ? (
        <>
          <Button label='Dismiss Errors' onClick={() => setErrors([])} />
          {errors.map((error, index) => {
            return <SchedulingErrorItem error={error} key={index} />
          })}
          <Button
            label='Dismiss Errors'
            onClick={() => setErrors([])}
            sx={{ marginTop: 1 }}
          />
        </>
      ) : (
        <Paper style={{ display: 'flex' }}>
          <div>
            <Stepper activeStep={activeStep} orientation='vertical'>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {index === 0 && (
                      <div style={{ display: 'flex' }}>
                        <SelectSchedulingOptionsStep
                          excludedStudentsIds={excludedStudentsIds}
                          handleNext={handleNext}
                          selectedFile={selectedFile}
                          selectedGraduationYears={selectedGraduationYears}
                          selectedSchedulingOption={selectedSchedulingOption}
                          selectedSchedulingStaffer={selectedSchedulingStaffer}
                          setSelectedFile={setSelectedFile}
                          setSelectedGraduationYears={
                            setSelectedGraduationYears
                          }
                          setSelectedSchedulingStaffer={
                            setSelectedSchedulingStaffer
                          }
                          setSelectedSchedulingOption={
                            setSelectedSchedulingOption
                          }
                        />
                        {selectedSchedulingOption === 'file' && !isXs && (
                          <Box>
                            <Typography paragraph>
                              An example showing how the CSV should be setup:
                            </Typography>
                            <img
                              alt='An example showing how the CSV should be setup'
                              src={csvExample}
                            />
                          </Box>
                        )}
                        {selectedSchedulingOption === 'group' &&
                          activeStep === 0 &&
                          studentsByGroupId.length > 0 &&
                          !isXs && (
                            <div
                              data-testid='student-group-container'
                              style={{
                                maxHeight: 530,
                                overflowY: 'auto'
                              }}>
                              <>
                                <StudentSubheading />
                                <List
                                  Component={StudentItem}
                                  data={studentsByGroupId}
                                  excludedStudentsIds={excludedStudentsIds}
                                  keyValue='userId'
                                  updateExcludedStudentIds={
                                    updateExcludedStudentIds
                                  }
                                />
                              </>
                            </div>
                          )}
                      </div>
                    )}
                    {index === 1 && (
                      <SetupSchedulingDateStep
                        endDate={endDate}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        periods={periods}
                        reducedWeekdays={reducedWeekdays}
                        schedulingOptions={schedulingOptions}
                        setIsoWeekdays={setIsoWeekdays}
                        setSchedulingOptions={setSchedulingOptions}
                        startDate={startDate}
                        updateDates={updateDates}
                      />
                    )}
                    {index === 2 && (
                      <div style={{ display: 'flex' }}>
                        <SetupSchedulingOptionsStep
                          appointmentTypes={appointmentTypes}
                          coursesByDatePeriodStaffer={
                            coursesByDatePeriodStaffer
                          }
                          departments={departments}
                          excludedCourseDates={excludedCourseDates}
                          handleBack={handleBack}
                          handleNext={handleNext}
                          schedulingOptions={schedulingOptions}
                          selectedDepartment={selectedDepartment}
                          staffersByDepartment={staffersByDepartment}
                          updateSchedulingOptionsByValue={
                            updateSchedulingOptionsByValue
                          }
                        />
                        {schedulingOptions.scheduleToStudentsHomeroom ===
                          'false' && (
                          <div
                            style={{
                              maxHeight: 530,
                              overflowY: 'auto'
                            }}>
                            <>
                              <CoursesSubheading />
                              <List
                                Component={CourseItem}
                                emptyArrayMessage='There are no Courses for the selected date(s) period and staffer'
                                excludedCourseDates={excludedCourseDates}
                                data={coursesByDatePeriodStaffer}
                                updateExcludedCourseDates={
                                  updateExcludedCourseDates
                                }
                              />
                            </>
                          </div>
                        )}
                      </div>
                    )}
                    {index === 3 && (
                      <SetOverwritePermissionsStep
                        handleBack={handleBack}
                        schedulingOptions={schedulingOptions}
                        startPreprocess={startPreprocess}
                        updateSchedulingOptionsByChecked={
                          updateSchedulingOptionsByChecked
                        }
                      />
                    )}
                    {/* // TODO: Clean this up */}
                    {index === 4 && (
                      <div>
                        {preProcessResults.errorMessages.length > 0 && (
                          <>
                            <Typography>
                              We encountered errors that will prevent the
                              Appointments from being scheduled. You must fix
                              these errors to proceed.
                            </Typography>
                            {preProcessResults.errorMessages.map(
                              (error, index) => (
                                <Typography
                                  key={index}
                                  style={{ marginTop: 8, marginBottom: 8 }}>
                                  {error}
                                </Typography>
                              )
                            )}
                          </>
                        )}
                        {preProcessResults.errorMessages.length < 1 && (
                          <>
                            <Typography>
                              Please Note: By clicking the schedule button, you
                              will attempt to create{' '}
                              {preProcessResults.numberOfApptToCreate}{' '}
                              appointments.
                            </Typography>
                            <List
                              data={preProcessResults.summaryMessages}
                              Component={SummaryMessages}
                            />
                          </>
                        )}
                        <StepNavButtons
                          disableNext={
                            preProcessResults.errorMessages.length > 0
                          }
                          nextText='Schedule'
                          onClickPrev={handleBack}
                          onClickNext={scheduleStudents}
                          stepNumber='5'
                        />
                      </div>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </Paper>
      )}
    </div>
  )
}

const WithProvider = () => (
  <MassAppointmentsProvider>
    <MassAppointments />
  </MassAppointmentsProvider>
)

export default WithProvider
