import { styled } from '@mui/material/styles'

const RequestItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isLastItem'
})(({ isLastItem }) => ({
  borderBottom: !isLastItem && '1px solid #d9d9d9',
  paddingTop: 8,
  paddingBottom: 8
}))

export default RequestItemContainer
