import React from 'react'
import { Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Container = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}))

const FileUploadButton = ({ id, onChange, selectedFileName }) => {
  return (
    <Container>
      <input
        accept='.csv'
        style={{ display: 'none' }}
        id={id}
        onChange={onChange}
        type='file'
      />
      <label htmlFor={id}>
        <Button
          variant='contained'
          component='span'
          style={{ marginBottom: 8 }}>
          Choose File
        </Button>
      </label>
      <Typography sx={{ marginLeft: 1 }}>{selectedFileName}</Typography>
    </Container>
  )
}

export default FileUploadButton
