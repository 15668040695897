import React from 'react'
import { Cancel, CheckCircle, Info } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledChipInfoIcon = styled(Info, {
  shouldForwardProp: (prop) => prop !== 'chipStyle'
})(({ chipStyle, theme }) => ({
  height: '20px !important',
  width: '20px !important',

  marginLeft: theme.spacing(0.5),
  // To match default spacing
  marginRight: -6,

  color:
    chipStyle === 'success'
      ? theme.palette.success.main
      : chipStyle === 'error'
      ? theme.palette.error.main
      : theme.palette.primary.main
}))

export const ChipBooleanIcon = ({ iconBoolean }) => {
  if (iconBoolean)
    return <StyledChipInfoIcon as={CheckCircle} chipStyle='success' />

  return <StyledChipInfoIcon as={Cancel} chipStyle='error' />
}

export const ChipInfoIcon = () => {
  return <StyledChipInfoIcon as={Info} chipStyle='info' />
}
