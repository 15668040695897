import React, { useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { esuUrls } from '../../constants'
import { AdjustedOfferingsProvider, useAdjustedOfferings } from '../../context'
import { useHeaderTitle, useSortedArray } from '../../hooks'
import {
  List,
  Loading,
  MobileSorting,
  PaginationOptions,
  Paper,
  PaperTitle,
  SelectField,
  Tabs,
  UserManualWithInternalLink,
  UserManualWithLinkButton
} from '../shared'

import { BulkEdit, OfferingItem, Subheading } from '../AdjustedOfferings'

const AdjustedTest = () => {
  const { seriesId, staffId } = useParams()
  const viewingSeries = seriesId === undefined ? false : true

  useHeaderTitle(
    viewingSeries ? 'Adjusted Series Details' : 'Adjusted Offerings'
  )

  const {
    adjustedOfferings,
    deleteAdjustedOffering,
    deleteAdjustedOfferingSeries,
    isAdmin,
    isDeleting,
    isLoading,
    isSaving,
    loggedInUserId,
    selectAll,
    selectedOfferingIds,
    selectedStaffer,
    setSelectedStaffer,
    staffers,
    updateAllOfferingsIsSelected,
    updateSingleOfferingIsSelected
  } = useAdjustedOfferings()

  const [bulkEditing, setBulkEditing] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [selectedTab, setSelectedTab] = useState(0)

  const [
    { sortedArray: sortedAdjustedOfferings, lastSortedBy, sortAscending },
    setSortedAdjustedOfferings
  ] = useSortedArray(adjustedOfferings, 'scheduleDate', false)

  const updateBulkEditing = () => {
    setBulkEditing(!bulkEditing)
  }

  const historicalOfferings = sortedAdjustedOfferings.filter((offering) =>
    moment(offering.scheduleDate).isBefore(moment().subtract(1, 'days'))
  )
  const upcomingOfferings = sortedAdjustedOfferings.filter(
    (offering) =>
      !moment(offering.scheduleDate).isBefore(moment().subtract(1, 'days'))
  )

  if (isDeleting) return <Loading text='Deleting Adjusted Offering' />
  if (isLoading) return <Loading text='Loading Adjusted Offerings' />
  if (isSaving) return <Loading text='Saving Adjusted Offerings' />

  return (
    <div>
      {viewingSeries ? (
        <UserManualWithInternalLink
          href={esuUrls.ADJUSTED_SERIES}
          label='Back to Adjusted Offerings'
          to={`/adjustedOfferings/${staffId}`}
        />
      ) : (
        <UserManualWithLinkButton
          href={esuUrls.ADJUSTED_OFFERINGS}
          label='Create Adjusted Offering'
          to={
            isAdmin
              ? `/createAdjustedOffering/${selectedStaffer}/0/false`
              : `/createAdjustedOffering/${loggedInUserId}/0/false`
          }
        />
      )}
      {isAdmin && !viewingSeries && (
        <SelectField
          label='Select Staffer'
          menuItems={staffers}
          onChange={(e) => setSelectedStaffer(e.target.value)}
          selectedValue={selectedStaffer}
        />
      )}
      <BulkEdit
        bulkEditing={bulkEditing}
        updateBulkEditing={updateBulkEditing}
      />
      <Tabs
        labels={['Upcoming Offerings', 'Historical Offerings']}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <Paper>
        <PaperTitle>
          {viewingSeries ? 'Adjusted Series Details' : 'Adjusted Offerings'}
        </PaperTitle>
        <MobileSorting
          initialValue={lastSortedBy}
          menuItems={[
            {
              description: 'Date',
              value: 'scheduleDate'
            },
            {
              description: 'Period',
              value: 'periodId'
            }
          ]}
          onClick={setSortedAdjustedOfferings}
        />
        <Subheading
          bulkEditing={bulkEditing}
          handleSelectAllChange={updateAllOfferingsIsSelected}
          lastSortedBy={lastSortedBy}
          onClick={setSortedAdjustedOfferings}
          selectAll={selectAll}
          sortAscending={sortAscending}
        />
        <List
          bulkEditing={bulkEditing}
          Component={OfferingItem}
          data={
            selectedTab === 0
              ? upcomingOfferings.slice(
                  currentPage * pageSize,
                  (currentPage + 1) * pageSize
                )
              : historicalOfferings.slice(
                  currentPage * pageSize,
                  (currentPage + 1) * pageSize
                )
          }
          deleteAdjustedOffering={deleteAdjustedOffering}
          deleteAdjustedOfferingSeries={deleteAdjustedOfferingSeries}
          emptyArrayMessage='There are no Adjusted Offerings for the selected staffer.'
          keyValue='courseAdjustedId'
          selectedOfferingIds={selectedOfferingIds}
          updateAdjustedOfferingIsSelected={updateSingleOfferingIsSelected}
          viewingSeries={viewingSeries}
        />
        <PaginationOptions
          defaultCurrent={currentPage}
          handlePaginationChange={setCurrentPage}
          handlePageSizeChange={setPageSize}
          pageSize={pageSize}
          total={
            selectedTab === 0
              ? upcomingOfferings.length
              : historicalOfferings.length
          }
        />
      </Paper>
    </div>
  )
}

const WithProvider = () => {
  return (
    <AdjustedOfferingsProvider>
      <AdjustedTest />
    </AdjustedOfferingsProvider>
  )
}

export default WithProvider
