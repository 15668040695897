import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { esuUrls } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  useConfigurationGraduationYearsQuery,
  useHeaderTitle,
  useIsXsBreakpoint,
  useRostersHomeroomQuery,
  useStaffQuery,
  useRosterByIdQuery,
  useStudentsMinimalQuery
} from '../../hooks'
import { Loading, Tabs, UserManualWithInternalLink } from '../shared'

import {
  FiltersMenu,
  RosteredStudents,
  RosterForm,
  UnrosteredStudents,
  useSaveStudentGroupMutation
} from '../CreateRoster'

const CreateRoster = () => {
  const isXs = useIsXsBreakpoint()

  const location = useLocation()
  const { isAdmin } = useLoggedInUser()
  const { rosterId, stafferId } = useParams()

  useHeaderTitle(rosterId === '0' ? 'Create Group' : 'Edit Group')

  const [filteredStudents, setFilteredStudents] = useState([])
  const [filters, setFilters] = useState({
    filterTerm: '',
    selectedGraduationYear: '',
    selectedHomeroomRoster: '',
    selectedLastNameLetter: 'All'
  })
  const [rosteredIds, setRosteredIds] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)

  const { data: graduationYears = [], isLoading: isLoadingGraduationYears } =
    useConfigurationGraduationYearsQuery({ useAll: true })

  const { data: homeroomRosters = [], isLoading: isLoadingHomeroomGroups } =
    useRostersHomeroomQuery({ useAllOption: true })

  const {
    data: rosterDetails = {
      rosterId: 0,
      rosterIsDefault: false,
      rosterName: '',
      studentIdsAssociatedWithRoster: []
    },
    isFetching: isFetchingStudentGroup,
    isLoading: isLoadingStudentGroup
  } = useRosterByIdQuery(rosterId)

  const { data: staffers = [], isLoading: isLoadingStaffers } = useStaffQuery({
    isEnabled: isAdmin
  })

  const { data: students = [], isLoading: isLoadingStudents } =
    useStudentsMinimalQuery()

  const rosteredStudents = students.filter((student) =>
    rosteredIds.includes(student.userId)
  )

  const unrosteredStudents = filteredStudents.filter(
    (student) => !rosteredIds.includes(student.userId)
  )

  const {
    data = { shouldRedirect: false },
    isLoading: isSavingStudentGroup,
    mutate: saveStudentGroup
  } = useSaveStudentGroupMutation({
    resetStudents: () => setRosteredIds([])
  })

  useEffect(() => {
    if (rosterDetails.studentIdsAssociatedWithRoster.length > 0) {
      setRosteredIds(rosterDetails.studentIdsAssociatedWithRoster)
    }
  }, [rosterDetails.studentIdsAssociatedWithRoster])

  useEffect(() => {
    if (students.length > 0) {
      setFilteredStudents(students)
    }
  }, [students])

  const handleFilterChange = (e) => {
    const { name, value } = e.target

    setFilters({
      ...filters,
      selectedGraduationYear: '',
      selectedHomeroomRoster: '',
      selectedLastNameLetter: '',
      [name]: value
    })

    if (name === 'selectedHomeroomRoster') {
      if (value === 0) {
        setFilteredStudents(students)
      } else {
        setFilteredStudents(
          students.filter((student) => student.homeroomRosterId === value)
        )
      }
    }

    if (name === 'selectedGraduationYear') {
      if (value === 0) {
        setFilteredStudents(students)
      } else {
        setFilteredStudents(
          students.filter((student) => student.yearOfGraduation === value)
        )
      }
    }

    if (name === 'selectedLastNameLetter') {
      if (value === 'All') {
        setFilteredStudents(students)
      } else {
        setFilteredStudents(
          students.filter((student) => student.lastName.startsWith(value))
        )
      }
    }
  }

  const moveStudentToRoster = (userId) => {
    setRosteredIds([...rosteredIds, userId])
  }

  const moveStudentToUnrostered = (userId) => {
    setRosteredIds([
      ...rosteredIds.filter((rosteredId) => rosteredId !== userId)
    ])
  }

  const save = (formData) => {
    saveStudentGroup({
      shouldRedirect: formData.saveAndClose,
      values: {
        isDefaultRoster: formData.isDefault,
        rosterId: parseInt(rosterId),
        rosterName: formData.rosterName,
        stafferId: isAdmin
          ? parseInt(formData.selectedStaffer)
          : parseInt(stafferId),
        studentIds: rosteredIds
      }
    })
  }

  const setupBackLink = () => {
    if (location.state === null) return '/manageRosters'

    return `/manageRosters?selectedStaffId=${location.state.selectedStaffId}`
  }

  const updateFilterTerm = (e) => {
    const { value } = e.target
    setFilters({ ...filters, filterTerm: value })

    if (value) {
      setFilteredStudents(
        students.filter((student) => {
          return (
            student.firstName.toLowerCase().includes(value.toLowerCase()) ||
            student.lastName.toLowerCase().includes(value.toLowerCase())
          )
        })
      )
    } else {
      setFilteredStudents(students)
    }
  }

  if (data.shouldRedirect) return <Navigate to={setupBackLink()} />

  if (
    isFetchingStudentGroup ||
    isLoadingGraduationYears ||
    isLoadingHomeroomGroups ||
    isLoadingStaffers ||
    isLoadingStudentGroup ||
    isLoadingStudents
  )
    return <Loading text='Loading Group Details' />
  if (isSavingStudentGroup) return <Loading text='Saving Group' />

  return (
    <div>
      <UserManualWithInternalLink
        href={esuUrls.CREATE_ROSTER}
        label='Back to Manage Student Groups'
        to={setupBackLink()}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FiltersMenu
          graduationYears={graduationYears}
          handleFilterChange={handleFilterChange}
          homeroomRosters={homeroomRosters}
          {...filters}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <RosterForm
            isAdmin={isAdmin}
            rosterDetails={rosterDetails}
            save={save}
            staffers={staffers}
            studentsInRoster={rosteredIds.length > 0 ? true : false}
          />
        </Grid>
        {isXs ? (
          <>
            <Tabs
              sx={{ display: 'block' }}
              labels={['Add Students To Group', 'Students In Group']}
              scrollable
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {selectedTab === 0 && (
              <Grid sx={{ display: 'block' }} item xs={12} sm={6} md={4}>
                <UnrosteredStudents
                  filterTerm={filters.filterTerm}
                  moveStudentToRoster={moveStudentToRoster}
                  students={unrosteredStudents}
                  updateFilterTerm={updateFilterTerm}
                />
              </Grid>
            )}
            {selectedTab === 1 && (
              <Grid
                sx={{ display: { xs: 'block', sm: 'none' } }}
                item
                xs={12}
                sm={6}
                md={4}>
                <RosteredStudents
                  moveStudentToUnrostered={moveStudentToUnrostered}
                  students={rosteredStudents}
                />
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid sx={{ display: 'block' }} item xs={12} sm={6} md={4}>
              <UnrosteredStudents
                filterTerm={filters.filterTerm}
                moveStudentToRoster={moveStudentToRoster}
                students={unrosteredStudents}
                updateFilterTerm={updateFilterTerm}
              />
            </Grid>
            <Grid sx={{ display: 'block' }} item xs={12} sm={6} md={4}>
              <RosteredStudents
                moveStudentToUnrostered={moveStudentToUnrostered}
                students={rosteredStudents}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default CreateRoster
