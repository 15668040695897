import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useStudentsBlockedForStudentSaveMutation = () => {
  return useMutation(async ({ id, listOfIds }) => {
    const response = await axios.post(
      `${endpoints.STUDENTS_BLOCKED_FOR_STUDENT}/save`,
      { id, listOfIds },
      { withCredentials: true }
    )

    return response.data
  })
}
