import React from 'react'
import { Modal } from '@mui/material'

import { Button, Paper } from '../shared'

import { useAppointments } from './AppointmentsContext'
import { appointmentSaveEnums } from '../../enums'

const GroupScheduleModal = ({
  appointmentDate,
  closeGroupActions,
  courseId,
  courseName,
  open,
  setOpen
}) => {
  const {
    getAppointmentsAfterSave,
    maxDateAdjusted,
    periodIdSp,
    saveAppointments,
    selectedStudentIds,
    studentsAndAppointments
  } = useAppointments()

  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveConfirmationClick = (overrideAppointments) => {
    closeGroupActions()
    handleClose()

    saveAppointments({
      courseId,
      date: appointmentDate,
      optionalCallback: () => {
        getAppointmentsAfterSave({ maxDateAdjusted })
      },
      overrideAppointments,
      processThatModifiedAppointment: appointmentSaveEnums.myCoursesGroup,
      periodId: periodIdSp,
      studentIds: selectedStudentIds,
      students: studentsAndAppointments
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <div>
        <Paper sx={{ padding: 1, margin: 0 }}>
          <h2 id='simple-modal-title'>{courseName}</h2>
          <p id='simple-modal-description'>
            Would you like to overwrite any existing appointments?
          </p>
          <Button
            esButtonClass='greyButton'
            label='No'
            marginRight
            onClick={() => handleSaveConfirmationClick(false)}
          />
          <Button
            label='Yes'
            onClick={() => handleSaveConfirmationClick(true)}
          />
        </Paper>
      </div>
    </Modal>
  )
}

export default GroupScheduleModal
