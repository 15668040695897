import React from 'react'
import { Box, IconButton, MenuItem } from '@mui/material'
import { ArrowUpward } from '@mui/icons-material'

import {
  DeleteModal,
  Menu,
  List,
  Paper,
  PaperTitle,
  SubheadingContainer,
  SubheadingText,
  MenuItemLink
} from '../shared'

import RosterItem from './RosterItem'

const Roster = React.forwardRef(
  (
    {
      deleteRoster,
      deleteStudentFromRoster,
      isDefault,
      isHomeroom,
      rosterId,
      rosterName,
      selectedStaffer,
      students
    },
    ref
  ) => {
    return (
      <div ref={ref}>
        <Paper>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box display={'flex'} alignItems={'center'}>
              <PaperTitle sx={{ marginRight: 2 }} dataTestId='roster-name'>
                {rosterName}
              </PaperTitle>
              <Menu>
                {isHomeroom ? (
                  <MenuItem>
                    This Group is a Homebase and cannot be deleted.
                  </MenuItem>
                ) : isDefault ? (
                  <MenuItem>
                    This Group is marked as Default and cannot be deleted.
                  </MenuItem>
                ) : (
                  <DeleteModal
                    label='Delete Group'
                    confirmDeleteCallback={() => deleteRoster(rosterId)}
                    confirmDeleteLabel='Delete Group'
                    confirmDeleteMessage='Are you sure you want to delete this Student Group?'
                    itemName={rosterName}
                  />
                )}
                <MenuItemLink
                  state={{
                    selectedStaffId: selectedStaffer
                  }}
                  text='Edit Student Group'
                  to={`/createRoster/${rosterId}/${selectedStaffer}`}
                />
              </Menu>
            </Box>
            <IconButton
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }>
              <ArrowUpward />
            </IconButton>
          </Box>
          <SubheadingContainer>
            <SubheadingText sx={{ gridColumn: '1/5' }} text='Name' />
            <SubheadingText sx={{ gridColumn: '5/8' }} text='Email Address' />
          </SubheadingContainer>
          <List
            Component={RosterItem}
            data={students}
            deleteStudentFromRoster={deleteStudentFromRoster}
            emptyArrayMessage='There are no students in this Group'
            keyValue='studentId'
          />
        </Paper>
      </div>
    )
  }
)
export default Roster
