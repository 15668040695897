import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useRostersOnlyByStafferQuery = ({ userId }) => {
  return useQuery(
    ['rosters', 'rosters-only-by-staffer', { userId: parseInt(userId) }],

    async () => {
      const response = await axios.get(
        `${endpoints.ROSTERS}/rostersonlybystaffer/${userId}`,
        { withCredentials: true }
      )

      response.data.forEach((r) => {
        r.description = r.rosterName
        r.value = r.rosterId
      })

      response.data.sort(sortByCollatingField(true, 'rosterName'))

      return response.data
    },
    {
      enabled: !!userId,
      staleTime: 1000 * 30
    }
  )
}
