import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStaffersIncludeAllQueryKey } from '../../../helpers'

export const useHomeroomMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (adjustedHomerooms) => {
      let array = []

      adjustedHomerooms.forEach((item) => {
        array.push({
          homeroom: item.homeRoom,
          userId: item.userId
        })
      })

      await axios.post(`${endpoints.STAFF}/updatehomerooms`, array, {
        withCredentials: true
      })

      return array
    },
    {
      onSuccess: (array) => {
        enqueueSnackbar(`Default Room Numbers have been saved`, {
          variant: 'success'
        })

        queryClient.invalidateQueries(['appointments'])

        array.forEach((item) => {
          queryClient.setQueryData(
            getStaffersIncludeAllQueryKey(),
            (prevStaffers) => {
              return [
                ...prevStaffers.map((staffer) => {
                  if (staffer.userId !== item.userId) return staffer
                  return { ...staffer, homeRoom: item.homeroom }
                })
              ]
            }
          )
        })
      }
    }
  )
}
