import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'

import { useLoggedInUser } from '../../context'
import {
  useHeaderTitle,
  useIsXsBreakpoint,
  useStaffQuery,
  useStudentsMinimalQuery
} from '../../hooks'
import {
  Button,
  Loading,
  PageInstructions,
  SelectField,
  Tabs,
  UserManual
} from '../shared'

import {
  BlockedStudents,
  UnblockedStudents,
  useStudentsBlockedByStafferQuery,
  useStudentsBlockedByStafferSaveMutation
} from '../BlockStudents'
import { Box, Grid } from '@mui/material'

const BlockStudents = () => {
  useHeaderTitle('Block Students')

  const isXs = useIsXsBreakpoint()
  const { isAdmin, loggedInUserId } = useLoggedInUser()
  const { enqueueSnackbar } = useSnackbar()

  const [filteredStudents, setFilteredStudents] = useState([])
  const [filterTerm, setFilterTerm] = useState('')
  const [selectedStaffer, setSelectedStaffer] = useState(loggedInUserId)
  const [selectedStudentIds, setSelectedStudentIds] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)

  const { data: staffers = [], isLoading: isLoadingStaffers } = useStaffQuery()

  const {
    data: students = [],
    isFetching: isFetchingStudents,
    isLoading: isLoadingStudents,
    status: studentsStatus
  } = useStudentsMinimalQuery()

  const {
    data: studentsBlockedByStaffer = [],
    isFetching: isFetchingStudentsBlockedByStaffer,
    isLoading: isLoadingStudentsBlockedByStaffer,
    status: studentsBlockedByStafferStatus
  } = useStudentsBlockedByStafferQuery(selectedStaffer)

  const {
    data: unsavedStudentIds = [],
    isLoading: isBlockingStudents,
    mutate
  } = useStudentsBlockedByStafferSaveMutation()

  useEffect(() => {
    if (studentsStatus === 'success') {
      setFilteredStudents(students)
    }
  }, [students, studentsStatus])

  useEffect(() => {
    if (unsavedStudentIds.length > 0) {
      unsavedStudentIds.forEach((studentId) => {
        const student = students.find((student) => student.userId === studentId)

        enqueueSnackbar(
          `${student.description} is in an existing Student Group for this staffer and cannot be blocked.`,
          {
            variant: 'error'
          }
        )

        setSelectedStudentIds((prevIds) => [
          ...prevIds.filter((id) => id !== studentId)
        ])
      })
    }
  }, [enqueueSnackbar, students, unsavedStudentIds])

  useEffect(() => {
    if (studentsBlockedByStafferStatus === 'success') {
      let array = []

      studentsBlockedByStaffer.forEach((student) => array.push(student.userId))

      setSelectedStudentIds(array)
    }
  }, [studentsBlockedByStaffer, studentsBlockedByStafferStatus])

  const blockedStudents = students.filter((student) =>
    selectedStudentIds.includes(student.userId)
  )

  const unblockedStudents = filteredStudents.filter(
    (student) => !selectedStudentIds.includes(student.userId)
  )

  const blockStudents = () => {
    mutate({ id: selectedStaffer, listOfIds: selectedStudentIds })
  }

  const moveStudentToBlocked = (userId) => {
    setSelectedStudentIds([...selectedStudentIds, userId])
  }

  const moveStudentToUnblocked = (userId) => {
    setSelectedStudentIds([...selectedStudentIds.filter((id) => id !== userId)])
  }

  const updateFilterTerm = (e) => {
    const { value } = e.target
    setFilterTerm(value)

    if (value) {
      setFilteredStudents(
        students.filter((student) => {
          return student.description.toLowerCase().includes(value.toLowerCase())
        })
      )
    } else {
      setFilteredStudents(students)
    }
  }

  if (
    isFetchingStudents ||
    isFetchingStudentsBlockedByStaffer ||
    isLoadingStaffers ||
    isLoadingStudents ||
    isLoadingStudentsBlockedByStaffer
  )
    return <Loading text='Loading Students' />

  if (isBlockingStudents) return <Loading text='Blocking Students' />

  return (
    <div>
      <UserManual href='' />
      <PageInstructions>
        Coming Soon: Page Instructions and User Manual Link
      </PageInstructions>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isAdmin && (
          <SelectField
            fullWidth={false}
            label='Selected Staffer'
            onChange={(e) => setSelectedStaffer(e.target.value)}
            menuItems={staffers}
            name='selectedStaffer'
            selectedValue={selectedStaffer}
          />
        )}
        <Button label='Save' onClick={blockStudents} />
      </Box>
      <Grid container spacing={2}>
        {isXs ? (
          <>
            <Tabs
              sx={{ display: 'block' }}
              labels={['Unblocked Students', 'Blocked Students']}
              scrollable
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            {selectedTab === 0 && (
              <Grid item xs={12} sm={6} md={4}>
                <UnblockedStudents
                  filterTerm={filterTerm}
                  onChange={moveStudentToBlocked}
                  students={unblockedStudents}
                  updateFilterTerm={updateFilterTerm}
                />
              </Grid>
            )}
            {selectedTab === 1 && (
              <Grid sx={{ display: 'block' }} item xs={12} sm={6} md={4}>
                <BlockedStudents
                  onChange={moveStudentToUnblocked}
                  students={blockedStudents}
                />
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <UnblockedStudents
                filterTerm={filterTerm}
                onChange={moveStudentToBlocked}
                students={unblockedStudents}
                updateFilterTerm={updateFilterTerm}
              />
            </Grid>
            <Grid sx={{ display: 'block' }} item xs={12} sm={6} md={4}>
              <BlockedStudents
                onChange={moveStudentToUnblocked}
                students={blockedStudents}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default BlockStudents
