import React, { useState } from 'react'
import { Typography, Box } from '@mui/material'
import { Email, PlayArrow, SkipNext } from '@mui/icons-material'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

import { getParsedValueFromStringifiedObject } from '../../helpers'
import { LabeledCheckbox, List, Paper, StudentInfo } from '../shared'

import { useAppointments } from './AppointmentsContext'
import AppointmentDetail from './AppointmentDetail'
import GroupActionsMenu from './GroupActionsMenu'
import {
  IconsContainer,
  MultiActionsContainer,
  MultiActionsItem,
  SkipIcon,
  StudentNameContainer
} from './styledComponents'

const AppointmentsList = () => {
  const [openHomeroom, setOpenHomeroom] = useState(false)
  const [openSubjectMatter, setOpenSubjectMatter] = useState(false)

  const {
    emailStudentSchedule,
    openSchedulePhrase,
    pageSettings,
    selectedStudentIds,
    staffIdSp,
    studentsAndAppointments,
    updateSelectedDateByNumber,
    updateSelectedStudentIds
  } = useAppointments()

  return (
    <>
      {studentsAndAppointments && studentsAndAppointments.length > 0 ? (
        <>
          <ScrollSync>
            <>
              <ScrollSyncPane group='horizontal'>
                <MultiActionsContainer>
                  {studentsAndAppointments[0].appointments.map((item, i) => (
                    <MultiActionsItem
                      noBorder={
                        i + 1 === studentsAndAppointments[0].appointments.length
                      }
                      key={i}>
                      {i === 0 && (
                        <IconsContainer>
                          <SkipIcon
                            onClick={() => updateSelectedDateByNumber(-7)}
                          />
                          <SkipIcon
                            as={PlayArrow}
                            onClick={() => updateSelectedDateByNumber(-1)}
                            style={{ transform: 'rotateY(180deg)' }}
                          />
                        </IconsContainer>
                      )}
                      <GroupActionsMenu
                        appointmentDate={item.appointmentDate}
                        openHomeroom={openHomeroom}
                        openSubjectMatter={openSubjectMatter}
                        selectedStaffer={staffIdSp}
                        setOpenHomeroom={setOpenHomeroom}
                        setOpenSubjectMatter={setOpenSubjectMatter}
                      />
                      {i ===
                        studentsAndAppointments[0].appointments.length - 1 && (
                        <IconsContainer>
                          <SkipIcon
                            as={PlayArrow}
                            onClick={() => updateSelectedDateByNumber(1)}
                          />
                          <SkipIcon
                            as={SkipNext}
                            onClick={() => updateSelectedDateByNumber(7)}
                          />
                        </IconsContainer>
                      )}
                    </MultiActionsItem>
                  ))}
                </MultiActionsContainer>
              </ScrollSyncPane>
              {studentsAndAppointments.map((student) => (
                <ScrollSyncPane group='horizontal' key={student.userId}>
                  <Paper id={`container-${student.userId}`} sx={{ padding: 0 }}>
                    <StudentNameContainer id={student.userId}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <Email
                          sx={{
                            color: 'primary.main',
                            marginRight: 1,
                            '&:hover': {
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => emailStudentSchedule(student.userId)}
                        />
                        <StudentInfo
                          firstName={student.firstName}
                          nickName={student.nickname}
                          lastName={student.lastName}
                          sisId={student.sisId}
                          useLink={true}
                          userId={student.userId}
                          yearOfGraduation={student.yearOfGraduation}
                        />
                      </span>
                      <LabeledCheckbox
                        checked={selectedStudentIds.includes(
                          parseInt(student.userId)
                        )}
                        id={student.userId.toString()}
                        label='Include Student'
                        onChange={(e) => updateSelectedStudentIds(e)}
                      />
                    </StudentNameContainer>
                    <Box sx={{ marginBottom: 1, display: 'flex' }}>
                      <List
                        Component={AppointmentDetail}
                        data={student.appointments}
                        firstName={student.firstName}
                        lastName={student.lastName}
                        nickName={student.nickname}
                        openSchedulePhrase={openSchedulePhrase}
                        selectedPeriodInfo={{}}
                        useMinifiedView={getParsedValueFromStringifiedObject(
                          'useMinifiedView',
                          pageSettings
                        )}
                        userId={student.userId}
                      />
                    </Box>
                  </Paper>
                </ScrollSyncPane>
              ))}
            </>
          </ScrollSync>
        </>
      ) : (
        <Typography>
          There are no Appointments for the selected Date, Period, Staffer and
          Student Group
        </Typography>
      )}
    </>
  )
}

export default AppointmentsList
