import React, { forwardRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Drawer } from '@mui/material'
import moment from 'moment'

import {
  useCoursesForDatePeriodStudentIdQuery,
  useDepartmentsQuery
} from '../../hooks'
import {
  Button,
  FlexColumn,
  PaperTitle,
  LabeledCheckbox,
  Loading,
  SelectField,
  TextArea
} from '../shared'

import { useAppointments } from './AppointmentsContext'
import { ScheduledText } from './styledComponents'
import { getStudentDisplayName } from '../../helpers'
import { appointmentSaveEnums } from '../../enums'

const QuickSchedule = forwardRef(
  (
    {
      appointmentId,
      courseId,
      courseName,
      firstName,
      lastName,
      nickName,
      open,
      selectedDate,
      setOpen,
      studentId,
      userId
    },
    ref
  ) => {
    const {
      getAppointmentsAfterSave,
      maxDateAdjusted,
      periodIdSp,
      saveAppointments
    } = useAppointments()

    const displayName = getStudentDisplayName(firstName, lastName, nickName)

    const {
      data: courses = [],
      isFetching: isFetchingCourses,
      isLoading: isLoadingCourses
    } = useCoursesForDatePeriodStudentIdQuery({
      date: selectedDate,
      periodId: periodIdSp,
      studentId
    })

    const {
      data: departments = [],
      isFetching: isFetchingDepartments,
      isLoading: isLoadingDepartments
    } = useDepartmentsQuery()

    const [state, setState] = useState({
      comment: '',
      lockAppointment: false,
      selectedCourse: '',
      selectedDepartment: ''
    })

    const coursesBySelectedDepartment = courses.filter(
      (course) => course.departmentId === state.selectedDepartment
    )

    const selectedCourseInfo = courses.find(
      (course) => course.value === state.selectedCourse
    )

    const disableSaveButton =
      state.selectedCourse === '' ||
      (state.selectedCourse !== '' && selectedCourseInfo.availableSeats === 0)
        ? true
        : false

    const closeDrawer = () => {
      setOpen(false)
    }

    const quickScheduleStudent = () => {
      saveAppointments({
        appointmentId,
        courseId: state.selectedCourse,
        date: selectedDate,
        isLocked: state.lockAppointment,
        optionalCallback: () => {
          closeDrawer()

          getAppointmentsAfterSave({ maxDateAdjusted })
        },
        overrideAppointments: true,
        periodId: periodIdSp,
        processThatModifiedAppointment:
          appointmentSaveEnums.quickScheduleSingle,
        schedulerComment: state.comment,
        studentIds: [userId],
        students: [
          {
            firstName,
            lastName,
            userId
          }
        ]
      })
    }

    return (
      <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
        <Box sx={{ padding: 1, width: 350 }}>
          {isFetchingCourses ||
          isFetchingDepartments ||
          isLoadingCourses ||
          isLoadingDepartments ? (
            <Loading fullscreen={false} text='Loading Courses' />
          ) : (
            <FlexColumn>
              <PaperTitle>Quick Schedule: {displayName}</PaperTitle>
              <Typography paragraph>
                <ScheduledText>Schedule Date:&nbsp;</ScheduledText>
                {moment(selectedDate).format('MM/DD/YYYY')}
              </Typography>
              {courseId !== 0 && (
                <Typography>
                  <ScheduledText>Scheduled To:&nbsp;</ScheduledText>
                  {courseName}
                </Typography>
              )}
              <SelectField
                label='Select Department'
                labelId='selected-department'
                menuItems={departments}
                onChange={(e) =>
                  setState({ ...state, selectedDepartment: e.target.value })
                }
                selectedValue={state.selectedDepartment}
              />
              <SelectField
                label='Select Course'
                labelId='selected-course'
                menuItems={coursesBySelectedDepartment}
                onChange={(e) =>
                  setState({ ...state, selectedCourse: e.target.value })
                }
                selectedValue={state.selectedCourse}
              />
              {selectedCourseInfo?.courseDescription && (
                <Typography sx={{ paddingTop: 1.5, paddingBottom: 1.5 }}>
                  <b>Course Description:&nbsp;</b>
                  {selectedCourseInfo.courseDescription}
                </Typography>
              )}
              {state.selectedCourse !== '' &&
                selectedCourseInfo.availableSeats === 0 && (
                  <Typography paragraph>
                    The selected course has no available seats. You will not be
                    able to schedule this student to this course.
                  </Typography>
                )}
              <TextArea
                onChange={(e) =>
                  setState({ ...state, comment: e.target.value })
                }
                placeholder='Add an optional comment'
                value={state.comment}
              />
              <LabeledCheckbox
                checked={state.lockAppointment}
                label='Lock Appointment'
                onChange={(e) =>
                  setState({ ...state, lockAppointment: e.target.checked })
                }
              />
              <div>
                <Button
                  esButtonClass='cancelButton'
                  label='Cancel'
                  onClick={closeDrawer}
                />
                <Button
                  disabled={disableSaveButton}
                  label='Schedule'
                  onClick={quickScheduleStudent}
                />
              </div>
            </FlexColumn>
          )}
        </Box>
      </Drawer>
    )
  }
)

export default QuickSchedule
