export const filteredStudentsMapping = {
  activeFilter: {
    1: (student) => student.userIsActive,
    2: (student) => !student.userIsActive
  },
  gradeCutOffFilter: {
    1: (student) => !student.isBelowGradeCutOff,
    2: (student) => student.isBelowGradeCutOff
  },
  selfScheduleFilter: {
    1: (student) => student.canScheduleSelf,
    2: (student) => !student.canScheduleSelf
  },
  overrideFilter: {
    1: (student) => student.canOverrideAppts,
    2: (student) => !student.canOverrideAppts
  }
}
