export const localStorageDashboardSettings =
  'enriching-students:dashboard-page-settings'
export const localStorageLoggedInUserDetails =
  'enriching-students:logged-in-user-details'
export const localStorageSchedulePageSettings =
  'enriching-students:appointments-page-settings'
export const localStorageStudentProfilePageSettings =
  'enriching-students:student-profile-page-settings'

export const localStorageLoggedInSchoolId =
  'enriching-students:logged-in-school-id'

export const localStorageHasBeenAuthenticated =
  'enriching-students:has-been-authenticated'
