import { Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

const SummaryMessages = ({ appointmentDate, messages }) => {
  return (
    <div
      style={{
        borderBottom: '1px solid #d9d9d9',
        marginTop: 8,
        marginBottom: 8,
        padding: 8
      }}>
      <Typography style={{ paddingTop: 4, paddingBottom: 4 }}>
        {moment(appointmentDate).format('MM/DD/YYYY')}
      </Typography>
      {messages.map((message) => (
        <Typography key={message}>{message}</Typography>
      ))}
    </div>
  )
}

export default SummaryMessages
