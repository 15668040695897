import moment from 'moment'
import { useEffect } from 'react'

export const useGetAppointmentsWithCounts = ({
  countType,
  endDate,
  id,
  periodIds,
  repeatOption,
  startDate,
  weekdaysToInclude,

  getAppointmentsWithCounts,
  selectedPeriodsWatch,
  selectedWeekdaysWatch,
  shouldRenderWeekdayControls
}) => {
  useEffect(() => {
    if (selectedPeriodsWatch.length < 1) return
    if (selectedPeriodsWatch.includes(-10)) return
    if (shouldRenderWeekdayControls && selectedWeekdaysWatch.length < 1) return

    getAppointmentsWithCounts({
      countType,
      endDate: moment(endDate).format('YYYY-MM-DD'),
      id,
      periodIds,
      repeatOption,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      weekdaysToInclude
    })
  }, [
    countType,
    endDate,
    getAppointmentsWithCounts,
    id,
    periodIds,
    repeatOption,
    selectedPeriodsWatch,
    selectedWeekdaysWatch.length,
    shouldRenderWeekdayControls,
    startDate,
    weekdaysToInclude
  ])
}
