import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(),
  marginBottom: theme.spacing()
}))

const EsRadioGroup = ({
  data,
  formLabel,
  name,
  onChange,
  radioOptionClass,
  selectedValue,
  sx
}) => {
  return (
    <StyledFormControl component='fieldset'>
      <FormLabel component='legend'>{formLabel}</FormLabel>
      <RadioGroup
        aria-label={name}
        sx={sx}
        name={name}
        value={selectedValue}
        onChange={onChange}>
        {data.map((item) => (
          <FormControlLabel
            className={radioOptionClass}
            control={<Radio color='primary' />}
            key={item.value}
            label={item.label}
            value={item.value}
          />
        ))}
      </RadioGroup>
    </StyledFormControl>
  )
}

EsRadioGroup.propTypes = {
  data: PropTypes.array.isRequired,
  formLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired
}

export default EsRadioGroup
