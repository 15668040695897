import React from 'react'
import { Button } from '@mui/material'

const StepNavButtons = ({
  disableNext = false,
  disablePrev = false,
  nextText = 'Next',
  onClickNext,
  onClickPrev,
  stepNumber
}) => {
  return (
    <div>
      <div>
        <Button disabled={disablePrev} onClick={onClickPrev}>
          Back
        </Button>
        <Button
          data-testid={`next-${stepNumber}`}
          disabled={disableNext}
          variant='contained'
          color='primary'
          onClick={onClickNext}>
          {nextText}
        </Button>
      </div>
    </div>
  )
}

export default StepNavButtons
