import React from 'react'
import { Box, Modal, Typography } from '@mui/material'

import { Button, SectionTitle, TextField } from '../shared'
import { Close } from '@mui/icons-material'
import { useLoggedInUser } from '../../context'

const ConfirmSaveModal = ({
  confirmSave,
  confirmationNumber,
  displaySaveConfirmation,
  hasConfirmationError,
  selectedStaffIds,
  setConfirmationNumber,
  setDisplaySaveConfirmation,
  setHasConfirmationError
}) => {
  const { loggedInUserId } = useLoggedInUser()

  const loggedInUserIdIncludedInSelectedStaffIds =
    selectedStaffIds.includes(loggedInUserId)

  const handleClose = () => {
    setConfirmationNumber('')
    setDisplaySaveConfirmation(false)
    setHasConfirmationError(false)
  }

  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={displaySaveConfirmation}
      onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          backdropFilter: `blur(30px) brightness(185%) saturate(100%)`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Close
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: 2,
            marginTop: 2,
            fontSize: 32,
            cursor: 'pointer'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <SectionTitle
            text={`You are about to deactivate ${selectedStaffIds.length} Staffers`}
          />
          <h3 style={{ textAlign: 'center ', maxWidth: 550 }}>
            Please Note: ALL future appointments for these Staffers will be
            deleted.{' '}
            {loggedInUserIdIncludedInSelectedStaffIds &&
              'Additionally you are attempting to deactivate your account.  All other changes will be saved, but your account will NOT be deactivated.'}
          </h3>

          <Typography
            sx={{
              textAlign: 'center'
            }}>{`If you are sure you want to deactivate ${selectedStaffIds.length}, please enter ${selectedStaffIds.length} in the textfield below. `}</Typography>
          <TextField
            sx={{
              width: 'auto !important',
              marginRight: 1
            }}
            label='Confirmation Number'
            name='confirmationNumber'
            onChange={(e) => {
              setHasConfirmationError(false)
              setConfirmationNumber(e.target.value)
            }}
            value={confirmationNumber}
          />
          {hasConfirmationError && (
            <Typography sx={{ color: 'error.main', paddingY: 1 }}>
              The number provided does not match, please try again.
            </Typography>
          )}
        </Box>
        <Button
          dataTestId='confirm-save-button'
          label='Save'
          onClick={() => {
            confirmSave()
            handleClose()
          }}
        />
      </Box>
    </Modal>
  )
}

export default ConfirmSaveModal
