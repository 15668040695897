import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useRostersHomeroomQuery = ({ useAllOption = false } = {}) => {
  return useQuery(
    ['rosters', 'homeroom'],
    async () => {
      const response = await axios.get(`${endpoints.ROSTERS}/homeroom`, {
        withCredentials: true
      })

      response.data.forEach(
        (r) =>
          (r.description = `${r.stafferLastName}, ${r.stafferFirstName} - ${r.rosterName}`)
      )
      response.data.forEach((r) => (r.value = r.rosterId))

      response.data.sort(sortByCollatingField(true, 'description'))

      if (useAllOption) {
        response.data.unshift({ description: 'All', value: 0 })
      }

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
