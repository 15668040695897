import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { sortArrayByTwoFields } from '../../../helpers'

export const useAdjustStudentsToRostersMutation = ({
  studentEmailAddress,
  studentFirstName,
  studentLastName,
  studentName,
  studentNickname,
  studentYearOfGraduation
} = {}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ modifiedRosters, staffId, studentId }) => {
      const array = []

      modifiedRosters.forEach((roster) => {
        array.push({
          add: roster.isSelected,
          rosterId: roster.rosterId,
          studentId
        })
      })

      const response = await axios.post(
        `${endpoints.ROSTERS}/adjustStudentsToRosters`,
        array,
        { withCredentials: true }
      )

      let arr2Map = response.data.reduce((acc, curr) => {
        acc[curr.rosterId] = curr
        return acc
      }, {})
      let combined = array.map((d) => Object.assign(d, arr2Map[d.rosterId]))

      const copy = queryClient.getQueryData([
        'rosters',
        'by-staff-id',
        { staffId: parseInt(staffId) }
      ])

      combined.forEach((item) => {
        copy.forEach((roster) => {
          if (roster.rosterId === item.rosterId) {
            if (item.add) {
              roster.students.push({
                rosterId: item.rosterId,
                rosteredStudentId: item.rosteredStudentId,
                studentEmailAddress,
                studentFirstName,
                studentLastName,
                studentNickname,
                studentId: item.studentId,
                studentYearOfGraduation
              })
            } else {
              roster.students = roster.students.filter(
                (student) => student.studentId !== item.studentId
              )
            }
          }
        })
      })

      copy.forEach((item) => {
        item.students = sortArrayByTwoFields(
          item.students,
          'studentLastName',
          'studentFirstName'
        )
      })

      return { data: copy, staffId }
    },
    {
      onSuccess: ({ data, staffId }) => {
        enqueueSnackbar(`Changes to ${studentName}'s Groups have been saved.`, {
          variant: 'success'
        })

        queryClient.setQueryData([
          'rosters',
          'by-staff-id',
          { staffId: parseInt(staffId) },
          () => data
        ])
      }
    }
  )
}
