import React from 'react'

import { LoginForm } from '../../Login'

import { Left, Right, TextContainer, TextContent } from './styledComponents'

const Login = () => {
  // If we aren't running a Dev build, redirect to the login site
  if (process.env.NODE_ENV !== 'development') {
    window.location.replace(process.env.REACT_APP_LOGIN_SITE_URL)

    return null
  } else {
    window.location.replace('http://localhost:3000/')
  }

  return (
    <div>
      <Left>
        <TextContainer>
          <TextContent>
            Empowering Teachers to Give Students What They Really Need. Time.
            This is a test.
          </TextContent>
        </TextContainer>
      </Left>
      <Right>
        <LoginForm />
      </Right>
    </div>
  )
}

export default Login
