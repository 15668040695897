import React, { forwardRef } from 'react'
import { MenuItem, Modal } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

import Button from './Button'
import SectionTitle from './SectionTitle'
import useOpen from '../../hooks/useOpen'

const ConfirmDeleteMessage = styled('p')(() => ({
  textAlign: 'center'
}))

const ModalContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const ModalInterior = styled('div')(() => ({
  position: 'absolute',
  width: '100vw',
  height: '100vh',

  backdropFilter: `blur(30px) brightness(185%) saturate(100%)`,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const StyledCloseIcon = styled(Close)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  marginRight: 16,
  marginTop: 16,
  fontSize: 32,
  cursor: 'pointer'
}))

const DeleteModal = forwardRef((props, ref) => {
  const {
    confirmDeleteCallback,
    confirmDeleteLabel,
    confirmDeleteMessage,
    itemName,
    label,
    useButton = false
  } = props

  const { open, handleClose, handleOpen } = useOpen()

  const handleConfirmDelete = () => {
    handleClose()

    confirmDeleteCallback()
  }

  return (
    <>
      {useButton ? (
        <Button
          dataTestId='menu-button'
          esButtonClass='deleteButton'
          label={label}
          onClick={handleOpen}
        />
      ) : (
        <MenuItem ref={ref} onClick={handleOpen}>
          {label}
        </MenuItem>
      )}

      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        onClose={handleClose}>
        <ModalInterior>
          <StyledCloseIcon onClick={handleClose} />
          <ModalContent>
            <SectionTitle text={`You are about to delete:`} />
            <h3>{itemName}</h3>
            <ConfirmDeleteMessage>{confirmDeleteMessage}</ConfirmDeleteMessage>
          </ModalContent>
          <Button
            dataTestId='confirm-delete-button'
            esButtonClass='deleteButton'
            label={confirmDeleteLabel}
            onClick={handleConfirmDelete}
          />
        </ModalInterior>
      </Modal>
    </>
  )
})

export default DeleteModal
