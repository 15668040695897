import React from 'react'
import { Typography } from '@mui/material'

import { esuUrls } from '../../constants'
import { useSortedArray } from '../../hooks'
import {
  ExternalLink,
  InternalLink,
  List,
  MobileSorting,
  Paper,
  PaperTitle,
  Popover
} from '../shared'

import UpcomingAdjustedCourseItem from './UpcomingAdjustedCourseItem'
import UpcomingAdjustedCoursesSubheading from './UpcomingAdjustedCoursesSubheading'

import {
  NoContent,
  StyledHelpIcon,
  StyledPaperContent,
  UpcomingAdjustedOfferingsInstructionText
} from './styledComponents'

const UpcomingAdjustedCoursesContainer = ({
  hideUpcomingAdjustedCourses,
  upcomingAdjustedCourses
}) => {
  const [
    { sortedArray: sortedAdjustedCourses, lastSortedBy, sortAscending },
    setSortedAdjustedCourses
  ] = useSortedArray(upcomingAdjustedCourses, 'scheduleDate', false)

  return (
    <>
      {!hideUpcomingAdjustedCourses && (
        <Paper
          sx={{
            maxHeight: { sm: 475 },
            minHeight: { sm: 475 }
          }}>
          <StyledPaperContent>
            <PaperTitle>Upcoming Adjusted Offerings</PaperTitle>
            <Popover
              content={
                <UpcomingAdjustedOfferingsInstructionText>
                  This displays any adjusted offerings you have created that are
                  coming up in the next two weeks. Create these offerings on the
                  Adjusted Offerings page.{' '}
                  <ExternalLink href={esuUrls.ADJUSTED_OFFERINGS}>
                    Learn more.
                  </ExternalLink>
                </UpcomingAdjustedOfferingsInstructionText>
              }>
              <StyledHelpIcon />
            </Popover>
          </StyledPaperContent>
          <MobileSorting
            initialValue={lastSortedBy}
            menuItems={[
              {
                description: 'Date',
                value: 'scheduleDate'
              },
              {
                description: 'Location',
                value: 'adjustedRoom'
              },
              {
                description: 'Offering Name',
                value: 'adjustedCourseName'
              }
            ]}
            onClick={setSortedAdjustedCourses}
          />
          <UpcomingAdjustedCoursesSubheading
            lastSortedBy={lastSortedBy}
            onClick={setSortedAdjustedCourses}
            sortAscending={sortAscending}
          />
          {upcomingAdjustedCourses.length > 0 ? (
            <List
              data={sortedAdjustedCourses}
              Component={UpcomingAdjustedCourseItem}
              keyValue='courseAdjustedId'
            />
          ) : (
            <NoContent>
              <Typography>
                You currently have no Upcoming Adjusted Offerings.
              </Typography>
              <InternalLink
                text='Please click here to create an Adjusted Offering'
                to='/adjustedOfferings/0'
              />
            </NoContent>
          )}
        </Paper>
      )}
    </>
  )
}

export default UpcomingAdjustedCoursesContainer
