import React, { useState } from 'react'

import { esuUrls, userTypeIds } from '../../constants'
import { useLoggedInUser } from '../../context'
import { useHeaderTitle, useStaffByIdQuery } from '../../hooks'
import {
  Loading,
  PageInstructions,
  Paper,
  SecurityForm,
  UserManual
} from '../shared'

import {
  DefaultAppointmentType,
  EmailPermissions,
  NavMenu,
  useAppointmentTypes,
  useEmailPermissions
} from '../MyAccount'

const UserSettings = () => {
  useHeaderTitle('My Account')
  const { isAdmin, isSubstitute, loggedInUserId } = useLoggedInUser()

  const [selectedSettings, setSelectedSettings] = useState('appointment-types')

  const {
    emails,
    hasEmailPermissionsBeenAltered,
    isLoadingEmails,
    isSavingEmailPermissions,
    saveEmailPermissions,
    updateEmails
  } = useEmailPermissions()

  const { data: staffer = {}, isLoading: isLoadingStafferDetails } =
    useStaffByIdQuery(loggedInUserId)

  const { courses } = staffer

  const {
    appointmentTypes,
    changeAppointmentType,
    homeroomCourseUpdated,
    isLoadingAppointmentTypes,
    isSavingAppointmentType,
    saveAppointmentTypes,
    selectedHomeroomAppointmentType,
    selectedSubjectMatterAppointmentType,
    subjectMatterCourseUpdated
  } = useAppointmentTypes(courses)

  if (isLoadingAppointmentTypes || isLoadingEmails || isLoadingStafferDetails)
    return <Loading text='Loading Account Details' />
  if (isSavingAppointmentType || isSavingEmailPermissions)
    return <Loading text='Saving Account Details' />

  return (
    <div>
      <UserManual
        href={isSubstitute ? esuUrls.MY_ACCOUNT_SUB : esuUrls.MY_ACCOUNT}
      />
      <PageInstructions>
        {isSubstitute
          ? 'On this page, select Password below to change your password.'
          : 'Use this page to set your default appointment type for your homebase or subject matter course, and change your password.'}
      </PageInstructions>
      <Paper
        sx={{
          display: { sm: 'flex' },
          paddingTop: { sm: 2 },
          paddingBottom: { sm: 2 }
        }}>
        <NavMenu
          selectedSettings={selectedSettings}
          setSelectedSettings={setSelectedSettings}
        />
        <div>
          {selectedSettings === 'appointment-types' && (
            <DefaultAppointmentType
              appointmentTypes={appointmentTypes}
              appointmentTypesHaveBeenAdjusted={
                homeroomCourseUpdated || subjectMatterCourseUpdated
              }
              changeAppointmentType={changeAppointmentType}
              data={courses}
              isSubstitute={isSubstitute}
              saveAppointmentTypes={saveAppointmentTypes}
              selectedHomeroomAppointmentType={selectedHomeroomAppointmentType}
              selectedSubjectMatterAppointmentType={
                selectedSubjectMatterAppointmentType
              }
            />
          )}
          {selectedSettings === 'password' && (
            <SecurityForm
              displayCurrentPassword={true}
              displayWelcomeEmail={false}
              emailAddress={staffer.staffer.emailAddress}
              userId={staffer.staffer.userId}
              userToSchoolId={staffer.staffer.userToSchoolId}
              userType={isAdmin ? userTypeIds.admin : userTypeIds.staffer}
            />
          )}
          {selectedSettings === 'email-permissions' && (
            <EmailPermissions
              emails={emails}
              hasEmailPermissionsBeenAltered={hasEmailPermissionsBeenAltered}
              saveEmailPermissions={() => {
                saveEmailPermissions(emails)
              }}
              updateEmails={updateEmails}
            />
          )}
        </div>
      </Paper>
    </div>
  )
}

export default UserSettings
