import { useMutation } from 'react-query'
import axios from 'axios'
import moment from 'moment'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useCoursesForDateAndPeriodMutation = () => {
  return useMutation(async ({ date, periodId }) => {
    const response = await axios.get(
      `${endpoints.COURSES}/fordateandperiod/${moment(date).format(
        'YYYY-MM-DD'
      )}/${periodId}/0`,
      { withCredentials: true }
    )

    response.data.forEach((r) => {
      r.description = r.courseName
      r.value = r.courseId
    })

    return response.data.sort(sortByCollatingField(true, 'description'))
  })
}
