import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useSelfSchedulingAdjustorGetDaysListQuery = () => {
  return useQuery(['self-scheduling-adjustor', 'get-days-list'], async () => {
    const response = await axios.get(
      `${endpoints.SELF_SCHEDULING_ADJUSTOR}/getdayslist`,
      {
        withCredentials: true
      }
    )

    return response.data
  })
}
