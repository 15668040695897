import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useDeleteStudentGroup = ({ staffId }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ rosterId, selectedRoster }) => {
      await axios.delete(`${endpoints.ROSTERS}/${rosterId}`, {
        withCredentials: true
      })

      return { rosterId, selectedRoster }
    },
    {
      onSuccess: ({ rosterId, selectedRoster }) => {
        enqueueSnackbar(`${selectedRoster.rosterName} has been deleted`, {
          variant: 'success'
        })

        queryClient.setQueryData(
          ['rosters', 'by-staff-id', { staffId: parseInt(staffId) }],
          (prevGroups) => {
            return [
              ...prevGroups.filter((roster) => roster.rosterId !== rosterId)
            ]
          }
        )
      }
    }
  )
}
