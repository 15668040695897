import React, { forwardRef } from 'react'
import { ListSubheader } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledListSubheader = styled(ListSubheader)(() => ({
  fontStyle: 'italic',
  lineHeight: '32px'
}))

const EsListSubheader = forwardRef(({ children, ...otherProps }, ref) => {
  return <StyledListSubheader {...otherProps}>{children}</StyledListSubheader>
})

export default EsListSubheader
