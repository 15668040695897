import React from 'react'

import { useLoggedInUser } from '../context'
import { setupMenuItems } from '../helpers'
import EsSelectField from '../components/shared/SelectField'

import {
  SelectFieldContainer,
  StyledWelcomeText,
  UserDetailsContainer
} from './styledComponents'

const LoggedInUserDetails = ({
  schools,
  selectedSchool,
  updateSelectedSchool
}) => {
  const { loggedInUserFirstName, loggedInUserLastName } = useLoggedInUser()

  return (
    <UserDetailsContainer>
      <StyledWelcomeText>
        Welcome: {loggedInUserFirstName} {loggedInUserLastName}
      </StyledWelcomeText>
      {schools.length > 1 && (
        <SelectFieldContainer>
          <EsSelectField
            style={{ width: 200 }}
            label='Selected School'
            menuItems={setupMenuItems({
              data: schools,
              descriptionField: 'schoolName',
              valueField: 'userToSchoolId'
            })}
            labelId='selected-school'
            onChange={updateSelectedSchool}
            selectedValue={selectedSchool}
          />
        </SelectFieldContainer>
      )}
    </UserDetailsContainer>
  )
}

export default LoggedInUserDetails
