import { useState } from 'react'

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return { anchorEl, handleClose, handleOpen, open, setAnchorEl }
}

export default useAnchorEl
