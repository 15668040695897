import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getReportsQueryKey, sortByCollatingField } from '../../../helpers'

export const useReportsQuery = () => {
  return useQuery(getReportsQueryKey(), async () => {
    const response = await axios.get(endpoints.REPORTS, {
      withCredentials: true
    })

    response.data.sort(sortByCollatingField(true, 'reportName'))

    return response.data
  })
}
