import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getPeriodQueryKey } from '../../../helpers'

// exporting to use for pre-fetching
export const fetchPeriodDetails = async (periodId) => {
  const response = await axios.get(`${endpoints.PERIODS}/${periodId}`, {
    withCredentials: true
  })

  return response.data
}

export const usePeriodsByIdQuery = (periodId) => {
  return useQuery(
    getPeriodQueryKey(periodId),
    () => fetchPeriodDetails(periodId),
    {
      onError: (error) => console.log({ error }),
      staleTime: 1000 * 60 // 1 minute
    }
  )
}
