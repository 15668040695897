import moment from 'moment'

export const getActiveStaffCountQueryKey = () => ['active-staff-count']

export const getAdjustedOfferingQueryKey = ({
  adjustedOfferingId,
  staffId
}) => [
  'adjusted-offering',
  {
    adjustedOfferingId: parseInt(adjustedOfferingId),
    staffId: parseInt(staffId)
  }
]

export const getAdjustedOfferingsByStaffIdNumberOfDaysQueryKey = ({
  numberOfDays,
  staffId
}) => [
  'adjusted-offerings-staffId-numberOfDays',
  {
    numberOfDays: parseInt(numberOfDays),
    staffId: parseInt(staffId)
  }
]

export const getAppointmentExportDefinitionQueryKey = (
  appointmentExportDefinitionId
) => [
  'appointment-export-definitions',
  { appointmentExportDefinitionId: parseInt(appointmentExportDefinitionId) }
]

export const getAppointmentExportDefinitionsByIdQueryKey = (
  appointmentExportDefinitionId
) => [
  'appointment-export-definitions',
  'by-id',
  {
    appointmentExportDefinitionId: parseInt(appointmentExportDefinitionId)
  }
]

export const getAppointmentExportDefinitionsQueryKey = () => [
  'appointment-export-definitions'
]

export const getAppointmentRequestByIdsQueryKey = (appointmentRequestIds) => [
  'appointment-requests',
  'by-ids',
  { appointmentRequestIds }
]

export const getAppointmentTypeQueryKey = (appointmentTypeId) => [
  'appointment-type',
  { appointmentTypeId: parseInt(appointmentTypeId) }
]

export const getAppointmentTypesQueryKey = ({
  checkHasBeenUsed,
  includeInactive,
  returnAsMenuItems
}) => [
  'appointment-types',
  {
    checkHasBeenUsed,
    includeInactive,
    returnAsMenuItems
  }
]

export const getAppointmentsQueryKey = ({
  numberOfDaysToRetrieve,
  periodId,
  rosterId,
  searchTerm,
  startDate
}) => [
  'appointments',
  'get',
  {
    numberOfDaysToRetrieve,
    periodId: parseInt(periodId),
    rosterId: parseInt(rosterId),
    searchTerm,
    startDate: moment(startDate).format('YYYY-MM-DD')
  }
]

export const getAppointmentsMassScheduleInitializeQueryKey = () => [
  'appointments',
  'mass-schedule-initialize'
]

export const getAttendanceTypeQueryKey = (attendanceTypeId) => [
  'attendance-types',
  { attendanceTypeId: parseInt(attendanceTypeId) }
]

export const getBlockedDatesByDepartmentQueryKey = ({
  departmentId,
  month,
  selectedTab
}) => [
  'blocked-dates',
  'by-department',
  {
    departmentId: parseInt(departmentId),
    month: parseInt(month),
    selectedTab: parseInt(selectedTab)
  }
]

export const getBlockedDatesBySchoolQueryKey = ({ month, selectedTab }) => [
  'blocked-dates',
  'by-school',
  { month: parseInt(month), selectedTab: parseInt(selectedTab) }
]

export const getBlockedDatesByStaffQueryKey = ({
  month,
  selectedTab,
  staffId
}) => [
  'blocked-dates',
  'by-staff',
  {
    month: parseInt(month),
    selectedTab: parseInt(selectedTab),
    staffId: parseInt(staffId)
  }
]

export const getConfigurationsQueryKey = () => ['configurations']

export const getDashboardStrugglingStudentsQueryKey = () => [
  'struggling-students'
]

export const getDashboardSummariesQueryKey = ({ date, numberOfDays }) => [
  'appointments',
  'summary-for-attendance',
  { date, numberOfDays }
]

export const getDayTypesQueryKey = () => ['day-types']

export const getDayTypesCalendarQueryKey = () => ['day-types', 'calendar']

export const getDepartmentQueryKey = (departmentId) => [
  'departments',
  {
    departmentId: parseInt(departmentId)
  }
]

export const getDepartmentsQueryKey = ({
  includeInactive,
  includeUnassigned,
  withCounts
}) => ['departments', { includeInactive, includeUnassigned, withCounts }]

export const getEmailLogsQueryKey = () => ['email-logs']

export const getEmailSubscriptionQueryKey = () => ['email-subscription']

export const getEmailTypesQueryKey = () => ['email-types']

export const getFreezeAppointmentsQueryKey = () => [
  'configuration',
  'freeze-appointments'
]

export const getGraduationYearsQueryKey = (useAll, useSelect) => [
  'configuration',
  'graduation-years',
  {
    useAll,
    useSelect
  }
]

export const getHomebaseGroupsQueryKey = () => ['homebase-groups']

export const getImportKeyQueryKey = () => ['import-key']

export const getInvalidEmailsQueryKey = () => ['invalid-emails']

export const getLookupValuesMessageForQueryKey = () => [
  'lookup-values',
  'message-for'
]

export const getManageEmailTemplateAdminListQueryKey = () => [
  'manage-email-template-admin',
  'list'
]

export const getNotificationsQueryKey = () => ['notifications', 'all-unexpired']

export const getMessageQueryKey = (messageId) => [
  'messages',
  { messageId: parseInt(messageId) }
]

export const getMessagesQueryKey = () => ['messages']

export const getPeriodQueryKey = (periodId) => [
  'periods',
  { periodId: parseInt(periodId) }
]

export const getPeriodsMinimalActiveOnlyQueryKey = () => [
  'periods',
  'minimal',
  'active-only'
]

export const getPeriodsQueryKey = ({ activeOnly }) => [
  'periods',
  { activeOnly }
]

export const getReportsQueryKey = () => ['reports']

export const getSchoolMasterGradesQueryKey = () => ['school-master-grades']

export const getSftpInfoQueryKey = () => ['sftp-info']

export const getSoftDeletedUsersQueryKey = () => [
  'base-user',
  'get-soft-deleted'
]

export const getStafferQueryKey = (userId) => [
  'staff',
  { userId: parseInt(userId) }
]

export const getStaffersQueryKey = ({
  determineIfCanBeDeleted,
  mustHaveActiveHomeroomCourse,
  mustHaveHomeroomRoster,
  useAllOption,
  useNoneOption
}) => [
  'staff',
  {
    determineIfCanBeDeleted,
    mustHaveActiveHomeroomCourse,
    mustHaveHomeroomRoster,
    useAllOption,
    useNoneOption
  }
]

export const getStaffersByDepartmentIdQueryKey = (departmentId) => [
  'staff',
  { departmentId: parseInt(departmentId) }
]

export const getStaffersIncludeAllQueryKey = () => ['staff', 'include-all']

export const getStudentQueryKey = (studentId) => [
  'students',
  { studentId: parseInt(studentId) }
]

export const getStudentContactsQueryKey = (studentId) => [
  'student-contacts',
  { studentId: parseInt(studentId) }
]

export const getStudentRequestsQueryKey = () => ['student-requests']

export const getStudentsByDateAttendanceQueryKey = ({
  appointmentDate,
  attendanceTypeId,
  periodId
}) => [
  'appointments',
  'students-by-date-attendance',
  {
    appointmentDate,
    attendanceTypeId,
    periodId
  }
]

export const getSubstiuteQueryKey = (userId) => [
  'substitutes',
  { userId: parseInt(userId) }
]

export const getSubstitutesQueryKey = () => ['substitutes']

export const getTimezonesQueryKey = () => ['timezones']
