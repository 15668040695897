import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStaffersByDepartmentIdQueryKey } from '../../../helpers'

export const fetchStaffByDepartmentDetails = async (departmentId) => {
  const response = await axios.get(
    `${endpoints.STAFF}/bydepartment/${departmentId}`,
    {
      withCredentials: true
    }
  )

  return response.data
}

export const useStaffByDepartmentId = (departmentId) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const queryKey = getStaffersByDepartmentIdQueryKey(departmentId)

  return useQuery(queryKey, () => fetchStaffByDepartmentDetails(departmentId), {
    onError: () => {
      queryClient.invalidateQueries(queryKey)

      enqueueSnackbar(
        `We are unable to find this record, it's likely that it has been deleted by another user.`,
        {
          variant: 'error'
        }
      )
    },
    staleTime: 1000 * 30
  })
}
