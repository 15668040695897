import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const StrugglingStudentsSubheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText
        sx={{
          gridColumn: '1/3'
        }}
        text='Date'
      />
      <SubheadingText
        sx={{
          display: 'grid',
          gridColumn: '3/5',
          textAlign: 'center'
        }}
        text='Total Seats All Periods'
      />
      <SubheadingText
        sx={{
          display: 'grid',
          gridColumn: '5/7',
          textAlign: 'center'
        }}
        text='Students Scheduled'
      />
      <SubheadingText
        sx={{
          display: 'grid',
          gridColumn: '7/9',
          textAlign: 'center'
        }}
        text='Final Attendance'
      />
    </SubheadingContainer>
  )
}

export default StrugglingStudentsSubheading
