import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import moment from 'moment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useSearchParams } from 'react-router-dom'

import { esuUrls, localStorageDashboardSettings } from '../../constants'
import { useLoggedInUser } from '../../context'
import { getNearestWeekdayFromDate } from '../../helpers'
import {
  useAppointmentsSummaryForAttendanceQuery,
  useHeaderTitle,
  useNotificationsQuery,
  usePageSettings,
  useSchoolMasterGradesQuery,
  useStrugglingStudentsQuery,
  useUpcomingAdjustedOfferingsQuery
} from '../../hooks'
import { DatePicker, Loading, Tabs, UserManual } from '../shared'

import {
  PageSettingsMenu,
  StrugglingStudentsContainer,
  SummariesContainer,
  UpcomingAdjustedOfferingsContainer
} from '../Dashboard'

const pageSettingsEnums = {
  displayClassicSummaries: 'displayClassicSummaries',
  hideStrugglingStudents: 'hideStrugglingStudents',
  hideUpcomingAdjustedCourses: 'hideUpcomingAdjustedCourses'
}

const DashboardScreen = () => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  useNotificationsQuery()

  useHeaderTitle('Dashboard')
  const { loggedInUserId } = useLoggedInUser()
  let [searchParams, setSearchParams] = useSearchParams()

  const date =
    searchParams.get('date') === null
      ? getNearestWeekdayFromDate()
      : moment(searchParams.get('date')).format()

  const { getParsedPageSetting, togglePageSetting } = usePageSettings({
    initialValues: {
      displayClassicSummaries: true,
      hideStrugglingStudents: false,
      hideUpcomingAdjustedCourses: false
    },
    localStorageKey: localStorageDashboardSettings
  })

  const [selectedTab, setSelectedTab] = useState(0)

  const { data: summaries = [], isLoading: isLoadingSummaries } =
    useAppointmentsSummaryForAttendanceQuery({
      date
    })

  const {
    data: strugglingStudents = [],
    isLoading: isLoadingStrugglingStudents
  } = useStrugglingStudentsQuery()

  const { data: grades = [], isLoading: isLoadingSchoolMasterGrades } =
    useSchoolMasterGradesQuery()

  const {
    data: upcomingAdjustedCourses = [],
    isLoading: isLoadingUpcomingAdjustedOfferings
  } = useUpcomingAdjustedOfferingsQuery({ staffId: loggedInUserId })

  const handleDateChange = (date) => {
    searchParams.set('date', moment(date).format())
    setSearchParams(searchParams)
  }

  const updatePageSetting = (settingName) => {
    togglePageSetting(settingName)
    setSelectedTab(0)
  }

  const setupLabelsForMobile = () => {
    const labels = ['Summaries']

    const settings = [
      {
        setting: pageSettingsEnums.hideStrugglingStudents,
        label: 'My At Risk Students'
      },
      {
        setting: pageSettingsEnums.hideUpcomingAdjustedCourses,
        label: 'Upcoming Adjusted Courses'
      }
    ]

    settings.forEach(({ setting, label }) => {
      if (!getParsedPageSetting(setting)) {
        labels.push(label)
      }
    })

    return labels
  }

  if (
    isLoadingSchoolMasterGrades ||
    isLoadingStrugglingStudents ||
    isLoadingSummaries ||
    isLoadingUpcomingAdjustedOfferings
  )
    return <Loading text='Loading Dashboard Details' />

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2
        }}>
        <UserManual href={esuUrls.DASHBOARD} sx={{ paddingBottom: 0 }} />
        <PageSettingsMenu
          displayClassicSummaries={getParsedPageSetting(
            pageSettingsEnums.displayClassicSummaries
          )}
          hideStrugglingStudents={getParsedPageSetting(
            pageSettingsEnums.hideStrugglingStudents
          )}
          hideUpcomingAdjustedCourses={getParsedPageSetting(
            pageSettingsEnums.hideUpcomingAdjustedCourses
          )}
          toggleCoursesDisplay={() =>
            updatePageSetting(pageSettingsEnums.hideUpcomingAdjustedCourses)
          }
          toggleStudentsDisplay={() =>
            updatePageSetting(pageSettingsEnums.hideStrugglingStudents)
          }
          toggleSummaries={() =>
            updatePageSetting(pageSettingsEnums.displayClassicSummaries)
          }
        />
      </Box>
      <DatePicker
        onChange={(v) => handleDateChange(v)}
        label='Start Date'
        sx={{ marginBottom: '8px' }}
        value={date}
      />
      <Grid container spacing={2}>
        {!isSmUp && (
          <Tabs
            labels={setupLabelsForMobile()}
            scrollable
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
        {(selectedTab === 0 || isSmUp) && (
          <Grid item xs={12}>
            <SummariesContainer
              displayClassicSummaries={getParsedPageSetting(
                pageSettingsEnums.displayClassicSummaries
              )}
              summaries={summaries}
            />
          </Grid>
        )}
        {(selectedTab === 1 || isSmUp) && (
          <>
            {grades.length > 0 && (
              <Grid item xs={12} xl={6}>
                <StrugglingStudentsContainer
                  hideStrugglingStudents={getParsedPageSetting(
                    pageSettingsEnums.hideStrugglingStudents
                  )}
                  grades={grades}
                  strugglingStudents={strugglingStudents}
                />
              </Grid>
            )}
          </>
        )}
        {(selectedTab === 2 || isSmUp) && (
          <Grid item xs={12} xl={6}>
            <UpcomingAdjustedOfferingsContainer
              hideUpcomingAdjustedCourses={getParsedPageSetting(
                pageSettingsEnums.hideUpcomingAdjustedCourses
              )}
              upcomingAdjustedCourses={upcomingAdjustedCourses}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default DashboardScreen
