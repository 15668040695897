import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { LoginScreen, ValidateTokenScreen } from '../components/screens'

const MainUnauthenticated = () => {
  return (
    <main>
      <Routes>
        <Route
          path='/redirect/:token1/:token2'
          element={({
            match: {
              params: { token1, token2 }
            }
          }) => {
            window.location.href = `${process.env.REACT_APP_STUDENT_VALIDATE}${token1}/${token2}`
            return null
          }}
        />
        <Route element={<ValidateTokenScreen />} exact path='/validateToken' />
        <Route element={<LoginScreen />} path='/' />
        <Route element={<LoginScreen />} path='*' />
      </Routes>
    </main>
  )
}

export default MainUnauthenticated
