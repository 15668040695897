import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useAttendanceTypesQuery = ({
  includeAllOption = false,
  includeHasBeenUsed = false,
  includeNotSetOption = false
} = {}) => {
  return useQuery(
    [
      'attendance-types',
      { includeAllOption, includeHasBeenUsed, includeNotSetOption }
    ],
    async () => {
      const urlParams = new URLSearchParams()

      if (includeHasBeenUsed) {
        urlParams.append('includeHasBeenUsed', includeHasBeenUsed)
      }

      const urlParamsString = urlParams.toString()

      const response = await axios.get(
        `${endpoints.ATTENDANCE_TYPES}${
          urlParamsString && `?${urlParamsString}`
        }`,
        {
          withCredentials: true
        }
      )

      response.data.forEach((r) => {
        r.value = r.attendanceTypeId
      })

      if (includeNotSetOption) {
        response.data.unshift({
          description: `(Not Set)`,
          isActive: true,
          value: 0
        })
      }

      if (includeAllOption) {
        response.data.unshift({
          description: `(All)`,
          hasBeenUsed: true,
          value: -10
        })
      }

      response.data.sort(sortByCollatingField(true, 'description'))

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
