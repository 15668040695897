import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useLookUpValuesSelfSchedulingMidnightQuery = () => {
  return useQuery(
    ['look-up-values', 'self-scheduling-midnight-values'],
    async () => {
      const response = await axios.get(
        `${endpoints.LOOK_UP_VALUES}/selfschedulingatmidnight`,
        {
          withCredentials: true
        }
      )

      response.data.forEach((r) => {
        r.description = r.enumDescription
        r.value = r.enumValue
      })

      return response.data
    }
  )
}
