import React from 'react'

import { useLoggedInUser } from '../context'
import logo from '../images/es_logo_icon.png'

import packageJson from '../../package.json'

import NotificationMenu from '../components/Header/NotificationMenu'
import LoggedInUserDetails from './LoggedInUserDetails'

import {
  BrandContainer,
  HideSmallDown,
  InfoContainer,
  LogoContainer,
  PageInfoContainer,
  SmallItalizedText,
  StyledAppBar,
  StyledFlexName,
  StyledHeaderTitle,
  StyledIconButton,
  StyledMenuIcon
} from './styledComponents'

const Header = ({
  headerTitle,
  mobileMenuOpen,
  schools,
  selectedSchool,
  setMobileMenuOpen,
  updateSelectedSchool
}) => {
  const { loggedInUserSchoolBrandName } = useLoggedInUser()

  const tag = 'Powered by Enriching Students'

  return (
    <StyledAppBar position='fixed'>
      <PageInfoContainer>
        <LogoContainer>
          <img alt='Enriching Students' src={logo} />
        </LogoContainer>
        <StyledIconButton
          color='inherit'
          aria-label='Open drawer'
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <StyledMenuIcon />
        </StyledIconButton>
        <InfoContainer>
          <StyledHeaderTitle data-testid='header-title'>
            {headerTitle}
          </StyledHeaderTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HideSmallDown>
              <LoggedInUserDetails
                schools={schools}
                selectedSchool={selectedSchool}
                updateSelectedSchool={updateSelectedSchool}
              />
            </HideSmallDown>
            <NotificationMenu />
            <BrandContainer>
              <StyledFlexName>{loggedInUserSchoolBrandName}</StyledFlexName>
              {loggedInUserSchoolBrandName !== 'Enriching Students' && (
                <SmallItalizedText>{tag}</SmallItalizedText>
              )}
              <SmallItalizedText>v{packageJson?.version}</SmallItalizedText>
            </BrandContainer>
          </div>
        </InfoContainer>
      </PageInfoContainer>
    </StyledAppBar>
  )
}

export default Header
