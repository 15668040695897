import React from 'react'

import {
  AdvancedAppointmentsMain,
  AdvancedAppointmentsProvider
} from '../AdvancedAppointments'

const AdvancedAppointmentsWithProvider = () => {
  return (
    <AdvancedAppointmentsProvider>
      <AdvancedAppointmentsMain />
    </AdvancedAppointmentsProvider>
  )
}

export default AdvancedAppointmentsWithProvider
