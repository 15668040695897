import { useQuery } from 'react-query'
import axios from 'axios'

import { BLOCKED_DATE_TYPES, endpoints } from '../../../constants'
import {
  getBlockedDatesByStaffQueryKey,
  sortByCollatingField
} from '../../../helpers'

import { blockedDatesBy } from '../enums'

export const useBlockedDatesByStafferQuery = ({
  month,
  selectedTab,
  staffId
}) => {
  return useQuery(
    getBlockedDatesByStaffQueryKey({ month, selectedTab, staffId }),
    async () => {
      const response = await axios.get(
        `${endpoints.BLOCKED_DATES}/${BLOCKED_DATE_TYPES.byStaff}/${month}/${staffId}`,
        { withCredentials: true }
      )

      response.data.forEach((item) => (item.blockType = 'Staff'))

      return response.data.sort(sortByCollatingField(true, 'scheduleDate'))
    },
    {
      enabled: parseInt(selectedTab) === blockedDatesBy.staff,
      staleTime: 1000 * 30
    }
  )
}
