import { Typography } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

const SearchDetails = ({ stafferDetails }) => {
  let [searchParams] = useSearchParams()

  const searchKey = searchParams.get('searchKey')
  const searchValue = searchParams.get('searchValue')

  return (
    <>
      {!!searchKey && searchKey !== 'searchTerm' && (
        <Typography paragraph>
          Showing Students by{' '}
          {searchKey === 'selectedGraduationYear'
            ? `Graduation Year: ${searchValue === '0' ? 'All' : searchValue}`
            : searchKey === 'selectedLastName'
            ? `Last Name: ${searchValue}`
            : `Staffer: ${
                stafferDetails === undefined ? null : stafferDetails.description
              }`}
        </Typography>
      )}
    </>
  )
}

export default SearchDetails
