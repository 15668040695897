import { useState } from 'react'
import { BLOCKED_DATE_TYPES } from '../../../constants'

export const useSelectedBlockOption = () => {
  const [selectedBlockOption, setSelectedBlockOption] = useState(
    BLOCKED_DATE_TYPES.byStaff.toString()
  )

  const updateSelectedBlockOptionValue = (e) => {
    setSelectedBlockOption(e.target.value)
  }

  return { selectedBlockOption, updateSelectedBlockOptionValue }
}
