import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { useSnackbar } from 'notistack'
import { getEmailTypesQueryKey } from '../../../helpers'

export const useManageEmailTemplateSaveMutation = ({
  onSuccess = () => {}
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (emailTemplates) => {
      await axios.post(
        `${endpoints.MANAGE_EMAIL_TEMPLATE_ADMIN}/save`,
        emailTemplates,
        { withCredentials: true }
      )

      return
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Changes have been saved', {
          variant: 'success'
        })

        // Invalidate list of emails on the MyAccounts page
        queryClient.invalidateQueries(getEmailTypesQueryKey())

        onSuccess()
      }
    }
  )
}
