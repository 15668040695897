import React, { useState } from 'react'

import { esuUrls } from '../../constants'
import {
  useAppointmentRequestsMarkListCancelledMutation,
  useAppointmentRequestsQuery,
  useHeaderTitle
} from '../../hooks'
import {
  Button,
  DeleteModal,
  Loading,
  List,
  PageInstructions,
  Paper,
  PaperTitle,
  Tabs,
  UserManual
} from '../shared'

import { FetchingIndicator, RequestItem, Subheading } from '../StudentRequests'

const StudentRequests = () => {
  useHeaderTitle('Student Requests')

  const [isCancellingMultipleRequests, setIsCancellingMultipleRequests] =
    useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [
    selectedAppointmentRequestIdsToCancel,
    setSelectedAppointmentRequestIdsToCancel
  ] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)

  const {
    data: appointmentRequests = [],
    isFetching: isFetchingAppointmentRequests,
    isLoading: isLoadingAppointmentRequests
  } = useAppointmentRequestsQuery()

  const activeRequests = appointmentRequests.filter(
    (request) => !request.isCancelled && !request.isScheduled
  )
  const historicalRequests = appointmentRequests.filter(
    (request) => request.isCancelled || request.isScheduled
  )

  const { isLoading: isCancellingRequests, mutate: markRequestsAsCancelled } =
    useAppointmentRequestsMarkListCancelledMutation({
      onSuccess: () => setSelectedAppointmentRequestIdsToCancel([])
    })

  const handleSelectAllChange = (e) => {
    const { checked } = e.target

    if (checked) {
      setSelectAll(true)
      setSelectedAppointmentRequestIdsToCancel(
        activeRequests.map((request) => request.appointmentRequestId)
      )
    } else {
      setSelectAll(false)
      setSelectedAppointmentRequestIdsToCancel([])
    }
  }

  const updateSelectedAppointmentRequestIdsToCancel = ({
    appointmentRequestId,
    checked
  }) => {
    if (checked) {
      setSelectedAppointmentRequestIdsToCancel((prevState) => [
        ...prevState,
        appointmentRequestId
      ])
    } else {
      setSelectedAppointmentRequestIdsToCancel((prevState) =>
        prevState.filter((id) => id !== appointmentRequestId)
      )
    }
  }

  if (isLoadingAppointmentRequests) return <Loading text='Loading Requests' />
  if (isCancellingRequests)
    return <Loading text={'Cancelling Appointment Requests'} />

  return (
    <div>
      <FetchingIndicator isFetching={isFetchingAppointmentRequests} />
      <UserManual href={esuUrls.STUDENT_REQUESTS} />
      <PageInstructions>
        Use this page to view appointment requests that students have made. Use
        the 'Actions' button to schedule, complete, or cancel the requests.
      </PageInstructions>
      <Button
        esButtonClass='greyButton'
        label='Cancel Requests'
        marginRight
        onClick={() =>
          setIsCancellingMultipleRequests(!isCancellingMultipleRequests)
        }
      />
      {isCancellingMultipleRequests &&
        selectedAppointmentRequestIdsToCancel.length > 0 && (
          <DeleteModal
            confirmDeleteCallback={() =>
              markRequestsAsCancelled({
                appointmentRequestIds: selectedAppointmentRequestIdsToCancel
              })
            }
            confirmDeleteLabel='Delete Requests'
            confirmDeleteMessage={`${selectedAppointmentRequestIdsToCancel.length} Appointment Requests`}
            label='Delete'
            useButton
          />
        )}
      <Tabs
        labels={['Unscheduled Requests', 'Scheduled Requests']}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        scrollable
      />
      <Paper>
        <PaperTitle>Requests</PaperTitle>
        <Subheading
          handleSelectAllChange={handleSelectAllChange}
          isCancellingMultipleRequests={isCancellingMultipleRequests}
          selectAll={selectAll}
          selectedTab={selectedTab}
        />
        <List
          Component={RequestItem}
          data={selectedTab === 0 ? activeRequests : historicalRequests}
          emptyArrayMessage={
            selectedTab === 0
              ? 'There are no Unscheduled Requests'
              : 'There are no Scheduled Requests'
          }
          isCancellingMultipleRequests={isCancellingMultipleRequests}
          keyValue='appointmentRequestId'
          selectedAppointmentRequestIdsToCancel={
            selectedAppointmentRequestIdsToCancel
          }
          selectedTab={selectedTab}
          updateSelectedAppointmentRequestIdsToCancel={
            updateSelectedAppointmentRequestIdsToCancel
          }
        />
      </Paper>
    </div>
  )
}

export default StudentRequests
