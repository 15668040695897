import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Drawer, MenuItem } from '@mui/material'

import Button from './Button'

const EsDrawer = forwardRef(
  ({ anchor, children, label, onClick, useMenuItem }, ref) => {
    const [state, setState] = useState({
      top: false,
      left: false,
      bottom: false,
      right: false
    })

    const toggleDrawer = (anchor, open) => (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return
      }

      // only run the onClick
      if (open && onClick) {
        onClick()
      }

      setState({
        ...state,
        [anchor]: open
      })
    }

    // controls what gets exposed to the parents via the ref
    useImperativeHandle(ref, () => ({
      closeDrawerFromParent() {
        setState({
          ...state,
          top: false,
          left: false,
          bottom: false,
          right: false
        })
      }
    }))

    return (
      <>
        {useMenuItem ? (
          <MenuItem onClick={toggleDrawer(anchor, true)}>{label}</MenuItem>
        ) : (
          <Button
            sx={{ marginRight: 1 }}
            esButtonClass='greyButton'
            label={label}
            onClick={toggleDrawer(anchor, true)}
          />
        )}
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}>
          {children}
        </Drawer>
      </>
    )
  }
)

export default EsDrawer
