import React, { useRef, useState } from 'react'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { sortByCollatingField } from '../../helpers'
import { useRostersSaveMutation } from '../../hooks'
import { Button, Checkbox, Drawer, PaperTitle, TextField } from '../shared'

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))

const Container = styled('div')(() => ({
  minWidth: 250
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  padding: theme.spacing(2)
}))

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 16px)'
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
  width: 250
}))

const StudentItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

const CreateRosterDrawer = ({
  attendanceDetails,
  periodDescription,
  rosters,
  stafferId
}) => {
  const [state, setState] = useState({
    rosterTitle: '',
    selectedRoster: '',
    students: attendanceDetails.sort(sortByCollatingField(true, 'lastName'))
  })

  const createRosterMenuRef = useRef()

  const { isLoading: isSavingRoster, mutate } = useRostersSaveMutation({
    resetData: () => {
      setState({
        rosterTitle: '',
        selectedRoster: '',
        students: attendanceDetails.sort(sortByCollatingField(true, 'lastName'))
      })

      createRosterMenuRef.current.closeDrawerFromParent()
    },
    staffId: stafferId
  })

  const handleCheckboxChange = (appointmentId) => {
    setState({
      ...state,
      students: state.students.map((detail) => {
        if (detail.appointmentId !== appointmentId) return detail
        return { ...detail, isSelected: !detail.isSelected }
      })
    })
  }

  const handleRosterChange = (e) => {
    setState({
      ...state,
      selectedRoster: e.target.value
    })
  }

  const handleRosterTitleChange = (e) => {
    setState({ ...state, rosterTitle: e.target.value })
  }

  const saveRoster = async () => {
    const selectedRoster = rosters.find(
      (roster) => roster.rosterId === parseInt(state.selectedRoster)
    )

    let studentIds = []

    state.students.forEach((student) => {
      if (student.isSelected) {
        studentIds.push(student.userId)
      }
    })

    mutate({
      values: {
        addOnly: true,
        isDefaultRoster:
          state.selectedRoster === 0 ? false : selectedRoster.isDefault,
        rosterId: state.selectedRoster,
        rosterName:
          state.selectedRoster === 0
            ? state.rosterTitle
            : selectedRoster.rosterName,
        stafferId,
        studentIds
      }
    })
  }

  return (
    <Drawer anchor='right' label='Create Group' ref={createRosterMenuRef}>
      <Container>
        {isSavingRoster ? (
          <LoadingContainer>
            <CircularProgress />
            <Typography>Saving Group</Typography>
          </LoadingContainer>
        ) : (
          <Content>
            <PaperTitle>Create Group from {periodDescription}</PaperTitle>
            <StyledFormControl variant='standard'>
              <InputLabel htmlFor='grouped-select' id='select-group'>
                Select a Group
              </InputLabel>
              <Select
                id='grouped-select'
                labelId='select-group'
                onChange={handleRosterChange}
                value={state.selectedRoster}>
                <MenuItem value={0}>
                  <em>Create New Group</em>
                </MenuItem>
                <ListSubheader
                  sx={{
                    color: 'primary.main'
                  }}>
                  OR ADD TO
                </ListSubheader>
                {rosters.map((roster) => (
                  <MenuItem key={roster.rosterId} value={roster.rosterId}>
                    {roster.rosterName}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            {state.selectedRoster === 0 && (
              <TextField
                id='new-group-title'
                label='Group Title'
                onChange={handleRosterTitleChange}
                value={state.rosterTitle}
              />
            )}
            {state.students.length > 0 &&
              state.students.map((detail) => (
                <StudentItem key={detail.appointmentId}>
                  <Typography>
                    {detail.lastName},{' '}
                    {detail.nickname === null || detail.nickname === ''
                      ? detail.firstName
                      : detail.nickname}
                  </Typography>
                  <Checkbox
                    checked={detail.isSelected}
                    onChange={() => handleCheckboxChange(detail.appointmentId)}
                  />
                </StudentItem>
              ))}
            <ButtonsContainer>
              <Button
                esButtonClass='cancelButton'
                label='Cancel'
                marginRight
                onClick={() =>
                  createRosterMenuRef.current.closeDrawerFromParent()
                }
              />
              <Button
                disabled={
                  (state.selectedRoster === '' || state.selectedRoster === 0) &&
                  state.rosterTitle === ''
                }
                label='Save'
                marginRight
                onClick={saveRoster}
              />
            </ButtonsContainer>
          </Content>
        )}
      </Container>
    </Drawer>
  )
}

export default CreateRosterDrawer
