import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '../Button'
import FlexColumn from '../FlexColumn'
import LabeledCheckbox from '../LabeledCheckbox'
import ListHeading from './ListHeading'
import RadioGroup from '../RadioGroup'
import ReactHookFormSelect from '../ReactHookFormSelect'
import SelectField from '../SelectField'
import Switch from '../Switch'
import TextArea from '../TextArea'

const ScheduleForm = ({
  appointmentTypes,
  courses,
  departments,
  formState,
  numberOfStudentsToSchedule,
  scheduleStudents
}) => {
  const [autoIncrease, setAutoIncrease] = useState(false)
  const [comment, setComment] = useState(formState.comment)
  const [hideHomeroomCourses, setHideHomeroomCourses] = useState(false)
  const [hideZeroSeatCountCourses, setHideZeroSeatCountCourses] =
    useState(false)
  const [scheduleToStaffer, setScheduleToStaffer] = useState('true')
  const [selectedDepartment, setSelectedDepartment] = useState('')

  const { control, errors, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      selectedAppointmentType: formState.selectedAppointmentType,
      selectedCourse: formState.selectedCourse,
      selectedStaffer: formState.selectedStaffer
    }
  })

  const watchSelectedCourse = watch('selectedCourse')

  const selectedCourseInfo = courses.find(
    (course) => course.value === watchSelectedCourse
  )

  useEffect(() => {
    if (selectedCourseInfo !== undefined) {
      setValue(
        'selectedAppointmentType',
        selectedCourseInfo.defaultAppointmentTypeId
      )
    }
  }, [selectedCourseInfo, setValue])

  const disableSave =
    numberOfStudentsToSchedule < 1 ||
    (selectedCourseInfo &&
      selectedCourseInfo.availableSeats === 0 &&
      !autoIncrease) ||
    (selectedCourseInfo &&
      numberOfStudentsToSchedule > selectedCourseInfo.availableSeats &&
      !autoIncrease)
      ? true
      : false

  const onSubmit = (values) => {
    const object = {
      autoIncrease,
      comment,
      ...values
    }

    scheduleStudents(object)
  }

  const setupFilteredCourses = () => {
    let filteredCourses = courses

    if (selectedDepartment === '') {
      filteredCourses = []
    }

    if (hideHomeroomCourses) {
      filteredCourses = filteredCourses.filter((course) => !course.isHomeroom)
    }

    if (hideZeroSeatCountCourses) {
      filteredCourses = filteredCourses.filter(
        (course) => course.availableSeats > 0
      )
    }

    filteredCourses = filteredCourses.filter(
      (course) => course.departmentId === selectedDepartment
    )

    return filteredCourses
  }

  const shouldRenderStafferAppointmentOptions = scheduleToStaffer === 'true'

  return (
    <Grid item xs={12} sm={4} lg={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexColumn>
          <ListHeading text='Schedule Appointment' />
          <Switch
            checked={hideHomeroomCourses}
            onChange={() => setHideHomeroomCourses(!hideHomeroomCourses)}
            label='Hide Homebase Courses'
            name='hideHomeroomCourses'
            color='primary'
          />
          <Switch
            checked={hideZeroSeatCountCourses}
            label='Hide Courses With No Available Seats'
            onChange={() =>
              setHideZeroSeatCountCourses(!hideZeroSeatCountCourses)
            }
            name='hideZeroSeatCountCourses'
            color='primary'
          />
          <TextArea
            onChange={(e) => setComment(e.target.value)}
            placeholder='Optional comment...'
            value={comment}
          />
          <RadioGroup
            data={[
              {
                label: 'Schedule to Staffer',
                value: 'true'
              },
              {
                label: 'Schedule to Homebase',
                value: 'false'
              }
            ]}
            name='schedule-options'
            onChange={(e) => setScheduleToStaffer(e.target.value)}
            selectedValue={scheduleToStaffer}
          />
          {shouldRenderStafferAppointmentOptions && (
            <>
              <SelectField
                labelId='selectedDepartment'
                label='Department'
                menuItems={departments}
                name='selectedDepartment'
                onChange={(e) => setSelectedDepartment(e.target.value)}
                selectedValue={selectedDepartment}
              />
              <ReactHookFormSelect
                control={control}
                errors={errors.selectedCourse}
                id='selectedCourse'
                label='Schedule To'
                menuItems={setupFilteredCourses()}
                name='selectedCourse'
                rules={{
                  required: 'Please select a Course'
                }}
              />
              <ReactHookFormSelect
                control={control}
                disabled={
                  selectedCourseInfo !== undefined &&
                  selectedCourseInfo.mustUseDefaultAppointmentType
                }
                errors={errors.selectedAppointmentType}
                id='selectedAppointmentType'
                label='Appointment Type'
                menuItems={appointmentTypes}
                name='selectedAppointmentType'
                rules={{
                  required: 'Please select an Appointment Type'
                }}
              />
              {selectedCourseInfo !== undefined &&
                selectedCourseInfo.mustUseDefaultAppointmentType && (
                  <Typography>
                    The selected course's appointment type cannot be changed.
                  </Typography>
                )}
              {numberOfStudentsToSchedule > 0 && disableSave && (
                <Typography>
                  The selected course does not have enough seats to support the
                  number of selected students.
                </Typography>
              )}
            </>
          )}
          <LabeledCheckbox
            checked={autoIncrease}
            label='Auto Increase Seats'
            onChange={() => setAutoIncrease(!autoIncrease)}
          />
          <Button
            color='secondary'
            disabled={disableSave}
            label='Save'
            marginTop
            type='submit'
          />
        </FlexColumn>
      </form>
    </Grid>
  )
}

export default ScheduleForm
