import { styled } from '@mui/material/styles'

const CourseBlockedOrOptions = styled('div', {
  shouldForwardProp: (prop) => prop !== 'courseBlocked'
})(({ courseBlocked, theme }) => ({
  display: 'flex',
  flexDirection: courseBlocked && 'column',
  flex: courseBlocked && 1,
  justifyContent: courseBlocked ? 'center' : 'space-between',
  alignItems: 'center'
}))

export default CourseBlockedOrOptions
