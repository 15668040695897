import React from 'react'
import { Fab } from '@mui/material'
import { Save } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import EsButton from './Button'
import { useIsXsBreakpoint } from '../../hooks'

export const FabContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2)
}))

const ResponsiveFabButton = ({ disabled, onClick }) => {
  const isXs = useIsXsBreakpoint()

  return (
    <>
      {isXs ? (
        <FabContainer>
          <Fab
            color='secondary'
            disabled={disabled}
            label='Save'
            onClick={onClick}>
            <Save />
          </Fab>
        </FabContainer>
      ) : (
        <EsButton disabled={disabled} label='Save' onClick={onClick} />
      )}
    </>
  )
}

export default ResponsiveFabButton
