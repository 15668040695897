import React, { forwardRef } from 'react'
import { CircularProgress, Drawer, MenuItem, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import {
  Button,
  List,
  PaperTitle,
  SubheadingContainer,
  SubheadingText
} from '../shared'

import DrawerItem from './DrawerItem'
import { useStaffByDeptDetailQuery } from './hooks/useStaffByDeptDetailQuery'
import useOpen from '../../hooks/useOpen'

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))

const DrawerContent = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  padding: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    width: '50vw'
  },

  [theme.breakpoints.up('md')]: {
    width: '25vw'
  }
}))

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',

  height: '100%'
}))

const StaffDrawer = forwardRef(
  (
    {
      blockedDateDetailId,
      blockedReason,
      closeParentMenu,
      departmentId,
      scheduleDate
    },
    ref
  ) => {
    const { open, handleClose, handleOpen, toggleOpen } = useOpen()

    const {
      staffers = [],
      isLoading,
      isSaving,
      save,
      updatedStaffers,
      updateStaffBlock
    } = useStaffByDeptDetailQuery({
      blockedDateDetailId,
      departmentId,
      enabled: open,
      resetAfterSave: () => {
        handleClose()
        closeParentMenu()
      }
    })

    const toggleDrawer = () => (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return
      }

      toggleOpen()
    }

    return (
      <div>
        <MenuItem onClick={handleOpen}>Adjust Blocked Staffers</MenuItem>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer()}>
          <DrawerContent>
            {isLoading || isSaving ? (
              <LoadingContainer data-testid='loading-component'>
                <CircularProgress />
                <Typography>
                  {isLoading
                    ? 'Loading Staff Details'
                    : 'Saving Staffer Adjustments'}
                </Typography>
              </LoadingContainer>
            ) : (
              <>
                <PaperTitle sx={{ borderBottom: '1px solid #d9d9d9' }}>
                  {blockedReason} - {scheduleDate}
                </PaperTitle>
                <SubheadingContainer>
                  <SubheadingText sx={{ gridColumn: '1/7' }} text='Staffer' />
                  <SubheadingText
                    sx={{ gridColumn: '7/13', textAlign: 'center' }}
                    text='Blocked'
                  />
                </SubheadingContainer>
                <List
                  Component={DrawerItem}
                  data={staffers}
                  keyValue='userId'
                  updateStaffBlock={updateStaffBlock}
                />
                <ButtonsContainer>
                  <Button
                    esButtonClass='cancelButton'
                    disableElevation
                    label='Cancel'
                    onClick={toggleDrawer()}
                  />
                  <Button
                    disabled={updatedStaffers.length > 0 ? false : true}
                    label='Save'
                    onClick={save}
                  />
                </ButtonsContainer>
              </>
            )}
          </DrawerContent>
        </Drawer>
      </div>
    )
  }
)

export default StaffDrawer
