import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useStudentsProfileQuery = ({ enabled = true, studentId }) => {
  return useQuery(
    ['students', 'profile', { studentId: parseInt(studentId) }],
    async () => {
      const response = await axios.get(
        `${endpoints.STUDENTS}/profile/${studentId}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      enabled,
      staleTime: 1000 * 60
    }
  )
}
