import React from 'react'
import { MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Menu, MenuItemLink } from '../shared'

export const MenuContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideXsDown'
})(({ hideXsDown, theme }) => ({
  display: hideXsDown ? 'none' : 'block',

  gridColumn: '1/13',

  [theme.breakpoints.up('sm')]: {
    display: !hideXsDown ? 'none' : 'block',
    gridColumn: '2/3',
    gridRow: 1,
    textAlign: 'start'
  }
}))

const ControlsActionsMenu = ({
  emailStudentSchedule,
  hideXsDown,
  isSubstitute
}) => {
  return (
    <MenuContainer hideXsDown={hideXsDown}>
      <Menu>
        {isSubstitute ? null : (
          <MenuItemLink
            state={{
              cameFromAppointmentsPage: true
            }}
            text='Delete Multiple Appointments'
            to='/deleteMultipleAppointments'
          />
        )}
        {isSubstitute ? null : (
          <MenuItemLink text='Homebase Report' to='/analysis/4' />
        )}
        <MenuItem onClick={() => emailStudentSchedule()}>
          Send Email Notifications
        </MenuItem>
      </Menu>
    </MenuContainer>
  )
}

export default ControlsActionsMenu
