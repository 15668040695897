import React from 'react'
import {
  AccountCircle,
  Dashboard,
  DateRange,
  EventAvailable,
  EventBusy,
  Group,
  Laptop,
  NotificationImportant,
  PermContactCalendar,
  School
} from '@mui/icons-material'
import moment from 'moment'

export const adminLinks = [
  {
    label: 'Configuration',
    to: '/admin/configuration'
  },
  {
    label: 'Email Logs',
    to: '/admin/emailLogs'
  },
  {
    hideOnMobile: true,
    label: 'Export Data',
    to: '/admin/exportData'
  },
  {
    label: 'Manage Appointment Types',
    to: '/admin/manageAppointmentTypes'
  },
  {
    label: 'Manage Appointments',
    to: '/admin/manageAppointments/0/0/0'
  },
  {
    label: 'Manage Attendance Types',
    to: '/admin/manageAttendanceTypes'
  },
  {
    label: 'Manage Day Types',
    to: '/admin/manageDayTypes'
  },
  {
    label: 'Manage Departments',
    to: '/admin/manageDepartments'
  },
  {
    label: 'Manage Grades',
    to: '/admin/orderGrades'
  },
  {
    label: 'Manage Email Notifications',
    to: '/admin/manageEmailNotifications'
  },
  {
    label: 'Manage Homebase Groups',
    to: '/admin/manageHomebaseGroups'
  },
  {
    label: 'Manage Invalid Emails',
    to: '/admin/manageInvalidEmails'
  },
  {
    label: 'Manage Messages',
    to: '/admin/manageMessages'
  },
  {
    label: 'Manage Periods',
    to: '/admin/managePeriods'
  },
  {
    label: 'Manage Reports',
    to: '/admin/manageReports'
  },
  {
    label: 'Manage Soft Deleted Users',
    to: '/admin/manageSoftDeletedUsers'
  },
  {
    label: 'Manage Staff',
    to: '/admin/manageStaff'
  },
  {
    label: 'Manage Student Attendance',
    to: '/admin/manageStudentAttendance'
  },
  {
    label: 'Manage Substitutes',
    to: '/admin/manageSubstitutes'
  },
  /* {
    label: 'Students Blocked For Students',
    to: '/admin/studentsBlockedForStudents'
  }, */
  {
    label: 'Unscheduled Students',
    to: '/admin/unscheduledStudents'
  }
]

export const stafferLinks = [
  {
    label: 'Dashboard',
    icon: <Dashboard />,
    to: '/'
  },
  {
    label: 'Appointments',
    icon: <EventAvailable />,
    to: '/appointments'
  },
  {
    label: 'Students',
    icon: <School />,
    to: '/students'
  },
  {
    label: 'Manage Student Groups',
    icon: <Group />,
    to: '/manageRosters'
  },
  {
    label: 'Adjusted Offerings',
    icon: <Laptop />,
    to: '/adjustedOfferings/0'
  },
  {
    label: 'Blocked Dates',
    icon: <EventBusy />,
    to: '/blockDate'
  },
  /* {
    label: 'Block Students',
    icon: <EventBusy />,
    to: '/blockStudents'
  }, */
  {
    label: 'Requests',
    icon: <PermContactCalendar />,
    to: '/studentRequests'
  },
  {
    icon: <DateRange />,
    label: 'Mass Appointments',
    /* Date/StaffId/RosterId/PeriodId */
    to: `/massAppointments/${moment().format('YYYY-MM-DD')}/0/0/0`
  },
  {
    icon: <NotificationImportant />,
    label: 'School Notifications',
    to: '/schoolNotifications'
  },
  {
    label: 'My Account',
    icon: <AccountCircle />,
    to: '/myAccount'
  }
]

export const subLinks = [
  {
    label: 'Dashboard',
    icon: <Dashboard />,
    to: '/'
  },
  {
    label: 'Appointments',
    icon: <EventAvailable />,
    to: '/appointments'
  },
  {
    label: 'My Account',
    icon: <AccountCircle />,
    to: '/myAccount'
  }
]
