import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getSchoolMasterGradesQueryKey } from '../../../helpers'

export const useSchoolMasterGradesSaveMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ gradesToSave }) => {
      let array = []

      gradesToSave.forEach((grade, index) => {
        array.push({
          gradeValue: grade.gradeValue,
          isCutOff: grade.isCutOff,
          schoolGradeMasterId: grade.schoolGradeMasterId,
          sortOrder: index
        })
      })

      await axios.post(`${endpoints.SCHOOL_MASTER_GRADES}/save`, array, {
        withCredentials: true
      })

      return { grades: array }
    },
    {
      onSuccess: ({ grades }) => {
        queryClient.setQueryData(getSchoolMasterGradesQueryKey(), () => {
          return grades
        })
      }
    }
  )
}
