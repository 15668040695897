import { ListItemContainer, ListItemText, TextField } from '../shared'

const HomeroomItem = ({
  firstName,
  homeRoom,
  isLastItem,
  lastName,
  updateHomeroom,
  userId
}) => {
  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', md: '1/4' } }}
        labelText='Name'>
        {lastName}, {firstName}
      </ListItemText>
      <TextField
        sx={{ gridColumn: { xs: '1/13', md: '4/7' } }}
        dataTestId='homeroom-input'
        onChange={(e) => updateHomeroom(e, userId)}
        value={homeRoom}
      />
    </ListItemContainer>
  )
}

export default HomeroomItem
