import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Chat, Forum, Lock } from '@mui/icons-material'
import moment from 'moment'

import { BLOCKED_DATE_TYPES } from '../../constants'
import { getStudentDisplayName } from '../../helpers'
import { Popover } from '../shared'

import AppointmentDetailInfo from './AppointmentDetailInfo'
import AppointmentDetailMenu from './AppointmentDetailMenu'
import QuickSchedule from './QuickSchedule'
import RequestsMenu from './RequestsMenu'

import { useAppointments } from './AppointmentsContext'
import {
  CourseBlockedOrOptions,
  CourseItemContainer,
  CourseItemDate
} from './styledComponents'

const MemoizedDetail = React.memo(function Detail({
  /* DTO Props */
  adjustedByFirstName,
  adjustedById,
  adjustedByLastName,
  appointmentDate,
  appointmentId,
  appointmentRequestIds,
  appointmentTypeDescription,
  blockedDateType,
  blockedReason,
  courseId,
  courseName,
  dayTypeDescription,
  instructorFirstName,
  instructorId,
  instructorLastName,
  instructorRoom,
  isHomeroomCourse,
  isLocked,
  processThatModified,
  schedulerComment,
  studentId,

  /* additional props */
  isLastItem,
  firstName,
  lastName,
  nickName,
  useMinifiedView,
  userId
}) {
  const { isAdmin, loggedInUserId, openSchedulePhrase } = useAppointments()

  const [quickScheduleOpen, setQuickScheduleOpen] = useState(false)
  const [requestsOpen, setRequestsOpen] = useState(false)

  const displayName = getStudentDisplayName(firstName, lastName, nickName)

  const canUserOverwriteLockedAppointment =
    isAdmin ||
    instructorId === loggedInUserId ||
    adjustedById === loggedInUserId
      ? true
      : false

  const blockedDateLabel =
    blockedDateType === BLOCKED_DATE_TYPES.notBlocked
      ? null
      : blockedDateType === BLOCKED_DATE_TYPES.bySchool
      ? 'Date Blocked For School'
      : blockedDateType === BLOCKED_DATE_TYPES.byDepartment
      ? 'Date Blocked For Department'
      : 'Date Blocked For Staffer'

  const appointmentExistsBecauseOfPrebooking = 5
  const scheduledByDescription =
    processThatModified === appointmentExistsBecauseOfPrebooking
      ? 'Nightly Import'
      : `${adjustedByLastName}, ${adjustedByFirstName}`

  return (
    <CourseItemContainer isLastItem={isLastItem}>
      <div>
        <CourseItemDate>
          {moment(appointmentDate).format('dddd - MMM Do')}
        </CourseItemDate>
        {dayTypeDescription && (
          <CourseItemDate>Day Type: {dayTypeDescription}</CourseItemDate>
        )}
        <Typography
          sx={{
            color: 'primary.main',
            fontSize: 16,
            textAlign: 'center',
            marginBottom: 1,
            wordBreak: 'break-all'
          }}>
          {courseName ? courseName : openSchedulePhrase}
        </Typography>
        {blockedDateLabel !== null && (
          <CourseBlockedOrOptions courseBlocked={true}>
            <AppointmentDetailInfo
              description={blockedReason}
              label={blockedDateLabel}
            />
          </CourseBlockedOrOptions>
        )}
        {blockedDateType !== BLOCKED_DATE_TYPES.bySchool && (
          <div>
            <CourseBlockedOrOptions courseBlocked={courseId === 0}>
              {appointmentRequestIds.length > 0 && courseId === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginBottom: 1
                  }}>
                  <Forum
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    data-testid='appointment-requests'
                    onClick={() => setRequestsOpen(true)}
                  />
                </Box>
              )}
              {!isLocked || canUserOverwriteLockedAppointment ? (
                <AppointmentDetailMenu
                  adjustedById={adjustedById}
                  appointmentDate={appointmentDate}
                  appointmentId={appointmentId}
                  courseId={courseId}
                  courseName={courseName}
                  dateIsBlockedForLoggedInUser={
                    blockedDateType === BLOCKED_DATE_TYPES.byDepartment ||
                    blockedDateType === BLOCKED_DATE_TYPES.byStaff
                      ? true
                      : false
                  }
                  firstName={firstName}
                  instructorId={instructorId}
                  isHomeroomCourse={isHomeroomCourse}
                  isLocked={isLocked}
                  lastName={lastName}
                  quickScheduleOpen={quickScheduleOpen}
                  setQuickScheduleOpen={setQuickScheduleOpen}
                  studentDisplayName={displayName}
                  studentId={studentId}
                  userId={userId}
                />
              ) : null}
              {quickScheduleOpen && (
                <QuickSchedule
                  appointmentId={appointmentId}
                  courseId={courseId}
                  courseName={courseName}
                  firstName={firstName}
                  lastName={lastName}
                  nickName={nickName}
                  open={quickScheduleOpen}
                  selectedDate={appointmentDate}
                  setOpen={setQuickScheduleOpen}
                  studentId={studentId}
                  userId={userId}
                />
              )}
              {requestsOpen && (
                <RequestsMenu
                  appointmentRequestIds={appointmentRequestIds}
                  open={requestsOpen}
                  selectedDate={appointmentDate}
                  setOpen={setRequestsOpen}
                  studentName={displayName}
                />
              )}
              <span style={{ display: 'flex' }}>
                {schedulerComment && courseId !== 0 && (
                  <Popover sx={{ minWidth: 20 }} content={schedulerComment}>
                    <Chat />
                  </Popover>
                )}
                {isLocked && courseId !== 0 && (
                  <Popover
                    sx={{
                      minWidth: 20,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: 0,
                      margin: 0,
                      '&:hover': {
                        background: '#fff'
                      }
                    }}
                    content='This appointment is locked'>
                    <Lock />
                  </Popover>
                )}
              </span>
            </CourseBlockedOrOptions>
            {!useMinifiedView && courseId !== 0 && (
              <div>
                <AppointmentDetailInfo
                  description={`${instructorLastName},
                          ${instructorFirstName} /
                          ${instructorRoom}`}
                  label='Instructor / Room'
                />
                <AppointmentDetailInfo
                  description={scheduledByDescription}
                  //description={`${adjustedByLastName},
                  //        ${adjustedByFirstName}`}
                  label='Scheduler'
                />
                <AppointmentDetailInfo
                  description={appointmentTypeDescription}
                  label='Appointment Type'
                />
              </div>
            )}
          </div>
        )}
      </div>
    </CourseItemContainer>
  )
})

export default MemoizedDetail
