import React from 'react'

import { Checkbox, SubheadingContainer, SubheadingText } from '../shared'

const Subheading = ({
  bulkEditing,
  handleSelectAllChange,
  lastSortedBy,
  onClick,
  selectAll,
  sortAscending
}) => {
  return (
    <SubheadingContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gridColumn: { lg: '1/3' }
        }}>
        {bulkEditing && (
          <Checkbox
            ariaLabel='selectAllAdjustedOfferings'
            checked={selectAll}
            onChange={(e) => handleSelectAllChange(e.target.checked)}
          />
        )}
        <SubheadingText
          ascValue={sortAscending}
          isSortable
          isSelected={lastSortedBy === 'scheduleDate'}
          onClick={() => onClick('scheduleDate')}
          text='Date'
        />
      </div>
      <SubheadingText
        ascValue={sortAscending}
        isSortable
        isSelected={lastSortedBy === 'periodId'}
        onClick={() => onClick('periodId')}
        sx={{
          gridColumn: { lg: '3/5' }
        }}
        text='Period'
      />
      <SubheadingText
        sx={{
          gridColumn: { lg: '5/12' }
        }}
        text='Details'
      />
    </SubheadingContainer>
  )
}

export default Subheading
