import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useGradesByStudentWithHistoryQuery = ({
  enabled = true,
  selectedNumberOfDays,
  studentId
}) => {
  return useQuery(
    [
      'grades',
      'by-student-with-history',
      {
        selectedNumberOfDays: parseInt(selectedNumberOfDays),
        studentId: parseInt(studentId)
      }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.GRADES}/bystudentwithhistory/${studentId}/${selectedNumberOfDays}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      enabled,
      staleTime: 1000 * 60
    }
  )
}
