import { styled } from '@mui/material/styles'

const MultiActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'auto',

  marginBottom: theme.spacing(),

  borderRadius: 4,
  boxShadow: theme.shadows[1],
  background: '#d9d9d9'
}))

export default MultiActionsContainer
