import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getManageEmailTemplateAdminListQueryKey,
  sortByCollatingField
} from '../../../helpers'

export const useManageEmailTemplateAdminListQuery = () => {
  return useQuery(getManageEmailTemplateAdminListQueryKey(), async () => {
    const response = await axios.get(
      `${endpoints.MANAGE_EMAIL_TEMPLATE_ADMIN}/list`,
      { withCredentials: true }
    )

    return response.data.sort(sortByCollatingField(true, 'emailTemplateName'))
  })
}
