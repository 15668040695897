import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStafferQueryKey } from '../../../helpers/queryKeys'

export const useStaffSaveStaffSpecificMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (values) => {
      await axios.post(`${endpoints.STAFF}/savestaffspecific`, values, {
        withCredentials: true
      })

      return {
        values
      }
    },
    {
      onSuccess: ({ values }) => {
        enqueueSnackbar('Course Info changes have been saved', {
          variant: 'success'
        })

        // Update the query cache for staffer
        queryClient.setQueryData(
          getStafferQueryKey(values.userId),
          (prevStafferDetails) => {
            return {
              courses: [
                ...prevStafferDetails.courses.map((course) => {
                  if (course.isHomeroom)
                    return {
                      ...course,
                      courseName: values.homeroomCourseName,
                      isActive: values.homeroomCourseIsActive
                    }
                  return {
                    ...course,
                    courseName: values.subjectMatterCourseName,
                    isActive: values.subjectMatterCourseIsActive
                  }
                })
              ],
              staffer: {
                ...prevStafferDetails.staffer,
                departmentId: values.departmentId,
                homeRoom: values.homeRoom,
                maxNumberStudents: values.maxNumberStudents
              }
            }
          }
        )

        // Invalidate query cache for list of staffers
        queryClient.invalidateQueries(['staffers'])
      }
    }
  )
}
