import React from 'react'
import { Box } from '@mui/material'

import {
  Button,
  List,
  PaperTitle,
  SubheadingContainer,
  SubheadingText
} from '../shared'

import PermissionItem from './PermissionItem'

const EmailPermissions = ({
  emails,
  hasEmailPermissionsBeenAltered,
  saveEmailPermissions,
  updateEmails
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <PaperTitle>Email Notifications</PaperTitle>
        <Button
          disabled={!hasEmailPermissionsBeenAltered}
          label='Save'
          onClick={saveEmailPermissions}
        />
      </Box>
      <SubheadingContainer>
        <SubheadingText sx={{ gridColumn: '1/6' }} text='Name' />
        <SubheadingText
          sx={{ gridColumn: '6/8', textAlign: 'center' }}
          text='Subscribe'
        />
      </SubheadingContainer>
      <List
        Component={PermissionItem}
        data={emails}
        updateEmails={updateEmails}
      />
    </>
  )
}

export default EmailPermissions
