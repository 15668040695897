import React from 'react'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import useAnchorEl from '../../hooks/useAnchorEl'

const ScrollToMenu = ({ rosters, scrollToRoster }) => {
  const { anchorEl, handleClose, handleOpen, open } = useAnchorEl()

  const handleMenuItemClick = (rosterId) => {
    handleClose()
    scrollToRoster(rosterId)
  }

  return (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <Button
        id='scroll-to-button'
        aria-controls={open ? 'scroll-to-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}>
        Scroll To
      </Button>
      <Menu
        id='scroll-to-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'scroll-to-button'
        }}>
        {rosters.map((item) => (
          <MenuItem
            key={item.rosterId}
            onClick={() => handleMenuItemClick(item.rosterId)}>
            {item.rosterName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default ScrollToMenu
