import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useDepartmentsDeleteMutation = ({
  includeInactive,
  includeUnassigned,
  withCounts
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ departmentId, departmentDescription, numberOfUsers }) => {
      await axios.delete(`${endpoints.DEPARTMENTS}/delete/${departmentId}`, {
        withCredentials: true
      })

      return { departmentId, departmentDescription, numberOfUsers }
    },
    {
      onSuccess: ({ departmentId, departmentDescription, numberOfUsers }) => {
        enqueueSnackbar(`${departmentDescription} has been deleted`, {
          variant: 'success'
        })

        queryClient.setQueryData(
          ['departments', { includeInactive, includeUnassigned, withCounts }],
          (prevDepartments) => {
            return [
              ...prevDepartments
                .filter(
                  (department) => department.departmentId !== departmentId
                )
                .map((prevDepartment) => {
                  if (!prevDepartment.isUnassignedDepartment)
                    return prevDepartment
                  return {
                    ...prevDepartment,
                    numberOfUsers: prevDepartment.numberOfUsers + numberOfUsers
                  }
                })
            ]
          }
        )
      }
    }
  )
}
