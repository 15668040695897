import React from 'react'
import { styled } from '@mui/material/styles'

import logo from '../../images/eslogo_icon_81px.png'

const Keyframes = styled('img')({
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(359deg)'
    }
  },
  animation: 'rotation 5s infinite linear',
  bottom: 16,
  right: 16,
  position: 'fixed',
  borderRadius: '100%',
  opacity: '75%',
  zIndex: 1000
})

const FetchingIndicator = ({ isFetching }) => {
  if (!isFetching) return null

  return <Keyframes src={logo} alt='Fetching Data' />
}

export default FetchingIndicator
