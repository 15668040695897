export const BLOCKED_DATE_SAVE_STATUS = {
  departmentNotBlockedDueToStaffAlreadySchedule: -8,
  noStafferToBlock: -7,
  alreadyBlocked: -6,
  noPeriodsSelected: -5,
  reasonRequired: -4,
  accountDoesntHavePermissions: -3,
  noDatesToSave: -2,
  stafferIsScheduled: -1,
  success: 0
}
