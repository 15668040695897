import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getNotificationsQueryKey } from '../../../helpers'

export const useNotificationsMarkAllReadMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      await axios.post(
        `${endpoints.NOTIFICATIONS}/markallread`,
        {},
        {
          withCredentials: true
        }
      )
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(
          getNotificationsQueryKey(),
          (prevNotifications) => {
            return prevNotifications.map((notification) => {
              return { ...notification, hasBeenRead: true }
            })
          }
        )
      }
    }
  )
}
