import React from 'react'

import { FlexColumn, RadioGroup, SelectField, TextArea } from '../shared'

import StepNavButtons from './StepNavButtons'

const SetupSchedulingOptionsStep = ({
  appointmentTypes,
  coursesByDatePeriodStaffer,
  departments,
  excludedCourseDates,
  handleBack,
  handleNext,
  schedulingOptions,
  selectedDepartment,
  staffersByDepartment,
  updateSchedulingOptionsByValue
}) => {
  const shouldDisableNext = () => {
    if (
      schedulingOptions.scheduleToStudentsHomeroom === 'false' &&
      (selectedDepartment === '' ||
        schedulingOptions.selectedStafferId === '' ||
        (excludedCourseDates.length > 0 &&
          excludedCourseDates.length === coursesByDatePeriodStaffer.length))
    )
      return true

    if (schedulingOptions.selectedAppointmentType === '') return true

    return false
  }

  return (
    <FlexColumn>
      <RadioGroup
        data={[
          {
            label: `Schedule Students to Their Homebase Course`,
            value: 'true'
          },
          {
            label: 'Select a Subject Matter Course',
            value: 'false'
          }
        ]}
        name='scheduleToStudentsHomeroom'
        onChange={updateSchedulingOptionsByValue}
        sx={{ marginBottom: { xs: 1, sm: 0 } }}
        selectedValue={schedulingOptions.scheduleToStudentsHomeroom}
      />
      {schedulingOptions.scheduleToStudentsHomeroom === 'false' && (
        <>
          <SelectField
            label='Department'
            menuItems={departments}
            name='selectedDepartmentId'
            onChange={updateSchedulingOptionsByValue}
            selectedValue={schedulingOptions.selectedDepartmentId}
          />
          <SelectField
            disabled={
              selectedDepartment && selectedDepartment.isHomeroomDepartment
            }
            label='Staffer'
            menuItems={staffersByDepartment}
            name='selectedStafferId'
            onChange={updateSchedulingOptionsByValue}
            selectedValue={
              selectedDepartment && selectedDepartment.isHomeroomDepartment
                ? ''
                : schedulingOptions.selectedStafferId
            }
          />
        </>
      )}
      <SelectField
        label='Appointment Type'
        labelId={'selected-appointment-type'}
        menuItems={appointmentTypes}
        name='selectedAppointmentType'
        onChange={updateSchedulingOptionsByValue}
        selectedValue={schedulingOptions.selectedAppointmentType}
      />
      <TextArea
        name='comment'
        onChange={updateSchedulingOptionsByValue}
        placeholder='Add a comment...'
        value={schedulingOptions.comment}
      />
      <StepNavButtons
        disableNext={shouldDisableNext()}
        onClickNext={handleNext}
        onClickPrev={handleBack}
        stepNumber='3'
      />
    </FlexColumn>
  )
}

export default SetupSchedulingOptionsStep
