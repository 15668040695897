import React from 'react'
import { Help, Person, PersonOutline } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useSearchParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import { getStudentQueryKey, sortArrayByTwoFields } from '../../helpers'
import {
  fetchStudentDetails,
  usePrefetchData,
  useStudentsDeleteMutation
} from '../../hooks'
import {
  Checkbox,
  Chip,
  ChipBooleanIcon,
  DeleteModal,
  ListItemContainer,
  ListItemText,
  Loading,
  Menu,
  MenuItemLink,
  Popover,
  StudentInfo
} from '../shared'

import ChangeEmailDrawer from './ChangeEmailDrawer'

const ChipContainer = styled('div')(() => ({
  gridColumn: '1/13'
}))

const StyledPersonIcon = styled(Person, {
  shouldForwardProp: (prop) => prop !== 'userIsDeleted'
})(({ theme, userIsDeleted }) => ({
  color: userIsDeleted && 'grey',
  marginRight: !userIsDeleted && theme.spacing()
}))

const StudentItem = ({
  bulkEditing,
  canOverrideAppts,
  canScheduleSelf,
  emailAddress,
  firstName,
  homeroomStaffers,
  isAdmin,
  isLastItem,
  lastName,
  nickname,
  selectedStudentIds,
  sisId,
  updateStudentIsSelected,
  userId,
  userIsActive,
  userIsDeleted,
  yearOfGraduation
}) => {
  const [prefetchStudentDetails] = usePrefetchData()

  let [searchParams] = useSearchParams()

  const isChecked = () => {
    const result = selectedStudentIds.find((id) => id === userId)

    return result === undefined ? false : true
  }

  const {
    error: errorDeletingStudent,
    isLoading: isDeletingStudent,
    mutate: deleteStudent
  } = useStudentsDeleteMutation({
    key: searchParams.get('searchKey'),
    value: searchParams.get('searchValue')
  })

  const setupHomebaseStaffers = () => {
    if (homeroomStaffers[0].stafferId === 0) return null

    const sortedStaffers = sortArrayByTwoFields(
      homeroomStaffers,
      'stafferLastName',
      'stafferFirstName'
    )

    return sortedStaffers.map((staffer, index) => {
      return (
        <span key={staffer.stafferId}>
          {staffer.stafferLastName}, {staffer.stafferFirstName}
          {index < homeroomStaffers.length - 1 ? <br /> : ''}
        </span>
      )
    })
  }

  if (isDeletingStudent) return <Loading text='Deleting Student' />

  return (
    <ListItemContainer dataTestId='student-item' isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/5' },
          display: 'flex',
          alignItems: 'center'
        }}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {bulkEditing && (
            <Checkbox
              checked={isChecked()}
              disabled={userIsDeleted || !userIsActive}
              id={userId.toString()}
              onChange={(e) =>
                updateStudentIsSelected(userId, e.target.checked)
              }
            />
          )}
          {userIsDeleted ? (
            <StyledPersonIcon
              as={PersonOutline}
              userIsDeleted={userIsDeleted}
            />
          ) : (
            <StyledPersonIcon userIsDeleted={userIsDeleted} />
          )}
          {userIsDeleted && (
            <Popover
              sx={{
                minWidth: 20,
                paddingTop: 0,
                paddingBottom: 0
              }}
              content={`<a
                    href='https://enrichingstudents.zendesk.com/hc/en-us/articles/360032135592-What-Is-A-Soft-Deleted-User'
                    target='__blank'>
                    This user has been soft deleted. Click here to learn more.
                  </a>`}
              dataTestId='user-is-deleted'
              shouldDangerouslySetInnerHtml={true}>
              <Help />
            </Popover>
          )}
          <StudentInfo
            firstName={firstName}
            lastName={lastName}
            nickName={nickname}
            sisId={sisId}
            useLink={true}
            userId={userId}
            yearOfGraduation={yearOfGraduation}
          />
        </span>
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '5/9' }
        }}
        displayColumn
        labelText='Email Address:'>
        {emailAddress}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '9/12' }
        }}
        displayColumn
        labelText='Homeroom Teacher:'>
        {setupHomebaseStaffers()}
      </ListItemText>
      {!errorDeletingStudent && isAdmin && (
        <Menu>
          <ChangeEmailDrawer
            emailAddress={emailAddress}
            name={`${lastName}, ${firstName}`}
            userId={userId}
          />
          <div>
            <DeleteModal
              confirmDeleteCallback={() => deleteStudent(userId)}
              confirmDeleteLabel='Delete Student'
              confirmDeleteMessage='Are you sure you wish to delete this student?'
              itemName={`${firstName}, ${lastName}`}
              label='Delete Student'
            />
            <MenuItemLink
              onMouseEnter={() =>
                prefetchStudentDetails(getStudentQueryKey(userId), () =>
                  fetchStudentDetails(userId)
                )
              }
              text='Edit'
              to={`/admin/addStudent/${userId}`}
              state={{
                activeFilter: searchParams.get('activeFilter'),
                currentPage: searchParams.get('currentPage'),
                overrideFilter: searchParams.get('overrideFilter'),
                scrollPosition: window.scrollY,
                searchKey: searchParams.get('searchKey'),
                searchValue: searchParams.get('searchValue'),
                selfScheduleFilter: searchParams.get('selfScheduleFilter')
              }}
            />
          </div>
        </Menu>
      )}
      <ChipContainer>
        <Chip
          chipStyle={userIsActive ? 'success' : 'error'}
          icon={<ChipBooleanIcon iconBoolean={userIsActive} />}
          label='Active'
        />
        <Chip
          chipStyle={canScheduleSelf ? 'success' : 'error'}
          icon={<ChipBooleanIcon iconBoolean={canScheduleSelf} />}
          label='Can Schedule Self'
        />
        {canScheduleSelf && (
          <Chip
            chipStyle={canOverrideAppts ? 'success' : 'error'}
            icon={<ChipBooleanIcon iconBoolean={canOverrideAppts} />}
            label='Can Override Appointments'
          />
        )}
      </ChipContainer>
      {errorDeletingStudent && (
        <Typography style={{ paddingTop: 8 }}>
          There was an error deleting this student. We apologize for the
          inconvenience.
        </Typography>
      )}
    </ListItemContainer>
  )
}

export default StudentItem
