import React from 'react'
import { Box } from '@mui/material'
import { Info } from '@mui/icons-material'

import LabeledCheckbox from '../LabeledCheckbox'
import Popover from '../Popover'
import StudentInfo from '../StudentInfo'

const StudentItem = ({
  errorMessage,
  firstName,
  lastName,
  moveStudent,
  nickname,
  sisId,
  studentType,
  userId,
  yearOfGraduation
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 0.5,
        paddingBottom: 0.5
      }}
      data-testid={`${studentType}-student-item`}>
      <LabeledCheckbox
        checked={false}
        dataTestId={`${studentType}-student`}
        label={
          <StudentInfo
            firstName={firstName}
            nickName={nickname}
            lastName={lastName}
            sisId={sisId}
            userId={userId}
            yearOfGraduation={yearOfGraduation}
          />
        }
        onChange={() => moveStudent(userId)}
      />
      {errorMessage && (
        <Popover content={errorMessage}>
          <Info sx={{ color: 'error.main' }} data-testid='student-error-icon' />
        </Popover>
      )}
    </Box>
  )
}

export default StudentItem
