import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'

export const useStudentsBlockedForStudentByIdQuery = (studentId) => {
  return useQuery(
    ['students-blocked-for-student', { studentId: parseInt(studentId) }],
    async () => {
      const response = await axios.get(
        `${endpoints.STUDENTS_BLOCKED_FOR_STUDENT}/${studentId}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      enabled: !!studentId
    }
  )
}
