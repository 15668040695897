import React, { createContext, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useLoggedInUser } from '../../context'
import {
  useAppointmentTypesQuery,
  useAppointmentsMassScheduleInitializeQuery,
  useConfigurationGraduationYearsQuery,
  useDepartmentsQuery,
  useIsXsBreakpoint,
  usePeriodsMinimalActiveOnlyQuery,
  useStaffQuery
} from '../../hooks'

import { useStudentsByRosterIdQuery } from './hooks'

const MassAppointmentsContext = createContext()

const MassAppointmentsProvider = ({ children }) => {
  const isXs = useIsXsBreakpoint()
  const {
    date: dateParam,
    groupId: groupIdParam,
    periodId: periodIdParam,
    staffId: staffIdParam
  } = useParams()
  const { isAdmin, loggedInUserId } = useLoggedInUser()

  const [selectedGroupId, setSelectedGroupId] = useState(
    groupIdParam === '0' ? '' : groupIdParam
  )

  const { data: appointmentTypes = [], isLoading: isLoadingAppointmentTypes } =
    useAppointmentTypesQuery()

  const { data: departments = [], isLoading: isLoadingDepartments } =
    useDepartmentsQuery()

  const { data: graduationYears = [], isLoading: isLoadingGraduationYears } =
    useConfigurationGraduationYearsQuery({ enabled: isAdmin })

  const { data: periods = [], isLoading: isLoadingPeriods } =
    usePeriodsMinimalActiveOnlyQuery({
      includeActiveOnly: true
    })

  const {
    isFetching: isFetchingMassScheduleInitialize,
    isLoading: isLoadingMassScheduleInitialize
  } = useAppointmentsMassScheduleInitializeQuery()

  const { data: staffers = [], isLoading: isLoadingStaffers } = useStaffQuery()

  const { data: studentsByGroupId = [] } = useStudentsByRosterIdQuery({
    rosterId: selectedGroupId
  })

  return (
    <MassAppointmentsContext.Provider
      value={{
        appointmentTypes,
        dateParam,
        departments,
        graduationYears,
        groupIdParam,
        isAdmin,
        isLoading:
          isFetchingMassScheduleInitialize ||
          isLoadingAppointmentTypes ||
          isLoadingDepartments ||
          isLoadingGraduationYears ||
          isLoadingMassScheduleInitialize ||
          isLoadingPeriods ||
          isLoadingStaffers,
        isXs,
        loggedInUserId,
        periodIdParam,
        periods,
        selectedGroupId,
        setSelectedGroupId,
        staffIdParam,
        staffers,
        studentsByGroupId
      }}>
      {children}
    </MassAppointmentsContext.Provider>
  )
}

const useMassAppointments = () => {
  const context = useContext(MassAppointmentsContext)

  if (!context) {
    throw new Error(
      'useMassAppointments must be used with a MassAppointmentsProvider'
    )
  }

  return context
}

export {
  MassAppointmentsContext,
  MassAppointmentsProvider,
  useMassAppointments
}
