import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useStaffNumberWithoutAdjustedOfferingsQuery = () => {
  return useQuery(['staff', 'number-without-adjusted-offerings'], async () => {
    const response = await axios.get(
      `${endpoints.STAFF}/numberWithoutAdjustedOfferings`,
      { withCredentials: true }
    )

    return response.data
  })
}
