import React, { useRef, useState } from 'react'
import { CircularProgress, MenuItem } from '@mui/material'
import moment from 'moment'

import { useCoursesForDatePeriodStafferQuery } from './hooks'
import { DeleteModal, ListSubheader, Menu, MenuItemLink } from '../shared'

import { useAppointments } from './AppointmentsContext'
import GroupScheduleModal from './GroupScheduleModal'
import { onSuccessClearAppointments } from './onSuccessClearAppointments'
import { useQueryClient } from 'react-query'

const GroupActionsMenu = ({
  appointmentDate,
  openHomeroom,
  openSubjectMatter,
  setOpenHomeroom,
  setOpenSubjectMatter
}) => {
  const queryClient = useQueryClient()
  const [shouldFetchData, setShouldFetchData] = useState(false)

  const {
    clearAppointments,
    dateSp,
    isSubstitute,
    loggedInUserId,
    numberOfDays,
    periodIdSp,
    searchValueSp,
    selectedStudentIds,
    staffIdSp,
    studentGroupIdSp,
    studentGroups,
    studentsAndAppointments
  } = useAppointments()

  const menuRef = useRef()

  const closeMenu = () => {
    menuRef.current.closeMenuFromParent()
  }

  const setupAppointmentsToClear = () => {
    let appointmentIds = []

    studentsAndAppointments.forEach((student) => {
      student.appointments.forEach((appointment) => {
        if (
          appointment.appointmentDate === appointmentDate &&
          appointment.appointmentId !== 0 &&
          selectedStudentIds.includes(student.userId)
        ) {
          appointmentIds.push(appointment.appointmentId)
        }
      })
    })

    clearAppointments({
      appointmentIds,
      optionalCallback: () => {
        onSuccessClearAppointments({
          appointmentDate,
          appointmentIds,
          numberOfDaysToRetrieve: numberOfDays,
          periodId: periodIdSp,
          queryClient,
          rosterId: studentGroupIdSp,
          searchTerm: searchValueSp,
          startDate: dateSp === null ? undefined : dateSp,
          studentIds: selectedStudentIds,
          userId: null
        })

        closeMenu()
      }
    })
  }

  const {
    data: courses = [],
    isFetching: isFetchingCourses,
    isLoading: isLoadingCourses
  } = useCoursesForDatePeriodStafferQuery({
    appointmentDate,
    enabled: shouldFetchData,
    periodId: periodIdSp,
    stafferId: loggedInUserId
  })

  const isLoading = isFetchingCourses || isLoadingCourses

  const setupMassAppointmentsLink = () => {
    let studentGroupId = studentGroupIdSp

    if (studentGroupIdSp === null) {
      const defaultStudentGroup = studentGroups.find((group) => group.isDefault)

      if (defaultStudentGroup === undefined) {
        studentGroupId = 0
        return
      }

      studentGroupId = defaultStudentGroup.rosterId
    }

    return `/massAppointments/${moment(appointmentDate).format(
      'YYYY-MM-DD'
    )}/${staffIdSp}/${studentGroupId}/${periodIdSp}`
  }

  return (
    <Menu
      dataTestId='group-actions-button'
      label='Group Actions'
      onClick={() => setShouldFetchData(true)}
      ref={menuRef}>
      {isLoading ? (
        <div
          style={{
            minHeight: 106,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {courses.length === 0 ? (
            <MenuItem>
              Your Subject Matter Course is not available for this date and
              period
            </MenuItem>
          ) : (
            <div>
              <ListSubheader>My Courses</ListSubheader>
              <div>
                {courses.map((course) => (
                  <div key={course.courseId}>
                    <MenuItem
                      onClick={
                        course.isHomeroom
                          ? () => setOpenHomeroom(!openHomeroom)
                          : () => setOpenSubjectMatter(!openSubjectMatter)
                      }>
                      {course.courseName} -{' '}
                      {course.maxSeatCount -
                        course.numberOfAppointmentsByStaffer}
                    </MenuItem>
                    <GroupScheduleModal
                      appointmentDate={appointmentDate}
                      closeGroupActions={closeMenu}
                      courseId={course.courseId}
                      courseName={course.courseName}
                      open={
                        course.isHomeroom ? openHomeroom : openSubjectMatter
                      }
                      setOpen={
                        course.isHomeroom
                          ? setOpenHomeroom
                          : setOpenSubjectMatter
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <ListSubheader>Other Options</ListSubheader>
      {studentGroupIdSp !== '' && !isSubstitute && (
        <MenuItemLink
          text='Mass Appointments'
          to={setupMassAppointmentsLink()}
        />
      )}
      <DeleteModal
        label='Delete Appointments'
        confirmDeleteCallback={() => setupAppointmentsToClear()}
        confirmDeleteLabel='Delete Appointments'
        confirmDeleteMessage='Are you sure you want to delete these Appointments?'
        itemName={`Appointments for ${moment(appointmentDate).format(
          'YYYY-MM-DD'
        )}`}
      />
    </Menu>
  )
}

export default GroupActionsMenu
