import { useQuery } from 'react-query'
import axios from 'axios'
import moment from 'moment'

import { endpoints } from '../../../constants'
import { sortArrayByLastNameNickNameFirstName } from '../../../helpers'

export const useAppointmentsDetailForAttendanceQuery = ({
  attendanceDate,
  userId
}) => {
  return useQuery(
    [
      'appointments',
      'detail-for-attendance',
      { attendanceDate, userId: parseInt(userId) }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.APPOINTMENTS}/detailforattendance/${moment(
          attendanceDate
        ).format('YYYY-MM-DD')}/${userId}`,
        { withCredentials: true }
      )

      response.data.forEach((item) => (item.isSelected = true))

      sortArrayByLastNameNickNameFirstName(response.data)

      return response.data
    },
    {
      enabled: !!attendanceDate && !!userId,
      staleTime: 1000 * 300
    }
  )
}
