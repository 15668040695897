import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getAppointmentTypeQueryKey } from '../../../helpers'

// Exporting to use for pre-fetching
export const fetchAppointmentTypeDetails = async (appointmentTypeId) => {
  const response = await axios.get(
    `${endpoints.APPOINTMENT_TYPES}/${appointmentTypeId}`,
    { withCredentials: true }
  )

  return response.data
}

export const useAppointmentTypesByIdQuery = (appointmentTypeId) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getAppointmentTypeQueryKey(appointmentTypeId),
    () => fetchAppointmentTypeDetails(appointmentTypeId),
    {
      enabled: appointmentTypeId !== '0',
      onError: (error) => {
        // Invalidate cached Appointment Types
        queryClient.invalidateQueries(['appointment-types'])

        enqueueSnackbar(
          `We are unable to find this record, it's likely that it has been deleted by another user.`,
          {
            variant: 'error'
          }
        )
      },
      staleTime: 1000 * 30
    }
  )
}
