import React from 'react'
import moment from 'moment'

import { Checkbox, ListItemContainer, ListItemText } from '../shared'

const CourseDatePeriodItem = ({
  appointmentDate,
  blockedReason,
  courseId,
  courseName,
  courseRoom,
  dayTypeDescription,
  departmentId,
  isAdjusted,
  isBlockedForSchool,
  isBlockedForStaffer,
  isHomeroom,
  maxSeatCount,
  numberOfAppointmentsByStaffer,
  periodDescription,
  periodId,
  stafferFirstName,
  stafferId,
  stafferLastName,

  isLastItem,
  isSelected,
  updateCourseWithAdjustment
}) => {
  return (
    <ListItemContainer
      dataTestId='course-date-period-item'
      isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/3' }
        }}
        labelText='Date'>
        {moment(appointmentDate).format('MM/DD/YYYY')}
      </ListItemText>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '3/6' } }}
        labelText='Course Name:'>
        {courseName}
      </ListItemText>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '6/8' } }}
        labelText='Period:'>
        {periodDescription}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '8/10' },
          textAlign: { lg: 'center' }
        }}
        labelText='Current Appointments:'>
        {numberOfAppointmentsByStaffer}
      </ListItemText>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '10/12' } }}
        labelText='Day Type:'>
        {dayTypeDescription}
      </ListItemText>
      <ListItemText
        sx={{
          textAlign: 'center',
          gridColumn: { xs: '1/13', lg: '12/13' }
        }}
        labelText='Include:'>
        <Checkbox
          checked={isSelected}
          onChange={(e) =>
            updateCourseWithAdjustment(
              appointmentDate,
              periodId,
              e.target.checked
            )
          }
        />
      </ListItemText>
    </ListItemContainer>
  )
}

export default CourseDatePeriodItem
