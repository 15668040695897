import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useStudentGroupsForSingleStudentQuery = ({
  enabled,
  staffId,
  studentId
}) => {
  return useQuery(
    [],
    async () => {
      const response = await axios.get(
        `${endpoints.ROSTERS}/bystaffidforsinglestudent/${staffId}/${studentId}`,
        { withCredentials: true }
      )

      response.data.sort(sortByCollatingField(true, 'rosterName'))

      return response.data
    },
    {
      enabled: enabled
    }
  )
}
