import React from 'react'
import { Box, Drawer, Typography } from '@mui/material'
import moment from 'moment'

import { useAppointmentRequestsByIdsQuery } from '../../hooks'
import { FlexColumn, PaperTitle, List, Loading } from '../shared'

import RequestItem from './RequestItem'
import { ScheduledText } from './styledComponents'

const RequestsMenu = ({
  appointmentRequestIds,
  open,
  selectedDate,
  setOpen,
  studentName
}) => {
  const {
    data: requests,
    isFetching: isFetchingAppointmentRequests,
    isLoading: isLoadingAppointmentRequests
  } = useAppointmentRequestsByIdsQuery({
    appointmentRequestIds
  })

  return (
    <>
      <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
        <Box sx={{ padding: 1, width: 350 }}>
          {isFetchingAppointmentRequests || isLoadingAppointmentRequests ? (
            <Loading fullscreen={false} text='Loading Appointment Requests' />
          ) : (
            <FlexColumn>
              <PaperTitle>Requests By: {studentName}</PaperTitle>
              <Typography paragraph>
                <ScheduledText>Schedule Date:&nbsp;</ScheduledText>
                {moment(selectedDate).format('MM/DD/YYYY')}
              </Typography>
              <Typography paragraph>
                <ScheduledText>Period:&nbsp;</ScheduledText>
                {requests[0].periodDescription}
              </Typography>
              <List
                Component={RequestItem}
                data={requests}
                scheduleDate={moment(selectedDate).format('YYYY-MM-DD')}
                setOpen={setOpen}
              />
            </FlexColumn>
          )}
        </Box>
      </Drawer>
    </>
  )
}

export default RequestsMenu
