import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useOnlyAdminsCanBlockQuery = () => {
  return useQuery(
    ['configuration', 'only-admins-can-block'],
    async () => {
      const response = await axios.get(
        `${endpoints.CONFIGURATION}/onlyadminscanblock`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
