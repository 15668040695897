import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getDepartmentQueryKey } from '../../../helpers'

// Export to use for pre-fetching
export const fetchDepartmentDetails = async (departmentId) => {
  const response = await axios.get(`${endpoints.DEPARTMENTS}/${departmentId}`, {
    withCredentials: true
  })

  return response.data
}

export const useDepartmentsByIdQuery = ({ departmentId }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getDepartmentQueryKey(departmentId),
    () => fetchDepartmentDetails(departmentId),
    {
      enabled: departmentId !== '0',
      onError: () => {
        // Invalidate cached Attendance Types
        queryClient.invalidateQueries(['departments'])

        enqueueSnackbar(
          `We are unable to find this record, it's likely that it has been deleted by another user.`,
          {
            variant: 'error'
          }
        )
      },
      staleTime: 1000 * 30
    }
  )
}
