import { styled } from '@mui/material/styles'
import { SelectField } from '../../shared'

const ControlInput = styled(SelectField)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 170,
    marginRight: theme.spacing()
  },

  [theme.breakpoints.up('lg')]: {
    width: 250
  }
}))

export default ControlInput
