import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'

import { useIsXsBreakpoint } from '../../hooks'
import { ListItemContainer, ListItemText, StudentInfo } from '../shared'

const StafferDisplayName = ({ stafferDisplayName }) => {
  return (
    <Box as='span' sx={{ fontWeight: 400, fontStyle: 'italic', fontSize: 13 }}>
      Instructor: {stafferDisplayName}
    </Box>
  )
}

const HiddenLabel = ({ label, sx }) => {
  return (
    <Typography
      sx={{
        ...sx,
        display: { xs: 'none', sm: 'block', lg: 'none' },
        fontWeight: 'bold',
        fontSize: 14
      }}>
      {label}:
    </Typography>
  )
}

const StrugglingStudentItem = ({
  courses,
  firstName,
  isLastItem,
  lastName,
  nickname,
  sisId,
  userId,
  yearOfGraduation
}) => {
  const isXs = useIsXsBreakpoint()

  return (
    <ListItemContainer dataTestId='student-item' isLastItem={isLastItem}>
      <ListItemText sx={{ gridColumn: { xs: '1/13', lg: '1/6' } }}>
        <StudentInfo
          firstName={firstName}
          lastName={lastName}
          nickName={nickname}
          sisId={sisId}
          useLink
          userId={userId}
          yearOfGraduation={yearOfGraduation}
        />
      </ListItemText>
      {isXs ? (
        <ListItemText
          labelText='Courses & Grade:'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start !important',
            gridColumn: { xs: '1/13', sm: '1/8', lg: '4/8', xl: '4/10' }
          }}>
          {courses.map((course) => (
            <Typography
              as='span'
              key={course.description}
              sx={{ display: 'flex', flexDirection: 'column' }}>
              {course.description} - {course.grade}
              <br />
              <StafferDisplayName
                stafferDisplayName={course.stafferDisplayName}
              />
            </Typography>
          ))}
        </ListItemText>
      ) : (
        <Box
          sx={{
            gridColumn: { xs: '1/13', sm: '1/8', lg: '6/13' },
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridAutoRows: 'auto',
            alignItems: 'center'
          }}>
          <HiddenLabel label='Course' sx={{ gridColumn: '1/11' }} />
          <HiddenLabel
            label='Grade'
            sx={{ gridColumn: '11/13', textAlign: 'center' }}
          />
          {courses.map((course, index) => (
            <Fragment key={index}>
              <Typography
                sx={{
                  gridColumn: '1/11',
                  fontSize: 14
                }}>
                <span>{course.description}</span>
                <br />
                <StafferDisplayName
                  stafferDisplayName={course.stafferDisplayName}
                />
              </Typography>
              <Typography
                sx={{
                  gridColumn: '11/13',
                  fontSize: 14,
                  textAlign: { sm: 'center', lg: 'initial' }
                }}>
                <span style={{ color: course.isBelowCutOff ? 'red' : 'black' }}>
                  {course.grade}
                </span>
              </Typography>
            </Fragment>
          ))}
        </Box>
      )}
    </ListItemContainer>
  )
}

export default StrugglingStudentItem
