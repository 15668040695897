import { useMutation } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { passwordRequirementValidationEnums } from '../../../enums'
import { endpoints } from '../../../constants'

export const useMembershipUpdatePasswordMutation = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ data, emailAddress, userId, userToSchoolId, userType }) => {
      const response = await axios.post(
        `${endpoints.MEMBERSHIP}/updatepassword`,
        { ...data, userId, userToSchoolId, userType },
        { withCredentials: true }
      )

      return {
        requirementValidationEnum: response.data,
        formData: data,
        emailAddress
      }
    },
    {
      onSuccess: ({ requirementValidationEnum, formData, emailAddress }) => {
        if (
          requirementValidationEnum ===
          passwordRequirementValidationEnums.passwordInCompromisedList
        ) {
          enqueueSnackbar('Unable to save this password', {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(
            formData.sendWelcomeEmail
              ? `The password has been updated and an email has been sent to ${emailAddress}`
              : `The password has been updated`,
            {
              variant: 'success'
            }
          )
        }
      }
    }
  )
}
