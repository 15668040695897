import React from 'react'
import { styled } from '@mui/material/styles'

import { Checkbox, SubheadingContainer, SubheadingText } from '../shared'

export const BulkContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

const Subheading = ({ bulkEditing, handleSelectAllChange, selectAll }) => {
  return (
    <SubheadingContainer>
      <BulkContainer>
        {bulkEditing && (
          <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
        )}
        <SubheadingText sx={{ gridColumn: '1/5' }} text='Name' />
      </BulkContainer>
      <SubheadingText sx={{ gridColumn: '5/9' }} text='Email Address' />
      <SubheadingText sx={{ gridColumn: '9/12' }} text='Homebase Teacher' />
    </SubheadingContainer>
  )
}

export default Subheading
