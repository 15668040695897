import { Typography } from '@mui/material'
import React from 'react'

const FileErrors = ({ errors }) => {
  return (
    <div style={{ margin: 16 }}>
      <Typography style={{ marginBottom: 8 }}>
        There are some errors with the file you've uploaded:
      </Typography>
      {errors.map((error, index) => (
        <Typography key={index} style={{ marginLeft: 8, marginBottom: 4 }}>
          {error}
        </Typography>
      ))}
    </div>
  )
}

export default FileErrors
