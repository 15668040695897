import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getStaffersQueryKey,
  sortArrayByLastNameFirstName
} from '../../../helpers'

export const useStaffQuery = ({
  determineIfCanBeDeleted = false,
  isEnabled = true,
  mustHaveActiveHomeroomCourse = false,
  mustHaveHomeroomRoster = false,
  useAllOption = false,
  useNoneOption = false
} = {}) => {
  return useQuery(
    getStaffersQueryKey({
      determineIfCanBeDeleted,
      mustHaveActiveHomeroomCourse,
      mustHaveHomeroomRoster,
      useAllOption,
      useNoneOption
    }),
    async () => {
      const urlParams = new URLSearchParams()

      if (determineIfCanBeDeleted) {
        urlParams.append('DetermineIfCanBeDeleted', determineIfCanBeDeleted)
      }

      if (mustHaveActiveHomeroomCourse) {
        urlParams.append(
          'MustHaveActiveHomeroomCourse',
          mustHaveActiveHomeroomCourse
        )
      }

      if (mustHaveHomeroomRoster) {
        urlParams.append('MustHaveHomeroomRoster', mustHaveHomeroomRoster)
      }

      const urlParamsString = urlParams.toString()

      const response = await axios.get(
        `${endpoints.STAFF}${urlParamsString ? `?${urlParamsString}` : ''}`,
        { withCredentials: true }
      )

      // Adding a description and value for use in menus
      response.data.forEach(
        (r) => (r.description = r.lastName + ', ' + r.firstName)
      )
      response.data.forEach((r) => (r.value = r.userId))

      if (useAllOption) {
        response.data.unshift({ description: 'All', value: 0 })
      }

      if (useNoneOption) {
        response.data.unshift({
          description: '(None)',
          value: 0
        })
      }

      response.data = sortArrayByLastNameFirstName(response.data)

      return response.data
    },
    {
      enabled: isEnabled,
      staleTime: 1000 * 30
    }
  )
}
