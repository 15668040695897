import React from 'react'
import moment from 'moment'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Chat } from '@mui/icons-material'

import { fetchAdjustedOfferingDetails, usePrefetchData } from '../../hooks'
import {
  Checkbox,
  Chip,
  ChipBooleanIcon,
  ChipInfoIcon,
  DeleteModal,
  ListItemText,
  ListItemContainer,
  Menu,
  Popover,
  MenuItemLink
} from '../shared'
import { getAdjustedOfferingQueryKey } from '../../helpers'

const MenuContainer = styled('div')(({ theme }) => ({
  gridColumn: '1/13',
  marginTop: theme.spacing(),
  textAlign: 'center',

  [theme.breakpoints.up('md')]: {
    gridColumn: '10/13',
    gridRow: '1 / span 8'
  },

  [theme.breakpoints.up('lg')]: {
    gridRow: '1',

    textAlign: 'center',
    gridColumn: '12/13'
  }
}))

const Privileges = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridColumn: '1/13',

  [theme.breakpoints.up('md')]: {
    gridColumn: '1/6',
    flexDirection: 'row',
    marginBottom: theme.spacing()
  },

  [theme.breakpoints.up('lg')]: {
    gridColumn: '1/6'
  }
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  paddingBottom: theme.spacing(0.5)
}))

const OfferingItem = ({
  adjustedCourseName,
  adjustedRoom,
  adjustedSeatCount,
  appointmentTypeDescription,
  bulkEditing = false,
  courseAdjustedId,
  courseDescription,
  courseTitle,
  deleteAdjustedOffering,
  deleteAdjustedOfferingSeries,
  groupId,
  isInSeries,
  isInUseAndAttendanceHasBeenTaken,
  isLastItem,
  isScheduled,
  mustUseDefaultAppointmentType,
  numberOfAppointments,
  periodDescription,
  preventOtherStaffFromScheduling,
  preventStudentRequesting,
  preventStudentSelfScheduling,
  scheduleDate,
  selectedOfferingIds,
  stafferId,
  updateAdjustedOfferingIsSelected,
  viewingSeries = false
}) => {
  const [prefetchAdjustedOfferingDetails] = usePrefetchData()

  const isChecked = () => {
    const result = selectedOfferingIds.find((id) => id === courseAdjustedId)

    return result === undefined ? false : true
  }

  const isPastDate =
    moment(scheduleDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')

  const disableCheckbox =
    isPastDate || isInUseAndAttendanceHasBeenTaken ? true : false

  return (
    <ListItemContainer dataTestId='offering-item' isLastItem={isLastItem}>
      {bulkEditing && (
        <ListItemText
          sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center' }}
          labelText='Include:'>
          <Checkbox
            checked={isChecked() && !isPastDate}
            disabled={disableCheckbox}
            id={courseAdjustedId.toString()}
            onChange={(e) =>
              updateAdjustedOfferingIsSelected(
                courseAdjustedId,
                e.target.checked
              )
            }
          />
        </ListItemText>
      )}
      <ListItemText
        sx={{
          gridColumn: { xs: '1/10', lg: '1/3' }
        }}
        labelText='Date:'>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {bulkEditing && (
            <Checkbox
              sx={{
                display: { xs: 'none', sm: 'flex' }
              }}
              checked={isChecked() && !isPastDate}
              disabled={disableCheckbox}
              id={courseAdjustedId.toString()}
              onChange={(e) =>
                updateAdjustedOfferingIsSelected(
                  courseAdjustedId,
                  e.target.checked
                )
              }
            />
          )}
          <span>{moment(scheduleDate).format('MM/DD/YYYY')}</span>
        </span>
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/10', lg: '3/5' }
        }}
        labelText='Period:'>
        {periodDescription}
      </ListItemText>
      <Box
        sx={{
          gridColumn: { xs: '1/10', lg: '5/12' },
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gridAutoRows: 'auto'
        }}>
        <StyledTypography
          data-testid='offering-name'
          sx={{ gridColumn: { xs: '1/13', lg: '1/6' } }}>
          <b>Offering:</b> {adjustedCourseName}
        </StyledTypography>
        <StyledTypography sx={{ gridColumn: { xs: '1/13', lg: '6/13' } }}>
          <b>Max Students:</b> {adjustedSeatCount}
        </StyledTypography>
        <StyledTypography sx={{ gridColumn: { xs: '1/13', lg: '1/6' } }}>
          <b>Appointment Type:</b> {appointmentTypeDescription}
        </StyledTypography>
        <StyledTypography sx={{ gridColumn: { xs: '1/13', lg: '6/13' } }}>
          <b>Number of Appointments:</b> {numberOfAppointments}
        </StyledTypography>
        <StyledTypography sx={{ gridColumn: { xs: '1/13', lg: '6/13' } }}>
          <b>Room:</b> {adjustedRoom}
        </StyledTypography>
        {courseDescription && (
          <StyledTypography sx={{ gridColumn: '1/13' }}>
            <b>Description:</b>{' '}
            <Popover
              content={courseDescription}
              dataTestId='course-description'
              sx={{
                paddingTop: 0,
                paddingBottom: 0
              }}>
              <Chat />
            </Popover>
          </StyledTypography>
        )}
      </Box>
      <Privileges>
        {courseTitle && (
          <Chip
            sx={{ maxWidth: 250 }}
            chipStyle='info'
            data-testid='adjusted-offering-series-title'
            icon={<ChipInfoIcon />}
            label={`Series: ${courseTitle}`}
          />
        )}
        {(mustUseDefaultAppointmentType === 'true' ||
          mustUseDefaultAppointmentType === true) && (
          <Chip
            sx={{ maxWidth: 250 }}
            chipStyle='success'
            data-testid='must-use-default-appt-type'
            icon={<ChipBooleanIcon iconBoolean={true} />}
            label='Must Use Default Appt Type'
          />
        )}
        {(preventStudentSelfScheduling === 'true' ||
          preventStudentSelfScheduling === true) && (
          <Chip
            sx={{ maxWidth: 250 }}
            chipStyle='error'
            data-testid='allow-self-scheduling'
            icon={<ChipBooleanIcon iconBoolean={false} />}
            label='Blocked Self-Scheduling'
          />
        )}
        {(preventStudentRequesting === 'true' ||
          preventStudentRequesting === true) && (
          <Chip
            sx={{ maxWidth: 250 }}
            chipStyle='error'
            data-testid='allow-student-requests'
            icon={<ChipBooleanIcon iconBoolean={false} />}
            label='Blocked Student Requests'
          />
        )}
        {(preventOtherStaffFromScheduling === 'true' ||
          preventOtherStaffFromScheduling === true) && (
          <Chip
            chipStyle='error'
            data-testid='allow-staff-scheduling'
            icon={<ChipBooleanIcon iconBoolean={false} />}
            label='Blocked Staff Scheduling'
          />
        )}
      </Privileges>
      {!isPastDate && (
        <>
          {!isInUseAndAttendanceHasBeenTaken ? (
            <MenuContainer>
              <Menu>
                {!isScheduled ? (
                  <DeleteModal
                    confirmDeleteCallback={() =>
                      deleteAdjustedOffering(courseAdjustedId)
                    }
                    confirmDeleteLabel='Delete Adjusted Offering'
                    confirmDeleteMessage='Are you sure you wish to delete this offering?'
                    itemName={adjustedCourseName}
                    label='Delete Offering'
                  />
                ) : (
                  <Typography
                    sx={{
                      paddingTop: 2,
                      paddingRight: 2,
                      paddingLeft: 2
                    }}>
                    This Offering has been scheduled and cannot be deleted.
                  </Typography>
                )}
                {isInSeries && (
                  <DeleteModal
                    confirmDeleteCallback={() =>
                      deleteAdjustedOfferingSeries(groupId, stafferId)
                    }
                    confirmDeleteLabel='Delete Adjusted Series'
                    confirmDeleteMessage='Please note, when deleting an Adjusted Offering series, ONLY appointments for future dates will be deleted.  Are you sure you wish to delete this series?'
                    itemName={adjustedCourseName}
                    label='Delete Series'
                  />
                )}
                <MenuItemLink
                  onMouseEnter={() =>
                    prefetchAdjustedOfferingDetails(
                      getAdjustedOfferingQueryKey({
                        adjustedOfferingId: courseAdjustedId,
                        staffId: stafferId
                      }),
                      () =>
                        fetchAdjustedOfferingDetails({
                          adjustedOfferingId: courseAdjustedId,
                          staffId: stafferId
                        })
                    )
                  }
                  text='Edit Offering'
                  to={
                    viewingSeries
                      ? `/createAdjustedOffering/${stafferId}/${courseAdjustedId}/true`
                      : `/createAdjustedOffering/${stafferId}/${courseAdjustedId}/false`
                  }
                />
                {isInSeries && !viewingSeries && (
                  <MenuItemLink
                    text='Edit Series'
                    to={`/adjustedSeries/${stafferId}/${groupId}`}
                  />
                )}
              </Menu>
            </MenuContainer>
          ) : (
            <MenuContainer as={Typography} sx={{ fontSize: 14 }}>
              Attendance has been taken
            </MenuContainer>
          )}
        </>
      )}
    </ListItemContainer>
  )
}

export default OfferingItem
