import React from 'react'
import { SubheadingContainer, SubheadingText } from '../shared'

const StrugglingStudentsSubheading = ({
  lastSortedBy,
  onClick,
  sortAscending
}) => {
  return (
    <SubheadingContainer>
      <SubheadingText
        sx={{ gridColumn: { lg: '1/3' } }}
        ascValue={sortAscending}
        isSelected={lastSortedBy === 'scheduleDate'}
        isSortable
        onClick={() => onClick('scheduleDate')}
        text='Date'
      />
      <SubheadingText
        sx={{
          gridColumn: { lg: '3/11' }
        }}
        ascValue={sortAscending}
        isSelected={lastSortedBy === ''}
        isSortable
        onClick={() => onClick('adjustedCourseName')}
        text='Offering Name'
      />
      <SubheadingText
        sx={{
          gridColumn: { lg: '11/13' }
        }}
        ascValue={sortAscending}
        isSelected={lastSortedBy === ''}
        isSortable
        onClick={() => onClick('adjustedRoom')}
        text='Location'
      />
    </SubheadingContainer>
  )
}

export default StrugglingStudentsSubheading
