import React, { useState } from 'react'
import { Grid } from '@mui/material'

import { Switch } from '../shared'

import DepartmentsTree from './DepartmentsTree'
import { useAdvancedAppointments } from './AdvancedAppointmentsContext'

const DepartmentsSection = () => {
  const { courses, departments } = useAdvancedAppointments()

  const [displayAdjustedCoursesOnly, setDisplayAdjustedCoursesOnly] =
    useState(false)
  const [hideUnavailableCourses, setHideUnavailableCourses] = useState(true)

  const setupFilteredCourses = () => {
    let result = courses

    if (hideUnavailableCourses) {
      result = result.filter(
        (course) =>
          !course.isBlockedForSchool &&
          !course.isBlockedForStaffer &&
          course.maxSeatCount - course.numberOfAppointmentsByStaffer > 0
      )
    }

    if (displayAdjustedCoursesOnly) {
      result = courses.filter((course) => course.isAdjusted)
    }

    return result
  }

  const filteredCourses = setupFilteredCourses()

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
      item
      xs={12}
      md={4}
      lg={3}>
      <Switch
        checked={displayAdjustedCoursesOnly}
        onChange={() =>
          setDisplayAdjustedCoursesOnly(!displayAdjustedCoursesOnly)
        }
        label='Display Adjusted Offerings Only'
      />
      <Switch
        checked={hideUnavailableCourses}
        onChange={() => setHideUnavailableCourses(!hideUnavailableCourses)}
        label='Hide Unavailable Courses'
      />
      {departments.map((department) => (
        <DepartmentsTree
          courses={filteredCourses.filter(
            (course) => course.departmentId === department.departmentId
          )}
          key={department.departmentId}
          {...department}
        />
      ))}
    </Grid>
  )
}

export default DepartmentsSection
