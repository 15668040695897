import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useRostersSaveMutation = ({ resetData, staffId }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ values }) => {
      const response = await axios.post(`${endpoints.ROSTERS}/save`, values, {
        withCredentials: true
      })

      return { rosterId: response.data, values }
    },
    {
      onSuccess: ({ rosterId, values }) => {
        enqueueSnackbar(
          values.rosterId === 0
            ? `${values.rosterName} has been created`
            : `${values.rosterName} has been updated`,
          {
            variant: 'success'
          }
        )

        // if this is a new student group
        // push new group into cache
        if (values.rosterId === 0) {
          queryClient.setQueryData(
            [
              'rosters',
              'rosters-only-by-staffer',
              { userId: parseInt(staffId) }
            ],
            (prevStudentGroups) => {
              return [
                ...prevStudentGroups,
                {
                  isDefault: false,
                  rosterId,
                  rosterName: values.rosterName,
                  stafferId: parseInt(staffId)
                }
              ]
            }
          )
        }

        resetData()
      }
    }
  )
}
