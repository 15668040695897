import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useSearchParams } from 'react-router-dom'

import { esuUrls, MONTHS } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  getBlockedDatesByDepartmentQueryKey,
  getBlockedDatesBySchoolQueryKey,
  getBlockedDatesByStaffQueryKey
} from '../../helpers'
import {
  useDepartmentsQuery,
  useHeaderTitle,
  useSortedCachedData,
  useStaffQuery
} from '../../hooks'
import {
  List,
  Loading,
  PageInstructions,
  PaginationOptions,
  Paper,
  PaperTitle,
  SelectField,
  Tabs,
  UserManual,
  UserManualWithLinkButton
} from '../shared'

import {
  BlockDateItem,
  blockedDatesBy,
  Subheading,
  useBlockedDatesByDepartmentQuery,
  useBlockedDatesBySchoolQuery,
  useBlockedDatesByStafferQuery,
  useDeleteBlockMutation,
  useDeleteSeriesMutation,
  useOnlyAdminsCanBlockQuery
} from '../BlockDate'

const TitleAndFilters = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  }
}))

const BlockDate = () => {
  useHeaderTitle('Blocked Dates')

  const { isAdmin, loggedInUserDepartmentId, loggedInUserId } =
    useLoggedInUser()
  let [searchParams, setSearchParams] = useSearchParams()

  const departmentIdSp =
    searchParams.get('departmentId') === null
      ? loggedInUserDepartmentId
      : searchParams.get('departmentId')

  const monthSp =
    searchParams.get('month') === null ? 0 : searchParams.get('month')

  const selectedTabSp =
    searchParams.get('selectedTab') === null
      ? 0
      : searchParams.get('selectedTab')

  const staffIdSp =
    searchParams.get('staffId') === null
      ? loggedInUserId
      : searchParams.get('staffId')

  const {
    data: blockedDatesByDepartment = [],
    isFetching: isFetchingBlockedDatesByDepartment,
    isLoading: isLoadingBlockedDatesByDepartment
  } = useBlockedDatesByDepartmentQuery({
    departmentId: departmentIdSp,
    month: monthSp,
    selectedTab: selectedTabSp
  })

  const {
    data: blockedDatesBySchool = [],
    isFetching: isFetchingBlockedDatesBySchool,
    isLoading: isLoadingBlockedDatesBySchool
  } = useBlockedDatesBySchoolQuery({
    month: monthSp,
    selectedTab: selectedTabSp
  })

  const {
    data: blockedDatesByStaffer = [],
    isFetching: isFetchingBlockedDatesByStaffer,
    isLoading: isLoadingBlockedDatesByStaffer
  } = useBlockedDatesByStafferQuery({
    month: monthSp,
    selectedTab: selectedTabSp,
    staffId: staffIdSp
  })

  const { data: departments = [], isLoading: isLoadingDepartments } =
    useDepartmentsQuery()

  const { data: onlyAdminsCanBlock, isLoading: isLoadingOnlyAdminsCanBlock } =
    useOnlyAdminsCanBlockQuery()

  const { data: staffers = [], isLoading: isLoadingStaffers } = useStaffQuery()

  const { isLoading: isDeletingBlockedDate, mutate: deleteBlock } =
    useDeleteBlockMutation({
      departmentId: departmentIdSp,
      month: monthSp,
      selectedTab: selectedTabSp,
      staffId: staffIdSp
    })

  const { isLoading: isDeletingBlockedSeries, mutate: deleteSeries } =
    useDeleteSeriesMutation({
      departmentId: departmentIdSp,
      month: monthSp,
      selectedTab: selectedTabSp,
      staffId: staffIdSp
    })

  const { lastSortedDetails, sortDataBy } = useSortedCachedData({
    initialSortedValue: 'scheduleDate'
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)

  const parsedSelectedTab = parseInt(selectedTabSp)

  const setupData = () => {
    let data = []

    if (parsedSelectedTab === 0) {
      data = blockedDatesByStaffer
    }

    if (parsedSelectedTab === 1) {
      data = blockedDatesByDepartment
    }

    if (parsedSelectedTab === 2) {
      data = blockedDatesBySchool
    }

    return data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
  }

  const sortBlockedDatesBy = (value) => {
    if (parsedSelectedTab === blockedDatesBy.department) {
      sortDataBy({
        queryKey: getBlockedDatesByDepartmentQueryKey({
          departmentId: departmentIdSp,
          month: monthSp,
          selectedTab: selectedTabSp
        }),
        value
      })
    }

    if (parsedSelectedTab === blockedDatesBy.school) {
      sortDataBy({
        queryKey: getBlockedDatesBySchoolQueryKey({
          month: monthSp,
          selectedTab: selectedTabSp
        }),
        value
      })
    }

    if (parsedSelectedTab === blockedDatesBy.staff) {
      sortDataBy({
        queryKey: getBlockedDatesByStaffQueryKey({
          month: monthSp,
          selectedTab: selectedTabSp,
          staffId: staffIdSp
        }),
        value
      })
    }
  }

  const updateSearchParamsByNameValue = (e) => {
    const { name, value } = e.target

    const stringedValue = value.toString()

    let departmentId =
      name === 'departmentId' ? stringedValue : searchParams.get('departmentId')

    let month = name === 'month' ? stringedValue : searchParams.get('month')

    let staffId =
      name === 'staffId' ? stringedValue : searchParams.get('staffId')

    if (departmentId !== null) {
      searchParams.set('departmentId', departmentId)
    }

    if (month !== null) {
      searchParams.set('month', month)
    }

    if (staffId !== null) {
      searchParams.set('staffId', staffId)
    }

    if (name === 'staffId') {
      const stafferInfo = staffers.find(
        (staffer) => staffer.userId === parseInt(value)
      )

      searchParams.set('departmentId', stafferInfo.departmentId)
    }

    setSearchParams(searchParams)
  }

  const updateSelectedTab = (value) => {
    const stringedValue = value.toString()

    searchParams.set('selectedTab', stringedValue)

    setCurrentPage(0)
    setSearchParams(searchParams)
  }

  if (isDeletingBlockedDate || isDeletingBlockedSeries)
    return <Loading text='Removing Blocked Date' />
  if (
    isFetchingBlockedDatesByDepartment ||
    isFetchingBlockedDatesBySchool ||
    isFetchingBlockedDatesByStaffer ||
    isLoadingBlockedDatesByDepartment ||
    isLoadingBlockedDatesBySchool ||
    isLoadingBlockedDatesByStaffer ||
    isLoadingDepartments ||
    isLoadingOnlyAdminsCanBlock ||
    isLoadingStaffers
  )
    return <Loading text='Loading Blocked Dates' />

  return (
    <div>
      {isAdmin || !onlyAdminsCanBlock ? (
        <UserManualWithLinkButton
          href={esuUrls.CREATE_BLOCKED_DATE}
          label='Create Blocked Date'
          to='/createBlockedDate'
        />
      ) : (
        <UserManual href={esuUrls.CREATE_BLOCKED_DATE} />
      )}
      <PageInstructions>
        On this page, you can view blocked dates and periods for you, another
        staffer, a department, or the school. Click the button Create Blocked
        Date to block specific periods or days when you don’t want appointments
        to be scheduled with you, another staffer, or a department. If you are
        an Admin user, you will also be able to block dates for the entire
        school.
      </PageInstructions>
      <Tabs
        labels={[
          'Blocked For Staff',
          'Blocked For Department',
          'Blocked For School'
        ]}
        scrollable
        selectedTab={parsedSelectedTab}
        setSelectedTab={updateSelectedTab}
      />
      <Paper>
        <TitleAndFilters>
          <PaperTitle>Blocked Dates</PaperTitle>
          <div>
            {parsedSelectedTab === blockedDatesBy.staff && (
              <SelectField
                label='Selected Staffer'
                labelId='selected-staffer'
                menuItems={staffers}
                name='staffId'
                onChange={updateSearchParamsByNameValue}
                selectedValue={staffIdSp}
              />
            )}
            {parsedSelectedTab === blockedDatesBy.department && (
              <SelectField
                label='Selected Department'
                labelId='selected-department'
                menuItems={departments}
                name='departmentId'
                onChange={updateSearchParamsByNameValue}
                selectedValue={departmentIdSp}
              />
            )}
            <SelectField
              sx={{ marginLeft: { md: 1 }, marginRight: { md: 1 } }}
              labelId='selected-month'
              label='Selected Month'
              menuItems={[
                {
                  description: 'All Current and Future Dates',
                  value: 0
                },
                ...MONTHS
              ]}
              name='month'
              onChange={updateSearchParamsByNameValue}
              selectedValue={monthSp}
            />
          </div>
        </TitleAndFilters>
        <Subheading
          lastSortedBy={lastSortedDetails.value}
          onClick={sortBlockedDatesBy}
          sortAscending={lastSortedDetails.sortAscending}
        />
        <List
          Component={BlockDateItem}
          data={setupData()}
          deleteBlock={deleteBlock}
          deleteSeries={deleteSeries}
          emptyArrayMessage={
            parsedSelectedTab === blockedDatesBy.staff
              ? [
                  'There are no Blocked Dates for this selected staffer and month.',
                  'Please select a different combination using the controls above.'
                ]
              : parsedSelectedTab === blockedDatesBy.department
              ? [
                  'There are no Blocked Dates for this selected department and month.',
                  'Please select a different combination using the controls above.'
                ]
              : [
                  'There are no Blocked Dates for your school and the selected month.',
                  'An Admin can create a Blocked Date for the school.'
                ]
          }
          isAdmin={isAdmin}
          onlyAdminsCanBlock={onlyAdminsCanBlock}
          selectedTab={selectedTabSp}
          selectedDepartment={departmentIdSp}
        />
        <PaginationOptions
          defaultCurrent={currentPage}
          handlePaginationChange={setCurrentPage}
          handlePageSizeChange={setPageSize}
          pageSize={pageSize}
          total={
            parsedSelectedTab === blockedDatesBy.staff
              ? blockedDatesByStaffer.length
              : parsedSelectedTab === blockedDatesBy.department
              ? blockedDatesByDepartment.length
              : blockedDatesBySchool.length
          }
        />
      </Paper>
    </div>
  )
}

export default BlockDate
