import { styled } from '@mui/material/styles'
import { Help, Settings } from '@mui/icons-material'
import { Card, FormGroup, Typography } from '@mui/material'

export const StyledPageSettingsIcon = styled(Settings)(() => ({
  color: '#757575'
}))

export const StyledPageSettingsOptionsMenu = styled(FormGroup)(({ theme }) => ({
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing()
}))

/* Summaries */
export const HideSummaries = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'displayClassicSummaries' && prop !== 'hideXs'
})(({ displayClassicSummaries, hideXs, theme }) => ({
  display: 'flex',
  flexDirection: displayClassicSummaries ? 'row' : 'column',
  justifyContent: displayClassicSummaries && 'space-between',

  marginBottom: theme.spacing(2),

  [theme.breakpoints.only('xs')]: {
    display: hideXs && 'none'
  },

  [theme.breakpoints.up('sm')]: {
    display: !hideXs && 'none'
  }
}))

/* Upcoming Adjusted Offerings */
export const UpcomingAdjustedOfferingsInstructionText = styled(Typography)(
  () => ({
    maxWidth: 500
  })
)

/* Shared */
export const NoContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}))

export const StyledHelpIcon = styled(Help)(({ theme }) => ({
  color: '#000'
}))

export const StyledPaperContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}))

/* Weekday Card */
export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isLastItem'
})(({ isLastItem, theme }) => ({
  padding: theme.spacing(),
  marginRight: !isLastItem && theme.spacing()
}))

export const SummaryTitle = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 'bold'
}))

export const SummaryDate = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: theme.palette.primary.main
}))

export const SummaryText = styled(Typography)(() => ({
  fontSize: 14
}))

export const SummaryInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

export const SummaryButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing()
}))
