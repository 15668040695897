import { styled } from '@mui/material/styles'

const MultiActionsItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noBorder'
})(({ noBorder, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,

  background: '#d9d9d9',
  borderRight: !noBorder && '1px solid #fff',
  padding: 8,

  [theme.breakpoints.up('sm')]: {
    minWidth: `calc(150.4px - 16px)`,
    justifyContent: 'center'
  }
}))

export default MultiActionsItem
