import React from 'react'
import { Collapse, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import Course from './Course'
import useOpen from '../../hooks/useOpen'

const CourseCount = styled('div', {
  shouldForwardProp: (prop) => prop !== 'coursesLength'
})(({ coursesLength, theme }) => ({
  background: coursesLength > 0 ? theme.palette.primary.main : 'lightgrey',
  color: '#fff',

  border:
    coursesLength > 0 ? `1px solid ${theme.palette.primary.main}` : 'lightgrey',
  borderRadius: '50%',

  width: 25,
  height: 25,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  padding: theme.spacing()
}))

const DepartmentContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(),

  display: 'flex',
  marginBottom: theme.spacing(),

  '&:hover': {
    cursor: 'pointer'
  }
}))

const DepartmentsTree = ({ courses, departmentDescription }) => {
  const { open, toggleOpen } = useOpen()

  return (
    <>
      <DepartmentContainer onClick={toggleOpen}>
        <CourseCount coursesLength={courses.length}>
          {courses.length}
        </CourseCount>
        {open ? (
          <ExpandMore />
        ) : (
          <ExpandLess sx={{ transform: 'rotate(90deg)' }} />
        )}
        <Typography sx={{ wordBreak: 'break-word' }}>
          {departmentDescription}
        </Typography>
      </DepartmentContainer>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {courses.map((course) => (
          <Course key={course.courseId} {...course} />
        ))}
      </Collapse>
    </>
  )
}

export default DepartmentsTree
