import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useSchoolDatesStartAndEnd = () => {
  return useQuery(
    ['school-dates', 'start-and-end'],
    async () => {
      const response = await axios.get(
        `${endpoints.SCHOOL_DATES}/startandend`,
        {
          withCredentials: true
        }
      )

      return response.data
    },
    {
      staleTime: Infinity
    }
  )
}
