import { styled } from '@mui/material/styles'

export const LegendAndBulkEdit = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing()
  }
}))
