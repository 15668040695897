import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { setupMenuItems, sortByCollatingField } from '../../../helpers'

export const useLookupValuesRepeatOptionsQuery = () => {
  return useQuery(['lookup-values', 'repeat-options'], async () => {
    const response = await axios.get(
      `${endpoints.LOOK_UP_VALUES}/repeatoptions`,
      { withCredentials: true }
    )

    return setupMenuItems({
      data: response.data,
      descriptionField: 'enumDescription',
      valueField: 'enumValue'
    }).sort(sortByCollatingField(true, 'description'))
  })
}
