import React from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'

import { sortArrayByTwoFields } from '../../helpers'
import { List, Paper, PaperTitle } from '../shared'

import AppointmentCountItem from './AppointmentCountItem'
import Subheading from './Subheading'

const AppointmentCounts = ({
  appointmentCounts,
  excludedCourses,
  isLoadingAppointmentCounts,
  periods,
  updateExcludedCourses
}) => {
  return (
    <Grid item sm={7} lg={8}>
      <Paper>
        <PaperTitle>Appointment Counts by Date & Period</PaperTitle>
        <Subheading />
        {isLoadingAppointmentCounts ? (
          <Box sx={{ textAlign: 'center', marginTop: 1, marginBottom: 1 }}>
            <CircularProgress />
            <Typography>Loading Counts</Typography>
          </Box>
        ) : (
          <List
            Component={AppointmentCountItem}
            data={sortArrayByTwoFields(
              appointmentCounts,
              'appointmentDate',
              'periodId'
            )}
            emptyArrayMessage='Please select a Date and Period combination to see a list of appointment counts'
            excludedCourses={excludedCourses}
            periods={periods}
            updateExcludedCourses={updateExcludedCourses}
          />
        )}
      </Paper>
    </Grid>
  )
}

export default AppointmentCounts
