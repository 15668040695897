import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useAppointmentsScheduledByAnalysisQuery = ({
  enabled = true,
  selectedNumberOfDays,
  studentId
}) => {
  return useQuery(
    [
      'appointments',
      'scheduled-by-analysis',
      { selectedNumberOfDays, studentId: parseInt(studentId) }
    ],
    async () => {
      const response = await axios.get(
        `${endpoints.APPOINTMENTS}/scheduledbyanalysis/${studentId}/${selectedNumberOfDays}`,
        { withCredentials: true }
      )

      response.data.forEach((r) => {
        const result = (r.unitTotal / r.unitGrandTotal) * 100

        r.percentage = isNaN(result) ? 0 : result
      })

      return response.data
    },
    {
      enabled,
      staleTime: 1000 * 60
    }
  )
}
