import { Badge, IconButton, Menu } from '@mui/material'
import { styled } from '@mui/material/styles'
import { NotificationImportant } from '@mui/icons-material'

import { SelectField } from '../shared'

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer'
  },

  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing()
  },

  '& .MuiBadge-badge': {
    marginTop: 6,
    marginRight: 4,
    color: '#fff'
  }
}))

export const StyledNotificationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    borderRight: '2px solid #707070',
    borderLeft: '2px solid #707070'
  }
}))

export const StyledNotificationHeading = styled('div')(({ theme }) => ({
  height: 80,
  background: '#eee',
  display: 'flex',
  flexDirection: 'column',

  position: 'sticky',
  top: 0,

  [theme.breakpoints.up('sm')]: {
    height: 60,

    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiMenu-list': {
    paddingTop: 0,
    paddingBottom: 0
  }
}))

export const StyledMenuContents = styled('div')(({ theme }) => ({
  boxShadow: theme.shadows[2],
  maxHeight: 500,
  overflow: 'auto',

  minWidth: 300,

  [theme.breakpoints.up('sm')]: {
    minWidth: 450
  }
}))

export const StyledNotificationIcon = styled(NotificationImportant)(
  ({ theme }) => ({
    fontSize: '32px !important'
  })
)

export const StyledNotificationIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'hasUnreadNotifications'
})(({ hasUnreadNotifications, theme }) => ({
  color: hasUnreadNotifications ? theme.palette.primary.main : '#d9d9d9'
}))

export const StyledSelectField = styled(SelectField)(({ theme }) => ({
  marginTop: '-16px !important',
  maxWidth: 250
}))
