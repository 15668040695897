import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { styled } from '@mui/material/styles'

const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 250
  }
}))

const ReactHookFormSelect = ({
  control,
  dataTestId,
  defaultValue,
  errors,
  label,
  menuItems,
  name,
  onClose,
  rules,
  ...props
}) => {
  const labelId = `${name}-label`

  return (
    <FormControl error={Boolean(errors)} variant='standard' {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <StyledSelect
            data-testid={dataTestId}
            labelId={labelId}
            label={label}
            onClose={onClose}>
            {menuItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.description}
              </MenuItem>
            ))}
          </StyledSelect>
        }
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
      />
      <FormHelperText>{errors && errors.message}</FormHelperText>
    </FormControl>
  )
}

export default ReactHookFormSelect
