import React from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { styled } from '@mui/material/styles'
import moment from 'moment'

const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
  width: 250,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),

  [theme.breakpoints.down('xs')]: {
    width: '100%'
  }
}))

const EsTimePicker = ({ label, minutesStep = 5, onChange, value }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  return (
    <StyledTimePicker
      label={label}
      onChange={onChange}
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
      slotProps={{
        popper: {
          sx: {
            '& .MuiList-root': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 65
            }
          }
        },
        textField: { onClick: handleOpen, variant: 'standard' }
      }}
      timeSteps={{ minutes: minutesStep }}
      value={moment(value)}
    />
  )
}

export default EsTimePicker
