import React from 'react'
import { Box, Typography } from '@mui/material'
import { Info } from '@mui/icons-material'

import { Popover } from '../shared'

import { useAdvancedAppointments } from './AdvancedAppointmentsContext'

const Course = ({ courseDescription, courseId, description }) => {
  const { selectedCourseId, updateSelectedCourse } = useAdvancedAppointments()

  const isSelected = courseId === selectedCourseId

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 3.5,
        marginRight: 2,
        paddingLeft: 1,
        paddingBottom: 0.5,
        paddingTop: 0.5,
        backgroundColor: isSelected && 'secondary.main',
        color: isSelected ? '#fff' : '#000',
        '&:hover': {
          cursor: 'pointer'
        }
      }}>
      <Typography onClick={() => updateSelectedCourse(courseId)}>
        {description}
      </Typography>
      {courseDescription && (
        <Popover content={courseDescription}>
          <Info
            sx={{
              color: courseId === selectedCourseId ? '#fff' : '#000'
            }}
          />
        </Popover>
      )}
    </Box>
  )
}

export default Course
