import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useLoggedInUser } from '../../context'
import { Checkbox } from '../shared'

const RosterDetailsAndContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const RosterItem = styled('div')(({ theme }) => ({
  display: 'flex',

  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing()
}))

const DrawerItem = ({
  isHomeroom,
  rosterName,
  rosterId,
  rosteredStudentId,
  stafferId,
  stafferFirstName,
  stafferLastName,
  updateModifiedRosters
}) => {
  const { isAdmin, loggedInUserId } = useLoggedInUser()

  const [checked, setChecked] = useState(rosteredStudentId === 0 ? false : true)

  const disableCheckbox = () => {
    if (isHomeroom) {
      return true
    } else if (!isAdmin && loggedInUserId !== stafferId) {
      return true
    } else {
      return false
    }
  }

  const handleChange = (e) => {
    const { checked } = e.target
    setChecked(checked)
    updateModifiedRosters({ rosterId, isSelected: checked })
  }

  return (
    <RosterItem>
      <Checkbox
        ariaLabel={rosterName}
        checked={checked}
        disabled={disableCheckbox()}
        onChange={handleChange}
        sx={{ paddingLeft: 0 }}
      />
      <RosterDetailsAndContainer>
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 'bold'
          }}>
          {rosterName}
        </Typography>
        <Typography sx={{ fontStyle: 'italic' }}>
          Staffer: {`${stafferLastName}, ${stafferFirstName}`}
        </Typography>
      </RosterDetailsAndContainer>
    </RosterItem>
  )
}

export default DrawerItem
