import React, { useState } from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'

import { NOTIFICATION_TYPES } from '../../constants'
import { useNotifications } from '../../context'
import { List } from '../shared'

import Notification from './Notification'

import {
  StyledBadge,
  StyledMenu,
  StyledMenuContents,
  StyledNotificationContainer,
  StyledNotificationHeading,
  StyledNotificationIcon,
  StyledNotificationIconButton,
  StyledSelectField
} from './styledComponents'
import { useNotificationsMarkAllReadMutation } from '../../hooks'
import { Check } from '@mui/icons-material'

const NotificationMenu = () => {
  const {
    hasUnreadNotifications,
    markAsRead,
    unexpiredNotifications,
    unreadNotificationsLength
  } = useNotifications()

  const { mutate: markAllRead } = useNotificationsMarkAllReadMutation()

  const [anchor, setAnchor] = useState(null)

  const [selectedFilterId, setSelectedFilterId] = useState(0)

  const filteredNotifications =
    selectedFilterId === 0
      ? unexpiredNotifications
      : selectedFilterId === NOTIFICATION_TYPES.studentRequest
      ? unexpiredNotifications.filter(
          (notification) =>
            notification.type === NOTIFICATION_TYPES.studentRequest
        )
      : unexpiredNotifications.filter(
          (notification) =>
            notification.type === NOTIFICATION_TYPES.schoolMessage
        )

  const handleClick = (e) => {
    setAnchor(e.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  const handleCloseAndResetFilter = () => {
    setAnchor(null)
    setSelectedFilterId(0)
  }

  const handleFilterChange = (e) => {
    setSelectedFilterId(e.target.value)
  }

  const setupMessage = () => {
    let message = 'There are no Notifications'

    if (unexpiredNotifications.length > 0 && selectedFilterId !== 0) {
      message = 'There are no Notifications that match the selected filter'
    }

    return message
  }

  return (
    <StyledNotificationContainer>
      <StyledNotificationIconButton
        hasUnreadNotifications={hasUnreadNotifications}
        aria-label='Notifications'
        onClick={handleClick}>
        {unreadNotificationsLength > 0 ? (
          <StyledBadge
            badgeContent={unreadNotificationsLength}
            color='secondary'>
            <StyledNotificationIcon />
          </StyledBadge>
        ) : (
          <StyledNotificationIcon />
        )}
      </StyledNotificationIconButton>
      <StyledMenu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        elevation={5}
        keepMounted
        id='notification-menu'
        onClose={handleClose}
        open={Boolean(anchor)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <StyledMenuContents>
          <StyledNotificationHeading>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                width: '100%',
                paddingLeft: 1,
                paddingRight: 1
              }}>
              <Typography variant='h6'>Notifications</Typography>
              <StyledSelectField
                menuItems={[
                  {
                    description: 'All',
                    value: 0
                  },
                  {
                    description: 'School Notifications',
                    value: NOTIFICATION_TYPES.schoolMessage
                  },
                  {
                    description: 'Student Requests',
                    value: NOTIFICATION_TYPES.studentRequest
                  }
                ]}
                onChange={handleFilterChange}
                selectedValue={selectedFilterId}
              />
              <Tooltip title='Mark All Read'>
                <IconButton onClick={markAllRead}>
                  <Check />
                </IconButton>
              </Tooltip>
            </Box>
          </StyledNotificationHeading>
          <List
            data={filteredNotifications}
            Component={Notification}
            emptyArrayMessage={setupMessage()}
            keyValue='id'
            handleCloseAndResetFilter={handleCloseAndResetFilter}
            markAsRead={markAsRead}
          />
        </StyledMenuContents>
      </StyledMenu>
    </StyledNotificationContainer>
  )
}

export default NotificationMenu
