import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLoggedInUser } from '../../context'

import {
  useAppointmentTypesQuery,
  useCoursesForDatePeriodStudentIdQuery,
  useDepartmentsQuery,
  useGradesByStudentIdQuery,
  usePeriodsMinimalActiveOnlyQuery,
  useStartAndEndDate,
  useStudentsCanOverrideAppointmentsQuery
} from '../../hooks'

import {
  useCoursesByCourseDatePeriodQuery,
  useScheduleAdvancedAppointmentMutation
} from './hooks'
import { appointmentSaveEnums } from '../../enums'

const AdvancedAppointmentsContext = createContext()

const AdvancedAppointmentsProvider = ({ children }) => {
  const { isAdmin, isSubstitute, loggedInUserId } = useLoggedInUser()
  const { date, periodId, studentId, studentName } = useParams()

  const [
    startDate,
    endDate,
    updateDates,
    DATE_CONSTANTS,
    isoWeekdays,
    setIsoWeekdays
  ] = useStartAndEndDate(date, date)

  const { data: appointmentTypes = [], isLoading: isLoadingAppointmentTypes } =
    useAppointmentTypesQuery()

  const { data: departments = [], isLoading: isLoadingDepartments } =
    useDepartmentsQuery()

  const { data: grades = [], isLoading: isLoadingGrades } =
    useGradesByStudentIdQuery({ studentId })

  const { data: periods = [], isLoading: isLoadingPeriods } =
    usePeriodsMinimalActiveOnlyQuery({
      includeActiveOnly: true
    })

  const [periodIdToUse, setPeriodIdToUse] = useState(periodId)

  // When coming from StudentProfile it's impossible to determine
  // what an active periodId is, so it uses 0
  // this code checks for that value and then assigns the selected period id
  // to the first active period we have
  useEffect(() => {
    if (periods.length === 0) return

    const periodInfo = periods.find(
      (period) => period.periodId === parseInt(periodId)
    )

    if (periodInfo === undefined) {
      setPeriodIdToUse(periods[0].periodId)
      setSelectedPeriods([periods[0].periodId])
    }
  }, [periodId, periods])

  const {
    error: coursesForDatePeriodStudentIdError,
    data: courses = [],
    isLoading: isLoadingCourses
  } = useCoursesForDatePeriodStudentIdQuery({
    date,
    periodId: periodIdToUse,
    studentId
  })

  const {
    data: studentCanOverrideAppointments = false,
    isLoading: isLoadingStudentCanOverrideAppointments
  } = useStudentsCanOverrideAppointmentsQuery({ studentId })

  const [errorNoAvailableCourses, setErrorNoAvailableCourses] = useState(false)
  const [errorNoSelectedCourse, setErrorNoSelectedCourse] = useState(false)

  const [autoIncreaseSeatCount, setAutoIncreaseSeatCount] = useState(false)
  const [canBeOverwrittenByStudent, setCanBeOverwrittenByStudent] =
    useState(false)
  const [isLocked, setIsLocked] = useState(false)
  const [schedulerComment, setSchedulerComment] = useState('')
  const [selectedAppointmentType, setSelectedAppointmentType] = useState('')
  const [
    selectedCourseMustUseDefaultAppointmentType,
    setSelectedCourseMustUseDefaultAppointmentType
  ] = useState(false)
  const [selectedCourseId, setSelectedCourseId] = useState('')
  const [selectedPeriods, setSelectedPeriods] = useState([parseInt(periodId)])

  const {
    coursesBySelections = [],
    excludedCourses,
    isLoadingCoursesBySelections,
    setExcludedCourses
  } = useCoursesByCourseDatePeriodQuery({
    courseId: selectedCourseId,
    endDate,
    periodIds: selectedPeriods,
    startDate,
    studentId,
    weekdaysToInclude: isoWeekdays
  })

  const {
    data: scheduleAppointmentResponse = {},
    collisions,
    isLoading: isSchedulingAdvancedAppointment,
    mutate: scheduleAppointment,
    setCollisions
  } = useScheduleAdvancedAppointmentMutation({
    resetFormAfterSave: () => {
      setAutoIncreaseSeatCount(false)
      setCanBeOverwrittenByStudent(false)
      setIsLocked(false)
      setSchedulerComment('')
      setSelectedAppointmentType('')
      setSelectedCourseId('')
      setSelectedPeriods([parseInt(periodId)])
    }
  })

  const { shouldRedirectAfterSave } = scheduleAppointmentResponse

  // This is separate from setupStudentToSchedule so that the
  // student can be scheduled from the 'main' section or
  // from the Summary that handles the collisions
  const scheduleStudent = ({ details, shouldRedirectAfterSave }) => {
    // User has not selected a course
    if (selectedCourseId === '') {
      setErrorNoSelectedCourse(true)
      return
    }

    // No courses are available
    if (details.datesAndPeriods.length === 0) {
      setErrorNoAvailableCourses(true)
      return
    }

    // Otherwise make the call
    scheduleAppointment({ details, shouldRedirectAfterSave })
  }

  const setupStudentToSchedule = ({ shouldRedirectAfterSave }) => {
    let datesAndPeriods = coursesBySelections.filter(
      ({ appointmentDate, periodId }) =>
        !excludedCourses.some(
          (e) =>
            e.appointmentDate === appointmentDate && e.periodId === periodId
        )
    )

    datesAndPeriods = datesAndPeriods.map((obj) => ({
      appointmentDate: obj.appointmentDate,
      periodId: obj.periodId
    }))

    const details = {
      adjustedById: loggedInUserId,
      appointmentId: 0,
      appointmentTypeId: selectedAppointmentType,
      autoIncreaseSeatCount,
      canBeOverwrittenByStudent,
      courseId: selectedCourseId,
      datesAndPeriods,
      isLocked,
      overrideAppointments: true,
      processThatModifiedAppointment:
        appointmentSaveEnums.advancedAppointmentsSingle,
      schedulerComment,
      studentIds: [parseInt(studentId)]
    }

    scheduleStudent({ details, shouldRedirectAfterSave })
  }

  const updateCoursesToExclude = ({ appointmentDate, checked, periodId }) => {
    if (checked) {
      setExcludedCourses([...excludedCourses, { appointmentDate, periodId }])
    }

    if (!checked) {
      setExcludedCourses(
        excludedCourses.filter(
          (course) =>
            course.appointmentDate !== appointmentDate ||
            course.periodId !== periodId
        )
      )
    }
  }

  const updateSelectedCourse = (courseId) => {
    const selectedCourseInfo = courses.find(
      (course) => course.courseId === courseId
    )

    setSelectedAppointmentType(selectedCourseInfo.defaultAppointmentTypeId)
    setSelectedCourseId(courseId)
    setSelectedCourseMustUseDefaultAppointmentType(
      selectedCourseInfo.mustUseDefaultAppointmentType
    )

    setErrorNoSelectedCourse(false)
  }

  return (
    <AdvancedAppointmentsContext.Provider
      value={{
        appointmentTypes,
        autoIncreaseSeatCount,
        canBeOverwrittenByStudent,
        collisions,
        courses,
        coursesBySelections,
        coursesForDatePeriodStudentIdError,
        departments,
        endDate,
        errorNoAvailableCourses,
        errorNoSelectedCourse,
        excludedCourses,
        grades,
        isAdmin,
        isLoading:
          isLoadingAppointmentTypes ||
          isLoadingCourses ||
          isLoadingDepartments ||
          isLoadingGrades ||
          isLoadingPeriods ||
          isLoadingStudentCanOverrideAppointments,
        isLoadingCoursesBySelections,
        isLocked,
        isSchedulingAdvancedAppointment,
        isSubstitute,
        isoWeekdays,
        loggedInUserId,
        periods,
        schedulerComment,
        scheduleStudent,
        selectedAppointmentType,
        selectedCourseId,
        selectedCourseMustUseDefaultAppointmentType,
        selectedPeriods,
        setAutoIncreaseSeatCount,
        setCanBeOverwrittenByStudent,
        setCollisions,
        setIsLocked,
        setIsoWeekdays,
        setSchedulerComment,
        setSelectedAppointmentType,
        setSelectedPeriods,
        setupStudentToSchedule,
        shouldRedirectAfterSave,
        startDate,
        studentCanOverrideAppointments,
        studentId,
        studentName,
        updateCoursesToExclude,
        updateDates,
        updateSelectedCourse
      }}>
      {children}
    </AdvancedAppointmentsContext.Provider>
  )
}

const useAdvancedAppointments = () => {
  const context = useContext(AdvancedAppointmentsContext)

  if (!context) {
    throw new Error(
      'useAdvancedAppointments must be used with a AdvancedAppointmentsProvider'
    )
  }

  return context
}

export {
  AdvancedAppointmentsContext,
  AdvancedAppointmentsProvider,
  useAdvancedAppointments
}
