import { styled } from '@mui/material/styles'
import { Button, Typography } from '@mui/material'

import { TextField } from '../shared'

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: 300,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',

  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
    justifyContent: 'center',

    paddingLeft: 0,
    paddingRight: 0
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    textAlign: 'center'
  }
}))

export const ForgotPasswordText = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: 12,
  cursor: 'pointer',

  marginBottom: theme.spacing(2),

  display: 'flex'
}))

export const LoginButton = styled(Button)(({ theme }) => ({
  minWidth: 300,
  height: 51,
  fontSize: 20,
  fontWeight: 'bold',
  backgroundColor: theme.palette.success.main,
  color: '#fff',

  [theme.breakpoints.up('md')]: {
    minWidth: 456
  }
}))

export const LoginStatusErrorMessage = styled('div')(({ theme }) => ({
  textAlign: 'start',

  color: '#fff',
  marginTop: theme.spacing(2),
  padding: theme.spacing(),

  background: theme.palette.error.main
}))

export const Logo = styled('img')(({ theme }) => ({
  height: 62,
  marginBottom: theme.spacing(3),

  [theme.breakpoints.only('xs')]: {
    width: 300
  }
}))

export const LogoContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

export const OrText = styled(Typography)(({ theme }) => ({
  color: '#fff',
  width: '100%',

  textAlign: 'center',
  borderBottom: '1px solid #fff',
  lineHeight: '0.07em',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(),

  span: {
    background: theme.palette.primary.main,
    padding: '0 10px',
    fontSize: 25
  }
}))

export const StyledImg = styled('img')(({ theme }) => ({
  width: 38,
  height: 38,
  marginRight: theme.spacing()
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '300px !important',
  marginBottom: theme.spacing(2),

  '& label.Mui-focused': {
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white'
    },
    '&:hover fieldset': {
      borderColor: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  },

  '.MuiInputLabel-root': {
    color: 'white',
    fontSize: 15,
    '&$focusedLabel': {
      color: 'white'
    },
    '&$erroredLabel': {
      color: theme.palette.error.main
    }
  },

  '.Mui-focused': {
    color: '#fff !important'
  },

  '.MuiInput-underline:before': {
    borderBottom: '1px solid white'
  },

  [theme.breakpoints.up('md')]: {
    width: '456px !important'
  }
}))

export const ThirdPartyButton = styled(Button)(({ theme }) => ({
  minWidth: 300,
  height: 51,

  marginTop: theme.spacing(3),

  fontSize: 20,
  background: '#fff',
  color: '#707070',

  '&:hover': {
    background: '#fff',
    color: '#707070'
  },

  textTransform: 'none',

  [theme.breakpoints.up('md')]: {
    minWidth: 456
  }
}))
