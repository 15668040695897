import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getActiveStaffCountQueryKey } from '../../../helpers/queryKeys'

export const useActiveStaffCountQuery = () => {
  return useQuery(
    getActiveStaffCountQueryKey(),
    async () => {
      const response = await axios.get(`${endpoints.STAFF}/activeStaffCount`, {
        withCredentials: true
      })

      return response.data
    },
    {
      staleTime: 1000 * 60
    }
  )
}
