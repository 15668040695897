import React, { useEffect } from 'react'
import { Cancel, CheckCircleRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

import { regExValidEmail } from '../../../constants'
import { useEmailValidation } from '../../../hooks'

import TextField from '../TextField'

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

const StyledCheck = styled(CheckCircleRounded)(({ theme }) => ({
  color: theme.palette.success.main,
  marginLeft: theme.spacing()
}))

const StyledCancel = styled(Cancel)(({ theme }) => ({
  color: theme.palette.error.main,
  marginLeft: theme.spacing()
}))

const EmailValidationField = ({
  clearErrors,
  emailAddress,
  emailErrors,
  register,
  serverErrors,
  setEmailValidationResponseData = () => {},
  setError,
  setParentValidating,
  userId,
  userToSchoolId,
  userType,
  watch
}) => {
  const { isUnique, isValidatingEmail, validateChangedEmailAddress } =
    useEmailValidation({
      clearErrors,
      emailAddress,
      setEmailValidationResponseData,
      setError,
      userId,
      userToSchoolId,
      userType,
      watch,
      watchValue: 'emailAddress'
    })

  useEffect(() => {
    setParentValidating(isValidatingEmail)
  }, [isValidatingEmail, setParentValidating])

  return (
    <Container>
      <TextField
        dataTestId='email-address'
        defaultValue={emailAddress}
        disabled={isValidatingEmail}
        error={Boolean(emailErrors) || Boolean(serverErrors)}
        helperText={
          (emailErrors && emailErrors.message) ||
          (serverErrors && serverErrors.message)
        }
        inputRef={register({
          required: 'Please enter an Email Address',
          pattern: {
            value: regExValidEmail,
            message: 'This email address is invalid'
          }
        })}
        label='Email Address'
        onBlur={validateChangedEmailAddress}
        name='emailAddress'
      />
      {isValidatingEmail && (
        <CircularProgress
          data-testid='validating-email-address-progress'
          size={20}
        />
      )}
      {isUnique === true && !isValidatingEmail && (
        <StyledCheck data-testid='email-validation-success-icon' />
      )}
      {isUnique === false && !isValidatingEmail && <StyledCancel />}
    </Container>
  )
}

export default EmailValidationField
