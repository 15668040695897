import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import moment from 'moment'

export const useCoursesForDatesPeriodStaffQuery = ({
  endDate,
  periodIds,
  stafferId,
  startDate,
  weekdaysToInclude
}) => {
  return useQuery(
    [
      'courses',
      'for-date-period-staffer',
      {
        endDate: moment(endDate).format('YYYY-MM-DD'),
        periodIds,
        stafferId,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        weekdaysToInclude
      }
    ],
    async () => {
      const response = await axios.post(
        `${endpoints.COURSES}/fordatesperiodstaffer`,
        {
          endDate: moment(endDate).format('YYYY-MM-DD'),
          periodIds,
          stafferId,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          studentId: 0,
          weekdaysToInclude
        },
        { withCredentials: true }
      )

      return response.data
    },
    { enabled: !!stafferId, staleTime: 1000 * 30 }
  )
}
