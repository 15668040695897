import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStafferQueryKey } from '../../../helpers'

export const fetchStaffDetails = async (userId) => {
  const response = await axios.get(`${endpoints.STAFF}/${userId}`, {
    withCredentials: true
  })

  return response.data
}

export const useStaffByIdQuery = (userId) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(getStafferQueryKey(userId), () => fetchStaffDetails(userId), {
    enabled: userId !== '0',
    onError: () => {
      // Invalidate cached Messages
      queryClient.invalidateQueries(getStafferQueryKey(userId))

      enqueueSnackbar(
        `We are unable to find this record, it's likely that it has been deleted by another user.`,
        {
          variant: 'error'
        }
      )
    },
    staleTime: 1000 * 30
  })
}
