import React, { useState } from 'react'

import { esuUrls, NOTIFICATION_TYPES } from '../../constants'
import { useNotifications } from '../../context'
import { useHeaderTitle } from '../../hooks'
import { List, Paper, PaperTitle, Tabs, UserManual } from '../shared'

import { MessageItem } from '../SchoolNotifications'

const SchoolNotifications = () => {
  useHeaderTitle('School Notifications')
  const { markAsRead, unexpiredNotifications } = useNotifications()

  const schoolNotifications = unexpiredNotifications.filter(
    (notification) => notification.type === NOTIFICATION_TYPES.schoolMessage
  )

  const [selectedTab, setSelectedTab] = useState(0)

  const updateSelectedTab = (value) => {
    setSelectedTab(value)
  }

  return (
    <div>
      <UserManual href={esuUrls.SCHOOL_NOTIFICATIONS} />
      <Tabs
        labels={['Unread Messages', 'Read Messages']}
        selectedTab={selectedTab}
        setSelectedTab={updateSelectedTab}
      />
      <Paper>
        <PaperTitle>Messages</PaperTitle>
        <List
          Component={MessageItem}
          data={
            selectedTab === 0
              ? schoolNotifications.filter((message) => !message.hasBeenRead)
              : schoolNotifications.filter((message) => message.hasBeenRead)
          }
          emptyArrayMessage={
            selectedTab === 0
              ? 'There are no unread messages'
              : 'There are no messages'
          }
          keyValue='id'
          markAsRead={markAsRead}
        />
      </Paper>
    </div>
  )
}

export default SchoolNotifications
