import { styled } from '@mui/material/styles'

export const ButtonAndLicenseInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  marginBottom: theme.spacing(),

  [theme.breakpoints.up('lg')]: {
    marginBottom: 0,

    flex: 1,
    justifyContent: 'space-between'
  }
}))
