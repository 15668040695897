import React from 'react'
import { MenuItem } from '@mui/material'

import { userTypeIds } from '../../constants'
import { Menu, SelectField } from '../shared'

import { StyledListSubheader } from './styledComponents'

const FiltersMenu = ({
  isActiveSearchParam,
  departmentIdSearchParam,
  departments,
  updateSearchParams,
  userTypeSearchParam
}) => {
  const setupDepartmentsMenu = () => {
    let array = [
      {
        description: 'All',
        value: 0
      }
    ]

    departments.forEach((department) => {
      array.push({
        description: department.departmentDescription,
        value: department.departmentId
      })
    })

    return array
  }

  return (
    <div>
      <Menu
        buttonAriaLabel='filter staffers by'
        dataTestId='filter-menu'
        label='Filter'>
        <StyledListSubheader>Filter Staffers By</StyledListSubheader>
        <MenuItem>
          <SelectField
            label='Department'
            labelId='filter-staffers-by-department'
            menuItems={setupDepartmentsMenu()}
            name='departmentId'
            onChange={updateSearchParams}
            selectedValue={
              departmentIdSearchParam ? departmentIdSearchParam : 0
            }
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='Is Active'
            labelId='filter-staffers-by-is-active'
            menuItems={[
              {
                description: 'All',
                value: 0
              },
              {
                description: 'Active',
                value: 1
              },
              {
                description: 'Not Active',
                value: 2
              }
            ]}
            name='isActive'
            onChange={updateSearchParams}
            selectedValue={isActiveSearchParam ? isActiveSearchParam : 0}
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='User Type'
            labelId='filter-staffers-by-usertype'
            menuItems={[
              {
                description: 'All',
                value: 0
              },
              {
                description: 'Admins',
                value: userTypeIds.admin
              },
              {
                description: 'District Admins',
                value: 1000
              },
              {
                description: 'Staffers',
                value: userTypeIds.staffer
              }
            ]}
            name='userTypeId'
            onChange={updateSearchParams}
            selectedValue={userTypeSearchParam ? userTypeSearchParam : 0}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default FiltersMenu
