import React from 'react'

import { useRostersOnlyByStafferQuery } from '../../hooks'
import {
  FileUploadButton,
  Loading,
  MultiSelect,
  RadioGroup,
  SelectField
} from '../shared'

import FileErrors from './FileErrors'
import { useMassAppointments } from './MassAppointmentsContext'
import StepNavButtons from './StepNavButtons'

import {
  useAppointmentsMassSchedulingSetupStudentsMutation,
  useFileUploadByTypeMutation
} from './hooks'
import { Typography } from '@mui/material'

const stafferOptions = [
  {
    label: 'File',
    value: 'file'
  },
  {
    label: 'Group',
    value: 'group'
  }
]

const adminOptions = [
  {
    label: 'Graduation Year',
    value: 'graduation-year'
  },
  ...stafferOptions
]

const SelectSchedulingOptionsStep = ({
  excludedStudentsIds,
  handleNext,
  selectedFile,
  selectedGraduationYears,
  selectedSchedulingOption,
  selectedSchedulingStaffer,
  setSelectedFile,
  setSelectedGraduationYears,
  setSelectedSchedulingOption,
  setSelectedSchedulingStaffer
}) => {
  const {
    graduationYears,
    isAdmin,
    selectedGroupId,
    setSelectedGroupId,
    staffers
  } = useMassAppointments()

  const { data: studentGroups = [] } = useRostersOnlyByStafferQuery({
    userId: selectedSchedulingStaffer
  })

  const {
    data: fileImportErrors = { errorMessages: [], generalMessages: [] },
    isLoading: isUploadingFile,
    mutate: uploadFile
  } = useFileUploadByTypeMutation()

  const { mutate: runSetupProcess } =
    useAppointmentsMassSchedulingSetupStudentsMutation({ handleNext })

  const disableNextButton = () => {
    if (
      (selectedSchedulingOption === 'graduation-year' &&
        selectedGraduationYears.length < 1) ||
      (selectedSchedulingOption === 'group' && selectedGroupId === '') ||
      (selectedSchedulingOption === 'file' && selectedFile.name === '') ||
      (selectedSchedulingOption === 'file' &&
        fileImportErrors?.generalMessages[0] ===
          '0 student(s) were matched and will be scheduled.')
    ) {
      return true
    }

    return false
  }

  const handleNextClick = () => {
    runSetupProcess({
      graduationYears:
        selectedSchedulingOption === 'graduation-year'
          ? selectedGraduationYears
          : [],
      rosterId:
        selectedSchedulingOption === 'graduation-year' ||
        selectedSchedulingOption === 'file'
          ? 0
          : selectedGroupId,
      studentIdsToExclude: excludedStudentsIds
    })
  }

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0])

    const formData = new FormData()
    formData.append('file', e.target.files[0])

    uploadFile(formData)
  }

  if (isUploadingFile) return <Loading text='Checking File For Errors' />

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 16 }}>
      <RadioGroup
        data={isAdmin ? adminOptions : stafferOptions}
        formLabel='Schedule By'
        name='schedule-by'
        onChange={(e) => setSelectedSchedulingOption(e.target.value)}
        selectedValue={selectedSchedulingOption}
      />
      {selectedSchedulingOption === 'graduation-year' && (
        <MultiSelect
          label='Graduation Years'
          onChange={(e) => setSelectedGraduationYears(e.target.value)}
          values={graduationYears}
          selectedValues={selectedGraduationYears}
        />
      )}
      {selectedSchedulingOption === 'file' && (
        <FileUploadButton
          id='upload-file'
          onChange={onFileChange}
          selectedFileName={selectedFile.name}
        />
      )}
      {selectedSchedulingOption === 'group' && (
        <>
          <SelectField
            label='Selected Staffer'
            labelId='selected-staffer'
            onChange={(e) => setSelectedSchedulingStaffer(e.target.value)}
            menuItems={staffers}
            selectedValue={selectedSchedulingStaffer}
          />
          <SelectField
            label='Selected Group'
            labelId='selected-group'
            onChange={(e) => setSelectedGroupId(e.target.value)}
            menuItems={studentGroups}
            selectedValue={selectedGroupId}
          />
        </>
      )}
      {selectedSchedulingOption === 'file' &&
        fileImportErrors.errorMessages.length > 0 && (
          <FileErrors errors={fileImportErrors.errorMessages} />
        )}
      {selectedSchedulingOption === 'file' &&
        fileImportErrors.generalMessages.length > 0 && (
          <>
            {fileImportErrors.generalMessages.map((message, i) => (
              <Typography key={i} sx={{ marginBottom: 1 }}>
                {message}
              </Typography>
            ))}
          </>
        )}
      <StepNavButtons
        disableNext={disableNextButton()}
        disablePrev={true}
        onClickNext={handleNextClick}
        stepNumber='1'
      />
    </div>
  )
}

export default SelectSchedulingOptionsStep
