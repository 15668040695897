import { styled } from '@mui/material/styles'

const Item3 = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSubstitute'
})(({ isSubstitute, theme }) => ({
  gridColumn: '1/13',

  [theme.breakpoints.up('sm')]: {
    gridColumn: isSubstitute ? '4/7' : '7/10'
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing()
  }
}))

export default Item3
