import { useState } from 'react'
import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints } from '../constants'
import useShouldDownloadCsv from './useShouldDownloadCsv'

const useExportData = () => {
  const [csvData, setCsvData] = useState('')

  const { csvRef, setShouldDownloadCsv } = useShouldDownloadCsv()

  const { isLoading: isLoadingExportData, mutate: getExportData } = useMutation(
    async (appointmentExportDefinitionId) => {
      const response = await axios.get(
        `${endpoints.APPOINTMENTS}/export/${appointmentExportDefinitionId}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      onSuccess: (data) => {
        setCsvData(data)
        setShouldDownloadCsv(true)
      }
    }
  )

  return {
    csvData,
    csvRef,
    getExportData,
    isLoadingExportData
  }
}

export default useExportData
