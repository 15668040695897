import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getLookupValuesMessageForQueryKey,
  sortByCollatingField
} from '../../../helpers'

export const useLookUpValuesMessageForQuery = () => {
  return useQuery(
    getLookupValuesMessageForQueryKey(),
    async () => {
      const response = await axios.get(
        `${endpoints.LOOK_UP_VALUES}/messagefor`,
        {
          withCredentials: true
        }
      )

      response.data.forEach((r) => {
        r.description = r.enumDescription
        r.value = r.enumValue
      })

      response.data.sort(sortByCollatingField(true, 'enumDescription'))

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
