import { styled } from '@mui/material/styles'

const ListContainer = styled('div')(() => ({
  height: 'calc(100vh - 81px - 118px)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}))

export default ListContainer
