import React, { useRef } from 'react'
import { FormHelperText, MenuItem } from '@mui/material'
import { Comment } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { getStudentDisplayName } from '../../helpers'
import { useAppointmentsClearByIdMutation } from '../../hooks'
import {
  Checkbox,
  DeleteModal,
  ListItemText,
  ListItemContainer,
  Loading,
  Menu,
  Popover,
  SelectField,
  StudentInfo
} from '../shared'

import CreateCommentDrawer from './CreateCommentDrawer'
import useOpen from '../../hooks/useOpen'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'

const AttendanceType = styled('div')(({ theme }) => ({
  gridColumn: '1/10',

  [theme.breakpoints.up('lg')]: {
    gridColumn: '4/7'
  }
}))

const MenuContainer = styled('div')(({ theme }) => ({
  gridColumn: '1/10',

  [theme.breakpoints.up('lg')]: {
    gridColumn: '12/13'
  }
}))

const AttendanceListItem = ({
  // API Props
  appointmentId,
  attendanceComment,
  currentAttendanceTypeId,
  firstName,
  freezeAppointments,
  lastName,
  nickname,
  schedulerComment,
  sisId,
  studentWasTardy,
  userId,
  yearOfGraduation,
  wasSetByImport,
  // Other Props
  appointments,
  attendanceDate,
  attendanceTypes,
  handleAttendanceCommentChange,
  handleAttendanceTypeChange,
  handleTardyChange,
  isFutureDate,
  removeComment,
  selectedStaffer
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const menuRef = useRef()

  const { open, handleClose, handleOpen } = useOpen()

  const appointmentDetails = appointments.find(
    (a) => a.appointmentId === appointmentId
  )
  const fullName = getStudentDisplayName(firstName, lastName, nickname)

  const { isLoading: isRemovingStudent, mutate: removeStudent } =
    useAppointmentsClearByIdMutation()

  const closeMenu = () => {
    menuRef.current.closeMenuFromParent()
  }

  const confirmDeleteCallback = () => {
    removeStudent({
      appointmentIds: [appointmentId],
      optionalCallback: () => {
        enqueueSnackbar(
          `${fullName} has been removed from ${appointmentDetails.periodDescription}`,
          {
            variant: 'success'
          }
        )

        queryClient.invalidateQueries(['appointments'])

        queryClient.setQueryData(
          [
            'appointments',
            'detail-for-attendance',
            { attendanceDate, userId: parseInt(selectedStaffer) }
          ],
          (prevAttendanceDetails) => {
            return [
              ...prevAttendanceDetails.filter(
                (detail) => detail.appointmentId !== appointmentId
              )
            ]
          }
        )
      }
    })
  }

  const handleRemoveCommentClick = () => {
    closeMenu()
    removeComment(appointmentId)
  }

  const saveComment = (comment) => {
    handleAttendanceCommentChange(appointmentId, comment)
    //closeDrawer()
    handleClose()
  }

  if (isRemovingStudent)
    return <Loading blockFromChild='true' text='Removing Student' />

  return (
    <ListItemContainer dataTestId='attendance-list-item'>
      <ListItemText sx={{ gridColumn: { xs: '1/10', lg: '1/4' } }}>
        <StudentInfo
          firstName={firstName}
          lastName={lastName}
          nickName={nickname}
          sisId={sisId}
          userId={userId}
          yearOfGraduation={yearOfGraduation}
        />
      </ListItemText>
      <AttendanceType>
        <SelectField
          dataTestId={`testid-${appointmentId}`}
          disabled={isFutureDate}
          inputProps={{ 'aria-label': `selected-attendance-type-${fullName}` }}
          menuItems={attendanceTypes}
          onChange={(e) =>
            handleAttendanceTypeChange(e.target.value, appointmentId)
          }
          selectedValue={currentAttendanceTypeId}
        />
        {wasSetByImport && (
          <FormHelperText>(Set by Attendance Sync)</FormHelperText>
        )}
      </AttendanceType>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/10', lg: '7/8' },
          display: 'flex',
          alignItems: 'center'
        }}
        labelText='Tardy:'>
        <Checkbox
          checked={studentWasTardy}
          disabled={isFutureDate}
          id={`id-${appointmentId}`}
          name={`name-${appointmentId}`}
          onChange={() => handleTardyChange(appointmentId)}
        />
      </ListItemText>
      {attendanceComment && (
        <ListItemText
          sx={{
            gridColumn: { xs: '1/10', lg: '8/10' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: { lg: 'center' }
          }}
          labelText='Attendance Comment:'>
          <Popover content={attendanceComment} dataTestId={`comment-popover`}>
            <Comment />
          </Popover>
        </ListItemText>
      )}
      {schedulerComment && (
        <ListItemText
          sx={{
            gridColumn: { xs: '1/10', lg: '10/12' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: { lg: 'center' }
          }}
          labelText='Scheduler Comment:'>
          <Popover content={schedulerComment} dataTestId={`comment-popover`}>
            <Comment />
          </Popover>
        </ListItemText>
      )}
      <MenuContainer>
        <Menu ref={menuRef}>
          {!isFutureDate && (
            <MenuItem
              onClick={() => {
                handleOpen()
                closeMenu()
              }}>
              {attendanceComment ? 'Edit Comment' : 'Add Comment'}
            </MenuItem>
          )}
          {attendanceComment && (
            <DeleteModal
              label='Remove Comment'
              confirmDeleteCallback={handleRemoveCommentClick}
              confirmDeleteLabel='Remove Comment'
              confirmDeleteMessage='Are you sure you want to remove this comment?'
              itemName={attendanceComment}
            />
          )}
          {(!freezeAppointments || isFutureDate) && (
            <DeleteModal
              label='Remove Student'
              //confirmDeleteCallback={() => removeStudent(appointmentId)}
              confirmDeleteCallback={confirmDeleteCallback}
              confirmDeleteLabel='Remove Student'
              confirmDeleteMessage='Are you sure you want to remove this student?'
              itemName={fullName}
            />
          )}
        </Menu>
      </MenuContainer>
      {open && (
        <CreateCommentDrawer
          attendanceComment={attendanceComment}
          closeDrawer={handleClose}
          fullName={fullName}
          saveComment={saveComment}
        />
      )}
    </ListItemContainer>
  )
}

export default AttendanceListItem
