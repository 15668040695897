import React from 'react'
import { styled } from '@mui/material/styles'

import { ListItemContainer, ListItemText } from '../shared'

const StyledInstructorDisplayNameSpan = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none'
  },

  [theme.breakpoints.up('lg')]: {
    display: 'block'
  },

  fontWeight: 400,
  fontStyle: 'italic',
  fontSize: '13px'
}))

const GradeItem = ({
  course,
  gradeValue,
  isBelowCutOff,
  isLastItem,
  stafferFirstName,
  stafferLastName
}) => {
  const stafferDisplayName = stafferLastName + ', ' + stafferFirstName

  return (
    <ListItemContainer
      sx={{ color: isBelowCutOff && 'error.main' }}
      isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/6' }
        }}
        labelText='Course:'>
        {course}
        <br />
        <StyledInstructorDisplayNameSpan>
          Instructor: {stafferDisplayName}
        </StyledInstructorDisplayNameSpan>
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '6/13' },
          display: { lg: 'none' }
        }}
        labelText='Instructor:'>
        {stafferDisplayName}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '6/13' }
        }}
        labelText='Grade:'>
        {gradeValue}
      </ListItemText>
    </ListItemContainer>
  )
}

export default GradeItem
