import React, { useState } from 'react'
import { Card, Collapse, IconButton, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

import { useAppointments } from './AppointmentsContext'
import ControlsActionsMenu from './ControlsActionsMenu'
import ControlsContent from './ControlsContent'
import { ControlsTitle } from './styledComponents'

const Controls = () => {
  const { emailStudentSchedule, isSubstitute, isXs } = useAppointments()

  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      {isXs ? (
        <>
          <ControlsActionsMenu
            emailStudentSchedule={emailStudentSchedule}
            hideXsDown={false}
            isSubstitute={isSubstitute}
          />
          <Card
            sx={{
              marginTop: 1,
              marginBottom: 1,
              padding: 1,
              display: { sm: 'none' }
            }}>
            <ControlsTitle>
              <Typography variant='h6'>Controls</Typography>
              <IconButton
                onClick={toggleExpanded}
                aria-expanded={expanded}
                aria-label='show controls'>
                <ExpandMore
                  sx={{
                    transform: expanded && 'rotate(180deg)'
                  }}
                />
              </IconButton>
            </ControlsTitle>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <ControlsContent />
            </Collapse>
          </Card>
        </>
      ) : (
        <ControlsContent />
      )}
    </>
  )
}

export default Controls
