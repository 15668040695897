import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useAppointmentsMassDeleteAppointmentsRequestMutation = () => {
  return useMutation(
    async ({
      cleanerType,
      deleteKey,
      endDate,
      periodId,
      startDate,
      weekDaysToInclude
    }) => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/massdeleteappointmentsrequest`,
        {
          cleanerType,
          deleteKey,
          endDate,
          periodId,
          startDate,
          weekDaysToInclude
        },
        { withCredentials: true }
      )

      return response.data
    }
  )
}
