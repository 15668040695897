import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { Button, Typography } from '@mui/material'

import { APPOINTMENT_EDITOR_RESPONSES, endpoints } from '../../../constants'
import { useLoggedInUser } from '../../../context'

export const useAppointmentsSaveMutation = () => {
  const { loggedInUserId } = useLoggedInUser()
  const queryClient = useQueryClient()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({
      appointmentId,
      courseId,
      date,
      isLocked,
      maxDateAdjusted,
      periodId,
      processThatModifiedAppointment = 0,
      optionalCallback = () => {},
      overrideAppointments,
      schedulerComment,
      studentIds,
      students
    }) => {
      const formattedDate = moment(date).format('YYYY-MM-DD')

      let objectToPost = {
        adjustedById: loggedInUserId,
        courseId,
        datesAndPeriods: [
          {
            appointmentDate: formattedDate,
            periodId
          }
        ],
        maxDateAdjusted,
        overrideAppointments,
        processThatModifiedAppointment,
        studentIds
      }

      if (studentIds.length <= 1) {
        objectToPost.appointmentId = appointmentId
        objectToPost.isLocked = isLocked
        objectToPost.schedulerComment = schedulerComment
      }

      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/save`,
        objectToPost,
        { withCredentials: true }
      )

      return { data: response.data, optionalCallback, studentIds, students }
    },
    {
      onSuccess: ({ data, optionalCallback, studentIds, students }) => {
        // invalidate the query cache for the Take Attendance page
        queryClient.invalidateQueries(['appointments'])

        const selectedStudents = students.filter((student) =>
          studentIds.includes(student.userId)
        )

        // let arrayOfAppointmentsWithErrors = []

        // data.forEach((r) => {
        //   if (
        //     r.appointmentEditorResponse !==
        //     APPOINTMENT_EDITOR_RESPONSES.appointmentSaved
        //   ) {
        //     arrayOfAppointmentsWithErrors.push(r)
        //   }
        // })

        let arrayOfAppointmentsWithErrors = data.filter(
          (r) =>
            r.appointmentEditorResponse !==
            APPOINTMENT_EDITOR_RESPONSES.appointmentSaved
        )

        if (arrayOfAppointmentsWithErrors.length !== 0) {
          arrayOfAppointmentsWithErrors.forEach((appointment) => {
            selectedStudents.forEach((student) => {
              if (appointment.studentId === student.userId) {
                appointment.firstName = student.firstName
                appointment.lastName = student.lastName
              }
            })
          })

          const action = (key) => (
            <>
              <Button
                onClick={() => closeSnackbar(key)}
                style={{ color: '#fff' }}>
                Dismiss
              </Button>
            </>
          )

          enqueueSnackbar(
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                Appointment Errors:
              </Typography>
              {arrayOfAppointmentsWithErrors.map((err) => (
                <Typography>
                  {err.lastName}, {err.firstName} - {err.errorMessage}
                </Typography>
              ))}
            </div>,
            {
              action,
              variant: 'error',
              persist: true
            }
          )
        }

        // If there is only student and there is an error, we don't make the callback
        // If there is multiple students, we make the callback no matter the errors
        if (students.length > 1 || arrayOfAppointmentsWithErrors.length === 0) {
          // ex. Appointment Request, update the queryClient isScheduled flag to true
          // ex. Appointments, make the call to get any Appointments made since the data loaded
          optionalCallback()
        }
      }
    }
  )
}
