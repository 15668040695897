import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledContent = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 'bold',

  marginBottom: theme.spacing()
}))

const PageInstructions = ({ children }) => {
  return (
    <div>
      <StyledTitle>How To Use This Page</StyledTitle>
      <StyledContent>{children}</StyledContent>
    </div>
  )
}

export default PageInstructions
