import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortByCollatingField } from '../../../helpers'

export const useGradesByStudentIdQuery = ({ studentId }) => {
  return useQuery(
    ['grades', 'by-student', { studentId: parseInt(studentId) }],
    async () => {
      const response = await axios.get(
        `${endpoints.GRADES}/bystudent/${studentId}`,
        { withCredentials: true }
      )

      return response.data.sort(sortByCollatingField(true, 'course'))
    },
    {
      enabled: !!studentId,
      staleTime: 1000 * 30
    }
  )
}
