import { useQuery } from 'react-query'
import axios from 'axios'
import moment from 'moment'

import { endpoints } from '../../../constants'
import { getDashboardSummariesQueryKey } from '../../../helpers/queryKeys'

export const useAppointmentsSummaryForAttendanceQuery = ({
  date,
  numberOfDays = 5
}) => {
  const formattedDate = moment(date).format('YYYY-MM-DD')

  return useQuery(
    getDashboardSummariesQueryKey({ date: formattedDate, numberOfDays }),
    async () => {
      const response = await axios.get(
        `${endpoints.APPOINTMENTS}/summaryforattendance/${formattedDate}/${numberOfDays}`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
