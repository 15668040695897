import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { APPOINTMENT_EDITOR_RESPONSES, endpoints } from '../../../constants'
import { useLocation, useNavigate } from 'react-router-dom'

export const useScheduleAdvancedAppointmentMutation = ({
  resetFormAfterSave
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const [collisions, setCollisions] = useState([])

  const { data, isLoading, mutate } = useMutation(
    async ({ details, shouldRedirectAfterSave }) => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/save`,
        details,
        { withCredentials: true }
      )

      let noCollisions = true

      response.data.forEach((r) => {
        if (
          r.appointmentEditorResponse !==
          APPOINTMENT_EDITOR_RESPONSES.appointmentSaved
        ) {
          noCollisions = false
        }
      })

      if (!noCollisions) {
        setCollisions(response.data)
      }

      return {
        noCollisions,
        shouldRedirectAfterSave
      }
    },
    {
      onSuccess: ({ noCollisions, shouldRedirectAfterSave }) => {
        if (noCollisions === true) {
          enqueueSnackbar('Appointment Scheduled', {
            variant: 'success'
          })

          queryClient.invalidateQueries(['appointments'])

          if (!shouldRedirectAfterSave) {
            resetFormAfterSave()
          } else {
            if (location.state === null && noCollisions) {
              navigate('/appointments')
            } else {
              navigate(-1)
            }
          }
        }
      }
    }
  )

  return {
    collisions,
    data,
    isLoading,
    mutate,
    setCollisions
  }
}
