import { useMutation } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'

export const useAppointmentsMassSchedulingRunPreprocessMutation = ({
  handleNext
}) => {
  return useMutation(
    async ({
      allowHomeroomToBeOverbooked,
      allowStudentsToModify,
      autoIncreaseSeatCountForSubjectMatterCourse,
      appointmentTypeId,
      schedulerComment,
      datesToSchedule,
      endDate,
      lockAppointments,
      overwriteHomeroomAppointments,
      overwriteLockedAppointments,
      overwriteSubjectMatterAppointments,
      periodId,
      scheduleToStudentsHomeroom,
      selectedStaffer,
      startDate,
      weekDaysToInclude
    }) => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/massschedulingrunpreprocess`,
        {
          allowHomeroomToBeOverbooked,
          allowStudentsToModify,
          appointmentTypeId,
          autoIncreaseSeatCountForSubjectMatterCourse,
          schedulerComment,
          datesToSchedule,
          endDate,
          lockAppointments,
          overwriteHomeroomAppointments,
          overwriteLockedAppointments,
          overwriteSubjectMatterAppointments,
          periodId,
          scheduleToStudentsHomeroom,
          selectedStaffer,
          startDate,
          weekDaysToInclude
        },
        { withCredentials: true }
      )

      return response.data
    },
    {
      onSuccess: () => {
        handleNext()
      }
    }
  )
}
