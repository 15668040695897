import { useEffect, useState } from 'react'

const useSortedArray = (
  initialArray = [],
  initialSortByValue = '',
  initialSortAscending = false
) => {
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base'
  })

  const [sortAscending, setSortAscending] = useState(initialSortAscending)
  const [lastSortedBy, setLastSortedBy] = useState(initialSortByValue)
  const [sortedArray, setSortedArray] = useState(
    initialArray.sort((a, b) => {
      if (initialSortAscending) {
        return collator.compare(b[initialSortByValue], a[initialSortByValue])
      } else {
        return collator.compare(a[initialSortByValue], b[initialSortByValue])
      }
    })
  )

  useEffect(() => {
    setSortedArray(initialArray)
  }, [initialArray])

  function updateSortByValue(newValue) {
    let array = [...sortedArray]

    if (newValue === lastSortedBy) {
      if (sortAscending) {
        setSortedArray(
          array.sort((a, b) => collator.compare(a[newValue], b[newValue]))
        )
      } else {
        setSortedArray(
          array.sort((a, b) => collator.compare(b[newValue], a[newValue]))
        )
      }

      setSortAscending(!sortAscending)
    } else {
      setSortedArray(
        array.sort((a, b) => collator.compare(a[newValue], b[newValue]))
      )

      setSortAscending(false)
    }

    setLastSortedBy(newValue)
    setSortedArray(array)
  }

  return [
    {
      sortedArray,
      lastSortedBy,
      sortAscending
    },
    updateSortByValue
  ]
}

export default useSortedArray
