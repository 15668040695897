import { useQuery } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getAppointmentsMassScheduleInitializeQueryKey } from '../../../helpers'

export const useAppointmentsMassScheduleInitializeQuery = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getAppointmentsMassScheduleInitializeQueryKey(),
    async () => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/massscheduleinitialize`,
        {},
        { withCredentials: true }
      )

      return response.data
    },
    {
      onError: (error) => {
        enqueueSnackbar(error, {
          variant: 'error'
        })
      }
    }
  )
}
