import React from 'react'
import { Box, Typography } from '@mui/material'

import {
  Button,
  List,
  ListItemContainer,
  ListItemText,
  PaperTitle,
  SelectField,
  SubheadingContainer,
  SubheadingText
} from '../shared'

const Item = ({
  appointmentTypes,
  changeAppointmentType,
  courseId,
  courseName,
  isHomeroom,
  selectedHomeroomAppointmentType,
  selectedSubjectMatterAppointmentType
}) => {
  return (
    <ListItemContainer>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '1/5' } }}
        labelText='Course Name:'>
        {courseName}
      </ListItemText>
      <SelectField
        dataTestId={
          isHomeroom
            ? 'homeroom-appointment-type'
            : 'subject-matter-appointment-type'
        }
        formControlSx={{
          gridColumn: { xs: '1/13', lg: '5/9' }
        }}
        menuItems={appointmentTypes}
        name={
          isHomeroom
            ? 'selectedHomeroomAppointmentType'
            : 'selectedSubjectMatterAppointmentType'
        }
        onChange={(e) => changeAppointmentType(e, courseId)}
        selectedValue={
          isHomeroom
            ? selectedHomeroomAppointmentType
            : selectedSubjectMatterAppointmentType
        }
      />
    </ListItemContainer>
  )
}

const DefaultAppointmentType = ({
  appointmentTypes,
  appointmentTypesHaveBeenAdjusted,
  changeAppointmentType,
  isSubstitute,
  data,
  saveAppointmentTypes,
  selectedHomeroomAppointmentType,
  selectedSubjectMatterAppointmentType
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <PaperTitle>Set Default Appointment Type</PaperTitle>
        {isSubstitute ? null : (
          <Button
            disabled={!appointmentTypesHaveBeenAdjusted}
            label='Save'
            onClick={saveAppointmentTypes}
          />
        )}
      </Box>
      <SubheadingContainer>
        <SubheadingText sx={{ gridColumn: { lg: '1/5' } }} text='Course Name' />
        <SubheadingText
          sx={{ gridColumn: '5/9' }}
          text='Default Appointment Type'
        />
      </SubheadingContainer>
      {isSubstitute && (
        <Typography sx={{ marginTop: 1 }}>
          Your current account is a school substitute account. You cannot set up
          default appointments with this user account type
        </Typography>
      )}
      <List
        appointmentTypes={appointmentTypes}
        changeAppointmentType={changeAppointmentType}
        data={data}
        Component={Item}
        keyValue='courseId'
        selectedHomeroomAppointmentType={selectedHomeroomAppointmentType}
        selectedSubjectMatterAppointmentType={
          selectedSubjectMatterAppointmentType
        }
      />
    </>
  )
}

export default DefaultAppointmentType
