import { useQuery } from 'react-query'
import axios from 'axios'

import { ROOT } from '../../constants'
import { getAdjustedOfferingsByStaffIdNumberOfDaysQueryKey } from '../../helpers/queryKeys'

export const useUpcomingAdjustedOfferingsQuery = ({
  numberOfDays = 10,
  staffId
}) => {
  return useQuery(
    getAdjustedOfferingsByStaffIdNumberOfDaysQueryKey({
      numberOfDays,
      staffId
    }),
    async () => {
      const response = await axios.get(
        `${ROOT}${staffId}/adjustedofferings/${numberOfDays}`,
        {
          withCredentials: true
        }
      )

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
