import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import {
  getBlockedDatesByDepartmentQueryKey,
  getBlockedDatesBySchoolQueryKey,
  getBlockedDatesByStaffQueryKey
} from '../../../helpers'

export const useDeleteSeriesMutation = ({
  departmentId,
  month,
  selectedTab,
  staffId
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ blockedDateId }) => {
      await axios.delete(`${endpoints.BLOCKED_DATES}/${blockedDateId}`, {
        withCredentials: true
      })

      return blockedDateId
    },
    {
      onSuccess: (blockedDateId) => {
        enqueueSnackbar('Blocked Dates have been deleted', {
          variant: 'success'
        })

        let queryKey

        if (selectedTab === '0') {
          queryKey = getBlockedDatesByStaffQueryKey({
            month,
            selectedTab,
            staffId
          })
        }

        if (selectedTab === '1') {
          queryKey = getBlockedDatesByDepartmentQueryKey({
            departmentId,
            month,
            selectedTab
          })
        }

        if (selectedTab === '2') {
          queryKey = getBlockedDatesBySchoolQueryKey({ month, selectedTab })
        }

        queryClient.setQueryData(queryKey, (prevBlockedDates) => {
          return prevBlockedDates.filter(
            (blockedDate) => blockedDate.blockedDateId !== blockedDateId
          )
        })

        queryClient.invalidateQueries(['appointments'])
      }
    }
  )
}
