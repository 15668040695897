import React from 'react'
import { Typography } from '@mui/material'

import { Paper, PaperTitle } from '../shared'

const EditingNote = () => {
  return (
    <Paper>
      <PaperTitle>Please Note</PaperTitle>
      <Typography paragraph>
        Please note, an existing offerings date and period cannot be changed. If
        you need to adjust the dates, please delete this offering and create an
        offering with the new dates.
      </Typography>
    </Paper>
  )
}

export default EditingNote
