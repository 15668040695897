import React from 'react'
import moment from 'moment'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Button } from '../shared'

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasBeenRead' && prop !== 'isLastItem'
})(({ hasBeenRead, isLastItem, theme }) => ({
  background: !hasBeenRead && 'rgba(3, 144, 3, 0.2)',

  marginLeft: -8,
  marginRight: -8,
  padding: theme.spacing(),

  borderBottom: !isLastItem && '1px solid #d9d9d9',

  '&:hover': {
    background: !hasBeenRead && 'rgba(3, 144, 3, 0.4)'
  }
}))

const MessageItem = ({
  body,
  dateCreated,
  hasBeenRead,
  id,
  isLastItem,
  markAsRead,
  title,
  type
}) => {
  return (
    <Container hasBeenRead={hasBeenRead} isLastItem={isLastItem}>
      <Typography
        sx={{
          fontSize: 20,
          color: 'primary.main',
          paddingBottom: 1
        }}>
        {title}
      </Typography>
      <Typography sx={{ paddingBottom: 1 }}>
        <span
          sx={{
            color: 'primary.main',
            fontWeight: 'bold'
          }}>
          Date Created:
        </span>{' '}
        {moment(dateCreated).format('MM/DD/YYYY')}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gridAutoRows: 'auto',
          alignItems: 'center'
        }}>
        <Typography
          sx={{
            gridColumn: { xs: '1/13', sm: '1/9', lg: '1/7' }
          }}
          dangerouslySetInnerHTML={{ __html: body }}
        />
        {!hasBeenRead && (
          <Box
            sx={{
              gridColumn: { xs: '1/13', sm: '9/13', lg: '8/10' },
              textAlign: 'center',
              marginTop: 1,
              marginBottom: 1
            }}>
            <Button
              esButtonClass='grey-button'
              label={hasBeenRead ? 'Mark As Unread' : 'Mark As Read'}
              onClick={() =>
                markAsRead({
                  messageId: id,
                  messageType: type
                })
              }
            />
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default MessageItem
