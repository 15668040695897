import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

const ReactHookFormCheckbox = ({
  control,
  defaultChecked,
  disabled = false,
  label,
  name
}) => {
  return (
    <FormControlLabel
      sx={{
        width: 'fit-content'
      }}
      control={
        <Controller
          control={control}
          defaultValue={defaultChecked}
          name={name}
          render={(props) => (
            <Checkbox
              color='primary'
              defaultChecked={defaultChecked}
              disabled={disabled}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          )}
        />
      }
      label={label}
    />
  )
}

export default ReactHookFormCheckbox
