import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getStudentRequestsQueryKey } from '../../../helpers'

export const useAppointmentRequestsMarkListCancelledMutation = ({
  onSuccess = () => {}
} = {}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ appointmentRequestIds }) => {
      await axios.post(
        `${endpoints.APPOINTMENT_REQUESTS}/marklistcancelled`,
        appointmentRequestIds,
        { withCredentials: true }
      )

      return { appointmentRequestIds }
    },
    {
      onSuccess: ({ appointmentRequestIds }) => {
        enqueueSnackbar('The Appointment Requests have been cancelled', {
          variant: 'success'
        })

        queryClient.setQueryData(
          getStudentRequestsQueryKey(),
          (prevAppointmentRequests) => {
            return prevAppointmentRequests.filter(
              (request) =>
                !appointmentRequestIds.includes(request.appointmentRequestId)
            )
          }
        )

        onSuccess()
      }
    }
  )
}
