import React from 'react'
import { Typography } from '@mui/material'
import { Person, PersonOutline } from '@mui/icons-material'

import { styled } from '@mui/material/styles'

const LegendItem = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(2)
}))

const StyledPersonIcon = styled(Person)(() => ({
  color: 'grey'
}))

const Legend = () => {
  return (
    <>
      <LegendItem>
        <Person />
        <Typography sx={{ marginLeft: 1 }}> = Student</Typography>
      </LegendItem>
      <LegendItem>
        <StyledPersonIcon as={PersonOutline} />
        <Typography sx={{ marginLeft: 1 }}>= Soft-Deleted Student</Typography>
      </LegendItem>
    </>
  )
}

export default Legend
