import React, { useState } from 'react'
import { CircularProgress, Drawer, MenuItem, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useLoggedInUser } from '../../context'
import { Button, List, PaperTitle } from '../shared'

import DrawerItem from './DrawerItem'
import {
  useAdjustStudentsToRostersMutation,
  useStudentGroupsForSingleStudentQuery
} from './hooks'

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingBottom: theme.spacing(2)
}))

const DrawerContent = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  padding: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    width: '50vw'
  },

  [theme.breakpoints.up('md')]: {
    width: '25vw'
  }
}))

const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',

  height: '100%'
}))

const PleaseNote = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold'
}))

const RosterDrawer = ({
  staffId,
  studentEmailAddress,
  studentFirstName,
  studentId,
  studentLastName,
  studentNickname,
  studentName,
  studentYearOfGraduation
}) => {
  const [state, setState] = useState({
    open: false
  })

  const [modifiedRosters, setModifiedRosters] = useState([])
  const { isAdmin } = useLoggedInUser()

  const { data: rosters = [], isLoading: isLoadingStudentGroups } =
    useStudentGroupsForSingleStudentQuery({
      enabled: state.open,
      staffId,
      studentId
    })

  const { isLoading: isAdjustingStudentGroups, mutate } =
    useAdjustStudentsToRostersMutation({
      studentEmailAddress,
      studentFirstName,
      studentLastName,
      studentName,
      studentNickname,
      studentYearOfGraduation
    })

  const save = () => {
    mutate({
      modifiedRosters,
      staffId,
      studentId
    })
  }

  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, open: !state.open })
  }

  const updateModifiedRosters = ({ rosterId, isSelected }) => {
    let array = [...modifiedRosters]

    function pushToArray(arr, obj) {
      const index = arr.findIndex((e) => e.rosterId === obj.rosterId)

      if (index === -1) {
        arr.push(obj)
      } else {
        arr[index] = obj
      }
    }

    pushToArray(array, { rosterId, isSelected })

    setModifiedRosters(array)
  }

  return (
    <div>
      <MenuItem onClick={(s) => setState({ ...s, open: true })}>
        View Groups
      </MenuItem>
      <Drawer anchor={'right'} open={state.open} onClose={toggleDrawer()}>
        <DrawerContent>
          {isLoadingStudentGroups ? (
            <LoadingContainer>
              <CircularProgress />
              <Typography>Loading Group Details</Typography>
            </LoadingContainer>
          ) : isAdjustingStudentGroups ? (
            <LoadingContainer>
              <CircularProgress />
              <Typography>Saving Group Changes</Typography>
            </LoadingContainer>
          ) : (
            <>
              <PaperTitle sx={{ borderBottom: '1px solid #d9d9d9' }}>
                {studentName}
              </PaperTitle>
              {isAdmin ? (
                <Typography>
                  <PleaseNote>Please note:</PleaseNote> A students Homebase
                  Group cannot be adjusted.
                </Typography>
              ) : (
                <Typography>
                  <PleaseNote>Please note:</PleaseNote> A students Homebase
                  Group cannot be adjusted. Additionally, you cannot adjust a
                  Group that does not belong to you.
                </Typography>
              )}

              <List
                Component={DrawerItem}
                data={rosters}
                keyValue='rosterId'
                updateModifiedRosters={updateModifiedRosters}
              />
              <ButtonsContainer>
                <Button
                  esButtonClass='cancelButton'
                  label='Cancel'
                  onClick={toggleDrawer()}
                />
                <Button
                  disabled={modifiedRosters.length > 0 ? false : true}
                  label='Save'
                  onClick={save}
                />
              </ButtonsContainer>
            </>
          )}
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default RosterDrawer
