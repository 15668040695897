import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getFreezeAppointmentsQueryKey } from '../../../helpers'

export const useConfigurationFreezeAppointmentsQuery = ({
  enabled = true,
  useAll = false
} = {}) => {
  return useQuery(
    getFreezeAppointmentsQueryKey(),
    async () => {
      const response = await axios.get(
        `${endpoints.CONFIGURATION}/freezeappointments`,
        { withCredentials: true }
      )

      return response.data
    },
    {
      enabled,
      staleTime: 1000 * 30
    }
  )
}
