import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getAppointmentRequestByIdsQueryKey } from '../../../helpers'

export const useAppointmentRequestsByIdsQuery = ({ appointmentRequestIds }) => {
  return useQuery(
    getAppointmentRequestByIdsQueryKey(appointmentRequestIds),
    async () => {
      const response = await axios.post(
        `${endpoints.APPOINTMENT_REQUESTS}/byids`,
        appointmentRequestIds,
        { withCredentials: true }
      )

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
