import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { getEmailSubscriptionQueryKey } from '../../../helpers'

export const useEmailSubscriptionQuery = () => {
  return useQuery(
    getEmailSubscriptionQueryKey(),
    async () => {
      const response = await axios.get(endpoints.EMAIL_SUBSCRIPTION, {
        withCredentials: true
      })

      return response.data
    },
    {
      staleTime: null
    }
  )
}
