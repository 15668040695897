import { useEffect } from 'react'

export const useScrollToPosition = ({ isLoading, location }) => {
  useEffect(() => {
    if (location.state === null) return

    if (location.state.scrollPosition === null) return

    if (!isLoading) {
      window.scrollTo(0, location.state.scrollPosition)
    }
  }, [isLoading, location])
}
