import React from 'react'
import { ListItemContainer, ListItemText } from '../shared'

const SchedulingErrorItem = ({ error }) => {
  return (
    <ListItemContainer>
      <ListItemText
        sx={{
          gridColumn: '1/13'
        }}>
        {error}
      </ListItemText>
    </ListItemContainer>
  )
}

export default SchedulingErrorItem
