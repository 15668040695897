import { Typography } from '@mui/material'
import React from 'react'
import InternalLink from './InternalLink'

const NetworkErrorMessage = ({ error, linkText, to }) => {
  return (
    <div>
      <Typography>{error}</Typography>
      <InternalLink text={linkText} to={to} />
    </div>
  )
}

export default NetworkErrorMessage
