import { Grid, Typography } from '@mui/material'
import React from 'react'

import {
  Button,
  DatePicker,
  FlexColumn,
  LabeledCheckbox,
  MultiSelect,
  SelectField,
  TextArea
} from '../shared'

import { useAdvancedAppointments } from './AdvancedAppointmentsContext'

const FormSection = () => {
  const {
    appointmentTypes,
    autoIncreaseSeatCount,
    canBeOverwrittenByStudent,
    courses,
    endDate,
    errorNoSelectedCourse,
    isAdmin,
    isLoadingCoursesToExclude,
    isLocked,
    isoWeekdays,
    loggedInUserId,
    periods,
    schedulerComment,
    selectedAppointmentType,
    selectedCourseId,
    selectedCourseMustUseDefaultAppointmentType,
    selectedPeriods,
    setAutoIncreaseSeatCount,
    setCanBeOverwrittenByStudent,
    setIsLocked,
    setIsoWeekdays,
    setSchedulerComment,
    setSelectedAppointmentType,
    setSelectedPeriods,
    setupStudentToSchedule,
    startDate,
    studentCanOverrideAppointments,
    updateDates
  } = useAdvancedAppointments()

  const selectedCourseInfo = courses.find(
    (course) => course.courseId === selectedCourseId
  )

  return (
    <Grid item xs={12} md={4} lg={4}>
      <FlexColumn>
        <SelectField
          disabled={selectedCourseMustUseDefaultAppointmentType}
          label='Appointment Type'
          onChange={(e) => setSelectedAppointmentType(e.target.value)}
          menuItems={appointmentTypes}
          name='selectedAppointmentType'
          selectedValue={selectedAppointmentType}
        />
        <MultiSelect
          label='Periods'
          onChange={(e) => setSelectedPeriods(e.target.value)}
          selectedValues={selectedPeriods}
          values={periods}
        />
        <DatePicker
          onChange={(v) => updateDates(v, 'startDate')}
          label='Start Date'
          value={startDate}
        />
        <DatePicker
          onChange={(v) => updateDates(v, 'endDate')}
          label='End Date'
          value={endDate}
        />
        <MultiSelect
          label='Weekdays'
          onChange={(e) => setIsoWeekdays(e.target.value)}
          selectedValues={isoWeekdays}
          values={[
            {
              description: 'Monday',
              value: 1
            },
            {
              description: 'Tuesday',
              value: 2
            },
            {
              description: 'Wednesday',
              value: 3
            },
            {
              description: 'Thursday',
              value: 4
            },
            {
              description: 'Friday',
              value: 5
            }
          ]}
        />
        <TextArea
          name='schedulerComment'
          onChange={(e) => setSchedulerComment(e.target.value)}
          placeholder='Add an optional comment...'
          value={schedulerComment}
        />
        {studentCanOverrideAppointments ? (
          <LabeledCheckbox
            checked={canBeOverwrittenByStudent}
            label='Allow Student to Overwrite Appointment'
            name='canBeOverwrittenByStudent'
            onChange={(e) => setCanBeOverwrittenByStudent(e.target.checked)}
          />
        ) : null}
        {isAdmin ||
        (selectedCourseInfo !== undefined &&
          loggedInUserId === selectedCourseInfo.stafferId) ? (
          <LabeledCheckbox
            checked={autoIncreaseSeatCount}
            label='Auto Increase Seat Count'
            name='autoIncreaseSeatCount'
            onChange={(e) => setAutoIncreaseSeatCount(e.target.checked)}
          />
        ) : null}
        <LabeledCheckbox
          checked={isLocked}
          label='Lock Appointment'
          name='isLocked'
          onChange={(e) => setIsLocked(e.target.checked)}
        />
        {errorNoSelectedCourse && (
          <Typography
            sx={{
              color: 'error.main',
              marginBottom: 1
            }}>
            You must select a Course to schedule a student.
          </Typography>
        )}
        <Button
          sx={{ marginBottom: 1 }}
          disabled={isLoadingCoursesToExclude}
          onClick={() =>
            setupStudentToSchedule({ shouldRedirectAfterSave: true })
          }
          label='Schedule & Close'
        />
        <Button
          disabled={isLoadingCoursesToExclude}
          onClick={() =>
            setupStudentToSchedule({ shouldRedirectAfterSave: false })
          }
          label='Schedule & Continue'
        />
      </FlexColumn>
    </Grid>
  )
}

export default FormSection
