import React from 'react'
import { MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const StyledMenuItemLink = styled(Link)(({ noStyling, theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none'
}))

const MenuItemLink = ({ text, to, state = {}, ...otherProps }) => {
  return (
    <StyledMenuItemLink state={state} to={to} {...otherProps}>
      <MenuItem>{text}</MenuItem>
    </StyledMenuItemLink>
  )
}

export default MenuItemLink
