import React from 'react'
import { useQuery } from 'react-query'
import { Typography } from '@mui/material'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  endpoints,
  localStorageHasBeenAuthenticated,
  localStorageLoggedInUserDetails
} from '../../constants'
import { useLoggedInUser } from '../../context'
import { Loading } from '../shared'

const useLoginValidateTokenQuery = ({
  setLoggedInUserDetails,
  token,
  updateIsAuthenticated
}) => {
  const navigate = useNavigate()

  return useQuery(
    ['login', 'validate-token'],
    async () => {
      const response = await axios.post(
        `${endpoints.LOGIN}/validatetoken`,
        { stringValue: token },
        { withCredentials: true }
      )

      return response.data
    },
    {
      onSuccess: (data) => {
        setLoggedInUserDetails(data.users[0])
        localStorage.setItem(
          localStorageLoggedInUserDetails,
          JSON.stringify(data.users[0])
        )

        localStorage.setItem(localStorageHasBeenAuthenticated, 'true')

        updateIsAuthenticated()

        navigate('/', { replace: true })
      }
    }
  )
}

const ValidateToken = () => {
  let [searchParams] = useSearchParams()

  const tokenSearchParam = searchParams.get('token')

  const { setLoggedInUserDetails, updateIsAuthenticated } = useLoggedInUser()

  const { error, isFetching, isLoading } = useLoginValidateTokenQuery({
    setLoggedInUserDetails,
    token: tokenSearchParam,
    updateIsAuthenticated
  })

  if (isFetching || isLoading)
    return (
      <Loading
        sx={{
          width: '100vw !important',
          height: '100vh !important',
          position: 'fixed !important',
          top: 0,
          left: 0,
          zIndex: 1000
        }}
        text='Logging Into Enriching Students'
      />
    )

  return (
    <div>{error !== '' && <Typography>{error.response.data}</Typography>}</div>
  )
}

export default ValidateToken
