import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { useLoggedInUser } from '../../../context'
import { getConfigurationsQueryKey } from '../../../helpers'

export const useSaveConfigurationsMutation = ({ setDataIsAltered }) => {
  const { updateSchoolBrandName } = useLoggedInUser()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (object) => {
      await axios.post(`${endpoints.CONFIGURATION}/save`, object, {
        withCredentials: true
      })

      return { configurations: object }
    },
    {
      onSuccess: ({ configurations }) => {
        enqueueSnackbar('Configuration changes have been saved', {
          variant: 'success'
        })

        setDataIsAltered(false)

        updateSchoolBrandName(configurations.schoolBrandName)

        queryClient.setQueryData(getConfigurationsQueryKey(), () => {
          return configurations
        })
      }
    }
  )
}
