import { MenuItem } from '@mui/material'
import moment from 'moment'

import { Menu, MenuItemLink } from '../../shared'

const RequestItemMenu = ({
  appointmentRequestId,
  autoSchedule,
  dateRequested,
  fullName,
  markRequestAsCancelled,
  periodId,
  selectedTab,
  userId
}) => {
  return (
    <>
      {selectedTab === 0 && (
        <Menu
          buttonSx={{
            gridColumn: { xs: '1/13', lg: '10/11', xl: 8 },
            textAlign: 'center'
          }}>
          <MenuItem
            onClick={() =>
              autoSchedule({
                appointmentRequestId,
                studentDisplayName: fullName
              })
            }>
            Auto Schedule
          </MenuItem>
          <MenuItemLink
            text='Go To Schedule'
            to={`/advancedAppointments/${userId}/${moment(dateRequested).format(
              'YYYY-MM-DD'
            )}/${periodId}/${fullName}`}
          />
          <MenuItem
            onClick={() => markRequestAsCancelled({ appointmentRequestId })}>
            Mark As Cancelled
          </MenuItem>
        </Menu>
      )}
    </>
  )
}

export default RequestItemMenu
