import React from 'react'
import moment from 'moment'

import { Checkbox, ListItemContainer, ListItemText } from '../shared'

import { getErrorMessage } from './getErrorMessage'

const CollisionItem = ({
  appointmentDate,
  appointmentEditorResponse,
  disableExclude,
  isExcluded,
  isLastItem,
  periodId,
  periods,
  updateExclusions
}) => {
  const selectedPeriod = periods.find((period) => period.value === periodId)

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '1/5' }
        }}
        labelText='Period:'>
        {selectedPeriod.description}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '5/7' }
        }}
        labelText='Date:'>
        {moment(appointmentDate).format('MM-DD-YYYY')}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '7/11' }
        }}
        labelText='Reason:'>
        {getErrorMessage(appointmentEditorResponse)}
      </ListItemText>
      {!disableExclude && (
        <ListItemText
          sx={{
            gridColumn: { xs: '1/13', lg: '11/13' },
            textAlign: { lg: 'center' }
          }}
          labelText='Exclude:'>
          <Checkbox
            checked={isExcluded}
            dataTestId={`exclude-${periodId}`}
            onChange={(e) =>
              updateExclusions({
                appointmentDate,
                checked: e.target.checked,
                periodId
              })
            }
          />
        </ListItemText>
      )}
    </ListItemContainer>
  )
}

export default CollisionItem
