import React, { createContext, useContext, useState } from 'react'

import {
  localStorageHasBeenAuthenticated,
  localStorageLoggedInUserDetails,
  userTypes
} from '../constants'
import { useLocalStorage } from '../hooks'

const LoggedInUserContext = createContext()

function isLoggedInUserAuthenticated() {
  const hasBeenAuthenticated = localStorage.getItem(
    localStorageHasBeenAuthenticated
  )

  if (hasBeenAuthenticated !== 'true') {
    return false
  } else {
    return true
  }
}

const LoggedInUserProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() =>
    isLoggedInUserAuthenticated()
  )

  const [loggedInUserDetails, setLoggedInUserDetails] = useLocalStorage(
    localStorageLoggedInUserDetails,
    {}
  )

  const isAdmin =
    loggedInUserDetails.userType === userTypes.ADMIN.value ? true : false
  const isSubstitute =
    loggedInUserDetails.userType === userTypes.SUBSTITUTE.value ? true : false
  const loggedInUserDepartmentId = loggedInUserDetails.departmentId
  const loggedInUserFirstName = loggedInUserDetails.firstName
  const loggedInUserLastName = loggedInUserDetails.lastName
  const loggedInUserSchoolBrandName = loggedInUserDetails.schoolBrandName
  const loggedInUserToSchoolId = loggedInUserDetails.userToSchoolId
  const loggedInUserId = loggedInUserDetails.userId
  const schoolId = loggedInUserDetails.schoolId

  const logout = () => {
    localStorage.removeItem(localStorageHasBeenAuthenticated)
    localStorage.removeItem(localStorageLoggedInUserDetails)
    updateIsAuthenticated()
  }

  const updateIsAuthenticated = () => {
    const hasBeenAuthenticated = localStorage.getItem(
      localStorageHasBeenAuthenticated
    )

    if (
      hasBeenAuthenticated === undefined ||
      hasBeenAuthenticated === null ||
      hasBeenAuthenticated === false ||
      hasBeenAuthenticated === 'false'
    ) {
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(true)
    }
  }

  const updateSchoolBrandName = (schoolBrandName) => {
    setLoggedInUserDetails({ ...loggedInUserDetails, schoolBrandName })
  }

  return (
    <LoggedInUserContext.Provider
      value={{
        isAdmin,
        isAuthenticated,
        isSubstitute,
        loggedInUserDepartmentId,
        loggedInUserFirstName,
        loggedInUserId,
        loggedInUserLastName,
        loggedInUserSchoolBrandName,
        loggedInUserToSchoolId,
        logout,
        schoolId,
        setLoggedInUserDetails,
        updateIsAuthenticated,
        updateSchoolBrandName
      }}>
      {children}
    </LoggedInUserContext.Provider>
  )
}

const useLoggedInUser = () => {
  const context = useContext(LoggedInUserContext)
  if (!context) {
    throw new Error('useLoggedInUser must be used with a LoggedInUserProvider')
  }

  return context
}

export { LoggedInUserContext, LoggedInUserProvider, useLoggedInUser }
