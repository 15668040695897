import React, { useState } from 'react'
import { Collapse, Grid } from '@mui/material'

import { userTypes } from '../../constants'
import { sortByCollatingField } from '../../helpers'
import {
  LabeledCheckbox,
  ResponsiveFabButton,
  SelectField,
  TextField
} from '../shared'

import ConfirmSaveModal from './ConfirmSaveModal'
import { OptionsContainer } from './styledComponents'

const TRUE_FALSE_MENU_ITEMS = [
  { description: '(select)', value: -10 },
  { description: 'True', value: true },
  { description: 'False', value: false }
]

const USER_TYPES_MENU_ITEMS = [
  { description: '(select)', value: -10 },
  userTypes.ADMIN,
  userTypes.STAFFER
]

const BulkEdit = ({
  bulkEditing,
  departments,
  handleSelectAllChange,
  saveBulkEdit,
  selectAll,
  selectedStaffIds
}) => {
  const [hasConfirmationError, setHasConfirmationError] = useState(false)
  const [confirmationNumber, setConfirmationNumber] = useState('')
  const [displaySaveConfirmation, setDisplaySaveConfirmation] = useState(false)
  const [state, setState] = useState({
    dataHasBeenUpdated: false,
    canCreateAdjustedCourseBlockedToOthers: '',
    canViewAllGrades: '',
    canViewAnalysis: '',
    canViewGrades: '',
    departmentId: '',
    homeroomIsActive: '',
    maxNumberStudents: '',
    notifiyWhenAutomatedImportRuns: '',
    notifiyWhenEmailBounces: '',
    subjectMatterCourseIsActive: '',
    userIsActive: '',
    userType: ''
  })

  const {
    canCreateAdjustedCourseBlockedToOthers,
    canViewAllGrades,
    canViewAnalysis,
    canViewGrades,
    dataHasBeenUpdated,
    departmentId,
    homeroomIsActive,
    maxNumberStudents,
    notifiyWhenAutomatedImportRuns,
    notifiyWhenEmailBounces,
    subjectMatterCourseIsActive,
    userIsActive,
    userType
  } = state

  const confirmSave = () => {
    if (parseInt(confirmationNumber) !== selectedStaffIds.length) {
      setHasConfirmationError(true)
      return
    }

    saveData()
  }

  const disableSave = () => {
    if (selectedStaffIds.length < 1) {
      return true
    }

    if (!dataHasBeenUpdated) {
      return true
    }

    return false
  }

  const handleSaveClick = () => {
    if (userIsActive === false) {
      setDisplaySaveConfirmation(true)
      return
    }

    saveData()
  }

  const saveData = () => {
    saveBulkEdit({
      canCreateAdjustedCourseBlockedToOthers,
      canViewAllGrades,
      canViewAnalysis,
      canViewGrades,
      departmentId,
      homeroomIsActive,
      maxNumberStudents,
      notifiyWhenAutomatedImportRuns,
      notifiyWhenEmailBounces,
      staffIds: selectedStaffIds,
      subjectMatterCourseIsActive,
      userIsActive,
      userType
    })
  }

  const updateState = (e) => {
    const { name, value } = e.target

    setState({ ...state, dataHasBeenUpdated: true, [name]: value })
  }

  const setupDepartmentMenuItems = () => {
    let array = [{ description: '(select)', value: -10 }]

    departments.forEach((department) => {
      array.push({
        description: department.description,
        value: department.value
      })
    })

    return array.sort(sortByCollatingField(true, 'departmentDescription'))
  }

  return (
    <>
      <Collapse in={bulkEditing} timeout='auto' unmountOnExit>
        <div>
          <Grid container>
            <Grid container item xs={12} sm={6} xl={4}>
              <OptionsContainer item xs={12} md={6}>
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  fullWidth={false}
                  label='Department'
                  labelId='select-department'
                  onChange={updateState}
                  menuItems={setupDepartmentMenuItems()}
                  name='departmentId'
                  selectedValue={departmentId}
                />
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  fullWidth={false}
                  label='User Type'
                  onChange={updateState}
                  menuItems={USER_TYPES_MENU_ITEMS}
                  name='userType'
                  selectedValue={userType}
                />
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Staffer Is Active'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='userIsActive'
                  selectedValue={userIsActive}
                />
                <TextField
                  sx={{
                    width: 'auto !important',
                    marginRight: 1
                  }}
                  label='Max Number of Students'
                  name='maxNumberStudents'
                  onChange={updateState}
                  value={maxNumberStudents}
                />
              </OptionsContainer>
              <OptionsContainer item xs={12} md={6}>
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Can Block Adjusted Offering'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='canCreateAdjustedCourseBlockedToOthers'
                  selectedValue={canCreateAdjustedCourseBlockedToOthers}
                />
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Can View Grades'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='canViewGrades'
                  selectedValue={canViewGrades}
                />
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Can View All Grades'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='canViewAllGrades'
                  selectedValue={canViewAllGrades}
                />
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Can View Analysis'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='canViewAnalysis'
                  selectedValue={canViewAnalysis}
                />
              </OptionsContainer>
            </Grid>
            <Grid container item xs={12} sm={6} xl={4}>
              <OptionsContainer item xs={12} md={6}>
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Homebase Is Active'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='homeroomIsActive'
                  selectedValue={homeroomIsActive}
                />
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Subject Matter Is Active'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='subjectMatterCourseIsActive'
                  selectedValue={subjectMatterCourseIsActive}
                />
              </OptionsContainer>
              <OptionsContainer item xs={12} md={6}>
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Notify On Automated Import'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='notifiyWhenAutomatedImportRuns'
                  selectedValue={notifiyWhenAutomatedImportRuns}
                />
                <SelectField
                  formControlSx={{
                    width: 'auto !important'
                  }}
                  sx={{ width: 'auto !important' }}
                  label='Notify When Email Bounces'
                  onChange={updateState}
                  menuItems={TRUE_FALSE_MENU_ITEMS}
                  name='notifiyWhenEmailBounces'
                  selectedValue={notifiyWhenEmailBounces}
                />
                <LabeledCheckbox
                  checked={selectAll}
                  formControlSx={{ display: { md: 'none' } }}
                  label='Select All'
                  onChange={handleSelectAllChange}
                />
                <ResponsiveFabButton
                  disabled={disableSave()}
                  onClick={handleSaveClick}
                />
              </OptionsContainer>
            </Grid>
          </Grid>
        </div>
      </Collapse>
      <>
        <ConfirmSaveModal
          confirmSave={confirmSave}
          confirmationNumber={confirmationNumber}
          displaySaveConfirmation={displaySaveConfirmation}
          hasConfirmationError={hasConfirmationError}
          selectedStaffIds={selectedStaffIds}
          setConfirmationNumber={setConfirmationNumber}
          setDisplaySaveConfirmation={setDisplaySaveConfirmation}
          setHasConfirmationError={setHasConfirmationError}
        />
      </>
    </>
  )
}

export default BulkEdit
