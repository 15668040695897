import {
  AppBar,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { headerHeight, navigationWidth, navigationWidthXs } from '../constants'

/* Header Styled Components */
export const SmallItalizedText = styled(Typography)(() => ({
  fontSize: 12,
  fontStyle: 'italic'
}))

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#fff',

  height: 81,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    width: '100%',
    zIndex: 1000
  }
}))

export const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  marginLeft: 0,
  fontWeight: 300,
  color: '#959292',

  [theme.breakpoints.up('sm')]: {
    fontSize: 25
  },

  [theme.breakpoints.up('md')]: {
    marginLeft: 10
  }
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: '#959292',
  marginRight: 0,

  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}))

export const StyledMenuIcon = styled(MenuIcon)(() => ({
  fontSize: 40
}))

export const StyledFlexName = styled(Typography)(() => ({
  fontSize: 16
}))

export const LogoContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },

  height: headerHeight,
  width: headerHeight,

  backgroundColor: theme.palette.primary.main,

  paddingRight: theme.spacing(2),

  img: {
    height: 80
  }
}))

export const BrandContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'none'
  },

  color: '#000',
  padding: theme.spacing(2)
}))

export const HideSmallDown = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const PageInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1
}))

export const InfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1
}))

export const StyledWelcomeText = styled(Typography)(({ theme }) => ({
  color: '#000',

  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),

  fontSize: 14,

  fontWeight: 300,
  textTransform: 'uppercase',

  wordBreak: 'break-word',

  [theme.breakpoints.up('md')]: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 18
  }
}))

export const SelectFieldContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginRight: theme.spacing()
}))

export const UserDetailsContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center'
  }
}))

/* Nav Styled Components */

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: '#eee',
    width: navigationWidthXs,

    [theme.breakpoints.up('md')]: {
      width: navigationWidth,
      top: `${headerHeight} !important`
    },

    [theme.breakpoints.up('xl')]: {
      height: `calc(100vh - ${headerHeight})`,

      paddingTop: theme.spacing()
    }
  }
}))

export const StyledDrawerContainer = styled('nav')(({ theme }) => ({
  height: '100%',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  paddingTop: theme.spacing(2)
}))

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: '#959292',

  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),

    minWidth: 0
  }
}))

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '	.MuiListItemText-primary': {
    color: '#959292',
    fontSize: 17
  }
}))

export const StyledNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: navigationWidth,
    zIndex: 0
  },

  [theme.breakpoints.up('lg')]: {
    position: 'absolute'
  }
}))

export const StyledNavLogo = styled('img')(({ theme }) => ({
  height: 80,
  width: 80,
  backgroundColor: theme.palette.primary.main
}))

export const StyledPatternImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'adminMenuOpen'
})(({ adminMenuOpen, theme }) => ({
  width: 300,

  [theme.breakpoints.up('sm')]: {
    width: adminMenuOpen
      ? // Need to account for scroll bar width
        `calc(${navigationWidth} - 18px)`
      : `calc(${navigationWidth} - 1px)`
  }
}))

/* Main Styles Components */
export const StyledMain = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),

  marginTop: headerHeight,

  paddingBottom: theme.spacing(),
  paddingTop: theme.spacing(2),

  maxWidth: '100vw',

  [theme.breakpoints.only('xs')]: {
    top: headerHeight
  },

  [theme.breakpoints.up('md')]: {
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  [theme.breakpoints.up('lg')]: {
    position: 'relative',
    left: navigationWidth,
    maxWidth: `calc(100% - ${navigationWidth})`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))
