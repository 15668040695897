import moment from 'moment'
import React from 'react'

import { Checkbox, ListItemContainer, ListItemText } from '../shared'

const CourseItem = ({
  appointmentDate,
  courseName,
  excludedCourseDates,
  isLastItem,
  updateExcludedCourseDates
}) => {
  const isChecked = () => {
    const result = excludedCourseDates.find(
      (date) => date === moment(appointmentDate).format('MM/DD/YYYY')
    )

    return result === undefined ? false : true
  }

  return (
    <ListItemContainer isLastItem={isLastItem}>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '1/5' } }}
        displayColumn
        labelText='Date:'>
        {moment(appointmentDate).format('MM/DD/YYYY')}
      </ListItemText>
      <ListItemText
        sx={{ gridColumn: { xs: '1/13', lg: '5/10' } }}
        displayColumn
        labelText='Course:'>
        {courseName}
      </ListItemText>
      <ListItemText
        sx={{
          gridColumn: { xs: '1/13', lg: '10/13' },
          textAlign: { lg: 'center' }
        }}
        labelText='Exclude:'>
        <Checkbox
          checked={isChecked()}
          onChange={(e) =>
            updateExcludedCourseDates({
              appointmentDate,
              checked: e.target.checked
            })
          }
        />
      </ListItemText>
    </ListItemContainer>
  )
}

export default CourseItem
