import { styled } from '@mui/material/styles'

export const AttendanceTypeContainer = styled('div')(({ theme }) => ({
  gridColumn: '1/13',
  [theme.breakpoints.up('sm')]: {
    gridColumn: '3/6'
  }
}))

export const ButtonAndDrawer = styled('div')(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

export const ControlsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  }
}))

export const PaperTitleContainer = styled('div')(({ theme }) => ({
  gridColumn: '1/13',
  [theme.breakpoints.up('sm')]: {
    gridColumn: '1/3'
  }
}))

export const PaperTitleAndControls = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridAutoRows: 'auto',

  alignItems: 'center',
  paddingBottom: theme.spacing()
}))

export const SaveContainer = styled('div')(({ theme }) => ({
  textAlign: 'right',
  gridColumn: '1/13',
  marginTop: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    gridColumn: '12/13'
  }
}))

export const UserManualAndButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}))
