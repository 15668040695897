import React from 'react'
import { ListSubheader, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Menu, SelectField } from '../shared'
import { setupAlphabetMenuItems } from '../../helpers'

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 21,
  fontWeight: 300
}))

const FiltersMenu = ({
  graduationYears,
  handleFilterChange,
  homeroomRosters,
  selectedGraduationYear,
  selectedHomeroomRoster,
  selectedLastNameLetter
}) => {
  return (
    <div>
      <Menu buttonAriaLabel='filter students by' label='Filter'>
        <StyledListSubheader>Filter Students By</StyledListSubheader>
        <MenuItem>
          <SelectField
            label='Last Name'
            menuItems={setupAlphabetMenuItems(true)}
            name='selectedLastNameLetter'
            onChange={handleFilterChange}
            selectedValue={selectedLastNameLetter}
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='Graduation Year'
            menuItems={graduationYears}
            name='selectedGraduationYear'
            onChange={handleFilterChange}
            selectedValue={selectedGraduationYear}
          />
        </MenuItem>
        <MenuItem>
          <SelectField
            label='Homebase Group'
            menuItems={homeroomRosters}
            name='selectedHomeroomRoster'
            onChange={handleFilterChange}
            selectedValue={selectedHomeroomRoster}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default FiltersMenu
