import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const Subheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText sx={{ gridColumn: '1/3' }} text='Date' />
      <SubheadingText sx={{ gridColumn: '3/6' }} text='Course Name' />
      <SubheadingText sx={{ gridColumn: '6/8' }} text='Period' />
      <SubheadingText
        sx={{ gridColumn: '8/10', textAlign: 'center' }}
        text='Current Appts'
      />
      <SubheadingText sx={{ gridColumn: '10/12' }} text='Day Type' />
      <SubheadingText sx={{ gridColumn: '12/13' }} text='Include' />
    </SubheadingContainer>
  )
}

export default Subheading
