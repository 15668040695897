import { Box, Typography } from '@mui/material'
import React from 'react'

const AppointmentDetailInfo = ({ borderRight = true, description, label }) => {
  return (
    <Box
      sx={{
        paddingTop: 1,
        paddingBottom: 1,
        paddingRight: 1,
        marginRight: 1,
        borderRight: borderRight && '1px solid #ddd'
      }}>
      <Typography
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: 15
        }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: 14 }}>{description}</Typography>
    </Box>
  )
}

export default AppointmentDetailInfo
