import React from 'react'
import { Grid, Typography } from '@mui/material'
import moment from 'moment'

import { BLOCKED_DATE_TYPES, weekdayMenuItems } from '../../constants'
import { useLoggedInUser } from '../../context'
import {
  Button,
  DatePicker,
  FlexColumn,
  ReactHookFormSelect,
  RhfMultiSelectFormControl,
  TextField
} from '../shared'

const CreateBlockedDateForm = ({
  control,
  departments,
  endDate,
  errors,
  handleSubmit,
  isAnyIncludedAppointmentCountAboveZero,
  periods,
  register,
  repeatOptions,
  selectedBlockOption,
  selectedPeriodsWatch,
  selectedWeekdaysWatch,
  setValue,
  shouldRenderEndDate,
  shouldRenderWeekdayControls,
  staffers,
  startDate,
  submitForm,
  updateDates
}) => {
  const { loggedInUserDepartmentId, loggedInUserId } = useLoggedInUser()

  return (
    <Grid item xs={12} sm={5} lg={4} xl={3}>
      <form onSubmit={handleSubmit(submitForm)}>
        <FlexColumn>
          {selectedBlockOption === BLOCKED_DATE_TYPES.byStaff.toString() && (
            <ReactHookFormSelect
              control={control}
              defaultValue={loggedInUserId}
              errors={errors.selectedStaffer}
              id='selectedStaffer'
              label='Select a Staffer'
              menuItems={staffers}
              name='selectedStaffer'
              rules={{ required: 'Please select a staffer' }}
            />
          )}
          {selectedBlockOption ===
            BLOCKED_DATE_TYPES.byDepartment.toString() && (
            <ReactHookFormSelect
              control={control}
              defaultValue={loggedInUserDepartmentId}
              errors={errors.selectedDepartment}
              id='selectedDepartment'
              label='Select a Department'
              menuItems={departments}
              name='selectedDepartment'
              rules={{ required: 'Please select a department' }}
            />
          )}
          <TextField
            defaultValue=''
            error={Boolean(errors.blockedReason)}
            helperText={
              errors.blockedReason ? errors.blockedReason.message : null
            }
            id='blockedReason'
            inputRef={register({ required: 'Please enter a reason' })}
            label='Reason'
            name='blockedReason'
          />
          <ReactHookFormSelect
            control={control}
            defaultValue={-1}
            errors={errors.selectedRepeatsOption}
            id='selectedRepeatsOption'
            label='Repeats'
            menuItems={repeatOptions}
            name='selectedRepeatsOption'
            rules={{ required: 'Please select a repeats option' }}
          />
          <DatePicker
            label='Start Date'
            minDate={moment()}
            onChange={(v) => updateDates(v, 'startDate')}
            value={startDate}
          />
          {shouldRenderEndDate && (
            <DatePicker
              label='End Date'
              minDate={moment()}
              onChange={(v) => updateDates(v, 'endDate')}
              value={endDate}
            />
          )}
          {shouldRenderWeekdayControls && (
            <RhfMultiSelectFormControl
              control={control}
              defaultValue={[]}
              errors={errors.selectedWeekdays}
              inputId='select-multiple-weekdays'
              label='Weekdays'
              labelId='select-weekdays'
              menuItems={weekdayMenuItems}
              name='selectedWeekdays'
              rules={{
                validate: (data) =>
                  data.length > 0 || 'Please select at least one weekday'
              }}
              selectedWatch={selectedWeekdaysWatch}
              setValue={setValue}
              useAllOption
            />
          )}
          <RhfMultiSelectFormControl
            control={control}
            defaultValue={[]}
            errors={errors.selectedPeriods}
            inputId='select-multiple-periods'
            label='Period To Block'
            labelId='select-periods'
            menuItems={periods}
            name='selectedPeriods'
            rules={{
              validate: (data) =>
                data.length > 0 || 'Please select at least one period'
            }}
            selectedWatch={selectedPeriodsWatch}
            setValue={setValue}
            useAllOption
          />
          {isAnyIncludedAppointmentCountAboveZero && (
            <Typography sx={{ marginTop: 1, marginBottom: 1 }}>
              <b>Please Note:</b> You have selected date(s) and period(s) that
              have appointments scheduled. If you save this data, you will{' '}
              <b style={{ color: 'red' }}>PERMANENTLY DELETE</b> any
              Appointments scheduled for these date(s) and period(s).
            </Typography>
          )}
          <div style={{ display: 'flex' }}>
            <Button
              esButtonClass='greenButton'
              label='Save & Continue'
              marginRight
              onClick={() => {
                setValue('saveAndClose', false)
              }}
              type='submit'
            />
            <Button
              esButtonClass='greenButton'
              label='Save & Close'
              onClick={() => {
                setValue('saveAndClose', true)
              }}
              type='submit'
            />
          </div>
        </FlexColumn>
      </form>
    </Grid>
  )
}

export default CreateBlockedDateForm
