import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useCoteacherSaveMutation = ({ resetSelectedCoTeacher }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ selectedStafferDetails, values }) => {
      await axios.post(`${endpoints.COTEACHER}/save`, values, {
        withCredentials: true
      })

      return { selectedStafferDetails, values }
    },
    {
      onSuccess: ({ selectedStafferDetails, values }) => {
        enqueueSnackbar(
          `${selectedStafferDetails.lastName}, ${selectedStafferDetails.firstName} has been added as a Co-Teacher`,
          {
            variant: 'success'
          }
        )

        resetSelectedCoTeacher()

        // Update query cache for staffers coteachers
        queryClient.setQueryData(
          [
            'coteacher',
            'where-primary-and-secondary',
            { useLoggedInUserDetails: false, userId: parseInt(values.userId) }
          ],
          (prevCoTeachers) => {
            return [
              ...prevCoTeachers,
              {
                description:
                  selectedStafferDetails.lastName +
                  ', ' +
                  selectedStafferDetails.firstName,
                firstName: selectedStafferDetails.firstName,
                lastName: selectedStafferDetails.lastName,
                userId: selectedStafferDetails.userId,
                value: selectedStafferDetails.userId
              }
            ]
          }
        )
      }
    }
  )
}
