import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'

export const useStudentsUpdateEmailAddressMutation = ({
  findByKey,
  findByValue
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ emailAddress, name, userId }) => {
      await axios.post(
        `${endpoints.STUDENTS}/updateemailaddress`,
        { emailAddress, userId },
        { withCredentials: true }
      )

      return { emailAddress, name, userId }
    },
    {
      onSuccess: ({ emailAddress, name, userId }) => {
        enqueueSnackbar(
          `The email address for ${name} has been updated to ${emailAddress}.`,
          {
            variant: 'success'
          }
        )

        queryClient.setQueryData(
          ['students', findByKey, findByValue],
          (prevStudents) => {
            return [
              ...prevStudents.map((student) => {
                if (student.userId !== userId) return student
                return { ...student, emailAddress: emailAddress }
              })
            ]
          }
        )

        queryClient.invalidateQueries(['students'])
      }
    }
  )
}
