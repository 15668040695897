import { useMutation } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import { endpoints } from '../../../constants'
import { getStudentDisplayName } from '../../../helpers'

export const useSendEmailStudentsScheduleMutation = ({
  dateSp,
  selectedStudentIds,
  studentsAndAppointments
}) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (userId) => {
      enqueueSnackbar('Sending Emails...', {
        variant: 'success'
      })

      const formattedDate = moment(dateSp).format('YYYY-MM-DD')
      let object = {}

      if (userId === undefined) {
        let array = []

        selectedStudentIds.forEach((id) => {
          array.push(id)
        })

        object = {
          numberOfDaysToRetrieve: 0,
          startDate: formattedDate,
          studentIds: array
        }
      } else {
        object = {
          numberOfDaysToRetrieve: 0,
          startDate: formattedDate,
          studentIds: [userId]
        }
      }

      await axios.post(`${endpoints.SEND_EMAIL}/studentschedule`, object, {
        withCredentials: true
      })

      return userId
    },
    {
      onSuccess: (userId) => {
        if (userId === undefined) {
          enqueueSnackbar('Emails Sent!', {
            variant: 'success'
          })
        } else {
          const studentDetails = studentsAndAppointments.find(
            (student) => student.userId === userId
          )
          const fullName = getStudentDisplayName(
            studentDetails.firstName,
            studentDetails.lastName,
            studentDetails.nickname
          )

          enqueueSnackbar(`Schedule was emailed to: ${fullName}`, {
            variant: 'success'
          })
        }
      }
    }
  )
}
