import React from 'react'

import { SubheadingContainer, SubheadingText } from '../shared'

const StudentSubheading = () => {
  return (
    <SubheadingContainer>
      <SubheadingText sx={{ gridColumn: '1/5' }} text='Name' />
      <SubheadingText
        sx={{ gridColumn: '5/7', textAlign: 'center' }}
        text='Exclude'
      />
    </SubheadingContainer>
  )
}

export default StudentSubheading
