import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import { endpoints } from '../../../constants'
import { getStudentRequestsQueryKey } from '../../../helpers'

export const useAppointmentRequestsAutoScheduleMutation = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ appointmentRequestId, studentDisplayName }) => {
      const response = await axios.post(
        `${endpoints.APPOINTMENT_REQUESTS}/autoschedule`,
        { id: appointmentRequestId },
        { withCredentials: true }
      )

      return { appointmentRequestId, data: response.data, studentDisplayName }
    },
    {
      onSuccess: ({ appointmentRequestId, data, studentDisplayName }) => {
        if (!data.appointmentWasCreated) {
          enqueueSnackbar(
            `${studentDisplayName} was unable to be auto-scheduled. There are no open appointment slots for the next 10 days from the requested date.`,
            {
              variant: 'error'
            }
          )

          return
        }

        enqueueSnackbar(
          `${studentDisplayName} was auto-scheduled on ${moment(
            data.appointmentDate
          ).format('MM/DD/YYYY')}.`,
          {
            variant: 'success'
          }
        )

        queryClient.setQueryData(
          getStudentRequestsQueryKey(),
          (prevAppointmentRequests) => {
            return prevAppointmentRequests.map((request) => {
              if (request.appointmentRequestId !== appointmentRequestId)
                return request
              return { ...request, isScheduled: true }
            })
          }
        )
      }
    }
  )
}
