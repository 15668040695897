import { lightBlue } from '@mui/material/colors'

const ES_BLUE = '#253686'
const ES_GREEN = '#039003'

export const esLight = {
  palette: {
    primary: { main: ES_BLUE },
    secondary: {
      main: ES_GREEN
    },
    error: { main: '#C90008' },
    success: { main: '#039003', dark: '#036903' },
    header: { background: '#fff' },
    nav: { background: '#eee', text: '#959292' },
    background: { default: '#fafafa', paper: '#fff' },
    link: { main: 'blue' },
    heading: { list: ES_BLUE },
    type: 'light'
  },
  typography: {
    fontSize: 14
  }
}

export const esDark = {
  palette: {
    primary: { main: ES_BLUE },
    secondary: {
      main: lightBlue[400]
    },
    error: { main: '#C90008' },
    success: { main: '#039003', dark: '#036903' },
    header: { background: ES_BLUE },
    nav: { background: '#424242', text: '#DBDBDE' },
    background: { default: '#303030', paper: '#424242' },
    link: { main: '#00A0FF' },
    heading: { list: '#fff' },
    type: 'dark'
  },
  typography: {
    fontSize: 15
  }
}

export const steelBlue = {
  palette: {
    primary: { main: ES_BLUE },
    secondary: {
      main: lightBlue[400]
    },
    error: { main: '#C90008' },
    success: { main: '#039003', dark: '#036903' },
    header: { background: '#293984' },
    nav: { background: '#93A3B1', text: '#E8E8EB' },
    background: { default: '#77878B', paper: '#849DB4' },
    link: { main: ES_BLUE },
    heading: { list: ES_BLUE },
    type: 'dark'
  },
  typography: {
    fontSize: 15
  }
}
