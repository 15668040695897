import React, { lazy, Suspense, useEffect, useRef } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { useNotifications } from '../context'
import { useInterval, usePageViews } from '../hooks'
import {
  AdjustedOfferingsScreen,
  AdvancedAppointmentsScreen,
  AppointmentsScreen,
  BlockDateScreen,
  BlockStudentsScreen,
  CreateAdjustedOfferingScreen,
  CreateBlockedDateScreen,
  CreateRosterScreen,
  DashboardScreen,
  DeleteMultipleAppointmentsScreen,
  ManageStudentGroupsScreen,
  MassAppointmentsScreen,
  MyAccountScreen,
  SchoolNotificationsScreen,
  StudentRequestsScreen,
  StudentsScreen,
  TakeAttendanceScreen,
  ValidateTokenScreen
} from '../components/screens'

import { RequireAdmin, returnIntervalTime } from '../components/Main'
import { StyledMain } from './styledComponents'

import { Loading } from '../components/shared'

const AdminRoutes = lazy(() => import('./AdminRoutes'))
const AnalysisScreen = lazy(() => import('../components/screens/Analysis'))
const StudentProfileScreen = lazy(() =>
  import('../components/screens/StudentProfile')
)

const LoggedInUserRoutes = () => {
  const location = useLocation()
  const { refetchNotifications } = useNotifications()
  const prevLocationRef = useRef()

  useEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      prevLocationRef.current = location.pathname

      refetchNotifications()
    }
  }, [location, refetchNotifications])

  useInterval(() => {
    refetchNotifications()
  }, returnIntervalTime())

  return (
    <Routes>
      <Route
        path='/admin/*'
        element={
          <Suspense fallback={<Loading text='Loading Admin Details' />}>
            <RequireAdmin>
              <AdminRoutes />
            </RequireAdmin>
          </Suspense>
        }
      />
      <Route exact path='/appointments' element={<AppointmentsScreen />} />
      <Route
        exact
        path='/advancedAppointments/:studentId/:date/:periodId/:studentName'
        element={<AdvancedAppointmentsScreen />}
      />
      <Route path='/students' element={<StudentsScreen />} />
      <Route
        exact
        path='/adjustedOfferings/:staffId'
        element={<AdjustedOfferingsScreen />}
      />
      <Route
        exact
        path='/adjustedSeries/:staffId/:seriesId'
        element={<AdjustedOfferingsScreen />}
      />
      <Route
        exact
        path='/analysis/:reportId'
        element={
          <Suspense fallback={<Loading text='Loading Analysis' />}>
            <AnalysisScreen />
          </Suspense>
        }
      />
      <Route exact path='/blockDate' element={<BlockDateScreen />} />
      <Route exact path='/blockStudents' element={<BlockStudentsScreen />} />
      <Route
        exact
        path='/createAdjustedOffering/:staffId/:adjustedOfferingId/:cameFromSeries'
        element={<CreateAdjustedOfferingScreen />}
      />
      <Route
        exact
        path='/createBlockedDate'
        element={<CreateBlockedDateScreen />}
      />
      <Route
        exact
        path='/createRoster/:rosterId/:stafferId'
        element={<CreateRosterScreen />}
      />
      <Route exact path='/dashboard' element={<DashboardScreen />} />
      <Route
        exact
        path='/deleteMultipleAppointments'
        element={<DeleteMultipleAppointmentsScreen />}
      />
      <Route
        exact
        path='/manageRosters'
        element={<ManageStudentGroupsScreen />}
      />
      <Route
        exact
        path='/massAppointments/:date/:staffId/:groupId/:periodId'
        element={<MassAppointmentsScreen />}
      />
      <Route exact path='/myAccount' element={<MyAccountScreen />} />
      <Route
        exact
        path='/schoolNotifications'
        element={<SchoolNotificationsScreen />}
      />
      <Route
        exact
        path='/studentProfile/:studentId/:studentName'
        element={
          <Suspense fallback={<Loading text='Loading Student Profile' />}>
            <StudentProfileScreen />
          </Suspense>
        }
      />
      <Route
        exact
        path='/studentRequests'
        element={<StudentRequestsScreen />}
      />
      <Route
        exact
        path='/takeAttendance/:attendanceDate'
        element={<TakeAttendanceScreen />}
      />
      <Route element={<ValidateTokenScreen />} exact path='/validateToken' />
      <Route exact path='/' element={<DashboardScreen />} />
    </Routes>
  )
}

const Main = () => {
  usePageViews()

  return (
    <StyledMain>
      <LoggedInUserRoutes />
    </StyledMain>
  )
}

export default Main
