import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import { endpoints } from '../../../constants'

export const useAppointmentsGetAppointmentsOnlyMutation = ({
  date,
  loggedInUserId,
  numberOfDaysToRetrieve,
  periodId,
  rosterId,
  searchTerm,
  studentIds
}) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ maxDateAdjusted }) => {
      const response = await axios.post(
        `${endpoints.APPOINTMENTS}/getappointmentsonly`,
        {
          maxDateAdjusted,
          numberOfDaysToRetrieve,
          periodId,
          rosterId:
            rosterId === null && searchTerm === null
              ? -1
              : rosterId === null
              ? 0
              : rosterId,
          searchTerm,
          stafferIdViewingAppointments: loggedInUserId,
          startDate: moment(date).format('YYYY-MM-DD'),
          studentIds
        },
        { withCredentials: true }
      )

      return {
        data: response.data,
        date,
        numberOfDaysToRetrieve,
        periodId,
        rosterId,
        searchTerm
      }
    },
    {
      onSuccess: ({
        data,
        date,
        numberOfDaysToRetrieve,
        periodId,
        rosterId,
        searchTerm
      }) => {
        enqueueSnackbar('Appointments Scheduled', {
          variant: 'success'
        })

        queryClient.setQueryData(
          [
            'appointments',
            'get',
            {
              numberOfDaysToRetrieve: parseInt(numberOfDaysToRetrieve),
              periodId: parseInt(periodId),
              rosterId: parseInt(rosterId),
              searchTerm,
              startDate: moment(date).format('YYYY-MM-DD')
            }
          ],
          (cachedAppointmentDetails) => {
            data.appointments.forEach((r) => {
              cachedAppointmentDetails.studentsAndAppointments =
                cachedAppointmentDetails.studentsAndAppointments.map(
                  (student) => {
                    if (student.userId !== r.studentId) return student
                    return {
                      ...student,
                      appointments: student.appointments.map((appointment) => {
                        if (appointment.appointmentDate !== r.appointmentDate)
                          return appointment
                        return {
                          ...r
                        }
                      })
                    }
                  }
                )
            })

            return {
              errorMessages: data.errorMessages,
              maxDateAdjusted: data.maxDateAdjusted,
              studentsAndAppointments:
                cachedAppointmentDetails.studentsAndAppointments
            }
          }
        )
      }
    }
  )
}
