import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { endpoints } from '../../../constants'
import { getMessageQueryKey, getMessagesQueryKey } from '../../../helpers'

export const fetchMessageDetails = async (messageId) => {
  const response = await axios.get(`${endpoints.MESSAGES}/${messageId}`, {
    withCredentials: true
  })

  return response.data
}

export const useMessagesByIdQuery = (messageId) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getMessageQueryKey(messageId),
    () => fetchMessageDetails(messageId),
    {
      enabled: parseInt(messageId) !== 0,
      onError: () => {
        // Invalidate cached Messages
        queryClient.invalidateQueries(getMessagesQueryKey())

        enqueueSnackbar(
          `We are unable to find this record, it's likely that it has been deleted by another user.`,
          {
            variant: 'error'
          }
        )
      },
      staleTime: 1000 * 60
    }
  )
}
