import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import { ROOT } from '../../constants'
import { getAdjustedOfferingQueryKey } from '../../helpers'

// Exporting to use for pre-fetching
export const fetchAdjustedOfferingDetails = async ({
  adjustedOfferingId,
  staffId
}) => {
  const response = await axios.get(
    `${ROOT}${staffId}/adjustedofferings/byId/${adjustedOfferingId}`,
    { withCredentials: true }
  )

  return response.data
}

export const useAdjustedOfferingQuery = ({ adjustedOfferingId, staffId }) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    getAdjustedOfferingQueryKey({ adjustedOfferingId, staffId }),
    () => fetchAdjustedOfferingDetails({ adjustedOfferingId, staffId }),
    {
      enabled: parseInt(adjustedOfferingId) !== 0,
      onError: () => {
        // Invalidate cached Adjusted Offerings
        queryClient.invalidateQueries(['adjusted-offerings'])

        enqueueSnackbar(
          `We are unable to find this record, it's likely that it has been deleted by another user.`,
          {
            variant: 'error'
          }
        )
      },
      staleTime: 1000 * 30
    }
  )
}
