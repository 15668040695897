import React, { createContext, useCallback, useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import { localStorageSchedulePageSettings } from '../../constants'
import { useLoggedInUser } from '../../context'
import { findMinProp, getParsedValueFromStringifiedObject } from '../../helpers'
import {
  useAppointmentsClearByIdMutation,
  useAppointmentsGetAppointmentsOnlyMutation,
  useAppointmentsGetQuery,
  useAppointmentsSaveMutation,
  useConfigurationQuery,
  useIsXsBreakpoint,
  useLocalStorage,
  usePeriodsMinimalActiveOnlyQuery,
  useRostersOnlyByStafferQuery,
  useSendEmailStudentsScheduleMutation,
  useStaffForListQuery
} from '../../hooks'

const AppointmentsContext = createContext()

const AppointmentsProvider = ({ children }) => {
  const isXs = useIsXsBreakpoint()
  const { isAdmin, isSubstitute, loggedInUserId } = useLoggedInUser()
  let [searchParams, setSearchParams] = useSearchParams()

  const [pageSettings, setPageSettings] = useLocalStorage(
    localStorageSchedulePageSettings,
    JSON.stringify({
      selectedNumberOfDays: 5,
      showSearchByName: true,
      showSelectedStaffer: true,
      useMinifiedView: false
    })
  )

  const numberOfDays = isXs
    ? 1
    : parseInt(
        getParsedValueFromStringifiedObject(
          'selectedNumberOfDays',
          pageSettings
        )
      )

  const dateSp =
    searchParams.get('date') === null ? moment() : searchParams.get('date')
  const periodIdSp = searchParams.get('periodId')
  const staffIdSp =
    searchParams.get('staffId') === null
      ? loggedInUserId
      : searchParams.get('staffId')
  const studentGroupIdSp = searchParams.get('studentGroupId')
  const searchValueSp = searchParams.get('searchValue')

  const {
    data: appointmentsDetails = {},
    isFetching: isFetchingAppointments,
    isLoading: isLoadingAppointments,
    selectedStudentIds,
    updateSelectedStudentIds
  } = useAppointmentsGetQuery({
    numberOfDaysToRetrieve: numberOfDays,
    periodId: periodIdSp,
    rosterId: studentGroupIdSp,
    searchTerm: searchValueSp,
    startDate: dateSp === null ? undefined : dateSp
  })

  const { data: staffers = [], isLoading: isLoadingStaffers } =
    useStaffForListQuery({
      includeDefaultStudentGroupId: true
    })

  const { errorMessages, maxDateAdjusted, studentsAndAppointments } =
    appointmentsDetails

  const { isLoading: isEmailingStudents, mutate: emailStudentSchedule } =
    useSendEmailStudentsScheduleMutation({
      dateSp,
      selectedStudentIds,
      studentsAndAppointments
    })

  const { configurations, isLoading: isLoadingConfigurations } =
    useConfigurationQuery()

  const { data: periods = [], isLoading: isLoadingPeriods } =
    usePeriodsMinimalActiveOnlyQuery({
      includeActiveOnly: true
    })

  const {
    isFetching: isFetchingAppointmentsAfterSave,
    isLoading: isLoadingAppointmentsAfterSave,
    mutate: getAppointmentsAfterSave
  } = useAppointmentsGetAppointmentsOnlyMutation({
    date: dateSp,
    loggedInUserId,
    numberOfDaysToRetrieve: numberOfDays,
    periodId: periodIdSp,
    rosterId: studentGroupIdSp,
    searchTerm: searchValueSp,
    studentIds: selectedStudentIds
  })

  const { isLoading: isClearingAppointments, mutate: clearAppointments } =
    useAppointmentsClearByIdMutation({
      numberOfDaysToRetrieve: numberOfDays,
      periodId: periodIdSp,
      rosterId: studentGroupIdSp,
      searchTerm: searchValueSp,
      startDate: dateSp === null ? undefined : dateSp,
      studentIds: selectedStudentIds
    })

  const { isLoading: isSavingAppointments, mutate: saveAppointments } =
    useAppointmentsSaveMutation()

  const updateSearchParamsByNameValue = useCallback(
    (e) => {
      const { name, value } = e.target

      // search clears when staffer changes or group change
      if (name === 'staffId' || name === 'studentGroupId') {
        searchParams.delete('searchValue')
      }

      // group clears when staffer changes or search changes
      if (name === 'searchValue') {
        searchParams.delete('studentGroupId')
      }

      // update the studentGroupId when the staff changes
      if (name === 'staffId') {
        const stafferInfo = staffers.find((staffer) => staffer.userId === value)
        searchParams.set('studentGroupId', stafferInfo.defaultStudentGroupId)
      }

      searchParams.set([name], value)

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams, staffers]
  )

  useEffect(() => {
    if (periodIdSp === null && periods.length > 0) {
      updateSearchParamsByNameValue({
        target: { name: 'periodId', value: findMinProp(periods, 'periodId') }
      })
    }
  }, [periodIdSp, periods, updateSearchParamsByNameValue])

  const { data: studentGroups = [], isLoading: isLoadingStudentGroups } =
    useRostersOnlyByStafferQuery({
      userId: staffIdSp
    })

  const updateSelectedDateByNumber = (number) => {
    let date = moment(dateSp)
    const dayOfWeek = moment(date).day()

    switch (number) {
      case 1:
        // if current day of week is Friday, go forward 3 days
        if (dayOfWeek === 5) {
          date = moment(date).add(3, 'days')
        } else {
          date = moment(date).add(1, 'days')
        }

        break
      case -1:
        // if current day of week is Monday, go back 3 days
        if (dayOfWeek === 1) {
          date = moment(date).subtract(3, 'days')
        } else {
          date = moment(date).subtract(1, 'days')
        }

        break
      case 7:
        date = moment(date).add(7, 'days')

        break
      case -7:
        date = moment(date).subtract(7, 'days')
        break
      default:
        break
    }

    updateSearchParamsByNameValue({
      target: { name: 'date', value: moment(date).format('YYYY-MM-DD') }
    })
  }

  return (
    <AppointmentsContext.Provider
      value={{
        clearAppointments,
        dateSp,
        emailStudentSchedule,
        getAppointmentsAfterSave,
        isAdmin,
        isClearingAppointments,
        isLoading:
          isFetchingAppointments ||
          isFetchingAppointmentsAfterSave ||
          isLoadingAppointments ||
          isLoadingAppointmentsAfterSave ||
          isLoadingConfigurations ||
          isLoadingPeriods ||
          isLoadingStaffers ||
          isLoadingStudentGroups,
        isSaving: isSavingAppointments,
        isSubstitute,
        isXs,
        loggedInUserId,
        maxDateAdjusted,
        numberOfDays,
        openSchedulePhrase: configurations.openSchedulePhrase,
        pageSettings,
        periodIdSp,
        periods,
        saveAppointments,
        searchValueSp,
        selectedStudentIds,
        setPageSettings,
        staffers,
        staffIdSp,
        studentGroupIdSp,
        studentGroups,
        studentsAndAppointments,
        updateSearchParamsByNameValue,
        updateSelectedDateByNumber,
        updateSelectedStudentIds
      }}>
      {children}
    </AppointmentsContext.Provider>
  )
}

const useAppointments = () => {
  const context = useContext(AppointmentsContext)

  if (!context) {
    throw new Error('useAppointments must be used with a AppointmentsProvider')
  }

  return context
}

export { AppointmentsContext, AppointmentsProvider, useAppointments }
