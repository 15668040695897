export const CHART_COLORS = [
  '#2F1A72',
  '#8BCF4D',
  '#EB8003',
  '#0E56B8',
  '#AA4488',
  '#0E9630',
  '#FF6699',
  '#5475D4',
  '#6600CC',
  '#5FAD07',
  '#00CCCC',
  '#1E3C8C',
  '#339966',
  '#F6AE5A',
  '#CC3300',
  '#3366FF',
  '#FF33CC',
  '#F6E419',
  '#4A4F8F',
  '#008E00'
]
