import React, { forwardRef } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { CalendarMonth } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import moment from 'moment'

import { useSchoolDatesStartAndEnd } from '../../hooks'

export const StyledDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  width: '100%',

  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    width: 250,

    marginRight: theme.spacing()
  }
}))

const EsDatePicker = forwardRef(
  (
    {
      className,
      dataTestId,
      disableWeekends = true,
      label,
      onChange,
      value,
      sx,
      ...otherProps
    },
    ref
  ) => {
    const {
      data = {
        endDate: '',
        startDate: ''
      }
    } = useSchoolDatesStartAndEnd()

    function shouldDisableWeekends(date) {
      return date._d.getDay() === 0 || date._d.getDay() === 6
    }

    return (
      <StyledDatePicker
        format='MM/DD/YYYY'
        disableMaskedInput={true}
        maxDate={moment(data.endDate)}
        minDate={moment(data.startDate)}
        label={label}
        onChange={onChange}
        shouldDisableDate={disableWeekends && shouldDisableWeekends}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <CalendarMonth
                  sx={{
                    fill: 'grey',
                    '&:hover': { cursor: 'pointer' }
                  }}
                />
              )
            }
          }
        }}
        sx={sx}
        value={moment(value)}
        {...otherProps}
      />
    )
  }
)

export default EsDatePicker
