import axios from 'axios'
import { useQuery } from 'react-query'

import { endpoints } from '../../../constants'
import { getSftpInfoQueryKey } from '../../../helpers/queryKeys'

export const useSftpInfoQuery = () => {
  return useQuery(
    getSftpInfoQueryKey(),
    async () => {
      const response = await axios.get(endpoints.SCHOOLS_FTP, {
        withCredentials: true
      })

      return response.data
    },
    {
      staleTime: 1000 * 60
    }
  )
}
