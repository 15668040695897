import { Box, CircularProgress, Typography } from '@mui/material'

import { useLoggedInUser } from '../../../context'
import { Button, ListItemText } from '../../shared'

import AppointmentDetailInfo from './AppointmentDetailInfo'

const RequestItemScheduledDetails = ({
  data,
  dateRequested,
  handleScheduleClick,
  hasAppointmentForRequestedDate,
  isLoading,
  mutate,
  periodId,
  selectedTab,
  setShowDetails,
  showDetails,
  userId
}) => {
  const { isAdmin, loggedInUserId } = useLoggedInUser()

  const canOverwriteAppointment =
    data !== undefined &&
    (isAdmin ||
      !data.isLocked ||
      loggedInUserId === data.adjustedById ||
      loggedInUserId === data.instructorId)

  return (
    <>
      {hasAppointmentForRequestedDate && selectedTab === 0 && (
        <>
          {data === undefined || !showDetails ? (
            <ListItemText
              sx={{
                gridColumn: '1/13'
              }}
              labelText='Requested Date:'>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <span
                  onClick={() => {
                    mutate({
                      periodId,
                      startDate: dateRequested,
                      studentIds: [userId]
                    })
                    setShowDetails(true)
                  }}
                  style={{
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer'
                  }}>
                  This student has been scheduled. Click for more details.
                </span>
              )}
            </ListItemText>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gridColumn: '1/13',
                  alignItems: 'top'
                }}>
                <AppointmentDetailInfo
                  description={`${data?.appointments[0]?.instructorLastName},
                            ${data?.appointments[0]?.instructorFirstName} /
                            ${data?.appointments[0]?.instructorRoom}`}
                  label='Instructor / Room'
                />
                <AppointmentDetailInfo
                  description={`${data?.appointments[0]?.adjustedByLastName},
                          ${data?.appointments[0]?.adjustedByFirstName}`}
                  label='Scheduler'
                />
                <AppointmentDetailInfo
                  borderRight={false}
                  description={
                    data?.appointments[0]?.appointmentTypeDescription
                  }
                  label='Appointment Type'
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {canOverwriteAppointment && (
                    <Button
                      onClick={handleScheduleClick}
                      label='Schedule'
                      sx={{
                        maxWidth: '100px !important',
                        height: 40,
                        marginRight: 2
                      }}
                    />
                  )}
                  <Button
                    esButtonClass='greyButton'
                    onClick={() => setShowDetails(false)}
                    label='Hide'
                    sx={{
                      maxWidth: '100px !important',
                      height: 40
                    }}
                  />
                </Box>
              </Box>
              {!canOverwriteAppointment && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gridColumn: '1/13',
                    alignItems: 'top'
                  }}>
                  <Typography>
                    <b>Please Note:</b> This appointment cannot be overwritten.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default RequestItemScheduledDetails
