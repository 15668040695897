import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Collapse, ListItem } from '@mui/material'
import {
  BugReport,
  Close,
  ExpandLess,
  ExpandMore,
  PieChart,
  Settings
} from '@mui/icons-material'

import { adminLinks, stafferLinks, subLinks } from '../constants'
import { useLoggedInUser } from '../context'
import { useLocalStorage } from '../hooks'
import bgImg from '../images/menu_graphic.png'
import logo from '../images/es_logo_icon.png'

import { NavMenuLink, SubNavMenuLink } from '../components/Navigation'
import LoggedInUserDetails from './LoggedInUserDetails'
import { ExternalLink, FlexColumn } from '../components/shared'

import {
  StyledDrawer,
  StyledDrawerContainer,
  StyledListItemIcon,
  StyledListItemText,
  StyledNav,
  StyledNavLogo,
  StyledPatternImage
} from './styledComponents'

const Navigation = ({
  container,
  mobileMenuOpen,
  schools,
  selectedSchool,
  setMobileMenuOpen,
  updateSelectedSchool
}) => {
  const { pathname } = useLocation()
  const [adminMenuOpen, setAdminMenuOpen] = useLocalStorage(
    'enriching-students:admin-menu-open',
    false
  )

  const { isAdmin, isSubstitute, logout } = useLoggedInUser()

  const adminLinksForMobile = adminLinks.filter((link) => !link.hideOnMobile)

  const handleAdminClick = () => {
    setAdminMenuOpen(!adminMenuOpen)
  }

  const DrawerContent = (
    <StyledDrawerContainer>
      <div>
        <div>
          {isSubstitute ? (
            <>
              {subLinks.map((link) => (
                <NavMenuLink
                  key={link.to}
                  label={link.label}
                  onClick={() => setMobileMenuOpen(false)}
                  Icon={link.icon}
                  pathName={pathname}
                  to={link.to}
                />
              ))}
            </>
          ) : (
            <>
              {stafferLinks.map((link) => (
                <NavMenuLink
                  key={link.to}
                  label={link.label}
                  onClick={() => setMobileMenuOpen(false)}
                  Icon={link.icon}
                  pathName={pathname}
                  to={link.to}
                />
              ))}
            </>
          )}
          {!isSubstitute && (
            <NavMenuLink
              label='Analysis'
              onClick={() => setMobileMenuOpen(false)}
              Icon={<PieChart />}
              pathName={pathname}
              to='/analysis/0'
            />
          )}

          {isAdmin && (
            <>
              <ListItem button onClick={handleAdminClick}>
                <StyledListItemIcon>
                  <Settings />
                </StyledListItemIcon>
                <StyledListItemText
                  primary='Admin'
                  style={{ paddingLeft: 0 }}
                />
                <StyledListItemIcon>
                  {adminMenuOpen ? <ExpandMore /> : <ExpandLess />}
                </StyledListItemIcon>
              </ListItem>
              <Collapse in={adminMenuOpen}>
                <Box
                  sx={{
                    display: { xs: 'none', sm: 'block' }
                  }}>
                  {adminLinks.map((link) => (
                    <SubNavMenuLink
                      key={link.to}
                      label={link.label}
                      onClick={() => setMobileMenuOpen(false)}
                      to={link.to}
                    />
                  ))}
                </Box>
                <Box
                  sx={{
                    display: { xs: 'block', sm: 'none' }
                  }}>
                  {adminLinksForMobile.map((link) => (
                    <SubNavMenuLink
                      key={link.to}
                      label={link.label}
                      onClick={() => setMobileMenuOpen(false)}
                      to={link.to}
                    />
                  ))}
                </Box>
              </Collapse>
              <ListItem>
                <StyledListItemIcon>
                  <BugReport />
                </StyledListItemIcon>
                <ExternalLink
                  href='https://enrichingstudents.zendesk.com/hc/en-us/requests/new'
                  sx={{ color: '#959292 !important', textDecoration: 'none' }}>
                  Submit a Support Ticket
                </ExternalLink>
              </ListItem>
            </>
          )}
          <ListItem button onClick={() => logout()}>
            <StyledListItemIcon>
              <Close />
            </StyledListItemIcon>
            <StyledListItemText primary='Logout' style={{ paddingLeft: 0 }} />
          </ListItem>
        </div>
      </div>
      <StyledPatternImage
        adminMenuOpen={adminMenuOpen}
        alt='Decorative pattern'
        src={bgImg}
      />
    </StyledDrawerContainer>
  )

  return (
    <StyledNav>
      {/* For mobile devices */}
      <StyledDrawer
        sx={{ display: { xl: 'none' } }}
        container={container}
        variant='temporary'
        anchor='left'
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}>
        <FlexColumn>
          <StyledNavLogo alt='Enriching Students' src={logo} />
          <LoggedInUserDetails
            schools={schools}
            selectedSchool={selectedSchool}
            updateSelectedSchool={updateSelectedSchool}
          />
        </FlexColumn>
        {DrawerContent}
      </StyledDrawer>
      {/* For larger devices */}
      <StyledDrawer
        variant='permanent'
        open
        sx={{ display: { xs: 'none', lg: 'block' } }}>
        {DrawerContent}
      </StyledDrawer>
    </StyledNav>
  )
}

export default Navigation
