import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import { sortArrayByLastNameNickNameFirstName } from '../../../helpers'

export const useStudentsQuery = ({ key, value }) => {
  return useQuery(
    ['students', key, value],
    async () => {
      let arg =
        key === 'selectedGraduationYear'
          ? 'filterbygraduationyear'
          : key === 'selectedLastName'
          ? 'filterbylastnamestartswith'
          : key === 'selectedStaffer'
          ? 'filterbyhomeroomstaffer'
          : key === 'searchTerm'
          ? 'filterbyeithernamestartswith'
          : ''

      const response = await axios.get(
        `${endpoints.STUDENTS}/${arg}/${value}`,
        { withCredentials: true }
      )

      return sortArrayByLastNameNickNameFirstName(response.data)
    },
    {
      enabled: !!key && !!value,
      staleTime: 1000 * 60 // 1 minute
    }
  )
}
