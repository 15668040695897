import { useQuery } from 'react-query'
import axios from 'axios'

import { endpoints } from '../../../constants'
import {
  getSubstitutesQueryKey,
  sortArrayByLastNameFirstName
} from '../../../helpers'

export const useSubstitutesQuery = () => {
  return useQuery(
    getSubstitutesQueryKey(),
    async () => {
      const response = await axios.get(endpoints.SUBSTITUTES, {
        withCredentials: true
      })

      response.data = sortArrayByLastNameFirstName(response.data)

      return response.data
    },
    {
      staleTime: 1000 * 30
    }
  )
}
